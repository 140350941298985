import React, { useState } from "react";
import Modal from "@/components/Modal";
import SuccessFullyDeletedModal from "../SuccessFullyDeletedModal";
import { useTranslation } from "react-i18next";
import Loader from "@/components/Loader";
import { ImSpinner9 } from "react-icons/im";
import clsx from "clsx";
import { FiAlertTriangle } from "react-icons/fi";

type DeleteAccountModal = {
  visible: boolean;
  title: string;
  isLoading?: boolean;
  onClose: () => void;
  onDelete: () => void;
};
const DeleteAccountModal = ({
  visible,
  title,
  onClose,
  onDelete,
  isLoading
}: DeleteAccountModal) => {
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const { t } = useTranslation('translation');

  return (
    <>
      <Modal
        classWrap="max-w-[30.188rem] rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:top-5 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visible}
        onClose={onClose}
      >
          <h1 className="h4 p-6">{title}</h1>
        <div className="pb-4 flex flex-col justify-center items-center max-w-[330px] m-auto ">
          <p className="text-sm mb-7 text-accent-1 bg-accent-1 bg-opacity-10 flex gap-3 items-center p-3 rounded-md">
            <span className="text-lg !font-mont"><FiAlertTriangle /></span>
            {t('dell')}
          </p>
        </div>
        <div className="flex items-center gap-4 w-full px-6 mb-3">
            <button className='px-8 py-3 rounded-xl bg-transparent truncate hover:bg-accent-1 text-n-4 hover:text-white duration-500 border border-accent-1 w-full' onClick={onClose}>{t('cancel_btn')}</button>
            <button className='px-8 py-3  rounded-xl border truncate border-accent-1 bg-accent-1 hover:bg-transparent text-white hover:text-n-4 w-full'
              disabled={isLoading || false}
              onClick={onDelete}>
              {isLoading ? <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-primary-1">
                <ImSpinner9 className={clsx("animate-spin text-sm")} />
              </div> : title}
            </button>
        </div>
      </Modal>
      <SuccessFullyDeletedModal
        visible={visibleModal}
        onClose={() => {
          setVisibleModal(false);
        }}
      />
    </>
  );
};
export default DeleteAccountModal;
