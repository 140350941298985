import React, { useEffect, useState } from "react";

const orientations = [
  { id: "one", value: "1:1", size: "1024 x 1024", styles: "w-[18px] h-[18px]" },
  { id: "two", value: "4:3", size: "1024 x 768", styles: "w-[18px] h-[14px]" },
  {
    id: "three",
    value: "3:4",
    size: "768 x 1024",
    styles: "w-[14px] h-[18px]",
  },
  {
    id: "four",
    value: "16:9",
    size: "1280 x 720",
    styles: "w-[18px] h-[10px]",
  },
  {
    id: "five",
    value: "9:16",
    size: "720 x 1280",
    styles: "w-[10px] h-[18px]",
  },
];

const newOrientations = [
  { id: "one", value: "1:1", size: "1024 x 1024", styles: "w-[18px] h-[18px]" },
  { id: "two", value: "4:5", size: "853 x 1333", styles: "w-[18px] h-[22px]" },
  {
    id: "three",
    value: "2:3",
    size: "716 x 1612",
    styles: "w-[18px] h-[27px]",
  },
  { id: "four", value: "4:7", size: "605 x 1853", styles: "w-[18px] h-[31px]" },
  { id: "five", value: "7:4", size: "1853 x 605", styles: "w-[31px] h-[18px]" },
  { id: "six", value: "3:2", size: "1612 x 716", styles: "w-[27px] h-[18px]" },
  {
    id: "seven",
    value: "5:4",
    size: "1792 x 1344",
    styles: "w-[22px] h-[18px]",
  },
];

const ResponseImageSelect = (props: any) => {
  const [orio, setOrio] = useState(orientations);

  useEffect(() => {
    if (props?.model === 2) {
      setOrio(newOrientations);
    } else {
      setOrio(orientations);
    }
  }, [props?.model]);

  return (
    <div className="mb-4">
      <div
        className={`py-2 bg-n-16 ${!props.fromFeature && "dark:bg-n-5"} grid ${
          props?.model === 2 ? "grid-cols-7" : "grid-cols-5"
        } rounded-xl`}
      >
        {orio.map((item: any, index: number) => (
          <div
            className={`border-r border-n-3 ${
              !props.fromFeature && "dark:border-n-6"
            } px-3 sm:px-1`}
            key={index}
          >
            <input
              onClick={() =>
                props.handleOrientationChange(item.value, item.size)
              }
              type="radio"
              id={item.id}
              name="hosting"
              value={item.id}
              className="hidden peer"
              checked={props.orientation === item.value}
            />
            <label
              className={`h-8 relative ${
                !props.fromFeature &&
                "dark:border-transparent dark:hover:border-n-8 dark:border-n-8 dark:peer-checked:border-n-8 dark:peer-checked:bg-n-6 dark:hover:bg-n-6"
              } cursor-pointer flex justify-center items-center border border-transparent  hover:border-n-29  border-n-29  peer-checked:border-n-29  rounded-lg bg-transparent hover:bg-white  peer-checked:bg-white `}
              htmlFor={item.id}
            >
              <div
                className={` ${item.styles} border-2 border-black ${
                  !props.fromFeature && " dark:border-n-4"
                } rounded-[3px]`}
              ></div>
              <span className="absolute !font-mont hidden text-xs text-white bg-black rounded-md px-2 py-1 bottom-10 left-1/2 transform -translate-x-1/2 whitespace-nowrap peer-hover:block">
                {item.value}
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResponseImageSelect;
