import { createAsyncThunk } from "@reduxjs/toolkit";
import { notificationsService } from "services/notifications.service";
import { IUpdateNotificationsList } from "interfaces/notifications.interface";

export const updateNotificationsList = createAsyncThunk(
  "notificationStore/updateNotificationsList",
  async (data: IUpdateNotificationsList) => {
    try {
      const response = await notificationsService.update(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);export const getMineNotifications = createAsyncThunk(
  "notificationStore/getAllNotifications",
  async () => {
    try {
      const response = await notificationsService.index();
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const readNotificationsList = createAsyncThunk(
  "notificationStore/read",
  async (data: any, {dispatch}) => {
    try {
      const response = await notificationsService.read(data);
      await dispatch(getMineNotifications())
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
