import { HttpService } from './base.service'
import { IChatData, IChatFeedbackData, IRegenerateChatData, IShowChatData, IUpdateChatListData } from '../interfaces/chat.interface'

class ChatService extends HttpService {
  private readonly prefix: string = "chats";

  promptAdvisor = (): Promise<any> => this.get(this.prefix + '/promptAdvisor');  
  index = (params: any): Promise<any> => this.get(this.prefix + `/index?search=${params?.search}&page=${params.page}&perPage=${params.perPage}&filter=${params?.filter}`);

  store = (data: IChatData, signal: any): Promise<any> => this.post(this.prefix + '/store', data, signal);
  show = (data: IShowChatData): Promise<any> => this.get(this.prefix + `/show/${data.chatId}`);
  reStore = (data: IRegenerateChatData, signal: any): Promise<any> => this.post(this.prefix + '/re-store', data, signal);
  updateFeedback = (data: IChatFeedbackData): Promise<any> => this.post(this.prefix + '/feedback', data);
  updateChatList = (data: IUpdateChatListData): Promise<any> => this.post(this.prefix + '/update-chat-list', data);
  storeGoal = (data: IChatData): Promise<any> => this.post(this.prefix + '/store-goal', data);
  deleteMessage = (data: any): Promise<any> => this.post(this.prefix + '/delete-message', data);
  stopMessageStream = (data: any): Promise<any> => this.post(this.prefix + '/stop-stream', data);
  updateMessage = (data: any): Promise<any> => this.post(this.prefix + '/update-message', data);
  storeStreamMessage = (data: any): Promise<any> => this.post(this.prefix + '/store-stream-message', data);
  updateStreamMessage = (data: any): Promise<any> => this.post(this.prefix + '/update-stream-message', data);

}

export const chatService = new ChatService();