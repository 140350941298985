import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import Icon from "../Icon";
import Switch from "../Switch";
import Field from "../Field";
import Calendar from "../Calendar";
import Select from "../Select";
import { ICreateGoalData, IGetGoalDataParams, IUpdateGoalData } from "interfaces/goal.interface";
import { createGoalHandler, getAllGoals, updateGoalById } from "store/actions/goal.actions";
import { useDispatch, useSelector } from "react-redux";
import { createGoalSchema } from "lib/validations/goals";
import { isEmpty } from "lodash";
import { BiErrorCircle } from "react-icons/bi";
import { formattedDateString } from "lib/DateTime";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import { resetCreateGoal, resetUpdatedGoal, setSelectedGoal } from "store/slices/GoalSlice";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { GOAL_STATUS } from "@/constants/goals";
import { RES_PER_PAGE } from "@/constants/pagination";
import { IoIosInformationCircleOutline } from "react-icons/io";

interface IInitialErrors {
  name: string;
  dueOnDate: string;
  remindersFrequency?: string;
}

const initialErrors: IInitialErrors = {
  name: "",
  dueOnDate: "",
  remindersFrequency: "",
};

type GoalSideBarProps = {
  className?: string;
  visible?: boolean;
  edit?: any;
  setEdit?: any;
  selectedItem?: any;
  setSelectedItem?: any;
  setaddGoalSide?: any;
};
const GoalManagementSideBar = ({
  className,
  visible,
  edit,
  setEdit,
  selectedItem,
  setSelectedItem,
  setaddGoalSide
}: GoalSideBarProps) => {
  const dispatch: any = useDispatch();
  const { t:lt } = useTranslation('translation');

  const goalStates = useSelector((state: any) => state.goalStore);

  const reminderFrequencyOptions = [
    {
      id: "0",
      title: `1 ${lt('hour_before')}`,
      value: '1hour',
      seconds:60*60
    },
    {
      id: "1",
      title: `2 ${lt('hour_before')}`,
      value: '2hours',
      seconds:120*60
    },
    {
      id: "2",
      title: `4 ${lt('hour_before')}`,
      value: '4hours',
      seconds:240*60
    },
    {
      id: "3",
      title: `6 ${lt('hour_before')}`,
      value: '6hours',
      seconds:360*60
    },
    {
      id: "4",
      title: `1 ${lt('day_before')}`,
      value: '1day',
      seconds:1440*60
    },
    {
      id: "5",
      title: `1 ${lt('week_before')}`,
      value: '1week',
      seconds:10080*60
    },
    {
      id: "6",
      title: `2 ${lt('week_before')}`,
      value: '2weeks',
      seconds:20160*60
    },
  ];

  const { createGoal, updatedGoal, deletedGoal } = goalStates;

  const [notificationsReminder, setNotificationsReminder] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [dueOnDate, setDueOnDate] = useState<any>('');
  const [selectedFrequency, setSelectedFrequency] = useState<any>(null);
  const [keyPoint1, setKeyPoint1] = useState<any>('');
  const [keyPoint2, setKeyPoint2] = useState<any>('');
  const [keyPoint3, setKeyPoint3] = useState<any>('');
  const [errors, setErrors] = useState<IInitialErrors>(initialErrors);
  
  useEffect(()=>{
    try {
      if(!isEmpty(selectedItem)) {
        setName(selectedItem?.name);
        setDueOnDate(selectedItem?.dueOnDate);
        setKeyPoint1(selectedItem?.keyPoint1);
        setKeyPoint2(selectedItem?.keyPoint2);
        setKeyPoint3(selectedItem?.keyPoint3);
        const frequency = reminderFrequencyOptions.find((frequency: any) =>frequency.value===selectedItem.reminderFrequency)
        setSelectedFrequency(frequency);
      }
    } catch (error) {
      
    }
    
  }, [selectedItem])

  useEffect(() => {
    if (!isEmpty(createGoal.error)) {
      toast(
        (t) => (
          <Notify iconDelete>
            <div className="h6 sm:text-sm">{createGoal.error}</div>
          </Notify>
        ),
        {
          duration: 1400,
        }
      );
      setTimeout(() => {
        dispatch(resetCreateGoal(null)); 
      }, 1500)
    }
    if (!isEmpty(createGoal.data) && !createGoal.loading) {
        toast(
          (t) => (
            <Notify iconCheck>
              <div className="h6 sm:text-sm">{lt('goal_created')}</div>
            </Notify>
          ),
          { duration: 1000 }
        );
        setTimeout(() => {
          dispatch(resetCreateGoal(null));
          handleCancelGoal();
        }, 1100)
      }
  }, [createGoal]);

  useEffect(() => {
    if (!isEmpty(updatedGoal.error)) {
      toast(
        (t) => (
          <Notify iconDelete>
            <div className="h6 sm:text-sm">{updatedGoal.error}</div>
          </Notify>
        ),
        { duration: 1000 }
      );
      setTimeout(() => {
        dispatch(resetUpdatedGoal(null));
        handleCancelGoal();
      }, 1100)
    }
    if (!isEmpty(updatedGoal.data) && !updatedGoal.loading) {
        toast(
          (t) => (
            <Notify iconCheck>
              <div className="h6 sm:text-sm">{lt('goal_updated')}</div>
            </Notify>
          ),
          { duration: 1000 }
        );
        setTimeout(() => {
          dispatch(resetUpdatedGoal(null));
          handleCancelGoal();
        }, 1100)
      }
  }, [updatedGoal]);

  useEffect(() => {
    if (!isEmpty(deletedGoal.error) || !isEmpty(deletedGoal.data)) {
      setTimeout(() => {
        handleCancelGoal();
      }, 10);
    }
  }, [deletedGoal]);

  const handleNotificationsChange = () => {
    setNotificationsReminder(!notificationsReminder);
  };

  const handleAddGoal = async () => {
    try {
      const data: ICreateGoalData = {
        name,
        keyPoint1,
        keyPoint2,
        keyPoint3,
        dueOnDate: dueOnDate,
        notificationsReminder: notificationsReminder,
      };
      if (notificationsReminder) {
        data.reminderFrequency = selectedFrequency.value;
      }
      let fieldErrors: any;
  
      await createGoalSchema
        .validate({ name, dueOnDate }, { abortEarly: false })
        .catch((error: any) => {
          fieldErrors = error.inner.reduce((acc: any, err: any) => {
            return {
              ...acc,
              [err.path]: err.message,
            };
          }, {});
  
          setErrors(fieldErrors);
        });
  
      if (notificationsReminder && !selectedFrequency?.value) {
        setErrors({
          name: fieldErrors.name || "",
          dueOnDate: fieldErrors.dueOnDate || "",
          remindersFrequency: lt('req_remindFreq'),
        });
      }
  
      if (isEmpty(fieldErrors)) {
        data.dueOnDate = formattedDateString(data.dueOnDate);
        setErrors(initialErrors);
        dispatch(createGoalHandler(data)).unwrap().then((response: any) => {
          if(response.data?.status === GOAL_STATUS.IN_PROGRESS) {
            const inProgressGoalParams: IGetGoalDataParams = {
              page: 1,
              perPage: RES_PER_PAGE,
              status: GOAL_STATUS.IN_PROGRESS
            };
            dispatch(getAllGoals(inProgressGoalParams));
          }
        });
      }
    } catch (error) {
      
    }
    
  };

  const handleUpdateGoal = async () => {
    try {
      const data: IUpdateGoalData = {
        name,
        dueOnDate: dueOnDate,
        goalId: selectedItem.id,
        keyPoint1: keyPoint1,
        keyPoint2: keyPoint2,
        keyPoint3: keyPoint3,
        notificationsReminder: notificationsReminder,
      };
      if (notificationsReminder) {
        data.reminderFrequency = selectedFrequency.value;
      }
      let fieldErrors: any;
  
      await createGoalSchema
        .validate({ name, dueOnDate }, { abortEarly: false })
        .catch((error: any) => {
          fieldErrors = error.inner.reduce((acc: any, err: any) => {
            return {
              ...acc,
              [err.path]: err.message,
            };
          }, {});
  
          setErrors(fieldErrors);
        });
  
      if (notificationsReminder && !selectedFrequency?.value) {
        setErrors({
          name: fieldErrors.name || "",
          dueOnDate: fieldErrors.dueOnDate || "",
          remindersFrequency: lt('req_remindFreq'),
        });
      }
  
      if (isEmpty(fieldErrors)) {
        data.dueOnDate = formattedDateString(data.dueOnDate);
        setErrors(initialErrors);
        dispatch(updateGoalById(data));
      }
    } catch (error) {
      
    }
    
  };


  const isAllowToCreate = () => {
    if (notificationsReminder && !selectedFrequency?.value) {
      return true;
    }
    if (!name || !dueOnDate || createGoal.loading || !keyPoint1) {
      return true;
    }
    return false;
  };
  const isAllowToUpdate = () => {
    if (notificationsReminder && !selectedFrequency?.value) {
      return true;
    }
    if (!name || !dueOnDate || updatedGoal.loading || !keyPoint1) {
      return true;
    }
    return false;
  };

  const handleCancelGoal = () => {
    setEdit(false);
    setSelectedItem(null);
    setName('');
    setDueOnDate('');
    setKeyPoint1('');
    setKeyPoint2('');
    setKeyPoint3('');
    setSelectedFrequency(null);
    dispatch(setSelectedGoal(null))
  }

  const isDateInPast = (inputDate: any) => {
    // Convert the input date string to a Date object
    const givenDate = new Date(inputDate);

    // Get the current date and time
    const currentDate = new Date();

    // Compare the two dates
    return givenDate < currentDate;
}

  return (
    <div
      className={twMerge(`p-5 pt-16 absolute overflow-auto  top-0 right-0 bottom-0 flex flex-col  pb-8 bg-n-1 duration-500 rounded-r-[1.25rem] border-l border-n-3 shadow-[inset_0_1.5rem_3.75rem_rgba(0,0,0,0.1)]  lg:rounded-[1.25rem] lg:invisible lg:opacity-0 lg:transition-opacity lg:z-20 lg:border-l-0 lg:shadow-2xl md:fixed  md:border-l md:rounded-none dark:bg-n-6 dark:border-n-5 ${
          visible && "lg:visible lg:opacity-100"
        } ${
          edit ? "w-[31.875rem] 2xl:w-[25rem] md:w-[calc(100%-4rem)] opacity-1" : "w-0 opacity-0"
        } ${className}`
      )}
    >
      <span
        className="absolute !font-mont right-5 top-5 lg:hidden"
        onClick={handleCancelGoal}
      >
        <Icon
          className="fill-n-4 transition-colors hover:fill-accent-1 cursor-pointer"
          name={"close"}
        />
      </span>

      <div className="flex flex-col justify-between h-full">
        <div className="">
        {selectedItem?.name ? (
          <h1 className="h4 mb-6">{lt('update_goal')}</h1>
        ) : (
          <h1 className="h4 mb-6">{lt('add_goal')}</h1>
        )}
        <div className="mb-4">
          <label className="font-semibold text-sm mb-2 block">{lt('goal_name')}</label>
          
          <Field
          className="mb-4"
          classInput={`dark:!text-white bg-n-3/75 dark:bg-n-5/50 text-[#6C7275] dark:text-white ${
            errors.name ? 'border-red-500' : ''
          }`}
          placeholder={lt('enter_goal')}
          type="text"
          value={name}
          onChange={(e: any) => {
            const newValue = e.target.value;
            if (newValue.length <= 25) {
              setName(newValue);
              setErrors({ ...errors, name: '' });
            } else {
              setErrors({ ...errors, name: lt('character_valid') });
            }
          }}
          required
        />
          {errors.name && (
            <div className="text-red-500 text-xs mt-1 flex items-center gap-1">
              {" "}
              <BiErrorCircle className="text-base" /> {errors.name}
            </div>
          )}
        </div>
        <div className="mb-4">
          <Calendar
            setDate={(date: string) => {
              setDueOnDate(date);
              setErrors(initialErrors);
              setSelectedFrequency(null)
            }}
            date={dueOnDate}
            minDate={dayjs().add(1, 'day').toDate()}
          />
          {(errors && errors.dueOnDate) && (
            <div className="text-red-500 text-xs mt-1 flex items-center gap-1">
              {" "}
              <BiErrorCircle className="text-base" /> {errors.dueOnDate}
            </div>
          )}
        </div>
        <div className="py-4 flex !font-mont justify-between gap-4 items-center flex-wrap mb-4">
          <span className="text-sm font-semibold !font-mont">
            {lt('notifi_remind')}
          </span>
          <Switch
            value={notificationsReminder}
            setValue={handleNotificationsChange}
          />
        </div>

        {notificationsReminder && (
          <div className="mb-4">
            <Select
              items={reminderFrequencyOptions.filter((i:any)=>{
                const selectedDate = new Date(dueOnDate).getTime();
                const differenceFromNow = selectedDate - new Date().getTime();
                return i?.seconds < Number(differenceFromNow)/1000;
              })}
              value={selectedFrequency}
              onChange={(value: any) => {
                setSelectedFrequency(value);
                setErrors(initialErrors);
              }}
              disabled={isDateInPast(dueOnDate)}
              placeholder={lt('select_freq')}
              label={lt('remind_freq')}
              classButton="px-3"
              classIcon="w-5 h-5 fill-n-4"
            />
            {errors.remindersFrequency && (
              <div className="text-red-500 text-xs mt-1 flex items-center gap-1">
                {" "}
                <BiErrorCircle className="text-base" />{" "}
                {errors.remindersFrequency}
              </div>
            )}
          </div>
        )}
        <div>
          <p className="text-sm !font-mont font-semibold mb-2 flex">{lt('goals3_keyPoint')}
          <span className="text-n-4">
          <IoIosInformationCircleOutline />
          </span>
          </p>
            <Field classInput="mb-2" label="" className="" value={keyPoint1} onChange={(e: any)=>setKeyPoint1(e.target.value)} placeholder={lt('mandatory')} />
            <Field classInput="mb-2" label="" className="" value={keyPoint2} onChange={(e: any)=>setKeyPoint2(e.target.value)} placeholder={lt('optional')} />
            <Field classInput="" label="" className="" value={keyPoint3} onChange={(e: any)=>setKeyPoint3(e.target.value)} placeholder={lt('optional')} />
        </div>
        </div>
        <div className="mt-9 flex gap-4">
          <button
            disabled={createGoal.loading || updatedGoal.loading}
            onClick={handleCancelGoal}
            className="btn-stroke-light w-full"
          >
            {lt('cancel_btn')}
          </button>
          {selectedItem?.name ? (
            <button
              disabled={isAllowToUpdate()}
              onClick={handleUpdateGoal}
              className="btn-blue w-full">{lt('update_btn')}
            </button>
          ) : (
            <button
              disabled={isAllowToCreate()}
              onClick={handleAddGoal}
              className="btn-blue w-full"
            >
              {lt('add_goal')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoalManagementSideBar;
