import { HttpService } from './base.service'
import { IPhotoGenerateData, IRegeneratePhotoData } from '../interfaces/imageGeneration.interface'

class PhotoGenerateService extends HttpService {
  private readonly prefix: string = "photo-generation";
  
  store = (data: IPhotoGenerateData, signal: any): Promise<any> => this.post(this.prefix + '/store', data, signal);
  generate = (data: IPhotoGenerateData, signal: any): Promise<any> => this.post(this.prefix + '/generate', data, signal);
  edit = (data: any, signal: any): Promise<any> => this.post(this.prefix + '/edit', data, signal);
  update = (data: IRegeneratePhotoData, signal: any): Promise<any> => this.post(this.prefix + '/update', data, signal);
  caption = (data: any): Promise<any> => this.post(this.prefix + '/caption', data);
}

export const photoGenerateService = new PhotoGenerateService();