import * as yup from "yup";

export const ProfileSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Z0-9\s]+$/, "Name should only contain letters and numbers")
    .required("Name is required")
    .max(25, "Name should not exceed 25 characters"),
    bio: yup.string().optional()
    .max(200, "Bio should not exceed 200 characters"),
});