import { IDeleteUserProfile } from 'interfaces/profile.interface';
import { HttpService } from './base.service';

class ProfileService extends HttpService {
  private readonly prefix: string = "profile";

  update = (data: any): Promise<any> => this.put(this.prefix + '/update', data);
  updatePassword = (data: any): Promise<any> => this.put(this.prefix + '/update-password', data);

  destroyUser = (data: IDeleteUserProfile): Promise<any> => this.post(this.prefix + '/destroyUser', data); 
  updateUserName = (data: any): Promise<any> => this.post(this.prefix + '/update-user-name', data);
  updateTourStatus = (data: any): Promise<any> => this.post(this.prefix + '/update-tour-status', data);
}

export const profileService = new ProfileService();