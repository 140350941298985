import { createAsyncThunk } from "@reduxjs/toolkit";
import { photoGenerateService } from "services/imageGeneration.service";
import { IPhotoGenerateData, IRegeneratePhotoData } from "interfaces/imageGeneration.interface";

export const createPhotoGenerate = createAsyncThunk(
  "photoGenerate/generatePhoto",
  async (data: IPhotoGenerateData, { signal }) => {
    
    let baseImages: any[] = [];
    if (data?.images?.length) {
      try {
        for (let item of data.images) {
          const temp: any = await toBase64(item);
          baseImages.push(temp);
        }
        data.images = baseImages;
      } catch (e) {
        console.log("eE", e);
      }
    }
    data.prompt = data.prompt.trim()
    data.prompt = data.prompt.replace(/^[^\w\s]+/, '');
    if (data.prompt.length < 3)  {
      throw 'Prompt length must be three characters'
    }

    if (data?.negativePrompt?.length) {
      data.negativePrompt = data.negativePrompt.trim();
      data.negativePrompt = data.negativePrompt.replace(/^[^\w\s]+/, '');
    }
    try {
      const response = await photoGenerateService.store(data, { signal });
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const createPhotoGenerate2 = createAsyncThunk(
  "photoGenerate/generatePhoto2",
  async (data: IPhotoGenerateData, { signal }) => {

    let baseImages: any[] = [];
    if (data?.images?.length) {
      try {
        for (let item of data.images) {
          const temp: any = await toBase64(item);
          baseImages.push(temp);
        }
        data.images = baseImages;
      } catch (e) {
        console.log("eE", e);
      }
    }
    data.prompt = data.prompt.trim()
    data.prompt = data.prompt.replace(/^[^\w\s]+/, '');
    if (data.prompt.length < 3) {
      throw 'Prompt length must be three characters'
    }

    if (data?.negativePrompt?.length) {
      data.negativePrompt = data.negativePrompt.trim();
      data.negativePrompt = data.negativePrompt.replace(/^[^\w\s]+/, '');
    }
    try {
      const response = await photoGenerateService.generate(data, { signal });
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

function objectToFormData(obj: any) {
  let formData = new FormData();
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      formData.append(key, obj[key]);
    }
  }
  return formData;
}

export const editImage = createAsyncThunk(
  "photoGenerate/generatePhoto",
  async (data: any, { signal }) => {

    data.prompt = data.prompt.trim()
    data.prompt = data.prompt.replace(/^[^\w\s]+/, '');
    if (data.prompt.length < 3) {
      throw 'Prompt length must be three characters'
    }

    if (data?.negativePrompt?.length) {
      data.negativePrompt = data.negativePrompt.trim();
      data.negativePrompt = data.negativePrompt.replace(/^[^\w\s]+/, '');
    }
    try {
      const formData = objectToFormData(data);
      const response = await photoGenerateService.edit(formData, { signal });
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const regeneratePhotoGenerate = createAsyncThunk(
  "photoGenerate/regeneratePhoto",
  async (data: IRegeneratePhotoData, { signal }) => {
    try {
      const response = await photoGenerateService.update(data, { signal });
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

const toBase64 = async (blobUrl: any) => {
  const response = await fetch(blobUrl);
  // console.log('response', response)
  const blob = await response.blob();
  // console.log('blob', blob)
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      console.log("reader.result", reader.result);
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
};
