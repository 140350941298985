import { Stripe } from '@/components/LandingPage/Icons'
import Loader from '@/components/Loader';
import { findMatchingPlanTitle } from '@/constants/subscriptions';
import { formatteDateIntoString } from 'lib/DateTime';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { planService } from 'services/plan.service';

const BillingAddress = () => {
  const [url, setUrl] = useState('');
  const [period, setPeriod] = useState('Yearly'); // Default value
  const [date, setDate] = useState('')
  const { t } = useTranslation('translation');
  const [curSubscription, setcurSubscription] = useState<any>({})

  const userData = useSelector((state: any) => state.authStore);
  const packages = useSelector((state: any) => state.planStore.getPlan);
  const [loading, setloading] = useState(false)

  const { user } = userData;
  const getPlans = packages?.data;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true)
        const response = await planService.billingPortal();
        console.log(response, "response");
        setUrl(response?.data?.data?.url)
        setcurSubscription(response?.data?.data?.subscription)
        setloading(false)
      } catch (error) {
        console.error("Error fetching data:", error);
        setloading(false)
      }
    };

    fetchData();
  }, []);

  const userSubscriptionId = user?.data?.plan;
  const matchingPlanTitle = findMatchingPlanTitle(userSubscriptionId, getPlans);
  useEffect(() => {
    const userSubscriptionId = user?.data?.plan;
    const matchingPlanTitle = findMatchingPlanTitle(userSubscriptionId, getPlans);
    console.log(matchingPlanTitle, 'matchingPlanTitle');

    if (matchingPlanTitle && matchingPlanTitle?.priceDetails) {
      if (matchingPlanTitle.priceDetails.includes("monthly")) {
        setPeriod("Monthly");
      } else if (matchingPlanTitle.priceDetails.includes("semesterly")) {
        setPeriod("Semesterly");
      } else {
        setPeriod("Yearly");
      }
    }
    nextBillingDate();
  }, [user, getPlans, user.data?.subscribedDate]);

  const handleOpenUrl = () => {
    // Use the router to open the URL in a new tab
    window.open(url, '_blank');
  };

  const nextBillingDate = () => {

    const subscribedDate = user.data?.subscribedDate;
    let billingDate;
    let subscriptionPeriod = period;
    if (subscribedDate) {
      billingDate = new Date(user.data?.subscribedDate);
    } else {
      billingDate = new Date();
    }
    const newDate = new Date(billingDate);

    if (matchingPlanTitle && matchingPlanTitle?.priceDetails) {
      if (matchingPlanTitle.priceDetails.includes("monthly")) {
        setPeriod("Monthly");
        subscriptionPeriod = "Monthly";
      } else if (matchingPlanTitle.priceDetails.includes("semesterly")) {
        setPeriod("Semesterly");
        subscriptionPeriod = "Semesterly";
      } else {
        setPeriod("Yearly");
        subscriptionPeriod = "Yearly";
      }
    }
    if (subscriptionPeriod === "Monthly") {
      newDate.setDate(newDate.getDate() + 30);
    } else if (subscriptionPeriod === "Semesterly") {
      newDate.setDate(newDate.getDate() + 180);
    } else if (subscriptionPeriod === "Yearly") {
      newDate.setDate(newDate.getDate() + 365);
    }

    setDate(newDate.toISOString())
  };
  console.log(matchingPlanTitle, " ===> matchingPlanTitle");
  const isCancelled = (curSubscription && curSubscription?.cancel_at && curSubscription?.cancel_at != null && curSubscription?.cancellation_details && curSubscription?.cancellation_details != null)

  return (
    <div>
      <h2 className='h4 mb-6 !font-mont !font-extrabold'>{t('billing_detail')}</h2>
      <div className='w-full p-[0.875rem] bg-n-2 dark:bg-n-6 rounded-xl mb-3'>
        <div className='flex justify-between items-center mb-13 gap-2'>
          <div className='flex gap-[14px] items-center truncate'>
            <h6 className='h6 font-bold max-w-[10rem] truncate'>{matchingPlanTitle?.title} {t('plan')}</h6>
            <div className='w-16 h-6 bg-[#D8F1E4] text-[#3FDD78] text-[11px] flex justify-center items-center rounded-md px-1'>{period}</div>
          </div>
          <div className='flex-shrink-0'>
            <h5 className='font-bold h5'>{matchingPlanTitle?.price} $ <span className='text-n-4 text-lg'>/{period}</span></h5>
          </div>
        </div>
        {
          loading == true ?
            <Loader />
            :
            <>
              {
                isCancelled ?
                  <>
                    {
                      new Date(curSubscription?.cancel_at * 1000) > new Date()
                        ?
                        <p className='text-sm' >
                          {
                            //@ts-ignore
                          `Your subscription has been cancelled, but you can continue to enjoy platform features until `
                          }
                          <span className='font-semibold !font-mont' >{
                            //@ts-ignore
                          formatteDateIntoString(new Date(curSubscription?.cancel_at * 1000))
                          }</span>
                          .
                        </p>
                        :
                        <div>Cancelled</div>
                    }
                  </>
                  :
                  <div>
                    <p className='text-base text-n-4 font-semibold'>{t('billing_date')}</p>
                    <p className='text-base font-semibold'>{
                      //@ts-ignore
                      formatteDateIntoString(new Date(curSubscription?.current_period_end * 1000)) ?? ''
                    }</p>
                  </div>
              }
            </>
        }

      </div>
      <button className='btn btn-blue flex gap-1 items-center w-full !bg-[#635BFF] hover:opacity-90' disabled={!url} onClick={handleOpenUrl}><Stripe /> {t('billing_portal')}</button>
    </div>
  )
}

export default BillingAddress
