export const SOCKET_EVENT_TYPES = {
  GOAL_NOTIFICATION: "GOAL_NOTIFICATION",
  CREATE_STREAM_COMPLETION: 'CREATE_STREAM_COMPLETION',
  UPDATE_STREAM_COMPLETION: 'UPDATE_STREAM_COMPLETION',
  PROFILE_NOTIFICATION: "PROFILE_NOTIFICATION",
  TRANSLATION_STATUS: "TRANSLATION_STATUS",
  SUMMARY_STATUS: "SUMMARY_STATUS",
  REFERRAL_COMPLETED: 'REFERRAL_COMPLETED',
  DEBATE_NOTIFICATION: "DEBATE_NOTIFICATION",
  UPDATE_DEBATE_INVITED_USERS: 'UPDATE_DEBATE_INVITED_USERS',
  UPDATE_DEBATE_STATUS_AND_DATA: 'UPDATE_DEBATE_STATUS_AND_DATA',
  AUDIO_GENERATED_NOTIFICATION:'AUDIO_GENERATED_NOTIFICATION',
  TRANSCRIPTION_GENERATED_NOTIFICATION:'TRANSCRIPTION_GENERATED_NOTIFICATION',
  SYSTEM_NOTIFICATION:'SYSTEM_NOTIFICATION',
  EMAIL_NOTIFICATION:'EMAIL_NOTIFICATION',
  CRITICAL_NOTIFICATION:'CRITICAL_NOTIFICATION',
  PHOTO_GENERATION_RESPONSE: 'PHOTO_GENERATION_RESPONSE'
};
