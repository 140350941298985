import { Loki, GPT, Copywriting, Education } from "@/components/LandingPage/Icons";

export const DATE_SPAN = {
  TODAY: "today",
  LASTWEEK: "lastWeek",
  LAST30DAYS: "last30Days",
  OLDER: "older",
};

export const CHAT_MODEL = {
  CHAT_GPT_3: 'gpt-35-turbo',
  LLAMA2MODEL: 'Llama-2-70b-chat-hf',
  GEMENI_MODEL: 'gemini-pro',
  COPY_WRITER: 'copy-writer',
  EMBEDED_MODEL: 'embeded-model'
}

export const CHAT_MODEL_TABS = [
  { name: "Loki", key: CHAT_MODEL.GEMENI_MODEL, icon:<Loki />, current: true },
  // { name: "Professor", key: CHAT_MODEL.CHAT_GPT_3, icon: <GPT />, current: false },
  // { name: "Professor", key:CHAT_MODEL.EMBEDED_MODEL ,icon: <Education />, current: false },
  { name: "Copywriter", key:CHAT_MODEL.COPY_WRITER ,icon: <Copywriting />, current: false },
  // { name: "Embeded Model", key:CHAT_MODEL.EMBEDED_MODEL ,icon: <Education />, current: false },
];

export const translateDates=(t:(string: string)=>{})=>{

  const dates = [
    {
      id: "all",
      title: t('all'),
    },
    {
      id: "today",
      title: t('today'),
    },
    {
      id: "lastWeek",
      title: t('last_week'),
    },
    {
      id: "last30Days",
      title: t('last_days'),
    },
    {
      id: "older",
      title: t('older'),
    },
  ];
  return dates;
}

export const CUSTOM_MODELS = [
  "artist_profile",
  "architect_profile",
  "biologist_profile",
  "business_profile",
  "chemical_profile",
  "designer_profile",
  "computer_profile",
  "doctor_profile",
  "lawyer_profile",
  "engineer_profile",
  "historian_profile",
  "economist_profile",
  "physicist_profile",
  "mathematician_profile",
  "political_profile",
  "philosopher_profile",
  "psychologist_profile",
  "theologist_profile",
  "professor_profile",
  "sociologist_profile",
  "mega_lawyer_profile"
]

export const ABORT_EVENTS = {
  GENERATE: 'generate',
  RE_GENERATE: 'regenerate',
  UPDATE: 'update'
}

export const MESSAGE_SENDER = {
  USER: 'user',
  ASSISTANT: 'assistant'
}

export const MAX_INPUT_FIELD_LIMIT = 10000;