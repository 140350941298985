import { createSlice } from "@reduxjs/toolkit";
import {
  getAllLibraryImages,
  deleteLibraryImage,
  updateLibraryImage,
} from "../actions/imageLibrary.actions";

export const initialState = {
  libraryImages: {
    data: [],
    totalCount: 0,
    loading: false,
    error: null,
  },
  updateLibraryImage: {
    data: null,
    loading: false,
    error: null,
  },
  deleteLibraryImage: {
    data: null,
    loading: false,
    error: null,
  },
 
};

export const imageLibrarySlice = createSlice({
  name: "imageLibraryStore",
  initialState,
  reducers: {
    resetImageLibraryData(state, action) {
      return initialState;
    },
  },
  extraReducers: {
    // Audio Library Implementations
    [getAllLibraryImages.pending.type]: (state, action) => {
      state.libraryImages.loading = true;
    },
    [getAllLibraryImages.fulfilled.type]: (state, action) => {
      if (action.meta.arg.page === 1) {
        state.libraryImages.data = action.payload.images;
      } else {
        // @ts-ignore
        state.libraryImages.data = [ ...state.libraryImages.data, ...action.payload.images];
      }
      state.libraryImages.totalCount = action.payload.totalCount;
      state.libraryImages.loading = false;
      state.libraryImages.error = null;
    },
    [getAllLibraryImages.rejected.type]: (state, action) => {
      state.libraryImages.loading = false;
      state.libraryImages.data = [];
      state.libraryImages.error = action.error?.message;
    },
    [deleteLibraryImage.pending.type]: (state, action) => {
      state.libraryImages.loading = true;
    },
    [deleteLibraryImage.fulfilled.type]: (state, action) => {
      console.log('action.payload.data', action.payload.data)
      // console.log('state.libraryImages.data', state.libraryImages.data)
      if(action.payload.data?.imagePathUrls) {
        const { id, imagePathUrls } = action.payload.data;
        
        // @ts-ignore
        state.libraryImages.data = state.libraryImages.data.map((item:any) => {
          if (item.id === id) {
            return { ...item, imagePathUrls };
          }
          return item;
        });
      } else {
        state.libraryImages.totalCount = state.libraryImages.totalCount - 1;
        state.libraryImages.data = [
          ...state.libraryImages.data.filter(
            (image: any) => image.id != action.payload.data.id
          ),
        ];
      }
      state.libraryImages.loading = false;
      state.libraryImages.error = null;
    },
    [deleteLibraryImage.rejected.type]: (state, action) => {
      state.libraryImages.loading = false;
      state.libraryImages.error = action.error?.message;
    },
    [updateLibraryImage.pending.type]: (state, action) => {
      state.updateLibraryImage.loading = true;
      state.updateLibraryImage.data = null;
      state.updateLibraryImage.error = null;
    },
    [updateLibraryImage.fulfilled.type]: (state, action) => {
      state.updateLibraryImage.loading = false;
      state.updateLibraryImage.data = action.payload.data;
      state.updateLibraryImage.data = null;
      
      const updatedLibraryImage = action.payload.data;
      const imageIndex = state.libraryImages.data.findIndex(
        (image: any) => image.id === updatedLibraryImage.id
      );
      if (imageIndex !== -1) {
        // @ts-ignore
        state.libraryImages.data[imageIndex] = updatedLibraryImage;
      }
      state.libraryImages.loading = false;
      state.libraryImages.error = null;
    },
    [updateLibraryImage.rejected.type]: (state, action) => {
      state.updateLibraryImage.loading = false;
      state.updateLibraryImage.data = null;
      state.updateLibraryImage.error = action.error?.message;
    },
  },
});

export const { resetImageLibraryData } = imageLibrarySlice.actions;

export default imageLibrarySlice.reducer;
