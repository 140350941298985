import dayjs from "dayjs";
const customParseFormat = require('dayjs/plugin/customParseFormat');
const localizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

export const formatDateForAudioLibrary = (dateTime: any) => {
  return dayjs(dateTime).format("DD MMM YY HH:mm");
};

export const formatDateRelative = (date: any) => {
  const diffInMinutes = dayjs().diff(date, "minutes");

  if (diffInMinutes < 1) {
    return "Just now";
  } else if (diffInMinutes < 2) {
    return "1 minute ago";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minutes ago`;
  } else if (diffInMinutes < 120) {
    return "1 hour ago";
  } else if (diffInMinutes < 24 * 60) {
    const diffInHours = Math.floor(diffInMinutes / 60);
    return `${diffInHours} hours ago`;
  } else if (diffInMinutes < 24 * 60 * 2) {
    return "1 day ago";
  } else if (diffInMinutes < 24 * 60 * 30) {
    const diffInDays = Math.floor(diffInMinutes / (24 * 60));
    return `${diffInDays} days ago`;
  } else if (diffInMinutes < 24 * 60 * 60) {
    return "1 month ago";
  } else if (diffInMinutes < 24 * 60 * 365) {
    const diffInMonths = Math.floor(diffInMinutes / (24 * 60 * 30));
    return `${diffInMonths} months ago`;
  } else {
    const diffInYears = Math.floor(diffInMinutes / (24 * 60 * 365));
    return `${diffInYears} years ago`;
  }
};

export const formatSeconds = (seconds = 0) => {
  if (!isNaN(seconds)) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
  } else return `00:00`;
};

export const checkTimestampInPast = ( createdAt: string, etaInSeconds: number) => {
  const createdAtDate: any = new Date(createdAt);
  const currentDate: any = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = createdAtDate - currentDate;

  if (timeDifference < 0) {
    // The createdAt timestamp is in the past
    return { isInPast: true, secondsLeft: 0 };
  } else {
    // Calculate how many seconds are left based on eta
    const secondsLeft = etaInSeconds - timeDifference / 1000;

    if (secondsLeft <= 0) {
      // You don't have to wait; the timestamp is very close to or at the current time
      return { isInPast: true, secondsLeft: 0 };
    } else {
      return { isInPast: false, secondsLeft: secondsLeft };
    }
  }
};

export const isCurrentMonth = (date: string) => {
  return dayjs(date).isSame(new Date(), 'month')
}

export const isPreviousMonth = (date: string) => {
  const dateToCheck = dayjs(date);
  const previousMonthDate = dayjs().subtract(1, "month");

  const isPreviousMonth = dateToCheck.year() === previousMonthDate.year() &&dateToCheck.month() === previousMonthDate.month();
  return isPreviousMonth;
};

export const formattedDateString = (dateString: any) => {

  const dateObject = dayjs(dateString);
  const formattedDate = dateObject.format('YYYY-MM-DD');
  const formattedDateString = formattedDate.toString();
  return formattedDateString;
}

export const isInPast = (date: string) => {
  const enteredDate = dayjs(date);
  const currentDate = dayjs();
  
  return enteredDate.isBefore(currentDate);
}

export const formatteDateIntoString = (dateString: string) => {
  const date = dayjs(dateString);
  return date.format('DD MMM, YYYY');
}

export const formatDateIntoUTC = (dateString: string) => {
  return dayjs(dateString).format('MMM DD, YYYY | HH:mm UTC');
}

export const formatOnlyDate = (datestring: string | Date) => {
  const date = dayjs(datestring);
  return date.format('ddd DD MMM');
}

export const secondsToHMS = (seconds: number) => {
  try {
    if(seconds >= 0) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.ceil(seconds % 60);
      
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else '00:00:00';

  } catch(error) {
    console.log('secondsToHMS Error', error);
    return '00:00:00'
  }
}