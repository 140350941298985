import { Button } from '@/components/LandingPage/Common/Button';
import { ROUTES } from '@/constants/routes';
import { useColorMode } from '@chakra-ui/color-mode';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import React from 'react'
type FreeTrialModal = {
};

const FreeTrialModal = ({ }: FreeTrialModal) => {
  const { colorMode, setColorMode } = useColorMode();
  const router = useRouter()
  return (
    <div
      className="rounded-3xl overflow-hidden w-full h-full bg-white flex">
      <div className="w-[20.5rem] flex-shrink-0 md:hidden">
        <video
          className="h-full w-full"
          width={324}
          height={756}
          autoPlay
          muted
          loop
        >
          <source src={`https://lokichatdev.blob.core.windows.net/images/30_Day_Trial_Model.webm`} type="video/mp4"></source>
        </video>
      </div>
      <div className="w-full pt-14">
        <div className="md:px-10 px-18">
          <figure className="">
            <Image
              src={colorMode !== "dark" ? "https://lokichatdev.blob.core.windows.net/images/loki/logo.svg" : "https://lokichatdev.blob.core.windows.net/images/loki/logow.svg"} height={40} width={136} alt={"Loki"} />
          </figure>
          <div className='pb-64 pt-48'>
            <h3 className='text-n-6 text-4xl font-extrabold !font-sans pb-5'>Your 7-day trial has ended</h3>
            <p className="text-n-6/50 text-xl tracking-wide font-medium">You can keep using our most powerful features. Select a plan, add your billing info, and you’re all set!</p>
          </div>
        </div>
        <div className="w-full flex justify-end border-t border-n-3 py-5 px-8">
          <Button className='ml-auto max-w-[15rem] w-full py-3 text-base' onClick={()=>{router.push(ROUTES.PRICING)}} >Select your plan</Button>
        </div>
      </div>
    </div>
  )
}

export default FreeTrialModal