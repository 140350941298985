import React, { useEffect, useState } from "react";

const OfflineAlert = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    !isOnline ? (
      <div
        style={{
          backgroundColor: "red",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        You are offline, please check your internet connection.
      </div>
    ) : null
  );
};

export default OfflineAlert;
