export const GOAL_STATUS = {
    IN_PROGRESS: 'inprogress',
    COMPLETED: 'completed'
}

export const REMINDER_FREQUENCY = {
    oneHour: "1hour",
    twoHours: "2hours",
    fourHours: "4hours",
    sixHours: "6hours",
    oneDay: "1day",
    oneWeek: "1week",
    twoWeek: "2weeks",
    oneMonth: "1month",
  };
  
  export const GOAL_REMINDER = 'Goal Reminder';