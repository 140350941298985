import React from 'react'
//@ts-ignore
import { Rings } from 'react-loader-spinner'

const Loader = ({width,height,color}:any) => {
  return (
    <div className='flex justify-center items-center'>
            <Rings
            height={height ?? "80"}
            width={width ?? "80"}
            color={color ?? "#0084FF"}
            radius="6"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
            />
    </div>
  )
}

export default Loader