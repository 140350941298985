import { createAsyncThunk } from "@reduxjs/toolkit";
import { advisorService } from "services/promptAdvisorService";

export const getAllCategory = createAsyncThunk(
  "advisorStore/getAllCategory",
  async (data: any) => {
    try {
      console.log(data, "getAllCategory");
      
      const response = await advisorService.getAllCategory(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getAllSubCategory = createAsyncThunk(
  "advisorStore/getAllSubCategory",
  async (data: any) => {
    try {
      const response = await advisorService.getAllSubCategory(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getAllPrompts = createAsyncThunk(
  "advisorStore/getAllPrompts",
  async (data: any) => {
    try {
      const response = await advisorService.getAllPrompts(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

