import { createSlice } from "@reduxjs/toolkit";
import { createPhotoGenerate, createPhotoGenerate2 } from "store/actions/photoGeneration.actions";

export const initialState = {
  photoGeneratedChat: {
    data: {},
    loading: false,
    error: null,
    noOfImages: 0,
  },
  queuedPhotoData: {
    data: {},
    loading: false,
    error: null,
  },
  selectedScreen: 0,
  editGeneratedImage: null,
  regeneratePhotoGeneratedMessage: {
    data: {},
    loading: false,
    error: null,
  },
  imageState: {
    addImageLibrarySideBar: false,
  },
  sidebarData: {
    taskType: "TEXT_IMAGE",
    negativeText: "",
    images: [],
    text: "",
    orientation: "1:1",
    editType: "",
    editImage: "",
    imageGenerationConfig: {
      cfgScale: 8,
      seed: 0,
      quality: "standard",
      width: 1024,
      height: 1024,
      numberOfImages: 1,
    },
    model: 1,
  },
  inProcessId: null
};

export const photoGenerationSlice = createSlice({
  name: "photoGenerationStore",
  initialState,
  reducers: {
    resetPhotoGenerations(state, action) {
      return initialState;
    },
    resetPhotoLoadingAndError(state, action) {
      state.photoGeneratedChat.loading = false;
      state.photoGeneratedChat.noOfImages = 0;
      state.photoGeneratedChat.error = null;
    },
    resetCreatePhotoData(state, action) {
      state.photoGeneratedChat.data = {};
    },
    setImageLibrarySidebar(state, action) {
      state.imageState.addImageLibrarySideBar = action.payload;
    },
    setSidebarData(state, action) {
      switch (action.payload.type) {
        case "SET_TEXT":
          return {
            ...state,
            sidebarData: {
              ...state.sidebarData,
              text: action.payload.payload,
            },
          };
        case "SET_ORIENTATION":
          return {
            ...state,
            sidebarData: {
              ...state.sidebarData,
              orientation: action.payload.payload,
            },
          };
        case "SET_SIZE_TYPE":
          console.log(
            "orientation from reducer",
            state.sidebarData.orientation
          );
          console.log("action.payload.payload:orientation:", action.payload.payload);
          return {
            ...state,
            sidebarData: {
              ...state.sidebarData,
              orientation: action.payload.payload,
            },
          };
        case "SET_NEGATIVE_TEXT":
          console.log('SET_NEGATIVE_TEXT',action)
          return {
            ...state,
            sidebarData: {
              ...state.sidebarData,
              negativeText: action.payload.payload,
            },
          };
        case "SET_MODEL":
          return {
            ...state,
            sidebarData: {
              ...state.sidebarData,
              model: action.payload.payload,
            },
          };
        case "SET_IMAGES":
          if (action.payload.payload.length) {
            return {
              ...state,
              sidebarData: {
                ...state.sidebarData,
                images: action.payload.payload,
                taskType: "IMAGE_VARIATION",
              },
            };
          } else {
            return {
              ...state,
              sidebarData: {
                ...state.sidebarData,
                images: action.payload.payload,
                taskType: "TEXT_IMAGE",
              },
            };
          }
        case "RESET_DATA":
          return {
            ...state,
            sidebarData: {
              ...initialState.sidebarData,
            },
            photoGeneratedChat: {
              ...state.photoGeneratedChat,
              error: null
            } 
          };
        case "SET_CFG_SCALE":
          console.log(
            "cfgScale: ",
            state.sidebarData.imageGenerationConfig.cfgScale
          );
          return {
            ...state,
            sidebarData: {
              ...state.sidebarData,
              imageGenerationConfig: {
                ...state.sidebarData.imageGenerationConfig,
                cfgScale: action.payload.payload,
              },
            },
          };
        case "SET_SEED":
          console.log("seed: ", state.sidebarData.imageGenerationConfig.seed);
          return {
            ...state,
            sidebarData: {
              ...state.sidebarData,
              imageGenerationConfig: {
                ...state.sidebarData.imageGenerationConfig,
                seed: action.payload.payload,
              },
            },
          };
        case "SET_QUALITY":
          console.log(
            "quality: ",
            state.sidebarData.imageGenerationConfig.quality
          );
          return {
            ...state,
            sidebarData: {
              ...state.sidebarData,
              imageGenerationConfig: {
                ...state.sidebarData.imageGenerationConfig,
                quality: action.payload.payload,
              },
            },
          };
        case "SET_WIDTH":
        case "SET_HEIGHT":
          const width = parseInt(action.payload.payload[0]);
          const height = parseInt(action.payload.payload[1]);
          console.log("SET_WIDTH0", width, height);
          return {
            ...state,
            sidebarData: {
              ...state.sidebarData,
              imageGenerationConfig: {
                ...state.sidebarData.imageGenerationConfig,
                width: width,
                height: height,
              },
            },
          };
        case "SET_NUMBER_OF_IMAGES":
          return {
            ...state,
            sidebarData: {
              ...state.sidebarData,
              imageGenerationConfig: {
                ...state.sidebarData.imageGenerationConfig,
                numberOfImages: action.payload.payload,
              },
            },
          };
        case "SET_EDIT_TYPE":
          return {
            ...state,
            sidebarData: {
              ...state.sidebarData,
              editType: action.payload.payload,
            },
          };
        case "SET_EDIT_IMAGE":
          return {
            ...state,
            sidebarData: {
              ...state.sidebarData,
              editImage: action.payload.payload,
            },
          };
        default:
          return state;
      }
    },
    setSelectedScreen(state, action) {
      state.selectedScreen = action.payload;
    },
    seteditGeneratedImageScreen(state, action) {
      state.editGeneratedImage = action.payload;
    },
    setPhotoGenerationData(state, action) {
      state.photoGeneratedChat.data = { imagePathUrls: action.payload, thumbPathUrls: action.payload };
      state.photoGeneratedChat.loading = false;
      state.photoGeneratedChat.noOfImages = 0;
      state.photoGeneratedChat.error = null;
      state.inProcessId = null
    }
  },
  extraReducers: {
    // Generate Photo  with respective prompt
    [createPhotoGenerate.pending.type]: (state, action: any) => {
      console.log("photoGeneratedChat:action.payload.pending", action.payload);
      state.photoGeneratedChat.loading = true;
      state.photoGeneratedChat.data = {};
      state.photoGeneratedChat.error = null;
      state.photoGeneratedChat.noOfImages = action.meta.arg.noOfImages;
    },
    [createPhotoGenerate.fulfilled.type]: (state: any, action: any) => {
      state.photoGeneratedChat.data = action.payload.data;
      if (action.payload.data.imagePathUrls.length) {
        state.photoGeneratedChat.loading = false;
        state.photoGeneratedChat.noOfImages = 0;
      }
      state.photoGeneratedChat.error = null;
    },
    [createPhotoGenerate.rejected.type]: (state, action) => {
      state.photoGeneratedChat.data = state.photoGeneratedChat.data || {};
      state.photoGeneratedChat.loading = false;
      state.photoGeneratedChat.noOfImages = 0;
      state.photoGeneratedChat.error = action.error?.message;
    },
    [createPhotoGenerate2.pending.type]: (state, action: any) => {
      state.photoGeneratedChat.loading = true;
      state.photoGeneratedChat.data = {};
      state.photoGeneratedChat.error = null;
      state.photoGeneratedChat.noOfImages = action.meta.arg.noOfImages;
    },
    [createPhotoGenerate2.fulfilled.type]: (state: any, action: any) => {
      state.inProcessId = action.payload.data?.id;
    },
    [createPhotoGenerate2.rejected.type]: (state, action) => {
      state.photoGeneratedChat.data = state.photoGeneratedChat.data || {};
      state.photoGeneratedChat.loading = false;
      state.photoGeneratedChat.noOfImages = 0;
      state.photoGeneratedChat.error = action.error?.message;
    },
  },
});

export const {
  resetPhotoGenerations,
  resetCreatePhotoData,
  resetPhotoLoadingAndError,
  setImageLibrarySidebar,
  setSidebarData,
  setSelectedScreen,
  seteditGeneratedImageScreen,
  setPhotoGenerationData
} = photoGenerationSlice.actions;

export default photoGenerationSlice.reducer;
