import { createAsyncThunk } from "@reduxjs/toolkit";
import { audioService } from "services/audio.service";
import { IAudioData } from "interfaces/audio.interface";

export const generateAudio = createAsyncThunk(
  "audioStore/store",
  async (data: IAudioData, { signal }) => {
    try {
      const response = await audioService.store(data, { signal });
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateAudioById = createAsyncThunk(
  "audiostore/updateAudioById",
  async (data: any) => {
    try {
      const response = await audioService.updateAudioById(data);
      return response.data; // Assuming your API returns the user data
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const destroyAudio = createAsyncThunk(
  "audioStore/destroyAudio",
  async (id: any) => {
    try {
      const response = await audioService.destroy(id);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getAllAudioLibrary = createAsyncThunk(
  "audioStore/getAllAudioLibrary",
  async (params:{date?: string, page?:number}) => {
    try {
      const response = await audioService.index({ ...params, timeZone: new Date().getTimezoneOffset() });
      return response?.data?.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getAllSharedAudioLibrary = createAsyncThunk(
  "audioStore/getAllAudioLibrary",
  async (params: {date?: string, page?: number}) => {
    try {
      const response =  await audioService.sharedAudioLibrary(params);
      return response?.data?.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getAllSharedAudioLibraryPrevMonth = createAsyncThunk(
  "audioStore/getAllAudioLibraryPrevMonth",
  async (params:{date?: string, page?:number}) => {
    try {
      const response =  await audioService.sharedAudioLibraryPrevMonth(params);
      return response?.data?.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getInprogressAudios = createAsyncThunk(
  "audioStore/getInprogressAudios",
  async () => {
    try {
      const response =  await audioService.getInprogressAudios({});
      return response?.data?.data?.inProgressCount || 0;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateAudioLibraryShare = createAsyncThunk(
  "audioStore/updateAudioLibrary",
  async (data?: any) => {
    try {
      const response = await audioService.addSharedAudio(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.error.message 
    }
  }
);

export const getAllAudioLibraryPrevMonth = createAsyncThunk(
  "audioStore/getAllAudioLibraryPrevMonth",
  async (params:{date?: string, page?:number}) => {
    try {
      
      const response =  await audioService.indexPrevMonth(params);
      
      return response?.data?.data;
    } catch (error: any) {
      console.log(error,"error");
      
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateAudioLibrary = createAsyncThunk(
  "audioStore/updateAudioLibrary",
  async (data?: any) => {
    try {
      
      const response = await audioService.update(data);
      
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateAudioLibraryPrev = createAsyncThunk(
  "audioStore/updateAudioLibraryPrev",
  async (data?: any) => {
    try {
      
      const response = await audioService.update(data);
      
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const deleteAudioLibrary = createAsyncThunk(
  "audioStore/deleteAudioLibrary",
  async (id: any) => {
    try {
      const response = await audioService.destroy(id);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const deleteAudioLibraryPrev= createAsyncThunk(
  "audioStore/deleteAudioLibraryPrev",
  async (id: any) => {
    try {
      const response = await audioService.destroy(id);
      return response.data;
    } catch (error: any) {
      console.log("errrrr", error)
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const deleteAudioMultiple = createAsyncThunk(
  "audioStore/deleteAudioMultiple",
  async (data: any) => {
    try {
      const response = await audioService.destroyMultiple(data);
      return response.data;
    } catch (error: any) {
      console.log("errrrr", error)
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "audioStore/getUserDetails",
  async () => {
    try {
      const response = await audioService.getUserDetails();
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
