import { useEffect, useState } from "react";
import Image from "@/components/Image";
import Icon from "@/components/Icon";
import Field from "@/components/Field";
import { ProfileSchema } from "lib/validations/profile";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "store/actions/profile.action";
import { ImSpinner9 } from 'react-icons/im';
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import { BiErrorCircle } from 'react-icons/bi';
import { useTranslation } from "react-i18next";
import { PROFILE_URL } from "@/constants/user";


type EditProfileProps = {};

const EditProfile = ({}: EditProfileProps) => {
    const dispatch: any = useDispatch()
    const { t:lt } = useTranslation('auth');
    const userData = useSelector((state: any) => state.authStore);
    const { user } = userData;
    
    const [objectURL, setObjectURL] = useState<any>(user?.data?.profileUrl ?? PROFILE_URL); 
    const [image,setImage] = useState('');
    const [hasChanges, setHasChanges] = useState(false);
    const [name, setName] = useState<string>(user?.data?.userName ?? "");
    const [bio, setBio] = useState<string>(user?.data?.bio ?? "");
    
    const initialErrors = {
        name: "",
        bio: "",
        imageUrl:""
      };
    const [errors, setErrors] = useState(initialErrors);
    const handleUpload = (e: any) => {
        if (e.target.files && e.target.files[0]) {
          const file = e.target.files[0];
          setImage(file); // Store the selected file in state
          setObjectURL(URL.createObjectURL(file)); // Create a data URL for image preview
        }
      };

      useEffect(() => {
        const hasNameChanged = name !== user?.data?.userName;
        const hasBioChanged = bio !== user?.data?.bio;
        const hasImageChanged = !!image;

        setHasChanges(hasNameChanged || hasBioChanged || hasImageChanged);
    }, [name, bio, image]);
const handleUpdateProfile = async (event: any) => {
    event.preventDefault();

    const data = new FormData();
    data.append('userName', name);
    data.append('bio', bio);
    data.append('image', image);

    let fieldErrors;
    if(image)
    //@ts-ignore
    {const fileSizeInBytes = image?.size;
    const fileSizeInKB = fileSizeInBytes / 1024; // Convert to KB
    const fileSizeInMB = fileSizeInKB / 1024; // Convert to MB
    if(fileSizeInMB>10){
return toast(
  <Notify iconError >
    <div className="ml-3 h6 sm:text-sm">{lt('profile_imgValid')}</div>
  </Notify>
);}
    }
    await ProfileSchema
      .validate({ name, bio }, { abortEarly: false })
      .catch((error: any) => {
        fieldErrors = error.inner.reduce((acc: any, err: any) => {
          return {
            ...acc,
            [err.path]: err.message,
          };
        }, {});

        setErrors(fieldErrors);
      });
      

    if (isEmpty(fieldErrors)) {
      setErrors(initialErrors);
      dispatch(updateProfile(data)).unwrap().then(() => {
        setHasChanges(false)
        toast(
            <Notify iconCheck>
              <div className="h6 sm:text-sm">{lt('profile_updated')}</div>
            </Notify>
          );
      });
    }
  };

    return (
        <form className="" action="" onSubmit={() => console.log(lt('submit'))}>
            <div className="mb-8 h4 md:mb-6 !font-mont !font-extrabold">{lt('edit_profile')}</div>
            <div className="mb-3 base2 !font-mont font-semibold text-n-6 dark:text-n-1">
                {lt('avatar')}
            </div>
            <div className="flex items-center mb-6">
                <div className="relative flex justify-center items-center shrink-0 w-28 h-28 mr-4 rounded-full overflow-hidden bg-n-2 dark:bg-n-6">
                    {objectURL !== null ? (
                        <Image
                            className="object-cover rounded-full"
                            src={objectURL}
                            fill
                            alt={lt('avatar')}
                        />
                    ) : (
                        <Icon
                            className="w-8 h-8 dark:fill-n-1"
                            name="profile"
                        />
                    )}
                </div>
                <div className="grow">
                    <div className="relative inline-flex mb-4">
                        <input
                            className="peer absolute inset-0 opacity-0 cursor-pointer"
                            type="file"
                            accept="image/jpeg, image/png, image/svg+xml"
                            onChange={handleUpload}
                        />
                        <button className="btn-stroke-light peer-hover:bg-n-3 dark:peer-hover:bg-n-5">
                            {lt('upload_newImg')}
                        </button>
                    </div>
                    <div className="caption1 text-n-4">
                        <p>{lt('img_recommend')}</p>
                        <p>{lt('img_valid')}</p>
                    </div>
                </div>
            </div>
            <div className="mb-2">
            <Field
                label={lt('name')}
                placeholder={lt('userName')}
                icon="profile-1"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                required
            />
             {errors.name && (
        <div className="text-red-500 text-xs mt-1 flex items-center gap-1"><BiErrorCircle className="text-base"/> {errors.name}</div>
      )}
      </div>
        <div className="mb-6">
          <Field
              label={lt('bio')}
              placeholder={lt('short_bio')}
              icon="user-check"
              value={bio}
              onChange={(e: any) => setBio(e.target.value)}
              textarea
          />
          {errors.bio && (
        <div className="text-red-500 text-xs mt-1 flex items-center gap-1"><BiErrorCircle className="text-base"/> {errors.bio}</div>
      )}
      </div>  
            <button  disabled={!hasChanges || user.loading} onClick={handleUpdateProfile} className="btn-blue w-full">{lt('save_changes')} {user.loading && <ImSpinner9 className="animate-spin"/> }</button>
        </form>
    );
};

export default EditProfile;
