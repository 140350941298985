export default {
    baseApiUrl: `${process.env.NEXT_PUBLIC_API_URL}/api` || 'https://dev-api.lokichat.com/api',
    backendBaseUrl: process.env.NEXT_PUBLIC_API_URL || 'https://dev-api.lokichat.com',
    frontnedUrl: process.env.NEXT_PUBLIC_FRONTEND_URL || "https://dev.lokichat.com",
    superUserDashboardUrl: process.env.NEXT_PUBLIC_SUPER_USER_DASHBOARD_FRONTEND_URL || 'https://dev-dashboard.lokichat.com/',
    // baseApiUrl: 'http://localhost:3003/api',
    // backendBaseUrl: 'http://localhost:3003',
    // frontnedUrl: process.env.NEXT_PUBLIC_FRONTEND_URL || "https://dev.lokichat.com",
    // superUserDashboardUrl: 'http://localhost:3001/',
    googleAnalyticesTID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICES_TID || '',
    transcriptionServiceApi : process.env.NEXT_PUBLIC_API_TRANSCRIPTION || ''
}
