export const exportImage = [
  {
    title: "Export",
    list: [
      {
        title: "JPG Small",
        details: "For web",
        image:
          "https://lokichatdev.blob.core.windows.net/images/image-purple.svg",
      },
      {
        title: "JPG Large",
        details: "Original + Settings",
        image:
          "https://lokichatdev.blob.core.windows.net/images/image-blue.svg",
      },
    ],
  },
  {
    title: "Share",
    list: [
      {
        title: "Get a link",
        icon: "link",
      },
      {
        title: "Invite teammates",
        icon: "invite",
      },
    ],
  },
  {
    title: "Upload",
    list: [
      {
        title: "Facebook",
        image: "https://lokichatdev.blob.core.windows.net/images/facebook.svg",
      },
      {
        title: "Twitter",
        image: "https://lokichatdev.blob.core.windows.net/images/twitter.svg",
      },
    ],
  },
];

export const audioTranslate = (lt: (string: string) => {}) => {
  const exportAudio = [
    {
      title: lt("export"),
      list: [
        {
          title: ".MP3",
          details: ` ${lt("small")} - 320kbps`,
          image:
            "https://lokichatdev.blob.core.windows.net/images/note-purple.svg",
        },
        {
          title: ".WAV",
          details: ` ${lt("large")}- 44.1 kHz`,
          image:
            "https://lokichatdev.blob.core.windows.net/images/note-blue.svg",
        },
      ],
    },
    {
      title: lt("share"),
      list: [
        {
          title: lt("get_link"),
          icon: "link",
        },
        // {
        //     title: "Invite teammates",
        //     icon: "invite",
        // },
      ],
    },
  ];

  return exportAudio;
};
