import React, { useEffect, useState } from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/slider";

interface IProps {
  min: number;
  max: number;
  step: number;
  defaultValue: number;
  onChange: any;
  fromFeature?: any;
}

const RangeInput = ({
  min,
  max,
  step,
  defaultValue,
  onChange,
  fromFeature,
}: IProps) => {
  const [sliderVale, setSliderVal] = useState(defaultValue);

  useEffect(() => {
    console.log("defaultValue", defaultValue);
    setSliderVal(defaultValue);
  }, [defaultValue]);

  const customTheme = extendTheme({
    colors: {
      teal: {
        500: "#CFEB92", // Set the color value here
      },
    },
  });
  return (
    <div className="RangeInput w-full">
      <ChakraProvider theme={customTheme}>
        <Slider
          aria-label="slider-ex-2"
          min={min}
          max={max}
          step={step}
          // defaultValue={defaultValue}
          value={sliderVale}
          onChange={onChange}
        >
          <SliderTrack>
            <SliderFilledTrack bg="teal.500" />
          </SliderTrack>
          <SliderThumb bg="teal.500" borderColor="white" />
        </Slider>
      </ChakraProvider>
    </div>
  );
};

export default RangeInput;
