import { IUpdateNotificationsList } from 'interfaces/notifications.interface';
import { HttpService } from './base.service'

class NotificationsService extends HttpService {
    private readonly prefix: string = "notification";

    index = (): Promise<any> => this.get(this.prefix + '/index');
    update = (data: IUpdateNotificationsList): Promise<any> => this.post(this.prefix + '/update', data);
    read = (data: any): Promise<any> => this.post(this.prefix + '/read', data);
}

export const notificationsService = new NotificationsService();