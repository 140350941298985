import { createSlice } from "@reduxjs/toolkit";
import {
  createSuperUserRequest,
  getAllSuperUserRequests,
  updateSuperUserRequest,
  getSuperUserRequest
} from "store/actions/superUser.actions";

export const initialState: any = {
  createSuperUser: {
    data: null,
    loading: false,
    error: null,
  },
  updateSuperUser: {
    data: null,
    loading: false,
    error: null,
  },
  getAllSuperUsers: {
    data: [],
    loading: false,
    error: null,
  },
  getSuperUser: {
    data: null,
    loading: false,
    error: null,
  },
};

export const superUserSlice = createSlice({
  name: "superUserStore",
  initialState: initialState,
  reducers: {
    resetAllSuperUserData(state, action) {
      return initialState;
    },
    resetCreateSuperUserRequest(state, action) {
      state.createSuperUser.loading = false;
      state.createSuperUser.data = null;
      state.createSuperUser.error = null;
    },
    resetGetSuperUser(state, action) {
      state.getSuperUser.loading = false;
      state.getSuperUser.data = null;
      state.getSuperUser.error = null;
    },
  },

  extraReducers: {
    [createSuperUserRequest.pending.type]: (state, action) => {
      state.createSuperUser.loading = true;
      state.createSuperUser.data = null;
      state.createSuperUser.error = null;
    },
    [createSuperUserRequest.fulfilled.type]: (state, action) => {
      state.createSuperUser.loading = false;
      state.createSuperUser.data = action.payload.data;
      state.createSuperUser.error = null;
    },
    [createSuperUserRequest.rejected.type]: (state, action) => {
      state.createSuperUser.loading = false;
      state.createSuperUser.data = null;
      state.createSuperUser.error = action.error?.message;
    },
    // get plans price
    [updateSuperUserRequest.pending.type]: (state, action) => {
      state.updateSuperUser.loading = true;
      state.updateSuperUser.data = null;
      state.updateSuperUser.error = null;
    },
    [updateSuperUserRequest.fulfilled.type]: (state, action) => {
      state.updateSuperUser.loading = false;
      state.updateSuperUser.data = action.payload.data;
      state.updateSuperUser.error = null;
    },
    [updateSuperUserRequest.rejected.type]: (state, action) => {
      state.updateSuperUser.loading = false;
      state.updateSuperUser.data = null;
      state.updateSuperUser.error = action.error?.message;
    },
    [getAllSuperUserRequests.pending.type]: (state, action) => {
      state.getAllSuperUsers.loading = true;
      state.getAllSuperUsers.data = [];
      state.getAllSuperUsers.error = null;
    },
    [getAllSuperUserRequests.fulfilled.type]: (state, action) => {
      state.getAllSuperUsers.loading = false;
      state.getAllSuperUsers.data = action.payload.data;
      state.getAllSuperUsers.error = null;
    },
    [getAllSuperUserRequests.rejected.type]: (state, action) => {
      state.getAllSuperUsers.loading = false;
      state.getAllSuperUsers.data = [];
      state.getAllSuperUsers.error = action.error?.message;
    },
    [getSuperUserRequest.pending.type]: (state, action) => {
      state.getSuperUser.loading = true;
      state.getSuperUser.data = null;
      state.getSuperUser.error = null;
    },
    [getSuperUserRequest.fulfilled.type]: (state, action) => {
      state.getSuperUser.loading = false;
      state.getSuperUser.data = action.payload.data;
      state.getSuperUser.error = null;
    },
    [getSuperUserRequest.rejected.type]: (state, action) => {
      state.getSuperUser.loading = false;
      state.getSuperUser.data = null;
      state.getSuperUser.error = action.error?.message;
    },
  },
});

export const { resetAllSuperUserData, resetCreateSuperUserRequest, resetGetSuperUser } = superUserSlice.actions;

export default superUserSlice.reducer;
