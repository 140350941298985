export const getQueryParamValue = (param: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(param);
};



export const parseQueryParams = (queryParamString: string) => {
  const params :any = new URLSearchParams(queryParamString);

  // Create an object to store the key-value pairs
  const queryParams:any = {};

  for (const [key, value] of params) {
    queryParams[key] = value;
  }

  return queryParams;
}