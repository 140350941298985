import React, { useState } from "react";
import Field from "@/components/Field";
import DeleteAccountModal from "@/components/Modal/DeleteAccountModal";
import { profileService } from "services/profile.service";
import { useDispatch, useSelector } from "react-redux";
import { resetAllUserData } from "store/slices/AuthSlice";
import { resetAllChatsData } from "store/slices/ChatGptSlice";
import { resetAllAudioData } from "store/slices/AudioSlice";
import { useRouter } from "next/router";
import { resetPhotoGenerations } from "store/slices/PhotoGenerationSlice";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import { clearLogoutStorage } from "lib/user";
import { ROUTES } from "@/constants/routes";
import { useTranslation } from "react-i18next";

type DeleteAccountProps = {
  setVisibleSettings?: any;
};

const DeleteAccount = ({ setVisibleSettings }: DeleteAccountProps) => {
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("");
  const userData = useSelector((state: any) => state.authStore);
  const { user } = userData;
  const dispatch = useDispatch();
  const { t } = useTranslation('auth');
  const router = useRouter();
  const handleDelete = async () => {
    try {
      setloading(true)
      await profileService.destroyUser({ password });
      setVisibleModal(false);
      dispatch(resetAllUserData(null));
      dispatch(resetAllChatsData(null));
      dispatch(resetPhotoGenerations(null));
      dispatch(resetAllAudioData(null));
      clearLogoutStorage();
      router.push(ROUTES.SIGNIN);
      setloading(false);
    } catch (error) {
      console.error("Error deleting user:", error);
      setloading(false)
      // Handle error scenario here
      return toast(
        <Notify iconError>
          <div className="ml-3 h6 sm:text-sm">{t('invalid_password')}</div>
        </Notify>
      );
    }
  };

  return (
    <form className="" onSubmit={(e) => e.preventDefault()}>
      <div className="mb-8 h4">{t('sorry_seeGo')}</div>
      <div className="mb-6 caption1 text-n-4">
        {t('warning_delAccount')}
      </div>
      {((user && user.data) && (!user?.data?.gmailProviderId && !user?.data?.appleProviderId)) && (
        <Field
          className="mb-6"
          label={t('your_password')}
          placeholder={t('your_password')}
          type="password"
          icon="lock"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
          required
        />
      )}
      <button
        disabled={(user?.data?.gmailProviderId || user?.data?.appleProviderId) ? false : !password}
        className="btn-red w-full"
        onClick={() => setVisibleModal(true)}
      >
        {t('del_account')}
      </button>
      <DeleteAccountModal
        title={t('del_account')}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        onDelete={handleDelete}
        isLoading={loading}
      />
    </form>
  );
};

export default DeleteAccount;
