import React, { useEffect, useRef, useState } from "react";
import Field from "@/components/Field";
import { useTranslation } from "react-i18next";
import Icon from "@/components/Icon";
import { addPromptQuestion } from "store/slices/ChatGptSlice";
import { useDispatch } from "react-redux";
import { resetAllAdvisorStates } from "store/slices/AdvisorSlice";
import { Keyboard } from "@/components/LandingPage/Icons";
import { getPromptAdvise, removeHeadings } from "lib/utils";
import toast from "react-hot-toast";
import Notify from "@/components/Notify";
import { ImSpinner9 } from "react-icons/im";
import { useRouter } from "next/router";
import { CopyIcon, RepeatIcon } from '../../../components/SVGs'

type NewDebateModal = {
    onClose: () => void;
};

const PromptAdvisorModal = ({ onClose }: NewDebateModal) => {
    const { t } = useTranslation('translation')
    const { t: lt } = useTranslation('auth')
    const [title, setTitle] = useState("");
    const [isEdited, setisEdited] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searched, setSearched] = useState<any>(null)
    const [result, setResult] = useState("");
    const router = useRouter()
    const dispatch = useDispatch();

    const keyboardClickHandler = () => {
        onClose();
        dispatch(addPromptQuestion(result));
        router.pathname !== '/photo-generation' && router.push('chat')
        dispatch(resetAllAdvisorStates({}));
    }

    const promptHandler = async () => {
        setLoading(true);
        try {
            const fromImageGeneration = router.pathname === '/photo-generation';
            const res = await getPromptAdvise(fromImageGeneration, title);
            if (res.status == 200) {
                console.log(res.data);

                let cleanResults = removeHeadings(res.data?.prompt ?? res.data["Image prompt"])
                setResult(cleanResults);
                setSearched(title);
                setLoading(false);
                toast(
                    () => (
                        <Notify iconCheck>
                            <div className="h6">{t('prompt_response')}</div>
                        </Notify>
                    ),
                    { duration: 5000 }
                );
            } else {
                toast.error("Failed to get the response");
                setLoading(false);
            }
        } catch (err) {
            console.log('err ', err);

            toast.error("Error in geeting response response");
            setLoading(false);
        }

    };

    const onCopy = () => {
        setTimeout(() => {
            const textArea = document.createElement("textarea");
            textArea.value = result;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            toast((t) => (
                <Notify iconCheck>
                    <div className="h6 sm:text-sm">{lt('prompt_copied', { ns: "auth" })}</div>
                </Notify>
            ));
        }, 1000);
    };

    const onRepeat = () => {
        if (result != "") {
            setResult("");
            promptHandler();
        }
    }

    return (
        <div className="p-7">
            <div className='flex items-center justify-between pb-5'>
                <h3 className="h4 !font-mont !font-extrabold">{router.pathname == '/photo-generation' ? lt('image_prompt_advisor') : lt('prompt_advisor')}</h3>
                <button onClick={onClose}><Icon name={'close'} className=" fill-n-4 d" /></button>
            </div>
            <div className=" relative ">
                <Field
                    value={title}
                    onChange={(e: any) => { setTitle(e.target.value); !isEdited && setisEdited(true) }}
                    placeholder={t('short_description_placeholder')}
                    label={t('type_text_here')}
                    // disabled={!isEditable}
                    classInput={"mb-4 mr-2 flex-grow w-full  focus:border-blue-300 hover:border-blue-300 pr-15 bg-n-2"}
                />

                <div className="absolute right-2 bottom-8">
                    <button onClick={() => { isEdited && setisEdited(false) }}><Icon name={isEdited && title !== '' ? "check" : 'edit'} className={`ml-3 w-${isEdited && title !== '' ? 5 : 4} h-${isEdited && title !== '' ? 5 : 4} icon-button fill-n-8`} /></button>
                    <button onClick={() => setTitle('')}><Icon name="close" className="ml-2 w-5 h-5 fill-n-8" /></button>
                </div>
            </div>
            <div className="mb-2 text-sm font-semibold !font-mont">{t('find_result')}</div>
            <div className={" w-full bg-n-2 dark:bg-n-6 h-36 rounded-xl pl-3 pt-2 relative"}>
                <div className=" text-xs !font-extralight leading-6 h-30 overflow-y-auto pr-8 ">{result}</div>
                <button disabled={result === ''} className="absolute right-4 bottom-2" onClick={() => keyboardClickHandler()}>
                    <span className={`w-7 h-7 md:w-6 md:h-6 !font-mont ${result === '' ? 'bg-neutral-500' : 'bg-primary-1'} flex justify-center items-center rounded-md cursor-pointer`}><Keyboard /></span>
                </button>
            </div>
            <div className="flex justify-end my-3">
                <button disabled={result === ""} className=" mr-1" onClick={onCopy}><CopyIcon /></button>
                <button disabled={result === "" || isEdited} onClick={onRepeat}><RepeatIcon /></button>
            </div>
            <button
                className="btn btn-blue hover:opacity-90 w-full"
                disabled={title === '' || loading || isEdited || title === searched}
                onClick={promptHandler}
            >
                {loading && <ImSpinner9 className="animate-spin" />}
                {t('convert_prompt')}
            </button>
        </div>
    );
};

export default PromptAdvisorModal;
