import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type PageRangeSelectorTypes = {
    maxLength:number,
    from: number,
    to: number,
    setValues:any;
    setRangeVisible: any;
  };
const PageRangeSelector = ({ maxLength, from, to, setValues,setRangeVisible }:PageRangeSelectorTypes) => {
  const [fromValue, setFromValue] = useState(from);
  const [toValue, setToValue] = useState(to);
  const { t } = useTranslation('translation');

  useEffect(() => {
    setFromValue(from);
    setToValue(to);
  }, [from, to]);

  const handleFromChange = (e:any) => {
    const value = parseInt(e.target.value, 10);
    if(value <= maxLength || !value){
      setFromValue(value);
      setValues({ from: value, to: toValue });
    }
  };

  const handleToChange = (e:any) => {
    const value = parseInt(e.target.value, 10);
    if(value <= maxLength || !value){
      setToValue(value);
      setValues({ from: fromValue, to: value });
    }
  };

  const clearRange = () => {
    setRangeVisible(false);
    setFromValue(1);
    setToValue(maxLength > 5 ? 5 : maxLength);
    setValues({ from: 1, to: maxLength > 5 ? 5 : maxLength });
  };

  return (
    <div className="flex items-center space-x-4 justify-between w-full">
        <div className="flex space-x-4 items-center">
      <div className="flex items-center space-x-2 border-[1.5px] border-black rounded">
        <label className="font-medium mx-3 text-black text-s">{t('from')}</label>
        <input
          type="number"
          value={fromValue}
          onChange={handleFromChange}
          min="1"
          max={maxLength}
          className="w-24 border-l-[1.5px] border-black px-2 py-1 text-center focus:outline-none custom-number-input text-black"
        />
      </div>
      <div className="flex items-center space-x-2 border-[1.5px] border-black rounded">
        <label className="font-medium mx-3 text-black text-s">{t('to')}</label>
        <input
          type="number"
          value={toValue}
          onChange={handleToChange}
          min={fromValue}
          max={maxLength}
          className="w-24 border-l-[1.5px] border-black px-2 py-1 text-center focus:outline-none custom-number-input custom-number-input text-black"
        />
      </div>
      </div>
      <button
        onClick={clearRange}
        className=" underline flex items-center text-[#0084FF] text-medium"
      >
        {t("clear_range")}  ✕
      </button>
    </div>
  );
};

export default PageRangeSelector;