import React, { useState, useEffect, useRef } from "react";
import Notify from "@/components/Notify";
import { toast } from "react-hot-toast";
import Icon from "@/components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import UploadLiveNoteBookModal from "../Modal/UploadLiveNoteBookModal";
import Select from "@/components/Select";
import { PitchOptions, modesList, speeds } from "@/constants/audio";
import { getPlainTextFromHTML } from "lib/getPlainText";
import { isEmpty, size } from "lodash";
import { getVoicesList } from "store/actions/voicesList.actions";
import {
  generateAudio,
  getInprogressAudios,
} from "store/actions/audio.actions";
import { TextInput } from "flowbite-react";
import {
  setAudioDetails,
  setIsAudioGenerated,
} from "store/slices/TextToSpeechSlice";
import { setFromAudioSummary } from "store/slices/AudioSlice";
import { useMediaQuery } from "react-responsive";
import { Language, SpeedIcon, VoiceIcon, VoicePitch } from "../SVGs";
import { loadDecryptedValue } from "lib/encryption";
import { USER_LOCAL } from "@/constants/user";
const LanguageDetect = require("languagedetect");

const lngDetector = new LanguageDetect();

const TextToSpeech = ({fromFeature}: any) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation("translation");
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const genders = [
    {
      id: "0",
      title: t("female"),
      // title: "Female",
      value: "Female",
    },
    {
      id: "1",
      title: t("male"),
      // title: 'Male',
      value: "Male",
    },
  ];

  const voicesListData = useSelector((state: any) => state.voicesListStore);
  const audioData = useSelector((state: any) => state.audioStore);
  const { inProgressAudios, createAudio } = audioData;
  const { voicesList } = voicesListData;
  const [detectedAllLanguages, setDetectedAllLanguages] = useState<any>([
    voicesList?.data[0],
  ]);
  const [speed, setSpeed] = useState<any>(speeds[1]);
  const [playbackRate, setPlaybackRate] = useState<any>(1);
  const [vList, setVList] = useState<any>([]);
  const { fileText } = useSelector((state: any) => state.TextToSpeechSlice);

  const [gender, setGender] = useState<any>(genders[0]);
  const [language, setLanguage] = useState<any>(null);
  const [voice, setVoice] = useState<any>(language?.gender?.female[0]);
  const [loading, setLoading] = useState(false);
  const [pitch, setpitch] = useState<any>(null);

  const session = loadDecryptedValue(USER_LOCAL.GUEST);

  useEffect(() => {
    const payload = {
      gender: gender,
      speed: speed,
      language: language,
      voice: voice,
    };
    if (router.pathname?.includes("/features")) {
      if (session) {
        dispatch(setAudioDetails(payload));
      }
    } else {
      dispatch(setAudioDetails(payload));
    }
  }, [gender, language, voice, speed]);

  useEffect(() => {
    if (router.pathname == "/features") {
      if (session) {
        dispatch(getInprogressAudios());
        dispatch(getVoicesList())
          .unwrap()
          .then((res: any) => {
            setVList([...res.data]);
          });
      }
    } else {
      dispatch(getInprogressAudios());
      dispatch(getVoicesList())
        .unwrap()
        .then((res: any) => {
          setVList([...res.data]);
        });
    }
  }, []);

  const handleGenderChange = (value: any) => {
    try {
      setGender({ ...value });
      if (value.value.toLowerCase() == "male") {
        setVoice(language.gender.male[0]);
      } else if (value.value.toLowerCase() == "female") {
        setVoice(language.gender.female[0]);
      }
    } catch (error) {}
  };
  const handleLanguageChange = (value: any) => {
    try {
      setLanguage({ ...value });
      if (gender.value.toLowerCase() == "male") {
        setVoice(value.gender.male[0]);
      } else if (gender.value.toLowerCase() == "female") {
        setVoice(value.gender.female[0]);
      }
    } catch (error) {}
  };
  const handleSpeed = (value: any) => {
    try {
      if (value.title === "Normal") {
        setPlaybackRate(1);
      } else {
        setPlaybackRate(parseFloat(value.title));
      }
      setSpeed(value);
    } catch (error) {}
  };

  const detectSingleLanguage = (text: any) => {
    try {
      const result = lngDetector.detect(text, 1); // Get only the top result
      return result.length > 0 ? result[0][0] : null; // Extract the language code
    } catch (error) {
      return null;
    }
  };
  const handleSetDetectedAllLanguages = (languges: any) => {
    try {
      const items: any = [];
      for (const languageData of languges) {
        items.push({
          id: languageData.id,
          title: languageData.title,
          ...languageData,
        });
      }
      setDetectedAllLanguages(items);
    } catch (error) {}
  };
  const handleSetDefaultLanguage = () => {
    try {
      if (!language) {
        if (vList && vList.length > 0) {
          const languagedetected = getLanguages(vList);
          const lan = { ...languagedetected[0] };
          setLanguage({ ...languagedetected[0] });
          if (lan && lan.gender && lan.gender.female) {
            setVoice(lan.gender.female[0]);
          } else if (lan && lan.gender && lan.gender.male) {
            setVoice(lan.gender.male[0]);
          }
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getLanguages = (voiceListArr: any, language: string = "english") => {
    const detectedLanguages = voiceListArr?.filter((lang: any) =>
      lang?.languageName?.toLowerCase().includes(language?.toLowerCase())
    );
    return detectedLanguages &&
      detectedLanguages.length &&
      detectedLanguages.length > 0
      ? detectedLanguages
      : null;
  };

  const setDetectedLanguages = (
    languageLists: any,
    concatenatedResponses: any
  ) => {
    const detectedLanguage = detectSingleLanguage(
      getPlainTextFromHTML(concatenatedResponses)
    );
    if (detectedLanguage) {
      const detectedLanguageObj = getLanguages(languageLists, detectedLanguage);
      handleSetDetectedAllLanguages(detectedLanguageObj);
      if (detectedLanguageObj) {
        const lan = { ...detectedLanguageObj[0] };
        setLanguage({ ...detectedLanguageObj[0] });
        setVoice(lan.gender.female[0]);
      } else {
        const languagedetected = getLanguages(languageLists);
        handleSetDetectedAllLanguages(languagedetected);
        const lan = { ...languagedetected[0] };
        setLanguage({ ...languagedetected[0] });
        setVoice(lan.gender.female[0]);
      }
    } else {
      handleSetDefaultLanguage();
    }
  };

  useEffect(() => {
    if (router.pathname?.includes("/features")) {
      if (session) {
        dispatch(getVoicesList())
          .unwrap()
          .then((res: any) => {
            setVList([...res.data]);
            setDetectedLanguages(
              res.data,
              fileText.length == 0 ? "Hello World" : fileText
            );
          });
      }
    } else {
      dispatch(getVoicesList())
        .unwrap()
        .then((res: any) => {
          setVList([...res.data]);
          setDetectedLanguages(
            res.data,
            fileText.length == 0 ? "Hello World" : fileText
          );
        });
    }
  }, [fileText]);

  const isAllowToGenerateAudio = () => {
    return (
      (inProgressAudios &&
        inProgressAudios.inProgressCount &&
        inProgressAudios.inProgressCount > 0) ||
      (createAudio && createAudio.loading)
    );
  };

  function replaceButtonWithBreak(input: string): string {
    // Define a regular expression to match the button element
    const buttonRegex =
      /<button[^>]*class=['"][^'"]*['"][^>]*>(?:.|\n)*?(\d+s)(?:.|\n)*?<\/button>/g;

    // Replace the matched button elements with the desired format
    return input.replace(buttonRegex, (match, p1) => {
      return `<break time="${p1}"/>`;
    });
  }

  const handleGenerateAudio = async () => {
    try {
      setLoading(true);
      const newString = replaceButtonWithBreak(fileText).replaceAll(
        /&nbsp;/g,
        " "
      );
      const audioData: any = {
        text: newString,
        gender: gender?.value,
      };
      toast(
        (t) => (
          <Notify iconError>
            <div className="mr-6 ml-3 h6">
              {"Your Audio is being Generated, Please Wait"}
            </div>
          </Notify>
        ),
        { duration: 3000 }
      );
      audioData.voiceName = voice?.name;
      audioData.voiceMode = "default";
      audioData.language = language.code;
      audioData.speed = speed.value || "1";
      audioData.speakerName = voice?.title;
      audioData.pitch = pitch?.value ?? 0;
      dispatch(setIsAudioGenerated(true));
      dispatch(setFromAudioSummary(false));
      dispatch(generateAudio(audioData))
        .unwrap()
        .then(() => setLoading(false))
        .catch((error: any) => {
          toast(
            (t) => (
              <Notify iconError>
                <div className="mr-6 ml-3 h6">
                  {"Error generating audio"}
                </div>
              </Notify>
            ),
            { duration: 3000 }
          );
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col justify-between items-center sm:flex-col sm:gap-3 sm:mb-2 px-9 md:px-6 h-[93%] -mt-[5%]">
        <UploadLiveNoteBookModal fromTextToSpeech />
        <div className=" w-full mt-5 pb-5">
          <Select
            className="w-full "
            classButton="bg-[#DAF8FF] dark:bg-[#DAF8FF] dark:text-n-6"
            IconComponent={() => <Language />}
            items={detectedAllLanguages}
            value={language}
            onChange={handleLanguageChange}
            placeholder={t("language")}
            arrowColor={"black"}
            fromFeature={fromFeature}
          />
          <Select
            className="w-full mt-3"
            classButton="bg-[#F0FAEB] dark:bg-[#F0FAEB] dark: text-n-6 "
            items={speeds}
            value={speed}
            onChange={handleSpeed}
            PreIcon={() => <SpeedIcon />}
            placeholder={t("speed")}
            title={t("speed")}
            fromFeature={fromFeature}
            up
            arrowColor={"black"}
          />
          <div className={`flex w-full lg:h-12 justify-between mr-1.5 2xl:mt-2 mt-4 rounded-md shadow-[0_0.125rem_0.25rem_rgba(0,0,0,0.15)] bg-[#F1EFFE] md:w-full md:mr-0  ${!fromFeature && "dark:bg-n-6 dark:shadow-[0_0.125rem_0.25rem_rgba(0,0,0,0.15),inset_0_0_0_0.0625rem_rgba(254,254,254,.1)]"}`}>
            <Select
              classButton={`shadow-none bg-transparent ui-open:shadow-none dark:bg-transparent dark:shadow-none h-12 ${fromFeature && 'text-black'}`}
              title={isMobile ? "" : t("voice")}
              items={genders}
              value={gender}
              onChange={handleGenderChange}
              PreIcon={() => <VoiceIcon />}
              fromFeature={fromFeature}
              arrowColor={fromFeature ? "black": undefined}
              up
            />
            {language?.gender &&
            language?.gender[gender?.value?.toLowerCase()]?.length > 0 ? (
              <Select
                classButton={`shadow-none bg-transparent ui-open:shadow-none dark:bg-transparent dark:shadow-none sm:px-1 ${fromFeature && 'text-black'} `}
                icon="volume"
                className=""
                items={language?.gender[gender?.value?.toLowerCase()] ?? []}
                value={voice}
                // disabled={!edit && !showGenerateAudioButton()}
                onChange={setVoice}
                fromFeature={fromFeature}
                up
                voice
                arrowColor={fromFeature ? "black": undefined}
              />
            ) : (
              language && (
                <>
                  <Icon name={"volume"} className=" self-center" />
                  <button className="btn-white btn-small hover:!bg-transparent md:w-[calc(50%-0.5rem)] flex items-center self-center mr-3">
                    <span className="!font-mont">
                      {language?.gender[gender?.value?.toLowerCase()][0]
                        ?.title ?? ""}
                    </span>
                  </button>
                </>
              )
            )}
          </div>
          <Select
            className="w-full mt-3"
            classButton="bg-[#FFE8D6] dark:bg-[#FFE8D6] dark:text-n-6"
            IconComponent={() => <VoicePitch />}
            items={PitchOptions}
            value={pitch}
            onChange={setpitch}
            placeholder={t("pitch_voice")}
            arrowColor={"black"}
            fromFeature={fromFeature}
            up
          />
          <button
            disabled={
              !fileText || fileText == "" || isAllowToGenerateAudio() || loading
            }
            className="btn bg-[#8E55EA] w-full mt-10 text-white dark:bg-[#8E55EA] dark:border-[#8E55EA]"
            onClick={handleGenerateAudio}
          >
            {isAllowToGenerateAudio() || loading
              ? t("generating_yourAudio") + "..."
              : t("generate_audio")}
          </button>
        </div>
      </div>
    </>
  );
};

export default TextToSpeech;
