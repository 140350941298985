import { useColorMode } from "@chakra-ui/color-mode";
import { twMerge } from "tailwind-merge";
import Icon from "@/components/Icon";
import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

type ToggleThemeProps = {
  visible?: boolean;
};

const LandingLightDark = ({ visible }: ToggleThemeProps) => {
  const { colorMode, setColorMode } = useColorMode();
  const { t } = useTranslation("translation");

  useLayoutEffect(() => {
    setColorMode("dark");
  }, []);

  const items = [
    {
      title: t("dark"),
      icon: "moon",
      active: colorMode === "dark",
      onClick: () => setColorMode("dark"),
    },
    {
      title: t("light"),
      icon: "sun",
      active: colorMode === "light",
      onClick: () => setColorMode("light"),
    },
  ];

  return (
    <div
      className={`
            ${
              !visible &&
              `relative flex w-full p-1 bg-lightGray-50 dark:bg-n-6 rounded-xl before:absolute before:left-1 before:top-1 before:bottom-1 before:w-[calc(50%-0.25rem)] before:bg-white dark:before:bg-n-7 before:rounded-[0.625rem] before:transition-all ${
                colorMode === "light" && "before:translate-x-full"
              }`
            }`}
    >
      {items &&
        items.map((item, index) => (
          <button
            className={twMerge(
              `relative z-1 group flex justify-center items-center
                         ${
                           visible
                             ? `flex w-full h-16 rounded-xl bg-lightGray-50 dark:bg-n-6 md:w-8 md:h-8 md:mx-auto ${
                                 item.active && "hidden"
                               }`
                             : `h-10 basis-1/2 base2 font-mont font-semibold text-black dark:text-n-4 transition-colors hover:text-black dark:hover:text-n-1 ${
                                 item.active
                                   ? "text-n-6 dark:text-n-1"
                                   : "text-n-4"
                               }`
                         }`
            )}
            key={index}
            onClick={item.onClick}
          >
            <Icon
              className={`fill-n-6 dark:fill-n-4 transition-colors group-hover:fill-n-6 dark:group-hover:fill-n-1 ${
                !visible && "mr-3"
              } ${
                item.active && !visible
                  ? "fill-black dark:fill-n-1"
                  : "fill-n-4"
              }`}
              name={item.icon}
            />
            {!visible && item.title}
          </button>
        ))}
    </div>
  );
};

export default LandingLightDark;
