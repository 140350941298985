import { createSlice } from "@reduxjs/toolkit";
import {
  createGoalHandler,
  getAllGoals,
  updateGoalById,
  deleteGoalById,
} from "../actions/goal.actions";
import { GOAL_STATUS } from "@/constants/goals";
import { RES_PER_PAGE } from "@/constants/pagination";

export const initialState = {
  createGoal: {
    data: null,
    loading: false,
    error: null,
  },
  allGoalList: {
    data: {
      completedGoals: { goals: [], currentPage: 1, totalGoals: 0, perPage: RES_PER_PAGE },
      inProgressGoals: { goals: [], currentPage: 1, totalGoals: 0, perPage: RES_PER_PAGE },
    },
    loading: false,
    error: null,
  },
  updatedGoal: {
    data: null,
    loading: false,
    error: null,
  },
  deletedGoal: {
    data: null,
    loading: false,
    error: null,
  },
  goalState: {
    edit: false,
    selectedItem: null,
    addGoalSide: false,
    goalsidebar: false,
    addGoalSideBar: false,
    addGoalRightSideBar: false,
  },
  refreshGoals:false

};

export const goalSlice = createSlice({
  name: "goalStore",
  initialState,
  reducers: {
    resetAllGoalData(state, action) {
      return initialState;
    },
    resetCreateGoal(state, action) {
      state.createGoal.loading = false;
      state.createGoal.data = null;
      state.createGoal.error = null;
    },
    resetDeletedGoal(state, action) {
      state.deletedGoal.loading = false;
      state.deletedGoal.data = null;
      state.deletedGoal.error = null;
    },
    resetUpdatedGoal(state, action) {
      state.updatedGoal.loading = false;
      state.updatedGoal.data = null;
      state.updatedGoal.error = null;
    },
    setGoalEdit(state, action) {
      state.goalState.edit = action.payload;
    },
    setSelectedGoal(state, action) {
      state.goalState.selectedItem = action.payload;
    },
    setAddGoalSide(state, action) {
      state.goalState.addGoalSide = action.payload;
    },
    setGoalSidebar(state, action) {
      state.goalState.goalsidebar = action.payload;
    },
    setAddGoalSidebar(state, action) {
      state.goalState.addGoalSideBar = action.payload;
    },
    setAddGoalRightSidebar(state, action) {
      state.goalState.addGoalRightSideBar = action.payload;
    },
  },
  extraReducers: {
    [createGoalHandler.pending.type]: (state, action) => {
      state.createGoal.loading = true;
      state.createGoal.data = null;
      state.createGoal.error = null;
    },
    [createGoalHandler.fulfilled.type]: (state, action) => {
      state.createGoal.loading = false;
      state.createGoal.data = action.payload.data;
      state.createGoal.error = null;
      state.refreshGoals = !state.refreshGoals;
    },
    [createGoalHandler.rejected.type]: (state, action) => {
      state.createGoal.data = null;
      state.createGoal.loading = false;
      state.createGoal.error = action.error?.message;
    },
    [getAllGoals.pending.type]: (state, action) => {
      state.allGoalList.loading = true;
      state.allGoalList.error = null;
    },
    [getAllGoals.fulfilled.type]: (state, action) => {
      state.allGoalList.loading = false;
      state.allGoalList.error = null;

      if (action.meta.arg.page === 1 && action.meta.arg.status === GOAL_STATUS.IN_PROGRESS) {
        state.allGoalList.data.inProgressGoals.goals = action.payload.data.goals;
        state.allGoalList.data.inProgressGoals.totalGoals = action.payload.data.totalGoals;
        state.allGoalList.data.inProgressGoals.currentPage = action.payload.data.currentPage;
      } else if(action.meta.arg.page === 1 && action.meta.arg.status === GOAL_STATUS.COMPLETED) {
        state.allGoalList.data.completedGoals.goals = action.payload.data.goals;
        state.allGoalList.data.completedGoals.totalGoals = action.payload.data.totalGoals;
        state.allGoalList.data.completedGoals.currentPage = action.payload.data.currentPage;
      } else if(action.meta.arg.status === GOAL_STATUS.COMPLETED) {
        // @ts-ignore
        state.allGoalList.data.completedGoals.goals = [ ...state.allGoalList.data.completedGoals.goals, ...action.payload.data.goals];
        state.allGoalList.data.completedGoals.currentPage = action.payload.data.currentPage;
        state.allGoalList.data.completedGoals.totalGoals = action.payload.data.totalGoals;
      } else if(action.meta.arg.status === GOAL_STATUS.IN_PROGRESS) {
        // @ts-ignore
        state.allGoalList.data.inProgressGoals.goals = [ ...state.allGoalList.data.inProgressGoals.goals, ...action.payload.data.goals];
        state.allGoalList.data.inProgressGoals.totalGoals = action.payload.data.totalGoals;
        state.allGoalList.data.inProgressGoals.currentPage = action.payload.data.currentPage;
      }; 
    },
    [getAllGoals.rejected.type]: (state, action) => {
      state.allGoalList.loading = false;
      state.allGoalList.error = action.error?.message;
    },
    [updateGoalById.pending.type]: (state, action) => {
      state.updatedGoal.loading = true;
      state.updatedGoal.data = null;
      state.updatedGoal.error = null;
    },
    [updateGoalById.fulfilled.type]: (state, action) => {
      const updatedGoal = action.payload.data;
      state.updatedGoal.loading = false;
      state.updatedGoal.data = updatedGoal;
      state.updatedGoal.error = null;

      let goalIndex = -1;
      if(updatedGoal.status === GOAL_STATUS.COMPLETED) {
        goalIndex = state.allGoalList.data.completedGoals.goals.findIndex((goal: any) => goal.id === updatedGoal.id);
      } else if (updatedGoal.status === GOAL_STATUS.IN_PROGRESS) {
        goalIndex = state.allGoalList.data.inProgressGoals.goals.findIndex((goal: any) => goal.id === updatedGoal.id);
      }

      if (goalIndex !== -1) {
        if (updatedGoal.status === GOAL_STATUS.IN_PROGRESS) {
          const inProgressGoals = state.allGoalList.data.inProgressGoals.goals;
          inProgressGoals.splice(goalIndex, 1);
          // @ts-ignore
          state.allGoalList.data.inProgressGoals.goals[goalIndex] = updatedGoal;
        } else {
          const completedGoals = state.allGoalList.data.completedGoals.goals;
          completedGoals.splice(goalIndex, 1);
          // @ts-ignore
          state.allGoalList.data.completedGoals.goals[goalIndex] = updatedGoal;
        }
      }
      state.refreshGoals = !state.refreshGoals;
    },
    [updateGoalById.rejected.type]: (state, action) => {
      state.updatedGoal.data = null;
      state.updatedGoal.loading = false;
      state.updatedGoal.error = action.error?.message;
    },
    [deleteGoalById.pending.type]: (state, action) => {
      state.deletedGoal.loading = true;
      state.deletedGoal.data = null;
      state.deletedGoal.error = null;
    },
    [deleteGoalById.fulfilled.type]: (state, action) => {
      state.deletedGoal.loading = false;
      state.deletedGoal.data = action.payload.data;
      state.deletedGoal.error = null;

      const item = action.payload.data;
      if (item.status === GOAL_STATUS.COMPLETED) {
        const allGoalItems = state.allGoalList.data.completedGoals.goals.filter((goal: any) => goal.id !== item.id);
        state.allGoalList.data.completedGoals.goals = allGoalItems;
      } else {
        const allGoalItems = state.allGoalList.data.inProgressGoals.goals.filter((goal: any) => goal.id !== item.id);
        state.allGoalList.data.inProgressGoals.goals = allGoalItems;
      }

      state.refreshGoals = !state.refreshGoals;
    },
    [deleteGoalById.rejected.type]: (state, action) => {
      state.deletedGoal.data = null;
      state.deletedGoal.loading = false;
      state.deletedGoal.error = action.error?.message;
    },
  },
});

export const {
  resetAllGoalData,
  resetCreateGoal,
  resetDeletedGoal,
  resetUpdatedGoal,
  setGoalEdit,
  setSelectedGoal,
  setAddGoalSide,
  setGoalSidebar,
  setAddGoalSidebar,
  setAddGoalRightSidebar
} = goalSlice.actions;

export default goalSlice.reducer;
