export const translate=(lt:(string: string)=>{})=> {
  const colors = [
  {
    id: "0",
    title: lt('yellow'),
    color: "#FFFF00",
  },
  {
    id: "1",
    title: lt('blue'),
    color: "#3E90F0",
  },
  {
    id: "2",
    title: lt('red'),
    color: "#FF0000",
  },
  {
    id: "3",
    title: lt('purple'),
    color: "#800080",
  },
  {
    id: "4",
    title: lt('green'),
    color: "#7ECE18",
  },
  {
    id: "5",
    title: lt('brown'),
    color: "#964B00",
  },
  {
    id: "6",
    title: lt('orange'),
    color: "#FFA500",
  },
];
return colors;
}
