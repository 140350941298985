import React from "react";
import Device from "./Device";
import { useDispatch, useSelector } from "react-redux";
import { deleteFromAllDevices } from "store/actions/device.actions";
import { resetAllUserData } from "store/slices/AuthSlice";

import { useRouter } from "next/router";
import { resetAllAudioData } from "store/slices/AudioSlice";
import { resetPhotoGenerations } from "store/slices/PhotoGenerationSlice";
import { resetAllGoalData } from "store/slices/GoalSlice";
import { resetImageLibraryData } from "store/slices/ImageLibrarySlice";
import { resetAllPlanData } from "store/slices/PlanSlice";
import { resetAllDevicesData } from "store/slices/DeviceSlice";
import { clearLogoutStorage } from "lib/user";
import { ROUTES } from "@/constants/routes";
import { resetAllChatsData } from "store/slices/ChatGptSlice";
import { useTranslation } from "react-i18next";

type SessionsProps = {};

const Sessions = ({}: SessionsProps) => {
  const router = useRouter();
    const dispatch: any = useDispatch();
    const { t } = useTranslation('translation');
    const devicesData = useSelector((state: any) => state.deviceStore);

    const { allDevicesList, deleteAllDevices } = devicesData;

    const handleDeleteDevices = () => {
        dispatch(deleteFromAllDevices()).unwrap().then(()=>{
          dispatch(resetAllUserData(null));
          dispatch(resetAllChatsData(null));
          dispatch(resetAllAudioData(null));
          dispatch(resetPhotoGenerations(null));
          dispatch(resetAllGoalData(null));
          dispatch(resetImageLibraryData(null));
          dispatch(resetPhotoGenerations(null));
          dispatch(resetAllPlanData(null));
          dispatch(resetAllDevicesData(null));
          clearLogoutStorage();
          router.push(ROUTES.SIGNIN);
        });
    }

    return (
    <>
      <div className="mb-8 h4 md:mb-6 !font-mont !font-extrabold">{t('your_session')}</div>
      <div className="!font-mont !font-normal mb-8 base2 text-n-4 md:mb-6">
        {t('session_desc')}
      </div>
      <div className="py-3 base2 text-n-4 !font-mont !font-bold">{t('devices')}</div>
      <div className="mb-6 max-h-[400px] grow overflow-auto scrollbar-none">
        {allDevicesList?.data?.map((device: any) => (
          <Device item={device} key={device.id}/>
        ))}
      </div>
      <button disabled={deleteAllDevices.loading} onClick={handleDeleteDevices} className="btn-blue w-full grow">{t('signOut_device')}</button>
    </>
  );
};

export default Sessions;
