import { HttpService } from "./base.service";

class PlanService extends HttpService {
  private readonly prefix: string = "subscriptions";
  show = (): Promise<any> => this.get(this.prefix + "/index");
  planSubscrition = (body: {
    planId: string;
    payId: any;
    isVerified: any;
    referralCode?: any;
    mPlan?: any;
    fiftyPercentOff?:any;
  }): Promise<any> => {
    return this.post(`${this.prefix}/plan-subscription`, body);
  };
  updateSubscrition = (body: {
    planId: string;
    isVerified: any;
  }): Promise<any> => {
    return this.post(`${this.prefix}/update-subscription`, body);
  };
  updateSubscritionThroughCard = (body: {
    planId: string;
    payId: any;
    isVerified: any;
    referralCode?: any;
    mPlan?: any;
  }): Promise<any> => {
    return this.post(`${this.prefix}/update-subscription-through-card`, body);
  };
  verifyReferralCode = (body: {
    referralCode: string;
    plan?: any;
  }): Promise<any> => {
    return this.post(`${this.prefix}/verifyReferralCode`, body);
  };
  paymentIntent = (item: any): Promise<any> => {
    return this.post(`${this.prefix}/create-payment-intent`, item);
  };
  billingPortal = (): Promise<any> => this.get(this.prefix + "/billingPortal");
  customPlan = (data: any): Promise<any> => {
    return this.post(`${this.prefix}/custom-plan`, data);
  };
}

export const planService = new PlanService();
