import he from 'he';

export const getPlainTextFromHTML = (content = "") => {
  let decodedContent = he.decode(content);
  let plainText = decodedContent.replace(/<[^>]*>/g, "");
  plainText = plainText.replace(/={2,}/g, ' ');
  plainText = plainText.replace(/\*{2,}/g, ' ');
  return plainText.replace(/\s+/g, " ").trim();
};

export const getPlainTextFromMarkdown = (content = "") => {
  // Remove emphasis (bold and italic)
  let plainText = content.replace(/(\*\*|__)(.*?)\1/g, "$2");
  plainText = plainText.replace(/(\*|_)(.*?)\1/g, "$2");
  // plainText = plainText.replace(/={2,}/g, ' ');

  // Remove headers
  plainText = plainText.replace(/#{1,6}\s/g, "");

  // Remove lists and bullets
  plainText = plainText.replace(/(\d+\.|\*|-)\s/g, "");

  // Remove links
  plainText = plainText.replace(/\[([^\]]+)\]\([^\)]+\)/g, "$1");

  // Remove inline code
  plainText = plainText.replace(/`([^`]+)`/g, "$1");

  // Remove block code
  plainText = plainText.replace(/```([\s\S]*?)```/g, "");

  // Remove horizontal rules
  plainText = plainText.replace(/(\*\*\*|---|\*\*\*)/g, "");

  // Remove lines composed of equal signs, dashes, or asterisks
  plainText = plainText.replace(/^[=\-*]+\s*$/gm, "");

  // Remove other HTML tags
  plainText = plainText.replace(/<[^>]*>/g, "");

  // Replace consecutive spaces with a single space and trim
  return plainText.replace(/\s+/g, " ").trim();
};

export const removeMarkdownHorizontalRules = (content = "") => {
  return content.replace(/^[=\-*]+\s*$/gm, "");
};

export const htmlToMarkdown = (html: any)=> {
  // Convert <strong> and <b> to **
  html = html.replace(/<(strong|b)>(.*?)<\/\1>/gi, '**$2**');

  // Convert <em> and <i> to *
  html = html.replace(/<(em|i)>(.*?)<\/\1>/gi, '*$2*');

  // Convert <a> to [text](url)
  html = html.replace(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/gi, '[$2]($1)');

  // Convert <p> to \n\n
  html = html.replace(/<p>(.*?)<\/p>/gi, '$1\n\n');

  // Convert <h1> to # Heading 1
  html = html.replace(/<h1>(.*?)<\/h1>/gi, '*$1*\n');

  // Convert <h2> to ## Heading 2
  html = html.replace(/<h2>(.*?)<\/h2>/gi, '**$1**\n');

  // Convert <h3> to ### Heading 3
  html = html.replace(/<h3>(.*?)<\/h3>/gi, '***$1***\n');

  // Convert <ul> to unordered list
  html = html.replace(/<ul>(.*?)<\/ul>/gi, (match: any) => {
    const listItems = match.match(/<li>(.*?)<\/li>/gi);
    if (listItems) {
      const listItemText = listItems.map((item: any) => item.replace(/<li>(.*?)<\/li>/i, '*$1*')).join('\n');
      return `\n${listItemText}\n`;
    }
    return match;
  });

  return html;
}