import React from "react";
import Image from "../Image";
import { useTranslation } from "react-i18next";

const NoChatlistPlaceholder = () => {
  const { t } = useTranslation('translation');
  return (
    <div className="flex flex-col justify-center items-center overflow-y-auto scroll-smooth scrollbar-none">
      <Image
        src={"https://lokichatdev.blob.core.windows.net/images/loki/no-chat-placeholder.png"}
        alt={"placeholder"}
        width={291}
        height={171}
        className=""
      />
      <h4 className="h5 mb-1 !font-mont">{t('noChat_start')}</h4>
      <p className="text-sm text-center  text-[#464665] dark:text-white">
        {t('start_chatAI')}
      </p>
    </div>
  );
};

export default NoChatlistPlaceholder;
