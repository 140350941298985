//@ts-ignore
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// @ts-ignore
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { createWrapper } from 'next-redux-wrapper';
import thunk from 'redux-thunk';

import authSlice from './slices/AuthSlice';
import chatGptSlice  from './slices/ChatGptSlice';
import audioSlice from './slices/AudioSlice';
import transcriptionSlice from './slices/TranscriptionSlice';
import translationSlice from './slices/TranslationSlice';
import voicesListSlice from './slices/VoicesListSlice';
import photoGenerationSlice from './slices/PhotoGenerationSlice';
import imageLibrarySlice from './slices/ImageLibrarySlice';
import planSlice from './slices/PlanSlice';
import goalSlice from './slices/GoalSlice';
import deviceSlice from './slices/DeviceSlice';
import superUserSlice from './slices/SuperUserSlice';
import notificationSlice from './slices/NotificationSlice';
import debateSlice from './slices/DebateSlice';
import preferenceSlice from './slices/PreferenceSlice';
import languageSlice from './slices/languageSlice';
import advisorSlice from './slices/AdvisorSlice';
import TextToSpeechSlice from './slices/TextToSpeechSlice';
import AudioSummarySlice from './slices/AudioSummarySlice';

import { REDUX_PERSISTOR } from '@/constants/user';
import { migrations } from './migrations';


// Create the root reducer by combining your slice reducers
const rootReducer = combineReducers({
  authStore: authSlice,
  audioStore: audioSlice,
  transcriptionStore: transcriptionSlice,
  translationStore:translationSlice,
  chatGptStore: chatGptSlice,
  voicesListStore: voicesListSlice,
  photoGenerateStore: photoGenerationSlice,
  imageLibraryStore: imageLibrarySlice,
  planStore: planSlice,
  goalStore: goalSlice,
  deviceStore: deviceSlice,
  superUserStore: superUserSlice,
  notificationStore: notificationSlice,
  debateStore: debateSlice,
  preferenceStore: preferenceSlice,
  language: languageSlice,
  advisorStore: advisorSlice,
  TextToSpeechSlice: TextToSpeechSlice,
  AudioSummarySlice: AudioSummarySlice,
});

// Configuration for redux-persist
const persistConfig = {
  key: REDUX_PERSISTOR.KEY,
  storage,
  transforms: [
    encryptTransform({
      secretKey: process.env.REDUX_SERCRET_KEY || 'test-enc-key-12frijuhbgfact'
    }),
  ],
  version: 2.33,
  migrate: createMigrate(migrations, {debug: true})
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with the persisted reducer and middleware
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

const makeStore = () =>{
  const store :any = configureStore({  reducer: persistedReducer, middleware: [thunk]});
  store.__persistor = persistStore(store);
  return store; 
}

// Initialize the persistor
export const persistor = createWrapper(makeStore);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;