import Image from "next/image";
import React, { useRef, useState } from "react";

type RoleCardProps = {
  img: string;
  title: string;
  selectedRole: string;
  setSelectedRole: React.Dispatch<React.SetStateAction<string>>;
};

export const RoleCard = ({
  img,
  title,
  selectedRole,
  setSelectedRole,
}: RoleCardProps) => {
  return (
    <div
      className={`flex small:mb-10 flex-col py-[51px] px-[20px] rounded-xl border w-[48%]  cursor-pointer ${
        selectedRole == title ? "bg-[#C0D6FF]" : "bg-white"
      }`}
      onClick={() => setSelectedRole(title)}
    >
      <figure className="flex justify-center  items-center pb-[39px] border-b-2 h-[110px]">
        <Image src={img} alt={img} height={100} width={100} />
      </figure>
      <div className="text-center mt-[30px] dark:text-black font-semibold">{title}</div>
    </div>
  );
};
