import axios, { CancelTokenStatic, CancelTokenSource } from "axios";
import Config from "../config/index";
import { encryptAndSave, loadDecryptedValue } from "lib/encryption";
import { USER_LOCAL } from "../constants/user";
import { IRefreshToken, ISession } from "interfaces/auth.interface";
import { clearLogoutStorage, getUserBrowserInfo, getUserIP } from "lib/user";
import { ROUTES } from "@/constants/routes";

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};
axios.defaults.timeout = 60 * 60 * 1000; // 1 hour in milliseconds
axios.interceptors.request.use(
  (config: any) => {
    if (!config.url?.includes("/auth") && !config.url?.includes("/subscriptions/index") && !config.url?.includes("/audio/show")) {
      let sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
      if (!sessions) {
        sessions = loadDecryptedValue(USER_LOCAL.GUEST);
        console.log(sessions)
        if(!sessions){
          clearLogoutStorage();
          localStorage.clear();
          window.location.href = ROUTES.SIGNIN;
        }
      }
      config.headers["Authorization"] = `Bearer ${sessions.token}`;
    }
    return config;
  },
  (error) => {
    console.log('request err', error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if(error.code === 'ERR_NETWORK') {
      console.log('error', error);
      const errorObj = {
        response: {
          statusText: error?.message ?? 'Network Error',
          data: {
            message: error?.message ?? 'Network Error'
          }
        }
      }

      return Promise.reject(errorObj);
    }

    if (error.response?.status === 403 || error.response?.status === 401) {
      if (window.location.pathname !== ROUTES.SIGNIN) {
        clearLogoutStorage();
        localStorage.clear();
        window.location.href = ROUTES.SIGNIN;
      }
    } else if (error.response?.status === 405 && !error.config.url?.includes("/login") && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(async function (resolve, reject) {
        let isGuest: boolean = false;
        let sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
        if(!sessions){
          console.log("not session");
          
          //commented to remove register guest
          // sessions = loadDecryptedValue(USER_LOCAL.GUEST);
          // isGuest = true;
        }
        const browserInfo = getUserBrowserInfo();
        const ipAddress = await getUserIP();

        const refreshTokenPayload: IRefreshToken | any = {
          refreshToken: sessions.refreshToken,
          userId: sessions.userId,
          ipAddress: ipAddress,
          os: browserInfo.os,
          browserName: browserInfo.browserName,
          browserVersion: browserInfo.browserVersion,
        };
        axios
          .post(Config.baseApiUrl + "/auth/refresh-token", refreshTokenPayload)
          .then(({ data }) => {
            const updatedResponse: ISession = {
              refreshToken: data.data.refreshToken,
              userId: sessions.userId,
              token: data.data.token,
              role: sessions?.role,
              loginType:sessions?.role
            };
            //commented to remove register guest
            // encryptAndSave(isGuest ? USER_LOCAL.GUEST : USER_LOCAL.LOCAL, JSON.stringify(updatedResponse));
            encryptAndSave(USER_LOCAL.LOCAL, JSON.stringify(updatedResponse));
            //commented to remove register guest
            
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.data.token;
            originalRequest.headers["Authorization"] = "Bearer " + data.data.token;
            processQueue(null, data.token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            console.log("erere", err);
            processQueue(err, null);
            if (window.location.pathname !== ROUTES.SIGNIN) {
              clearLogoutStorage();
              localStorage.clear();
              window.location.href = ROUTES.SIGNIN;
            }
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    console.log('response err', error);
    return Promise.reject(error);
  }
);

export class HttpService {
  CancelToken: CancelTokenStatic;
  source: CancelTokenSource;

  constructor() {
    this.CancelToken = axios.CancelToken;
    this.source = this.CancelToken.source();
  }

  static setToken(token: any): void {
    console.log("setting token:", token);
    //@ts-ignore
    axios.defaults.headers["Authorization"] = `Bearer ${token}`;
  }

  protected get = (url: string, params?: any): Promise<any> => {
    return axios.get(`${Config.baseApiUrl}/${url}`, {
      params,
      cancelToken: this.source.token,
    });
  };

  protected post = (url: string, body: any, options = {}): Promise<any> => {
    return axios.post(`${Config.baseApiUrl}/${url}`, body, {
      ...options,
      cancelToken: this.source.token,
    });
  };

  protected delete = (url: string, params?: any, data?: any): Promise<any> => {
    return axios.delete(`${Config.baseApiUrl}/${url}`, { params, data });
  };

  protected put = (url: string, body?: any, params?: any): Promise<any> => {
    return axios.put(`${Config.baseApiUrl}/${url}`, body, {
      ...params,
      cancelToken: this.source.token,
    });
  };

  private updateCancelToken() {
    this.source = this.CancelToken.source();
  }

  cancel = () => {
    this.source.cancel("Explicitly cancelled HTTP request");
    this.updateCancelToken();
  };
}
