export const StudioVoices = [
    "en-US-Studio-M",
    "en-US-Studio-O",
    "en-US-Studio-Q",
    "en-US-Journey-D",
    "en-US-Journey-F",
    "en-US-Polyglot-1",
    "en-US-News-K",
    "en-US-News-L",
    "en-US-News-N",
    "en-GB-Studio-B",
    "en-GB-Studio-C",
    "es-ES-Studio-C",
    "es-ES-Studio-F",
    "es-US-Studio-B",
    "fr-FR-Studio-D",
    "fr-FR-Studio-A",
    "de-DE-Studio-C",
    "de-DE-Studio-B",
    "pt-BR-Studio-B",
    "pt-BR-Studio-C"
]