import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import Icon from "@/components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { languageActions } from "../../../store/actions/language.actions";
import { RootState } from "store";

export default function SelectLanguage({ className, setLanguage }: any) {
  const dispatch: any = useDispatch();
  const { t, i18n } = useTranslation("translation");

  const language = useSelector((state: RootState) => state.language);

  const data = [
    {
      id: 1,
      img: "https://lokichatdev.blob.core.windows.net/languages/lang/uk.svg",
      title: t("English"),
      locale: "en-US",
    },
    {
      id: 2,
      img: "https://lokichatdev.blob.core.windows.net/languages/lang/spanish.svg",
      title: t("Spanish"),
      locale: "es",
    },
    {
      id: 3,
      img: "https://lokichatdev.blob.core.windows.net/languages/lang/french.png",
      title: t("French"),
      locale: "fr",
    },
    {
      id: 4,
      img: "https://lokichatdev.blob.core.windows.net/languages/lang/germany.png",
      title: t("German"),
      locale: "de",
    },
    {
      id: 5,
      img: "https://lokichatdev.blob.core.windows.net/languages/lang/italy.png",
      title: t("Italian"),
      locale: "it",
    },
    {
      id: 6,
      img: "https://lokichatdev.blob.core.windows.net/languages/lang/japan.png",
      title: t("Japanese"),
      locale: "ja",
    },
    {
      id: 7,
      img: "https://lokichatdev.blob.core.windows.net/languages/lang/norway.png",
      title: t("Norwegian"),
      locale: "nb",
    },
    {
      id: 8,
      img: "https://lokichatdev.blob.core.windows.net/languages/lang/portugal.png",
      title: t("Portuguese"),
      locale: "pt",
    },
    {
      id: 9,
      img: "https://lokichatdev.blob.core.windows.net/languages/lang/russia.png",
      title: t("Russian"),
      locale: "ru",
    },
    {
      id: 10,
      img: "https://lokichatdev.blob.core.windows.net/languages/lang/china.png",
      title: t("Chinese"),
      locale: "zh",
    },
    {
      id: 11,
      img: "https://lokichatdev.blob.core.windows.net/languages/lang/netherlands.png",
      title: t("Dutch"),
      locale: "nl",
    },
  ];

  const handleLanguageChange = (item: any) => {
    try {
      if (setLanguage) {
        setLanguage(item.id);
      }
      dispatch(languageActions.setLanguage(item.locale));
      i18n.changeLanguage(item.locale);
    } catch (error) {
      console.error("error is : ", error);
    }
  };

  return (
    <Listbox as="div" value={language} onChange={handleLanguageChange}>
      <div className="relative flex items-center">
        <Listbox.Button className="relative">
          <figure className="w-9 ss:w-6 ss:h-6 h-9 rounded-full">
            <Image
              src={
                data.find((item) => item.locale === language.language)?.img ||
                "https://lokichatdev.blob.core.windows.net/images/loki/symbol.svg"
              }
              alt="flag"
              fill
              className="h-full w-full rounded-full"
            />
          </figure>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={`${className} absolute top-full right-0 z-10 mt-1 w-96 overflow-auto rounded-lg py-4 bg-white dark:bg-n-5 text-xl focus:outline-none shadow-xl`}
          >
            <p className="text-base !font-extrabold px-4 pb-4 border-n-2 dark:border-n-4 border-b mb-4">
              {t("select_language")}
            </p>
            <div className="grid grid-cols-3 justify-between px-6 gap-4">
              {data &&
                data.map((item: any, idx: any) => (
                  <Listbox.Option
                    key={idx}
                    className={({ active }: any) =>
                      `relative flex flex-col items-center ${active ? "" : ""}`
                    }
                    value={item}
                  >
                    <div
                      className="relative cursor-pointer w-[50px] h-[50px] bg-[#E9EAEC] rounded-full border border-[#0BA259] flex justify-center items-center mb-[6px]"
                      onClick={() => handleLanguageChange(item)}
                    >
                      {item?.locale === language?.language && (
                        <button
                          className={`right-0 -top-2 rounded-full absolute w-6 h-6 bg-[#E9EAEC] flex justify-center items-center z-40`}
                        >
                          <span className="w-4 h-4 !font-mont rounded-full bg-[#0BA259] flex justify-center items-center">
                            <Icon className="fill-white" name={"check"} />
                          </span>
                        </button>
                      )}
                      <figure className="w-9 h-9 rounded-full relative">
                        <Image
                          src={item?.img}
                          alt="flag"
                          fill
                          className="h-full w-full rounded-full"
                        />
                      </figure>
                    </div>
                    <p className="text-sm !font-mont whitespace-nowrap">
                      {t(item?.title)}
                    </p>
                  </Listbox.Option>
                ))}
            </div>
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
