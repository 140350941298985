import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  verifyOtp,
  requestOtp,
  userOnBoardRole,
} from "store/actions/auth.actions";
import { size } from "lodash";
import { useColorMode } from "@chakra-ui/color-mode";
import { useTranslation } from "react-i18next";
import Loader from "@/components/Loader";
import Individual from "../../../../public/images/13.svg";
import Company from "../../../../public/images/29.svg";
import darkCompany from "../../../../public/images/Group.svg";
import darkIndividaul from "../../../../public/images/Group 1171275727.svg";
import { RoleCard } from "./component";

type OnBoardingProps = {
  setForgot?: any;
  setSelectedIndex?: any;
  setForgetSelectedIndex?: any;
  userEmail?: string;
};

const OnBoarding = ({
  setForgot,
  setForgetSelectedIndex,
  setSelectedIndex,
  userEmail,
}: OnBoardingProps) => {
  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation("auth");
  const { colorMode } = useColorMode();
  const isLightMode = colorMode === "light";

  const userData = useSelector((state: any) => state.authStore);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [fullCode, setFullCode] = useState("");

  const handleOnBoardRole = (event: any) => {
    event.preventDefault();
    const payload = {
      email: userEmail,
      onBoardRole: selectedRole,
    };
    dispatch(userOnBoardRole(payload));
  };

  return (
    <>
      <p className="text-center text-lg m-auto mb-[3rem] font-semibold">
        {/* {lt("onboard_desc")} */}
        We're excited to have you onboard. Are you registering as an individual
        or a company?
      </p>
      <div>
        <div className="flex small:flex-col small:items-center align-center justify-between mb-10 ">
          <RoleCard
            img={isLightMode ? Individual : darkIndividaul}
            title={"Individual"}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
          />
          <RoleCard
            img={isLightMode ? Company : darkCompany}
            title={"Company"}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
          />
        </div>
      </div>
      <button
        className="btn-blue btn-large w-full mb-6"
        type="submit"
        disabled={!selectedRole}
        onClick={handleOnBoardRole}
      >
        {userData?.verifyOtp?.loading ||
        userData?.verifyRefferalCode?.loading ? (
          <Loader color={"white"} />
        ) : (
          "Continue"
        )}
      </button>
    </>
  );
};

export default OnBoarding;
