import React from 'react'
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useColorMode } from "@chakra-ui/color-mode";

const ChatSearchSkelton = () => {
    const { colorMode } = useColorMode();
    const dark = colorMode === "dark";
  return (
    <div>
    <SkeletonTheme baseColor={dark?"#343839":""} highlightColor={dark?"#343839":""} >
        <div className='w-full relative '>
                <Skeleton containerClassName='block' className="w-full h-full" height={100}  inline={true} borderRadius="0.75rem" />
                <div className="absolute top-1/2 px-3 -translate-y-1/2 left-0 z-2  w-full">
                    <div className='flex justify-between items-center w-full'>
                        <div>
                      <div className='w-[200px] md:w-[200px] sm:w-[180px]'>
                   <Skeleton height={5} className='w-full' borderRadius="2px" highlightColor={ dark?"#777E90" :'#d8d8d8'}/>
                   </div>
                   <div className='w-[450px] md:w-[300px] sm:w-[200px]'>
                   <Skeleton height={5} className='w-full' borderRadius="2px" highlightColor={ dark?"#777E90" :'#d8d8d8'}/>
                   </div>
                   </div>
                   </div>
                   <div className='flex justify-between items-center'>
                   <div className='w-[150px]'>
                   <Skeleton height={5} className='w-full' borderRadius="2px" highlightColor={ dark?"#777E90" :'#d8d8d8'}/>
                   </div>
                      <div className='flex gap-3 items-center'>
                   <Skeleton height={20} width={100} borderRadius="4px" highlightColor={ dark?"#777E90" :'#d8d8d8'}/>
                   </div>
                   </div>
        </div>
        </div>
      </SkeletonTheme>
      </div>
  )
}

export default ChatSearchSkelton