import React from "react";
import { formatDateRelative, formatOnlyDate } from "lib/DateTime";
import { useDispatch } from "react-redux";
import { resetChatAndNotebooks, setSearchModal, setSelectedChatListItem, setSelectedNotebooks } from "store/slices/ChatGptSlice";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { resetAllAudioData, resetCreateAudioData } from "store/slices/AudioSlice";

type ItemProps = {
  title: any;
  date?: any;
  list: any;
};

const DebateItem = ({ title, date, list }: ItemProps) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation('translation');
  
  const handleJump = (item: any) => {
    dispatch(setSearchModal(false));
    dispatch(resetChatAndNotebooks(null));
    dispatch(setSelectedChatListItem(null));
    // dispatch(resetAllAudioData(null));
    dispatch(resetCreateAudioData(null));
    dispatch(setSelectedNotebooks([]));
    router.push(`/debate/${item.id}`);
  }

    return (
    <div>
      <div className="flex items-center py-3 md:pt-6">
        <div className="h6 !font-mont !font-semibold">{title}</div>
        {date && <div className="ml-5 caption1 text-n-4/75">{formatOnlyDate(date)}</div>} 
      </div>
      <div className="space-y-3">
        {list?.map((x: any) => (
          <div
          className="relative rounded-lg p-5 cursor-pointer transition-colors bg-n-3/50 dark:bg-n-6"
            key={x?.id}
            onClick={()=> handleJump(x)}
          >
            {/* <div className="w-[calc(100%-4rem)] pl-5"> */}
              <div className="mb-1 truncate base1 font-semibold">
                {x?.title}
              </div>
            {/* </div> */}
            <div className="caption1 text-n-4/50">
              {list?.createdAt}
            </div>
            <div className="flex justify-between items-center">
            <div className="flex items-center rounded-lg whitespace-nowrap text-xs text-n-7/75 dark:text-n-3/75">
            {t('last_edit')} {" "}
              <span className="mr-2 font-semibold !font-mont text-n-1 dark:text-n-7">
                {list?.id}
              </span>
              {formatDateRelative(x?.updatedAt)}
            </div>
              <div className="flex gap-2 items-center">
            <div onClick={()=> handleJump(x)} className="px-2 rounded bg-n-1 caption1 font-semibold text-n-4 md:right-0 dark:bg-n-5 dark:text-n-3">
              <span className="!font-mont">{t('jump')} {' '}</span>
            </div>
            {x?.unreadMessages != 0 && (<span className="w-7 h-7 text-xs flex justify-center items-center rounded-full bg-primary-1 text-white">{x?.unreadMessages}</span>)}
          </div>
         
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DebateItem;
