import { config } from "dotenv"
import Config from "./index";

export const SOCKET_OPTIONS = {
    //they are commented i uncommented for testing production
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
    transports: ["websocket"],
    upgrade: false,
}

export const SOCKET_URL = Config.backendBaseUrl;