import { ISuperUserData, IUpdateSuperUserRequest } from 'interfaces/superUser.interface';
import { HttpService } from './base.service';

class SuperUserService extends HttpService {
    private readonly prefix: string = "super-user";

    store = (data: ISuperUserData): Promise<any> => this.post(this.prefix + '/store', data);
    update = (data: IUpdateSuperUserRequest): Promise<any> => this.post(this.prefix + '/update', data);

    show = (): Promise<any> => this.get(this.prefix + '/show');
    index = (): Promise<any> => this.get(this.prefix + '/index');
}

export const superUserService = new SuperUserService();