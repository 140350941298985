import AES from "crypto-js/aes";
import CryptoJS from "crypto-js/core";

const phrase = "G0ldenEye";

const encryption = {
  encrypt(phraseToEncrypt: any) {
    return AES.encrypt(phraseToEncrypt, phrase).toString();
  },
  decrypt(ciphertext: any) {
    return AES.decrypt(ciphertext, phrase).toString(CryptoJS.enc.Utf8);
  },
};

export const encryptAndSave = (key: string, value: string) => {
  if (!key) {
    return null;
  }

  try {
    const encryptedValue = encryption.encrypt(value);
    localStorage.setItem(key, `"${encryptedValue}"`);
    console.log(key, 'saved ', encryptedValue);
    
    return true;
  } catch (e) {
    console.log("e", e);
    return null;
  }
};

export const loadDecryptedValue = (key: string) => {
    if (!key) {
      return null;
    }

    try {
      let item;
      if (key === "LOCAL" || key === 'GUEST') {
        item = localStorage.getItem(key);
      }
      if (!item) return null;
      const encryptedResult = encryption.decrypt(JSON.parse(item));
      return JSON.parse(encryptedResult||"{}");
    } catch (e) {
      return null;
    }
  }