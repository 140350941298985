import { AUDIO_PLAYER } from '@/constants/audio';
import { ROUTES } from '@/constants/routes';
import { isEmpty, size } from 'lodash';
import { useRouter } from 'next/router';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAudioItem, setAudioToPlay, setDrawerPlayingAudio, setIsPlaying, setLibraryPlayingAudio, setPlayingAudio, setPlayingDuration, setSidebarPlayingAudio, setVolume } from 'store/slices/AudioSlice';
import EditorCKEditor from '@/components/EditorCKEditor'
//@ts-ignore
import TurndownService from 'turndown';
import { convertMarkdownToHTML } from 'lib/notebooks';
import { useTranslation } from 'react-i18next';
import { estimateAudioDuration } from 'lib/chatMedia';
import { getPlainTextFromMarkdown } from 'lib/getPlainText';
import AudioProgressBar from './AudioProgressBar';
import { FaPlay, FaPause } from "react-icons/fa";
import { secondsToHMS } from 'lib/DateTime';
import { destroyAudio } from "store/actions/audio.actions";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import { RxCross2 } from 'react-icons/rx';
import WaveSurfer from 'wavesurfer.js';

const turndownS = new TurndownService();

const CustomAudioPlayer = ({
  audioUrl,
  onPlay,
  onPause,
  isPlaying,
  audioItem,
  isLibraryAudio,
  edit,
  onSave,
  setUpdatedContent,
  updatedContent,
  playbackRate,
  onCancel,
  isChatOrDebateAudio
}: any) => {

  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation('translation');

  const audioData = useSelector((state: any) => state.audioStore);
  const { createAudio, playingDuration } = audioData;
  const [totalDuration, setTotalDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [error, setError] = useState(null);
  const [estimatedDuration, setEstimatedDuration] = useState('00:00');
  const [chCount, setchCount] = useState<any>();
  const [playAudio, setPlayAudio] = React.useState(false);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(false);

  const audioRef = useRef<any>(null);
  const editorRef = useRef<any>(null);

  const waveformRef = useRef<any>();
  const wavesurfer = useRef<any>();

  useEffect(() => {
    if (audioUrl?.includes('storage.googleapis.com') && !wavesurfer.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#D7D7D7",
        progressColor: "#B1D9FF",
        cursorColor: "#9E9E9E",
        cursorWidth: 3,
        barWidth: 2,
        barRadius: 1,
        height: 30,
        normalize: true
      });

      wavesurfer.current.load(audioUrl);
      wavesurfer.current.on('ready', () => {
        setTotalDuration(wavesurfer.current.getDuration())
      });

      wavesurfer.current.on('audioprocess', () => {
        if (audioData.volume === 'player') {
          setCurrentTime(wavesurfer?.current?.getCurrentTime());
        }
      });

      wavesurfer.current.on('finish', () => {
        setPlayAudio(false);
        onPause();
        setTimeout(() => {
          setCurrentTime(0)
          wavesurfer.current.seekTo(0);
          dispatch(setIsPlaying(false));
        }, 500);
      });
    }

  }, [audioUrl]);


  useEffect(() => {

    if (audioData?.isPlaying && wavesurfer?.current && audioData?.isPlaying === audioItem?._id) {
      wavesurfer?.current.play();
      // wavesurfer.current.seekTo(playingDuration / wavesurfer.current.getDuration())
      setPlayAudio(true);
      dispatch(setVolume('player'));
    } else {
      wavesurfer?.current?.pause();
      setPlayAudio(false);
    }
  }, [audioData.isPlaying]);

  useEffect(() => {
    if (audioItem?._id === audioData?.audioItem?._id) {
      setCurrentTime(playingDuration)
    }
  }, [playingDuration])


  useEffect(() => {
    if (audioData?.volume === 'player') {
      dispatch(setPlayingDuration(currentTime));
    }
  }, [currentTime])


  const isValidSaveClick = () => {
    return size(updatedContent) <= 1;
  };

  const getSize = (t: string | any) => {
    const chWithoutSpaces = getPlainTextFromMarkdown(t)?.trim()?.replace(/\s/g, '')?.replace(/&nbsp;/g, '')
    const chWithoutEmojis = chWithoutSpaces.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD00-\uDFFF])/g, '');

    const chCount = chWithoutEmojis.length
    return chCount
  }
  useEffect(() => {
    const sz = getSize(updatedContent);
    setchCount(sz)
  }, [updatedContent])

  useEffect(() => {
    const result = estimateAudioDuration(updatedContent, playbackRate);
    setEstimatedDuration(result);
  }, [updatedContent]);

  useEffect(() => {
    changePlaybackSpeed(playbackRate);
  }, [playbackRate]);

  const changePlaybackSpeed = (speed = 1) => {
    if (audioRef.current) {
      audioRef.current.playbackRate = speed;
    }
  };

  const handleDestroyAudio = () => {
    if (deleteButtonDisabled) return;
    setDeleteButtonDisabled(true);
    const toastId = toast((t) => (
      <Notify className="md:flex-col md:items-center md:px-10" iconDelete>
        <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">
          {lt('del_audio')}
        </div>
        <div className="flex justify-center">
          <button
            className="btn-stroke-light btn-medium md:min-w-[6rem]"
            onClick={() => {
              setDeleteButtonDisabled(false);
              toast.dismiss(t.id);
            }}
          >
            {lt('cancel_btn')}
          </button>
          <button
            className="btn-blue btn-medium ml-3 md:min-w-[6rem]"
            onClick={() => {
              dispatch(destroyAudio(createAudio.data.data.id));
              setDeleteButtonDisabled(false);
              toast.dismiss(t.id);
            }}
          >
            {lt('yes')}
          </button>
        </div>
      </Notify>
    ), { duration: Infinity });
  };

  const handlePlayPause = () => {
    if (wavesurfer.current.isPlaying()) {
      wavesurfer.current.pause()
      setPlayAudio(false);
      dispatch(setIsPlaying(false));
    } else {
      wavesurfer.current.play();
      dispatch(setVolume('player'));
      dispatch(setAudioItem({ ...audioItem, url: audioUrl, _id: audioItem?._id }));
      dispatch(setIsPlaying(audioItem?._id));
      setPlayAudio(true)
    }
  }

  return (
    <div className='w-full'>
      <div className='flex gap-2 items-center bg-gray-750 dark:bg-gray-750/10 p-2 py-3 rounded-lg relative overflow-hidden z-1'>
        <button onClick={handlePlayPause} className='shrink-0 w-7 h-7 text-xs bg-white flex justify-center items-center rounded-full text-primary-1'>
          {(playAudio || (audioData.isPlaying === audioItem?._id)) ? <FaPause /> : <FaPlay />}
        </button>
        {isChatOrDebateAudio &&
          <button onClick={handleDestroyAudio} className='absolute top-1 right-2 text-black dark:text-white hover:text-red-500'>
            <RxCross2 />
          </button>
        }
        <div id="waveform" ref={waveformRef} className=" w-full" />
      </div>
      <div className='flex justify-between mt-2'>
        <p className='shrink-0 text-sm font-mont'>{secondsToHMS(currentTime)}</p>
        <p className='shrink-0 text-sm font-mont'>{secondsToHMS(totalDuration)}</p>
      </div>
      {edit && (
        <>
          <EditorCKEditor
            value={convertMarkdownToHTML(updatedContent)}
            onChange={(v: any) => {
              const updatedMarkdownText = turndownS.turndown(v)
              setUpdatedContent(updatedMarkdownText);
            }}
            editorRef={editorRef}
          />
          <div className="flex justify-between items-center sm:flex-col flex-row gap-5 px-3.5 pt-3.5 pb-2.5 border-t border-n-3 dark:border-n-5">
            <div className="caption1 text-n-4/75">
              {lt('estimate_duration')}: {estimatedDuration}
            </div>
            <div className="caption1 text-n-4/75 capitalize">
              {lt('characters')} {chCount}
            </div>
            <div className="flex gap-3">
              <button
                disabled={isValidSaveClick()}
                className="btn-blue btn-medium"
                onClick={onSave}
              >
                {lt('save')}
              </button>
              <button disabled={isValidSaveClick()} className="btn btn-medium" onClick={onCancel}>
                {lt('cancel_btn')}
              </button>
            </div>
          </div>
        </>
      )}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default CustomAudioPlayer;
