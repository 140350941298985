import { createSlice } from "@reduxjs/toolkit";
import {
  generateAudio,
  updateAudioById,
  destroyAudio,
  getAllAudioLibrary,
  deleteAudioLibrary,
  updateAudioLibrary,
  getInprogressAudios,
  updateAudioLibraryPrev,
  deleteAudioLibraryPrev,
  updateAudioLibraryShare,
  getAllAudioLibraryPrevMonth,
} from "../actions/audio.actions";

export const initialState = {
  createAudio: {
    data: {
      data: null,
      notebookIds: [],
    },
    loading: false,
    error: null,
    draggableLoading: false,
    draggableError: false,
    draggableSuccess:'NONE',
    draggableShow:false,
    message: null
  },
  destroyAudio: {
    data: null,
    loading: false,
    error: null,
  },
  audios: {
    data: [],
    totalCount: 0,
    loading: true,
    error: null,
  },
  inProgressAudios: {
    inProgressCount: 0,
    loading: false,
    error: null
  },
  prevAudios: {
    data: [],
    totalCount: 0,
    loading: true,
    error: null,
  },
  sidebarAudioData: { data: {} },
  playingAudio: { data: null },
  currentlyPlayingAudios: {
    libraryAudio: false,
    sidebarAudio: false,
    drawerAudio: false,
    audioToPlay: null
  },
  shareAudioData: {
    data: null,
    loading: false,
    error: null
  },
  playingDuration: 0,
  isPlaying: false,
  fromAudioSummary: false,
  audioItem: null,
  volume: null,
};

export const audioSlice = createSlice({
  name: "audioStore",
  initialState,
  reducers: {
    resetAllAudioData(state, action) {
      return initialState;
    },
    closeDraggable(state,action){
      state.createAudio.draggableShow = false;
    },
    resetAudio(state) {
      return { ...state, audios: initialState.audios };
    },
    resetCreateAudioLoadingError(state, action) {
      state.createAudio.loading = false;
      state.createAudio.error = null;
    },
    resetCreateAudioLoadingMessage(state, action) {
      state.createAudio.message = null;
    },
    resetCreateAudioData(state, action) {
      state.createAudio.loading = false;
      state.createAudio.error = null;
      state.createAudio.data.data = null;
      state.createAudio.data.notebookIds = [];
    },
    setPlayingAudio(state, action) {
      console.log('Im Called!');
      state.playingAudio.data = action.payload;
    },
    setPlayingDuration(state, action) {
      state.playingDuration = action.payload;
    },
    setIsPlaying(state, action) {
      state.isPlaying = action.payload
    },
    setDrawerPlayingAudio(state, action) {
      state.currentlyPlayingAudios.drawerAudio = action.payload;
    },
    setLibraryPlayingAudio(state, action) {
      state.currentlyPlayingAudios.libraryAudio = action.payload;
    },
    setSidebarPlayingAudio(state, action) {
      state.currentlyPlayingAudios.sidebarAudio = action.payload;
    },
    setAudioToPlay(state, action) {
      state.currentlyPlayingAudios.audioToPlay = action.payload;
    },
    setFromAudioSummary(state, action) {
      state.fromAudioSummary = action.payload;
    },
    resetAllPlayingAudios(state, action) {
      state.currentlyPlayingAudios.drawerAudio = false;
      state.currentlyPlayingAudios.libraryAudio = false;
      state.currentlyPlayingAudios.sidebarAudio = false;
      state.currentlyPlayingAudios.audioToPlay  = null;
    },
    setAudioItem(state, action) {
      state.audioItem = action.payload
    },
    setVolume(state, action) {
      state.volume = action.payload
    },
    updateLibraryAudiosData(state, action) {
      const audioData = action.payload;
      const updatedAudio = audioData.updatedAudio;
      if(audioData.isPrev) {
        const audioIndex =  state.prevAudios.data.findIndex((audio: any) => audio._id === updatedAudio._id);
        if(audioIndex !== -1) {
          // @ts-ignore
          state.prevAudios.data[audioIndex] = updatedAudio;
        }
      } else {
        const audioIndex =  state.audios.data.findIndex((audio: any) => audio._id === updatedAudio._id);
        if(audioIndex !== -1) {
          // @ts-ignore
          state.audios.data[audioIndex] = updatedAudio;
        }
      }
    }
  },
  extraReducers: {
    // Generate a new Audio
    [generateAudio.pending.type]: (state, action) => {
      state.createAudio.loading = true;
      state.createAudio.data.data = null;
      state.createAudio.data.notebookIds = action?.meta?.arg?.notebookIds || [];
      state.createAudio.error = null;
      state.createAudio.draggableLoading = true;
      state.createAudio.draggableShow = true;
      state.createAudio.draggableSuccess = 'PENDING';
    },
    [generateAudio.fulfilled.type]: (state, action) => {
      state.createAudio.data.data = action.payload.data;
      state.createAudio.loading = false;
      state.createAudio.error = null;
      state.createAudio.draggableLoading = false;
      state.createAudio.draggableError = false;
      if(action.payload && action?.payload?.status == 202){
        state.createAudio.draggableShow = false;
        state.createAudio.draggableSuccess = 'NONE';
        state.createAudio.message = action.payload.message;
        state.inProgressAudios.inProgressCount = 1;
      }
      else {
        state.createAudio.draggableShow = true;
        state.createAudio.draggableSuccess = 'OK';
        state.createAudio.message = null;
      }
      
    },
    [generateAudio.rejected.type]: (state, action) => {
      // toast.error(action.error?.message)
      state.createAudio.data.data = null;
      state.createAudio.data.notebookIds = [];
      state.createAudio.loading = false;
      state.createAudio.error = action.error?.message;
      state.createAudio.draggableLoading = false;
      state.createAudio.draggableShow = false;
      state.createAudio.draggableError = true;
      state.createAudio.draggableSuccess = 'REJECT';
    },
    // Update Audio By Id
    [updateAudioById.pending.type]: (state, action) => {
      state.createAudio.loading = true;
      state.createAudio.data.data = null;
      state.createAudio.error = null;
    },
    [updateAudioById.fulfilled.type]: (state, action) => {
      state.createAudio.data.data = action.payload.data;
      state.createAudio.loading = false;
      state.createAudio.error = null;

      if(action.payload && action?.payload?.status == 202){
        state.createAudio.message = action.payload.message;
        state.inProgressAudios.inProgressCount = 1;
      }
    },
    [updateAudioById.rejected.type]: (state, action) => {
      state.createAudio.data.data = null;
      state.createAudio.data.notebookIds = [];
      state.createAudio.loading = false;
      state.createAudio.error = action.error?.message;
    },
    // Delete Audio By Id
    [destroyAudio.pending.type]: (state, action) => {
      state.destroyAudio.loading = true;
      state.destroyAudio.data = null;
      state.destroyAudio.error = null;
    },
    [destroyAudio.fulfilled.type]: (state, action) => {
      state.destroyAudio.data = action.payload.data;
      state.createAudio = initialState.createAudio;
      state.destroyAudio.loading = false;
      state.destroyAudio.error = null;
    },
    [destroyAudio.rejected.type]: (state, action) => {
      state.destroyAudio.data = null;
      state.destroyAudio.loading = false;
      state.destroyAudio.error = action.error?.message;
    },
    [getInprogressAudios.pending.type]: (state,action)=>{
      state.inProgressAudios.loading = true;
    },
    [getInprogressAudios.fulfilled.type]: (state,action)=>{
      state.inProgressAudios.loading = false;
      state.inProgressAudios.inProgressCount = action.payload;
      state.audios.error = null;
    },
    [getInprogressAudios.rejected.type]: (state,action)=>{
      state.inProgressAudios.loading = false;
      state.inProgressAudios.inProgressCount = 0;
      state.audios.error = action.error?.message;
    },
    // Audio Library Implementations
    [getAllAudioLibrary.pending.type]: (state, action) => {
      state.audios.loading = true;
    },
    [getAllAudioLibrary.fulfilled.type]: (state, action) => {
      if (action.meta.arg.page === 1) {
        state.audios.data = action.payload.audios;
      } else {
        // @ts-ignore
        state.audios.data = [...state.audios.data, ...action.payload.audios];
      }
      state.audios.totalCount = action.payload.totalAudios;
      state.audios.loading = false;
      state.audios.error = null;
    },
    [getAllAudioLibrary.rejected.type]: (state, action) => {
      state.audios.loading = false;
      state.audios.data = [];
      state.audios.error = action.error?.message;
    },
    [getAllAudioLibraryPrevMonth.pending.type]: (state, action) => {
      state.prevAudios.loading = true;
    },
    [getAllAudioLibraryPrevMonth.fulfilled.type]: (state, action) => {
      if (action.meta.arg.page === 1) {
        state.prevAudios.data = action.payload.audios;
      } else {
        // @ts-ignore
        state.prevAudios.data = [
          ...state.prevAudios.data,
          ...action.payload.audios,
        ];
      }
      state.prevAudios.totalCount = action.payload.totalAudios;
      state.prevAudios.loading = false;
      state.prevAudios.error = null;
    },
    [getAllAudioLibraryPrevMonth.rejected.type]: (state, action) => {
      state.prevAudios.loading = false;
      state.prevAudios.data = [];
      state.prevAudios.error = action.error?.message;
    },
    [deleteAudioLibrary.fulfilled.type]: (state, action) => {
      const deletedAaudioIndex = state.audios.data.findIndex((audio: any) => audio._id == action.payload.data._id);
      if(deletedAaudioIndex > -1){
        state.audios.data.splice(deletedAaudioIndex, 1);
      }
      state.audios.totalCount = state.audios.totalCount - 1;
      state.audios.loading = false;
      state.audios.error = null;
    },
    [deleteAudioLibrary.rejected.type]: (state, action) => {
      state.audios.loading = false;
      state.audios.error = action.error?.message;
    },
    [deleteAudioLibraryPrev.fulfilled.type]: (state, action) => {
      state.prevAudios.data = [...state.prevAudios.data.filter((audio: any) => audio._id != action.payload.data._id)];
      state.prevAudios.totalCount = state.prevAudios.totalCount - 1;
      state.prevAudios.loading = false;
      state.prevAudios.error = null;
    },
    [deleteAudioLibraryPrev.rejected.type]: (state, action) => {
      state.prevAudios.loading = false;
      state.prevAudios.error = action.error?.message;
    },
    [updateAudioLibrary.fulfilled.type]: (state, action) => {
      const updatedNotebook = action.payload.data.updatedAudio;
      const notebookIndex = state.audios.data.findIndex((audio: any) => audio._id === updatedNotebook._id);
      if (notebookIndex !== -1) {
        // @ts-ignore
        state.audios.data[notebookIndex] = {...updatedNotebook};
      }
      state.audios.error = null;
    },
    [updateAudioLibrary.rejected.type]: (state, action) => {
      // state.audios.loading = false;
      state.audios.error = action.error?.message;
    },
    [updateAudioLibraryPrev.fulfilled.type]: (state, action) => {
      const updatedNotebook = action.payload.data.updatedAudio;
      const notebookIndex = state.prevAudios.data.findIndex((audio: any) => audio._id === updatedNotebook._id);

      if (notebookIndex !== -1) {
        // @ts-ignore
        state.prevAudios.data[notebookIndex] = {...updatedNotebook};
      }
      state.prevAudios.loading = false;
      state.prevAudios.error = null;
    },
    [updateAudioLibraryPrev.rejected.type]: (state, action) => {
      state.prevAudios.loading = false;
      state.prevAudios.error = action.error?.message;
    },
    [updateAudioLibraryShare.pending.type]: (state, action) => {
      state.shareAudioData.loading = true;
      state.shareAudioData.data = null;
      state.shareAudioData.error = null;
    },
    [updateAudioLibraryShare.fulfilled.type]: (state, action) => {
      state.shareAudioData.loading = false;
      state.shareAudioData.data = action.payload?.data?.updatedAudio;
      state.shareAudioData.error = null;
    },
    [updateAudioLibraryShare.rejected.type]: (state, action) => {
      state.shareAudioData.loading = false;
      state.shareAudioData.data = null;
      state.shareAudioData.error = action.error?.message;
    },
  },
});

export const {
  resetAudio,
  closeDraggable,
  setAudioToPlay,
  setPlayingAudio,
  resetAllAudioData,
  resetCreateAudioData,
  resetAllPlayingAudios,
  setDrawerPlayingAudio,
  setLibraryPlayingAudio,
  setSidebarPlayingAudio,
  updateLibraryAudiosData,
  resetCreateAudioLoadingError,
  resetCreateAudioLoadingMessage,
  setPlayingDuration,
  setIsPlaying,
  setFromAudioSummary,
  setAudioItem,
  setVolume
} = audioSlice.actions;

export default audioSlice.reducer;
