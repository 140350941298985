import { emailRegex, passwordRegex } from "lib/regex";
import * as yup from "yup";

export const signupSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .matches(/^[a-zA-Z0-9\s]+$/, "Name should only contain letters and numbers")
    .max(25, "Name should not exceed 25 characters"),
  email: yup
    .string()
    .matches(emailRegex, 'Invalid email format')
    .required("Email is required"),
  password: yup
    .string()
    .matches(passwordRegex, 'Password must contain Uppercase, Lowercase and 8 to 20 characters long')
    .required("Password is required"),
});

export const signinSchema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, 'Invalid email format')
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be at least 8 characters long")
    .required("Password is required"),
});

export const requestOtpSchema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, 'Invalid email format')
    .required("Email is required"),
});

export const resetPasswordSchema = yup.object().shape({
  newPassword: yup
  .string()
  .matches(passwordRegex, 'Password must contain Uppercase, Lowercase and 8 to 20 characters long')
  .required("Password is required"),
  confirmNewPassword: yup
  .string()
  // @ts-ignore
  .oneOf([yup.ref('newPassword'), null], 'Password must match')
  .matches(passwordRegex, 'Password must contain Uppercase, Lowercase and 8 to 20 characters long')
  .required("Confirm New Password is required"),
});
