import { HttpService } from "./base.service";
import {
  IDebateData,
  ILeaveDebateData,
  IRemoveUserFromDebate,
  IShowDebateData,
  IUpdateDebateBotMsg,
  IUpdateDebateData,
} from "../interfaces/debate.interface";

class DebateService extends HttpService {
  private readonly prefix: string = "debate";

  index = (params: any): Promise<any> =>
    this.get(
      this.prefix +
        `/index?search=${params.search}&page=${params.page}&perPage=${params.perPage}&filter=${params.filter}`
    );
  show = (debateId: string): Promise<any> => this.get(this.prefix + `/show/${debateId}`);

  store = (data: IDebateData): Promise<any> => this.post(this.prefix + "/store", data);
  update = (data: IUpdateDebateData, signal: any): Promise<any> => this.post(this.prefix + "/update", data, { signal });
  updateMessage = (data: any, signal: any): Promise<any> => this.post(this.prefix + "/update-message", data, { signal });
  updateInvitedUsers = (data: any): Promise<any> => this.post(this.prefix + "/update-invited-users", data);
  updateBotMessage = (data: IUpdateDebateBotMsg, signal: any): Promise<any> => this.post(this.prefix + '/update-bot-message', data, { signal });
  leaveDebate = (data: ILeaveDebateData): Promise<any> => this.post(this.prefix + '/leave-debate', data);
  removeUserFromDebate = (data: IRemoveUserFromDebate): Promise<any> => this.post(this.prefix + '/remove-user', data);
  readDebateMessages = (data: IShowDebateData): Promise<any> => this.post(this.prefix + '/read-all-messages', data);
  stopMessageStream = (data: IShowDebateData): Promise<any> => this.post(this.prefix + '/stop-stream', data);
}

export const debateService = new DebateService();
