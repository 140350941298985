import { createAsyncThunk } from "@reduxjs/toolkit";
import { notebookService } from "services/notebook.service";
import { IAddToLiveNotebookData, IDeleteUserNotebooks } from "interfaces/notebook.interface";

export const addToLiveNotebook = createAsyncThunk(
  "chatgptStore/addToLiveNotebook",
  async (data: IAddToLiveNotebookData) => {
    try {
      const response = await notebookService.store(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getChatNotebooks = createAsyncThunk(
  "chatgptStore/getChatNotebooks",
  async (data: any) => {
    try {
      const response = await notebookService.index(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const deleteNotebookById = createAsyncThunk(
  "chatgptStore/deleteLiveNotebook",
  async (id: string | number) => {
    try {
      const response = await notebookService.destroy(id);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const deleteNotebookByUserId = createAsyncThunk(
  "chatgptStore/deleteLiveNotebookByUser",
  async (data: IDeleteUserNotebooks) => {
    try {
      const response = await notebookService.destroyByUserId(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateNotebookById = createAsyncThunk(
  "chatgptStore/updateLiveNotebook",
  async (data: any) => {
    try {
      const response = await notebookService.update(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
