import * as yup from "yup";

export const createGoalSchema = yup.object().shape({
    name: yup
      .string()
      .required("Goal name is required")
      .min(3, "Name should be at least 4 characters")
      .max(25, "Name should not exceed 25 characters"),
    dueOnDate: yup
      .string()
      .required("DueOnDate is required"),
  });
  