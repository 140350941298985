import Image from 'next/image'
import React from 'react'

const NofificationPlaceholder = () => {
  return (
            <div className='flex flex-col justify-center items-center overflow-y-auto scroll-smooth scrollbar-none'>
            <Image src={'https://lokichatdev.blob.core.windows.net/images/loki/notification-placeholder.svg'} alt={'placeholder'} width={342} height={300} className=''/>
            <h4 className='h5 mb-2 !font-mont'>{'No Notifications Yet!'}</h4>
            <p className='text-base text-center text-[#464665] dark:text-white mb-4'>{'Explore Loki chat to get some important alerts.'}</p>
        </div>

  )
}

export default NofificationPlaceholder