import { IUpdateImage } from 'interfaces/imageGeneration.interface';
import { HttpService } from './base.service';

class ImageLibraryService extends HttpService {
    private readonly prefix: string = "image-library";

    index = (params?: any): Promise<any> => this.get(this.prefix + `/index`,params);
    update = (data: IUpdateImage): Promise<any> => this.post(this.prefix + '/update', data);
    destroy = (id: any, url: any): Promise<any> => this.delete(this.prefix + `/destroy/${id}`, null, {url});
}

export const imageLibraryService = new ImageLibraryService();