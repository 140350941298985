import { HttpService } from './base.service'

class LanguageListService extends HttpService {
    private readonly prefix: string = "language-List";

    index = (): Promise<any> => this.get(this.prefix + '/index');

    store = (): Promise<any> => this.get(this.prefix + '/store');
}

export const languageListService = new LanguageListService();