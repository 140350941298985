import axios from "axios";
import config from '@/config/index';
import { size } from "lodash";

export const capitalizeFirstLetter = (string: string) => {
  // Check if the input string is not empty
  if (size(string) == 0) {
    return string;
  }

  // Capitalize the first letter and concatenate it with the rest of the string
  return string?.charAt(0).toUpperCase() + string.slice(1);
};

export const generatePasscode = (length: any) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let passcode = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * size(characters));
    passcode += characters?.charAt(randomIndex);
  }

  return passcode;
};

export const exportImageFile = async (imageSrc: string) => {
  try {
    const response = await fetch(imageSrc);
    const blob = await response.blob();
    const fileName = imageSrc.substring(imageSrc.lastIndexOf('/') + 1, imageSrc.lastIndexOf('?') || imageSrc.length);
    const url = window.URL.createObjectURL(new Blob([blob]));

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    // Append the link to the body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading image:', error);
  }
};

export function removeHeadings(text: string) {
  const headingRegex = /^Heading\s?\d?\s?:/gm;
  const cleanedText = text.replace(headingRegex, "");

  return cleanedText.trim();
}

export const getCopyWriter = async (text: string) => {
  let response = await axios.post(
    `https://dev-copy-writer.lokichat.com/copy_writer`,
    { query: text },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const getPromptAdvise = async (fromImageGeneration: boolean, text: string) => {
  const endPoint = fromImageGeneration ? 'https://dev-prompt-advisor.lokichat.com/get_image_prompt' : 'https://dev-prompt-advisor.lokichat.com/get_prompt';
  let response = await axios.post(
    endPoint,
    { query: text },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const getSignedUrl = async (url: String) => {
  const response = await await axios.post(
    `${config.baseApiUrl}/transcriptions/getUrl`, { url },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.data) {
    return response.data
  } else {
    return null
  }
}

export const getInstantTranslation = async (text: string, language: string) => {
  const endPoint = 'https://dev-instanttranslator.lokichat.com/get_translation';
  let response = await axios.post(
    endPoint,
    { query: text, language: language },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const getTextLanguage = async (text: string) => {
  const endPoint = 'https://dev-instanttranslator.lokichat.com/get_language';
  let response = await axios.post(
    endPoint,
    { query: text },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};
