export const loki_LOgo = "https://lokichatdev.blob.core.windows.net/images/loki/loki-logo.svg";
export const LOKI_LOGO = "https://lokichatdev.blob.core.windows.net/images/logo-chat.svg";
export const lokiChatMention = { _id: '1', userName:'LokiChat', email:'noreply@lokichat.com', profileUrl: loki_LOgo }

export const DEBATE_INVITATION = 'Debate Invitation';
export const DEBATE_UNARCHIVED = 'Debate Unarchived';
export const DEBATE_LEAVE = 'Debate Leave';
export const DEBATE_NEW_MESSAGE = 'Debate New Message';
export const DEBATE_UPDATE_MESSAGE = 'Debate Update Message';

export const MESSAGE_TYPES = {
  DEBATE_NOTIFICATION: 'DEBATE_NOTIFICATION',
  CONVERT_MSG_TO_BOT_MSG_RESPONSE: 'CONVERT_MSG_TO_BOT_MSG_RESPONSE',
  UPDATE_DEBATE_MESSAGE_NOTIFICATION: 'UPDATE_DEBATE_MESSAGE_NOTIFICATION',
}

export const DEBATE_MSG_TYPE = {
  USER_MESSAGE: 'USER_MESSAGE',
  USER_INVITED: 'USER_INVITED',
  USER_LEAVED: 'USER_LEAVED',
  USER_REMOVED: 'USER_REMOVED',
}