import React, { useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import Modal from "@/components/Modal";
import Form from "@/templates/SignInPage/Form";
import Field from "@/components/Field";
import MultiSelect from "@/components/MultiSelect";
import Notify from "@/components/Notify";

import { people } from "@/mocks/people";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";

type ModalShareChatProps = {
  visible: boolean;
  onClose: () => void;
  navigateTo? : string;
};

const SignUpModal = ({ visible, onClose, navigateTo }: ModalShareChatProps) => {
  const router = useRouter();

  return (
    <Modal
      classWrap="max-w-[40rem] max-h-[90vh] overflow-auto relative"
      classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:top-5 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
      visible={visible}
      onClose={onClose}
    >
      <div className="p-5">
        <Form navigateTo={navigateTo} />
      </div>
    </Modal>
  );
};

export default SignUpModal;
