import React, { useEffect, useState } from "react";
import Switch from "@/components/Switch";
import Icon from "@/components/Icon";
import Select from "@/components/Select";
import { useDispatch, useSelector } from "react-redux";
import { exportChatLists } from "store/actions/chatList.actions";
import { size } from "lodash";
import { useTranslation } from "react-i18next";
import { exportArrayAsPDF } from "lib/notebooks";
import toast from "react-hot-toast";
import Notify from "@/components/Notify";

const typesFile = [
  {
    id: "0",
    title: "PDF",
  },
  {
    id: "1",
    title: "DOC",
  },
  {
    id: "2",
    title: "JPG",
  },
];

const ChatExport = () => {
  const dispatch: any = useDispatch();
  const { t:lt } = useTranslation('translation');

  const chatData = useSelector((state: any) => state.chatGptStore);
  const { storedChatLists, exportChatListData } = chatData;

  const [exportChat, setExportChat] = useState<boolean>(false);
  const [typeFile, setTypeFile] = useState<any>(typesFile[0]);
  const [checkboxes, setCheckboxes] = useState<any>([]);

  useEffect(() => {
    const checkBoxesList = storedChatLists.data.map((item: any) => {
      return { ...item, isChecked: false };
    });
    setCheckboxes(checkBoxesList);
  }, [storedChatLists.data]);

  const handleCheckboxChange = (checkboxId: string) => {
    const updatedCheckboxes = [...checkboxes];
    const checkboxIndex = updatedCheckboxes.findIndex(
      (checkbox) => checkbox?.id === checkboxId
    );
    updatedCheckboxes[checkboxIndex].isChecked =
      !updatedCheckboxes[checkboxIndex].isChecked;
    setCheckboxes(updatedCheckboxes);
  };

  const handleNotificationsChange = (value: boolean) => {
    setExportChat(value);
    const updatedCheckboxes = [...checkboxes];
    for (let checkbox of checkboxes) {
      checkbox.isChecked = value;
    }
    setCheckboxes(updatedCheckboxes);
  };

  const downloadConversation = (event: any) => {
    event.preventDefault();
    try {
      const chatListIds = checkboxes
      .filter((item: any) => item.isChecked === true)
      .map((item: any) => item.id);
    dispatch(exportChatLists({ chatListIds })).unwrap().then(async (response: any)=>{
      if(size(response.data) > 0) {
        await exportArrayAsPDF(response.data, true);
      } else {
        let errorTExt = '';
        if (size(chatListIds) === 1) {
          errorTExt = 'Selected Chat list is empty';
        } else {
          errorTExt = 'Selected Chat lists are empty';
        }
        toast(
          (t) => (
            <Notify iconError>
              <div className="h6 sm:text-sm">{errorTExt}</div>
            </Notify>
          ),
          { duration: 2400 }
        );
      }
    });
    } catch (error) {
      
    }
    
  };

  const isAllowedToDownload = () => {

    const isSelected = checkboxes?.filter((item: any) => item.isChecked);
    if (size(isSelected) === 0 && !exportChat) return true;
    else if (exportChatListData.loading) return true;
    else if (exportChat && size(isSelected) > 0) return false;
    else if (!exportChat && size(isSelected) === 0) return false;

    return !(exportChat || size(isSelected) > 0 || exportChatListData.loading);
    // return false;
  };

  return (
    <form className="" action="" onSubmit={() => console.log("Submit")}>
      <div className="mb-8 h4 !font-mont !font-extrabold">{lt('chat_export')}</div>
      { checkboxes && checkboxes?.length > 0 &&<div className="flex items-center mb-8">
        
        <div className="mr-auto base2 text-n-4">{lt('chatList_export')}</div>
        <Switch value={exportChat} setValue={handleNotificationsChange} />
      </div>}
     {
      checkboxes && checkboxes?.length > 0?
      <div className="mb-6 h-[450px] overflow-auto scrollbar-none">
        {checkboxes?.map((checkbox: any) => (
          <label
            className={`flex items-center h-14 mb-2 p-4 rounded-xl base2 font-semibold text-n-4 transition-colors hover:bg-n-2 cursor-pointer last:mb-0 dark:hover:bg-n-6 ${
              checkbox?.isChecked && "!bg-primary-1/5 text-n-6 dark:text-n-3"
            }`}
            key={checkbox.id}
          >
            <input
              className="absolute top-0 left-0 opacity-0 invisible"
              type="checkbox"
              checked={checkbox?.isChecked}
              onChange={() => handleCheckboxChange(checkbox.id)}
            />
            <div className="flex justify-center items-center shrink-0 w-6 h-6 mr-3">
              <div
                className="w-3 h-3 rounded"
                style={{ backgroundColor: checkbox.color }}
              />
            </div>
            {checkbox.title}
            <Icon
              className={`ml-auto fill-primary-1 opacity-0 transition-opacity ${
                checkbox?.isChecked && "opacity-100"
              }`}
              name="check-thin"
            />
          </label>
        ))}
      </div>:
      <div className="mb-6 h-[450px] overflow-auto scrollbar-none flex justify-center items-center">
        <h1 className="h4">{lt('no_chatList')}</h1>
      </div>
      
    }
      <div className="inline-flex bg-primary-1 rounded-xl md:w-full">
      {checkboxes?.some((checkbox:any) => checkbox.isChecked) && (
        <button
          disabled={isAllowedToDownload()}
          onClick={downloadConversation}
          className="btn-blue pr-4 rounded-r-none md:grow"
        >
          {lt('download_conversation')}
        </button>
         )}
         {checkboxes?.some((checkbox:any) => checkbox.isChecked) && (
        <Select
          classButton="h-12 rounded-l-none rounded-r-xl shadow-[inset_0_0_0_0.0625rem_#0084FF] bg-transparent text-n-1 font-semibold dark:bg-transparent"
          classArrow="fill-n-1"
          classOptions="min-w-full"
          items={typesFile}
          disabled
          value={typeFile}
          onChange={setTypeFile}
          up
        />
        )}
        </div>
    </form>
  );
};

export default ChatExport;
