import React from 'react';
import ReactMarkdown from 'react-markdown';

const components={
    // Use a fancy hr
    code: ({node, ...props}: any) => <code {...props} />
  }

const MarkdownRenderer = ({ markdownContent }: any) => {
  return (
    <ReactMarkdown components={components} children={markdownContent} />
  );
};

export default MarkdownRenderer;
