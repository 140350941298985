import React, { useState, useEffect } from "react";
import Actions from "@/components/Actions";
import Export from "@/components/Export";
import Select from "@/components/Select";
import Icon from "@/components/Icon";
import AudioLoader from "../AudioLoader";
import { useDispatch, useSelector } from "react-redux";
import { generateAudio, updateAudioById, getInprogressAudios } from "store/actions/audio.actions";
import { isEmpty, size } from "lodash";
import { getVoicesList } from "store/actions/voicesList.actions";
import { getPlainTextFromHTML } from "lib/getPlainText";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import { closeDraggable, resetCreateAudioLoadingError, resetCreateAudioLoadingMessage, setFromAudioSummary } from "store/slices/AudioSlice";
import { speeds, modesList } from "@/constants/audio";
import { estimateAudioDuration } from "lib/chatMedia";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@/constants/routes";
import { useRouter } from "next/router";
import { setProcessingNoteBookItem } from "store/slices/ChatGptSlice";
import { capitalizeFirstLetter } from "lib/utils";
import CustomAudioPlayer from "../AudioPlayer/CustomAudioPlayer";
import Voice from "./voice";
import { i18n } from "services/i18n";
import { Language, SpeedIcon, VoiceIcon } from "../SVGs";
import { useMediaQuery } from "react-responsive";
import { resetTextToSpeechData } from "store/slices/TextToSpeechSlice";
import LoaderWave from '@/components/LoaderWave';

const LanguageDetect = require('languagedetect');

const lngDetector = new LanguageDetect();
interface AudioProps {
  abortGeneration: any;
  setAbortGeneration: any;
  setIsAudioLoading?: any;
}

const Audio = ({ abortGeneration, setAbortGeneration }: AudioProps) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation('translation');
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const genders = [
    {
      id: "0",
      title: t('female'),
      // title: "Female",
      value: "Female",
    },
    {
      id: "1",
      title: t('male'),
      // title: 'Male',
      value: 'Male',
    },
  ];

  const chatData = useSelector((state: any) => state.chatGptStore);
  const audioData = useSelector((state: any) => state.audioStore);
  const voicesListData = useSelector((state: any) => state.voicesListStore);
  const debateData = useSelector((state: any) => state.debateStore);
  const userData = useSelector((state: any) => state.authStore);

  const { user } = userData;
  const { voicesList } = voicesListData;
  const { createAudio, inProgressAudios, fromAudioSummary } = audioData;
  const { storeDebate, debateInvitedUsers } = debateData;
  const { createChat, selectedNotebooks, allLiveNotebooks } = chatData;
  const { isAudioGenerated } = useSelector((state: any) => state.TextToSpeechSlice);

  const isDebateUser = debateInvitedUsers?.find((el: any) => el?._id === user?.data?.id);
  const isDebateAdmin = storeDebate.data?.user?._id === user?.data?.id;
  const isDebateArchived = storeDebate.data?.isArchived;

  const [edit, setEdit] = useState<boolean>(false);
  const [isContentLoading, setIsContentLoading] = useState<boolean>(false);
  const [allowEdit, setAllowEdit] = useState(true);
  const [speed, setSpeed] = useState<any>(speeds[1]);
  const [availableGenders, setAvailableGenders] = useState<any>(genders)
  const [gender, setGender] = useState<any>(genders[0]);
  const [language, setLanguage] = useState<any>(null);
  const [detectedAllLanguages, setDetectedAllLanguages] = useState<any>([voicesList?.data[0]]);
  const [voice, setVoice] = useState<any>(language?.gender?.female[0]);
  const [mode, setMode] = useState<any>(modesList[0]);
  const [updatedContent, setUpdatedContent] = useState<string>("");
  const [playbackRate, setPlaybackRate] = useState<any>(1);
  const [abortAudioGenerate, setAbortAudioGenerate] = useState<any>(null);
  const [vList, setVList] = useState<any>([]);
  const [audioDuration, setAudioDuration] = useState<any>(null);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [_, _voices] = useState<any>([]);

  const getFileteredNoteBookById = async (id: any) => {
    try {
      const filtered = await allLiveNotebooks?.data.find((item: any) => item.id == id);
      return filtered;
    } catch (error) {
      console.log({ error });
      return null;
    }
  }

  useEffect(() => {
    handleNotebookDetection(false);
    dispatch(getInprogressAudios());
    dispatch(getVoicesList()).unwrap().then((res: any) => {
      setVList([...res.data]);
    })

  }, []);

  const handleNotebookDetection = async (isUpdatedContent: boolean) => {
    try {
      const concatenatedResponsesArr = await Promise.all(
        selectedNotebooks.map(async (currentValue: any) => {
          const filteredNotebook = await getFileteredNoteBookById(currentValue);
          return filteredNotebook?.response || '';
        })
      );
      const concatenatedResponses = concatenatedResponsesArr.join(' ');

      if (!concatenatedResponses.trim()) {
        dispatch(setProcessingNoteBookItem(null));
        return false;
      }
      if (size(vList) === 0) {
        dispatch(getVoicesList()).unwrap().then((res: any) => {
          setVList([...res.data]);
          setDetectedLanguages(res.data, concatenatedResponses, isUpdatedContent);
        })
      } else {
        setDetectedLanguages(vList, concatenatedResponses, isUpdatedContent);
      }
      dispatch(setProcessingNoteBookItem(null));
      showEstimatedAudioDuration(concatenatedResponses);
    } catch (error) {
      dispatch(setProcessingNoteBookItem(null));
    }
  }
  useEffect(() => {
    setGender(genders[0]);
  }, [i18n.language])

  // useEffect(() => {
  //   if (language && language.title) {
  //     const lang = language.title?.toLowerCase();
  //     if (!(lang.includes('english') || lang.includes('french') || lang.includes('german'))) {
  //       setSpeed(speeds[1])
  //       setPlaybackRate(0.75);
  //     } else {
  //       setSpeed(speeds[0])
  //       setPlaybackRate(1);
  //     }
  //   }
  // }, [language?.title])

  const setDetectedLanguages = (languageLists: any, concatenatedResponses: any, isUpdatedContent: boolean) => {
    const detectedLanguage = detectSingleLanguage(getPlainTextFromHTML(isUpdatedContent ? updatedContent : concatenatedResponses));
    if (detectedLanguage) {
      const detectedLanguageObj = getLanguages(languageLists, detectedLanguage);
      handleSetDetectedAllLanguages(detectedLanguageObj);
      if (detectedLanguageObj) {
        const lan = { ...detectedLanguageObj[0] };
        setLanguage({ ...detectedLanguageObj[0] });
        setVoice(lan.gender.female[0]);
        setGendersList(lan);
      } else {
        const languagedetected = getLanguages(languageLists);
        handleSetDetectedAllLanguages(languagedetected);
        const lan = { ...languagedetected[0] }
        setLanguage({ ...languagedetected[0] });
        setVoice(lan.gender.female[0]);
        setGendersList(lan);
      }
    } else {
      handleSetDefaultLanguage();
    }
  }

  const handleSetDefaultLanguage = () => {
    try {
      if (!language) {
        if (vList && vList.length > 0) {
          const languagedetected = getLanguages(vList);
          const lan = { ...languagedetected[0] }
          setLanguage({ ...languagedetected[0] });
          if (lan && lan.gender && lan.gender.female) {
            setVoice(lan.gender.female[0])
          }
          else if (lan && lan.gender && lan.gender.male) {
            setVoice(lan.gender.male[0]);
          }
          setGendersList(lan)
        }
      }

    } catch (error) {
      console.log({ error });
    }

  }

  useEffect(() => {
    if(!isEmpty(createAudio.data?.data) && size(vList) > 0) {
      try {
        setTimeout(() => {
          console.log('timeOut--', createAudio);
          const { data } = createAudio;
          const detectedLanguage = detectSingleLanguage(getPlainTextFromHTML(data?.data?.text));
          if (detectedLanguage) {
            const detectedLanguageObj = getLanguages(vList, detectedLanguage);
            if (detectedLanguageObj) {
              const alreadySelectedLanguage = detectedLanguageObj.find((language: any) => language.code === data?.data?.language)
              if (!isEmpty(alreadySelectedLanguage)) {
                const genders = alreadySelectedLanguage.gender[data?.data.voiceIdentifier?.toLowerCase()];
                const selectedGender = genders.find((gen: any) => gen?.title === data?.data?.speakerName);
                if (!isEmpty(selectedGender)) {
                  setLanguage({ ...alreadySelectedLanguage });
                  setTimeout(() => {
                    if(data?.data.voiceIdentifier && data?.data.voiceIdentifier?.toLowerCase() === 'male') {
                      setGender({ id: '0', title: 'Male', value: 'Male' });
                    } else if (data?.data.voiceIdentifier && data?.data.voiceIdentifier?.toLowerCase() === 'female') {
                      setGender({ id: '0', title: 'Female', value: 'Female' });
                    }
                    setVoice(selectedGender);
                  }, 0)
                }
              }
            }
          }
        }, 10);
      } catch(error) {
        console.log('error', error);
        handleSetDefaultLanguage();
      }
    } else {
      handleSetDefaultLanguage();
    }

  }, [vList])

  const setGendersList = (lan: any) => {
    const gendersA = [];
    try {
      if (lan && lan?.gender) {
        const genderEntries = Object.entries(lan?.gender);
        for (let i = 0; i < size(genderEntries); i++) {
          const [key, value] = genderEntries[i];
          //@ts-ignore
          if (value && value[0]) {
            gendersA.push({ id: i, title: capitalizeFirstLetter(key), value: key.toUpperCase() })
          }
        }
        setAvailableGenders(gendersA);
      }
    } catch (err) {
      console.log('err', err);
    }
  }

  useEffect(() => {
    handleNotebookDetection(true);
  }, [updatedContent]);

  useEffect(() => {
    if (size(selectedNotebooks) > 0) {
      handleNotebookDetection(false);
    } else {
      handleNotebookDetection(true);
    }

  }, [selectedNotebooks]);

  useEffect(() => {
    try {
      console.log('language', language);
      if (language && language.gender && language.gender.female && language.gender.female[0]) {
        setVoice(language.gender.female[0]);
        setGender({ id: '0', title: 'Female', value: 'Female' });
      }
      else if (language && language.gender && language.gender.male && language.gender.male[0]) {
        setVoice(language.gender.male[0]);
        setGender({ id: '0', title: 'Male', value: 'Male' });
      }
      setGendersList(language);
    } catch (error) {

    }
  }, [language]);

  const getLanguages = (voiceListArr: any, language: string = "english") => {
    const detectedLanguages = voiceListArr?.filter((lang: any) => lang?.languageName?.toLowerCase().includes(language?.toLowerCase()));
    return detectedLanguages && detectedLanguages.length && detectedLanguages.length > 0 ? detectedLanguages : null;
  };

  useEffect(() => {
    try {
      if (!isEmpty(abortAudioGenerate) && abortGeneration) {
        abortAudioGenerate.abort();
        setAbortGeneration(false);
      }
    } catch (error) {

    }

  }, [abortGeneration]);

  useEffect(() => {
    if (!isEmpty(createAudio.error)) {
      toast((t) => (
        <Notify iconError>
          <div className="mr-6 ml-3 h6">{createAudio.error}</div>
        </Notify>
      ), { duration: 3000 });
      setTimeout(() => {
        dispatch(resetCreateAudioLoadingError(null));
      });
    } else if (!isEmpty(createAudio?.message)) {
      toast((t) => (
        <Notify iconError>
          <div className="mr-6 ml-3 h6">{createAudio.message}</div>
        </Notify>
      ), { duration: 3000 });

      setTimeout(() => {
        dispatch(resetCreateAudioLoadingMessage(null));
      });
    }
  }, [createAudio]);

  const handleGenerateAudio = async () => {
    try {
      // !isEmpty(createChat.data?.id) && -> removed this from if
      if ( router.pathname !== ROUTES.DEBATE_ID) {
        const chatData = createChat.data;
        const chatId = chatData?.id;

        const concatenatedResponsesArr = await Promise.all(
          selectedNotebooks.map(async (currentValue: any) => {
            const filteredNotebook = await getFileteredNoteBookById(currentValue);
            return filteredNotebook?.response || '';
          })
        );

        const concatenatedResponses = concatenatedResponsesArr.join(' ');
        const notebookIds = selectedNotebooks.map((notebook: any) => ({ id: notebook }));

        const audioData: any = {
          chatId,
          notebookIds,
          text: concatenatedResponses,
          gender: gender?.value,
        };

        audioData.voiceName = voice?.name;
        audioData.voiceMode = mode?.id || "default";
        audioData.language = language.code;
        audioData.audioLabel = chatData?.title || null;
        audioData.audioColor = chatData?.color || null;
        audioData.speed = speed.value || '1';
        audioData.speakerName = voice?.title;
        dispatch(resetTextToSpeechData());
        const audioGeneration = dispatch(generateAudio(audioData)).unwrap().then((response: any) => {
          dispatch(setProcessingNoteBookItem(null));
        });
        setAbortAudioGenerate(audioGeneration);
      } else if (router.pathname === ROUTES.DEBATE_ID) {
        const debateId = router.query.id;
        const concatenatedResponsesArr = await Promise.all(
          selectedNotebooks.map(async (currentValue: any) => {
            const filteredNotebook = await getFileteredNoteBookById(currentValue);
            return filteredNotebook?.response || '';
          })
        );
        const concatenatedResponses = concatenatedResponsesArr.join(' ');
        const notebookIds = selectedNotebooks.map((notebook: any) => ({ id: notebook }));

        const audioData: any = {
          debateId,
          text: getPlainTextFromHTML(concatenatedResponses),
          notebookIds,
          gender: gender?.value,
        };

        audioData.voiceName = voice?.name;
        audioData.voiceMode = mode?.id || "default";
        audioData.language = language.code;
        audioData.audioLabel = chatData?.title || null;
        audioData.audioColor = chatData?.color || null;
        audioData.speed = speed.value || '1'
        audioData.speakerName = voice?.title;
        dispatch(setFromAudioSummary(false));
        const audioGeneration = dispatch(generateAudio(audioData)).unwrap().then((response: any) => {
          dispatch(setProcessingNoteBookItem(null));
        });
        setAbortAudioGenerate(audioGeneration);
      }
    } catch (error) {

    }

  };

  const detectSingleLanguage = (text: any) => {
    try {
      const result = lngDetector.detect(text, 1); // Get only the top result
      return result.length > 0 ? result[0][0] : null; // Extract the language code
    } catch (error) {
      return null;
    }

  };

  const showEstimatedAudioDuration = async (concatenatedResponses: any) => {
    const plainTExt = getPlainTextFromHTML(concatenatedResponses);
    const calculatedEstimatedDuration = estimateAudioDuration(plainTExt, speed?.value);
    setAudioDuration(calculatedEstimatedDuration);
  }

  const isAllowToGenerateAudio = () => {
    return size(selectedNotebooks) == 0 && isEmpty(createAudio?.data?.data) || (inProgressAudios && inProgressAudios.inProgressCount && inProgressAudios.inProgressCount > 0);
  };

  const showGenerateAudioButton = () => {
    try {
      let isSelectedNotebookUpdated = false;

      if (router.pathname === ROUTES.DEBATE_ID && ((!isDebateAdmin && !isDebateUser) || isDebateArchived)) {
        return false;
      }

      if (size(selectedNotebooks) === 0 || isContentLoading || createAudio.loading) {
        return false;
      }
      if (size(selectedNotebooks) !== size(createAudio.data?.notebookIds)) {
        isSelectedNotebookUpdated = true;
      } else if (createAudio.loading) {
        return false;
      } else if (size(selectedNotebooks) === size(createAudio.data?.notebookIds)) {
        const existingIds = createAudio.data?.notebookIds.map((item: any) => item.id);

        const newIds = selectedNotebooks.filter((id: string) => !existingIds.includes(id));

        if (size(newIds) > 0  || createAudio?.data?.data?.voiceIdentifier !== gender?.value) {
          isSelectedNotebookUpdated = true;
        }
      }
      if (isSelectedNotebookUpdated) return true;
      else return false;
    } catch (error) {
      return false;
    }
  };

  const handleGenderChange = (value: any) => {
    try {
      setGender({ ...value });
      if (value.value.toLowerCase() == "male") {
        setVoice(language.gender.male[0])
      } else if (value.value.toLowerCase() == "female") {
        setVoice(language.gender.female[0])
      }
    } catch (error) {

    }

  };

  const handleEditResponse = () => {
    try {
      const updatedData: any = {
        text: updatedContent,
        audioId: createAudio.data?.data?.id,
        voiceMode: mode?.id,
        gender: gender.value,
        speed: speed.value
      };

      updatedData.voiceName = voice?.name;
      updatedData.voiceMode = mode?.id || "default";
      updatedData.language = language.code;
      updatedData.speakerName = voice?.title;

      setEdit(false);
      dispatch(updateAudioById(updatedData)).unwrap().then((resp: any) => {
        dispatch(setProcessingNoteBookItem(null));
      });
    } catch (error) {
      console.log({ error });
      setEdit(false);
    }
  };

  const handleSpeed = (value: any) => {
    try {
      if (value.title === "Normal") {
        setPlaybackRate(1);
      } else {
        setPlaybackRate(parseFloat(value.title));
      }
      setSpeed(value);
    } catch (error) {

    }

  };

  const handleSetDetectedAllLanguages = (languges: any) => {
    try {
      const items: any = [];
      for (const languageData of languges) {
        items.push({
          id: languageData.id,
          title: languageData.title,
          ...languageData
        });
      }
      setDetectedAllLanguages(items);
    } catch (error) {

    }

  }
  useEffect(() => {
    //@ts-ignore
    dispatch(closeDraggable());
  }, [createAudio?.data?.data])


  return (
    <div className={(!isEmpty(createAudio?.data?.data) || showGenerateAudioButton()) ? "px-2 2xl:py-2 rounded-lg" : ''}>

      {(createAudio && createAudio.loading) && <div className="bg-n-3 dark:bg-n-5/50 px-2 2xl:py-2 py-3 rounded-lg flex justify-center "><LoaderWave size={60}/></div>}
      <>
        {!isEmpty(createAudio?.data?.data) && !isAudioGenerated && (
          // <AudioPlayer
          //   className="w-full"
          //   edit={edit}
          //   onSave={handleEditResponse}
          //   onCancel={() => {setEdit(false); setUpdatedContent(createAudio?.data?.data?.text)}}
          //   playbackRate={playbackRate}
          //   url={createAudio?.data?.data?.audioFilePath}
          //   setUpdatedContent={(content: string) => {
          //     if (isEmpty(content?.trim())) {
          //       setAllowEdit(false);
          //     } else setAllowEdit(true);
          //     setUpdatedContent(content);
          //   }}
          //   updatedContent={updatedContent}
          //   isContentLoaded={(loadingState: boolean) => {
          //     setIsContentLoading(loadingState);
          //     setIsAudioLoading(loadingState);
          //   }}
          //   audioItem={createAudio?.data?.data}
          // />
          <CustomAudioPlayer audioUrl={createAudio?.data?.data?.audioFilePath}
            edit={edit}
            onSave={handleEditResponse}
            onCancel={() => { setEdit(false); setUpdatedContent(createAudio?.data?.data?.text) }}
            playbackRate={playbackRate}
            setUpdatedContent={(content: string) => {
              if (isEmpty(content?.trim())) {
                setAllowEdit(false);
              } else setAllowEdit(true);
              setUpdatedContent(content);
            }}
            updatedContent={updatedContent}
            isChatOrDebateAudio={true}
            isPlaying={audioPlaying}
            isLibraryAudio={true}
            onPlay={() => setAudioPlaying(true)}
            onPause={() => setAudioPlaying(false)}
            audioItem={{
              text: updatedContent,
              audioId: createAudio.data?.data?.id,
              _id: createAudio.data?.data?.id,
              gender: gender.value,
              speed: speed.value,
              voiceName: voice?.name,
              voiceMode: mode?.id || "default",
              language: language?.code,
              speakerName: voice?.title

            }}
          />
        )}
        {/*isLibraryAudio={true}
            isPlaying={audioPlaying}
            onPlay={() => setAudioPlaying(true)}
            onPause={() => setAudioPlaying(false)}
            audioItem={{
              text: updatedContent,
              audioId: createAudio.data?.data?.id,
              gender: gender.value,
              speed: speed.value,
              voiceName: voice?.name,
              voiceMode: mode?.id || "default",
              language: language?.code,
              speakerName: voice?.title

            }}*/}
        <div className="flex flex-wrap w-full">
          {!isEmpty(createAudio?.data?.data) && !isContentLoading && !isAudioGenerated && (
            <>
              <Actions
                className=" 2xl:mt-2 mt-4 md:w-[calc(50%-0.5rem)] "
                title={t('exporting_audio')}
                classButton="border-0"
                classTitle="pl-3"
                buttonInner={
                  <>
                    <Icon name="share" className=" !fill-blue-700" />
                  </>
                }
              >
                <Export url={createAudio?.data?.data?.audioFilePath} webLink={`${window.location.origin}/play-audio/${createAudio?.data?.data?._id ?? createAudio?.data?.data?.id}`} />
              </Actions>
              {(router.pathname === ROUTES.DEBATE_ID && ((isDebateAdmin || isDebateUser) && !isDebateArchived) || router.pathname !== ROUTES.DEBATE_ID) && <button
                disabled={!allowEdit}
                className="mr-1.5 2xl:mt-2 mt-5 md:w-[calc(50%-0.5rem)] md:mr-0 md:ml-2"
                onClick={() => {
                  setEdit(true);
                  setUpdatedContent(createAudio?.data?.data?.text);
                }}
              >
                <Icon name="edit" className=" h-5 w-5 fill-blue-700" />
              </button>
              }
            </>
          )}
          {((!isEmpty(createAudio?.data?.data) && !isContentLoading) || showGenerateAudioButton()) && (!fromAudioSummary) && (
            <>
              {detectedAllLanguages && size(detectedAllLanguages) > 0 ? (
                <Select
                  className="w-full"
                  classButton={`bg-[#DAF8FF] dark:bg-[#DAF8FF] dark:text-n-6 lg:h-12 ${!isEmpty(createAudio?.data?.data) && !isContentLoading ? 'mt-2' : ''}`}
                  IconComponent={() => <Language />}
                  items={detectedAllLanguages}
                  value={language}
                  onChange={setLanguage}
                  placeholder={t('language')}
                 arrowColor={'black'}
                />
              ) : (
                language && <button
                  disabled={!allowEdit}
                    className="btn w-full bg-[#DAF8FF] hover:!bg-transparent px-1.5 mr-1.5 2xl:mt-2 mt-4 md:w-[calc(50%-0.5rem)] md:mr-0 md:ml-2"
                >
                  <span className="!font-mont">{language ? language?.title : ''}</span>
                </button>
              )}
              <Select
                className="w-full mt-3"
                classButton="bg-[#F0FAEB] dark:bg-[#F0FAEB] dark: text-n-6 "
                items={speeds}
                value={speed}
                onChange={setSpeed}
                PreIcon={() => <SpeedIcon />}
                placeholder={t('speed')}
                title={t('speed')}
                arrowColor={'black'}
            />
             <div className="flex w-full lg:h-12 justify-between  2xl:mt-2 mt-4 rounded-md md:w-full md:mr-0 shadow-[0_0.125rem_0.25rem_rgba(0,0,0,0.15)] bg-[#F1EFFE] md:w-full md:mr-0 dark:bg-[#F1EFFE] dark:shadow-[0_0.125rem_0.25rem_rgba(0,0,0,0.15),inset_0_0_0_0.0625rem_rgba(254,254,254,.1)]">   
             <Select
                  classButton="shadow-none bg-transparent ui-open:shadow-none dark:bg-transparent dark:shadow-none dark:text-n-6"
                  title={isMobile ? "" : t('voice')}
                  items={genders}
                  value={gender}
                  onChange={handleGenderChange}
                  PreIcon={() => <VoiceIcon />}
                  up
                  arrowColor={'black'}
                />
                {language?.gender && language?.gender[gender?.value?.toLowerCase()]?.length > 0 ? (<Select
                  classButton="shadow-none bg-transparent ui-open:shadow-none dark:bg-transparent dark:shadow-none sm:px-1 dark:bg-transparent dark:shadow-none dark:text-n-6"
                  icon="volume"
                  className=""
                  items={language?.gender[gender?.value?.toLowerCase()] ?? []}
                  value={voice}
                  // disabled={!edit && !showGenerateAudioButton()}
                  onChange={setVoice}
                  up
                  arrowColor={'black'}
                  voice
                />) : (language &&
                  <>
                    <Icon name={'volume'} className=" self-center" />
                    <button
                      className="btn-white btn-small hover:!bg-transparent md:w-[calc(50%-0.5rem)] flex items-center self-center mr-3"
                    >
                      <span className="!font-mont">{language?.gender[gender?.value?.toLowerCase()][0]?.title ?? ""}</span>

                    </button>
                  </>
                )}
              </div>
              {showGenerateAudioButton() && (
                <>
                  <div className="mt-2 -mb-1 2xl:text-sm">{t('estimate_duration')}: {audioDuration}</div>
                  <button
                    disabled={isAllowToGenerateAudio() || !voice?.name}
                    className="bg-[#8E55EA] btn flex justify-center w-full border text-white mb-2 2xl:h-8 2xl:text-xs mt-3"
                    onClick={handleGenerateAudio}
                  >
                    {t('generate_audio')}
                  </button>
                </>
              )}

            </>
          )}

        </div>
      </>
    </div >
  );
};

export default Audio;
