import { Fragment, useEffect, useReducer, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";

const datas = [
  { type:1, name:"1024 x 1024" },
  { type:1, name:"512 x 512" },
  { type:1, name:"576 x 384" },
  { type:1, name:"576 x 448" },
  { type:1, name:"640 x 384" },
  { type:1, name:"704 x 320" },
  { type:1, name:"704 x 384" },
  { type:1, name:"768 x 768" },
  { type:1, name:"1152 x 768" },
  { type:1, name:"1152 x 896" },
  { type:1, name:"1280 x 768" },
  { type:1, name:"1408 x 640" },
  { type:1, name:"1408 x 768" },
  { type:1, name:"1152 x 640" },
  { type:1, name:"1173 x 640" },
  //9:16
  { "type": 2, "name": "720 x 1280" },
  { "type": 2, "name": "1080 x 1920" },
  { "type": 2, "name": "1440 x 2560" },
  { "type": 2, "name": "2160 x 3840" },
  //16:9
  { "type": 3, "name": "1280 x 720" },
  { "type": 3, "name": "1920 x 1080" },
  { "type": 3, "name": "2560 x 1440" },
  { "type": 3, "name": "3840 x 2160" },
  //3:4
  { "type": 4, "name": "768 x 1024" },
  { "type": 4, "name": "960 x 1280" },
  { "type": 4, "name": "1536 x 2048" },
  //4:3
  { "type": 5, "name": "1024 x 768" },
  { "type": 5, "name": "1280 x 960" },
  { "type": 5, "name": "1600 x 1200" },

  //1:1
  { type: 6, name: "1024 x 1024" },
  //4:5
  { type: 7, name: "853 x 1333" },
  //2:3
  { type: 8, name: "716 x 1612" },
  //4:7
  { type: 9, name: "605 x 1853" },
  //7:4
  { type: 10, name: "1853 x 605" },
  //3:2
  { type: 11, name: "1612 x 716" },
  //5:4
  { type: 12, name: "1792 x 1344" }

];

export default function ImageSizeSelect(props: any) {
  const { t: lt } = useTranslation("translation");
  const [selected, setSelected] = useState<any>()
  const [data, setData] = useState([])

  const handleSettingDimensions = (val:any)=> {
    setSelected(val)
    props.handleDimensionsChange(val)
  }
  useEffect(()=>{
    const tempData: any = datas.filter((item: any) => item.type === props.orientation)
    // @ts-ignore
    setData(tempData)
    
  },[props.orientation])
  useEffect(()=>{
    const tempData: any = datas.filter((item:any)=>{
      const isthis = item.name.includes(`${props.width} x ${props.height}`)
      if (isthis) {
        return isthis
      }
    })
    setSelected(tempData[0])
  },[props.width, props.height])
  useEffect(()=>{
    console.log(selected)
    
  },[selected])

  return (
    <Listbox as="div" value={selected?.name} onChange={handleSettingDimensions}>
      <div className="relative mb-4">
        <h4 className={`text-base font-semibold mb-4 !font-mont ${props.fromFeature && "text-black"}`}>{lt('Resolution')}</h4>
        <Listbox.Button
          className={`relative flex w-full !font-mont items-center justify-between rounded-[4.5px] bg-n-16 ${!props.fromFeature && "dark:bg-n-5"} p-2 px-3 pr-14 text-left text-base focus:outline-none`}
        >
          <span
            className={`block truncate text-secondary !font-mont ${props.fromFeature && "text-black"}`}
            title={selected?.name || lt('Resolution')}
          >
            {selected?.name || lt('Resolution')}
          </span>
          <span className="absolute !font-mont flex items-center pointer-events-none inset-y-1/2 right-5">
         <IoIosArrowDown/>
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={`absolute z-10 mt-1 max-h-[10.5rem] w-full overflow-auto shadow-xl 
               rounded-[4.5px] bg-n-16 ${!props.fromFeature && "dark:bg-n-5"} text-base focus:outline-none`}
          >
            {data?.map((item: any, Idx: any) => {
              return (
            
                  <Listbox.Option
                    key={Idx}
                    className={({ active }: any) =>
                      `relative cursor-pointer select-none !font-mont p-2 px-3 ${
                        active ? "bg-primary-1 text-white" : ""
                      }`
                    }
                    value={item}
                  >
                    <div className="truncate text-primary">{item.name}</div>
                  </Listbox.Option>
         
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}