import React, { useState } from "react";
import RatingStars from "@/components/RatingStars";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

type FeedbackModalProps = {
  onSkip?: any;
  onSave?: any;
};

const ChatFeedbackModal = ({
  onSkip,
  onSave,
}: FeedbackModalProps) => {
  const chatData = useSelector((state: any) => state.chatGptStore);
  const { chatFeedback } = chatData;
  const { t } = useTranslation('translation');

  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);

  const handleFeedbackChange = (event: any) => {
    setFeedback(event.target.value);
  };

  const isAllowedToSave = () => {
    if (isEmpty(feedback) || rating === 0 || chatFeedback.loading) return true;
    return false;
  };

  return (
    <>
      <h3 className="h4 mb-1 !font-extrabold !font-mont">{t('share_feedback')}</h3>
      <p className="text-[#919293] text-base mb-2  !font-mont">
        {t('satisfy_service')}
      </p>
      <RatingStars setRating={setRating} />
      <textarea
        value={feedback}
        onChange={handleFeedbackChange}
        placeholder={t('descLike_most')}
        className="resize-none w-full mt-[18px] sm:mt-3 mb-8 sm:mb-4 h-[200px] py-[14px] px-4 bg-n-2 border-[1.5px] border-[#F3F3F4] dark:border-n-6 rounded-xl base2  text-n-7 outline-none transition-colors placeholder:text-n-4/50 dark:placeholder:text-[#5C6366] focus:bg-transparent  dark:bg-[#1E2122]  dark:text-n-3 dark:focus:bg-transparent"
      />

      <div className="flex justify-end">
        <div className="flex items-center gap-3 w-full justify-end">
          <button
            className="btn dark:btn-stroke-dark btn-stroke-light w-[135px] sm:w-full"
            onClick={onSkip}
          >
            {t('skip')}
          </button>
          <button disabled={isAllowedToSave()} onClick={()=>onSave(feedback, rating)} className="btn btn-blue w-[182px] sm:w-full">Submit</button>
        </div>
      </div>
    </>
  );
};

export default ChatFeedbackModal;