export const AUDIO_GENERATED = 'Audio Generated';

export const speeds = [
  {
    id: "0",
    title: "0.75x",
    value:0.75
  },
  {
    id: "1",
    title: "1.0x",
    value:1
  },
  {
    id: "2",
    title: "1.25x",
    value:1.25
  },
  {
    id: "3",
    title: "1.5x",
    value:1.5
  },
];

export const LANGUAGE_CODE = {
  EN: "en",
  FR: "fr",
  ES: "es",
  JA: "ja",
  PT: "pt",
  RU: "ru",
  HI: "hi",
  DE:"de",
  TR:"tr"
};
export const translate =(t:(string: string)=>{})=>{
  const languagesList = [
    {
      id: 0,
      code: "en",
      title: t('english'),
      titleForMatching:"english"
    },
    {
      id: 1,
      code: "es",
      title: t('spanish'),
      titleForMatching: "spanish",
    },
    {
      id: 2,
      code: "fr",
      title: t('French'),
      titleForMatching: "french",
    },
    {
      id: 3,
      code: "ja",
      title: t('japnese'),
      titleForMatching: "japnese",
    },
    {
      id: 4,
      code: "pt",
      title: t('portuguese'),
      titleForMatching: "portuguese",
    },
    {
      id: 5,
      code: "ru",
      title: t('russian'),
      titleForMatching: "russian",
    },
    {
      id: 6,
      code: "hi",
      title: t('hindi'),
      titleForMatching: "spanish",
    },
    {
      id: 7,
      code: "de",
      title: t('german'),
      titleForMatching: "german",
    },
    {
      id: 8,
      code: "tr",
      title: t('turkish'),
      titleForMatching: "turkish",
    }
  ];
  return languagesList;
}

export const gendersTranslate=(t:(string: string)=>{})=>{
  
  const genders = [
    {
      id: "0",
      title: t('female'),
      // title: "Female",
      value: "Female",
    },
    {
      id: "1",
      title: t('male'),
      // title: 'Male',
      value: 'Male',
    },
  ];
  return genders;
}

export const voices = [
  {
    id: "0",
    title: "Jenny",
  },
  {
    id: "1",
    title: "Mark",
  },
  {
    id: "2",
    title: "Jack",
  },
];

export const modesList = [
  {
    id: "default",
    title: "Default",
  },
  {
    id: "friendly",
    title: "Friendly",
  },
  {
    id: "sad",
    title: "Sad",
  },
  {
    id: "angry",
    title: "Angry",
  },
  {
    id: "whispering",
    title: "Whispering",
  },
  {
    id: "terrified",
    title: "Terrified",
  },
  {
    id: "unfriendly",
    title: "Unfriendly",
  },
  {
    id: "excited",
    title: "Excited",
  },
  {
    id: "hopeful",
    title: "Hopeful",
  },
  {
    id: "shouting",
    title: "Shouting",
  },
];

export const getLanguageCode = (language: string) => {
  return language.split("-")[0];
};

export const AUDIO_PLAYER = {
  SIDEBAR: 'sidebar',
  LIBRARY: 'library',
  DRAWER: 'drawer'
}

export const languages = [
  "English",
  "Spanish",
  "French",
  "German",
  "Chinese (Simplified and Traditional)",
  "Japanese",
  "Russian",
  "Italian",
  "Portuguese",
  "Dutch",
  "Arabic",
  "Korean",
  "Hindi",
  "Turkish",
  "Swedish",
  "Polish",
  "Norwegian",
  "Finnish",
  "Danish",
  "Greek",
  "Hebrew",
  "Thai",
  "Vietnamese",
  "Indonesian",
  "Czech",
  "Hungarian",
  "Romanian",
  "Ukrainian",
  "Malay",
  "Filipino (Tagalog)",
  "Catalan",
  "Bengali",
  "Tamil",
  "Telugu",
  "Gujarati",
  "Kannada",
  "Marathi",
  // "Punjabi",
  "Ukrainian",
  "Swahili",
  "Afrikaans",
  "Icelandic",
  "Slovak",
  "Slovenian",
  "Lithuanian",
  "Latvian",
  "Estonian",
  "Croatian",
  "Serbian",
  "Bosnian",
  "Macedonian",
  "Maltese",
  "Irish",
  "Welsh",
  "Scottish Gaelic",
  "Basque",
  "Galician",
  "Kurdish",
  "Pashto",
  "Dari",
  "Amharic",
  "Hausa",
  "Yoruba",
  "Igbo",
  "Somali",
  "Oromo",
  "Tigrinya",
  "Zulu",
  "Xhosa",
  "Sesotho",
  "Shona",
  "Malagasy",
  "Wolof",
  "Kinyarwanda",
  "Kirundi",
  "Afar",
  "Fulfulde",
  "Tswana",
  "Chichewa (Nyanja)",
  "Lingala",
  "Sinhala",
  "Khmer (Cambodian)",
  "Lao",
  "Tajik",
  "Uzbek",
  "Kyrgyz",
  "Turkmen",
  "Azerbaijani",
  "Georgian",
  "Armenian",
  "Maldivian (Dhivehi)",
  "Nepali",
  "Bhutanese (Dzongkha)",
  "Tibetan",
  "Mongolian",
  "Uighur",
  "Kazakh",
  "Tuvan",
  "Sakha (Yakut)",
  "Greenlandic (Kalaallisut)",
]
export const AudioMinutes = [
  {
    id: "0",
    title: "1 Minute",
    value: 1
  },
  {
    id: "1",
    title: "2 Minutes",
    value: 2
  },
  {
    id: "2",
    title: "3 Minutes",
    value: 3
  },
  {
    id: "3",
    title: "4 Minutes",
    value: 4
  },
  {
    id: "4",
    title: "5 Minutes",
    value: 5
  }
 ]

export const PitchOptions = [
  {
    id: "-20.0",
    title: "Very Low Pitch",
    value: -20.0,
  },
  {
    id: "-10.0",
    title: "Low Pitch",
    value: -10.0,
  },
  {
    id: "-5.0",
    title: "Slightly Low Pitch",
    value: -5.0,
  },
  {
    id: "0.0",
    title: "Normal Pitch",
    value: 0.0,
  },
  {
    id: "5.0",
    title: "Slightly High Pitch",
    value: 5.0,
  },
  {
    id: "10.0",
    title: "High Pitch",
    value: 10.0,
  },
  {
    id: "20.0",
    title: "Very High Pitch",
    value: 20.0
  },
];

export const speakerCountOptions = (t: (string: string) => {}) => {

  const speakers = [
    {
      id: "1",
      title: "2 " + t("speakers"),
      value: 2
    },
    {
      id: "2",
      title: "3 " + t("speakers"),
      value: 3
    },
    {
      id: "3",
      title: "4 " + t("speakers"),
      value: 4
    },
  ]
  return speakers;
}
