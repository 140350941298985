import React, { useEffect, useRef, useState } from "react";
import Icon from "../Icon";
import AsyncSelect from "react-select/async";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useColorMode } from "@chakra-ui/color-mode";

interface SearchEmailSelectProps {
  loadOptions: any;
  onSearchChange: (search: string) => void;
  selectedUserIds: string[];
  onUserSelect: (selectedUserId: string | any) => void;
  setSelectedUser?: any;
  selectedUser?: any;
  isMulti?: boolean;
}
const SearchEmailSelect: React.FC<SearchEmailSelectProps> = ({
  onSearchChange,
  selectedUserIds,
  onUserSelect,
  loadOptions,
  setSelectedUser,
  selectedUser,
  isMulti,
}) => {
  const [error, setError] = useState("");
  const { t } = useTranslation("auth");
  const asyncSelectRef = useRef<any>(null);
  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";

  // Define a custom styles object for React Select
  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? isDarkMode
          ? "#242627"
          : "#f1f5f9"
        : isDarkMode
        ? "black"
        : "white", // You can change the background color here
      color: state.isSelected ? "black" : "inherit", // You can change the text color here
      padding: "8px",
    }),
  };

  useEffect(() => {
    if (isEmpty(selectedUser)) {
      if (asyncSelectRef.current) {
        asyncSelectRef.current.clearValue();
      }
    }
  }, [selectedUser]);

  return (
    <div className="relative mb-3">
      <AsyncSelect
        ref={asyncSelectRef}
        loadOptions={loadOptions}
        onChange={(selectedOption: any) => {
          if (selectedOption) {
            if (isMulti == true) {
              if (selectedOption?.length > 0) {
                onUserSelect([...selectedOption?.map((i: any) => i?.value)]);
              } else {
                onUserSelect([]);
              }
            } else {
              const isUserAlreadySelected = selectedUserIds.find(
                (id: any) => id === selectedOption.value
              );
              if (isEmpty(isUserAlreadySelected)) {
                onUserSelect(selectedOption.value);
              }
              if (
                typeof setSelectedUser === "function" &&
                isEmpty(isUserAlreadySelected)
              ) {
                setSelectedUser(selectedOption);
              }
            }
          }
        }}
        className=" dark:bg-n-6 relative SearchEmail"
        placeholder={t("email_address")}
        classNamePrefix={"Email-select"}
        cacheOptions
        defaultOptions
        isClearable={true}
        styles={customStyles} // Apply custom styles
        // menuIsOpen={true}
        isMulti={isMulti && isMulti == true ? true : false}
      />
      <span>
        {/* <Icon
          className={`absolute top-1/2 -translate-y-1/2 left-4 fill-n-4/50 pointer-events-none transition-colors`}
          name={"email"}
        /> */}
      </span>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default SearchEmailSelect;
