import { createSlice } from "@reduxjs/toolkit";
import {
  storeDebate,
  updateDebate,
  getDebateById,
  getAllDebates,
  updateInvitedUsers,
  updateDebateMessage,
  leaveDebateById,
  readDebateMessages,
  removeUserFromDebate
} from "../actions/debate.actions";
import { RES_PER_PAGE } from "@/constants/pagination";
import { isEmpty, size } from "lodash";

const debateDataInitialState = {
  messages: []
}

export const initialState = {
  storeDebate: {
    data: debateDataInitialState,
    loading: false,
    error: null,
  },
  updateDebate: {
    data: null,
    loading: false,
    error: null,
  },
  leaveDebate: {
    data: null,
    loading: false,
    error: null
  },
  updateDebateMessage: {
    data: null,
    loading: false,
    error: null,
  },
  updateDebateBotMessage: {
    data: null,
    loading: false,
    error: null,
  },
  allDebates: {
    data: { debates: [], currentPage: 0, pages: 0, totalDebates: 0, perPage: RES_PER_PAGE, isAvailableRecords:false },
    loading: false,
    error: null,
  },
  getDebateById: {
    data: null,
    loading: false,
    error: null,
  },
  updateDebateUsers: {
    data: null,
    loading: false,
    error: null,
  },
  readDebateMessages: {
    data: null,
    loading: false,
    error: null,
  },
  debateStates: {
    debateModal: false,
  },
  modalStates: {
    searchModal: false,
    inviteUsers: false,
  },
  stopGenerateDebate: {
    keyName: "",
    value: false,
  },
  debateInvitedUsers: [],
  // Debate Stream
  updateDebateStream: {
    text: '',
    loading: false,
    error: null,
  }
};

export const debateSlice = createSlice({
  name: "debateStore",
  initialState,
  reducers: {
    resetAllDebateData(state, action) {
      return initialState;
    },
    setDebateModal(state, action) {
      state.debateStates.debateModal = action.payload;
    },
    setDebateUsersModal(state, action) {
      state.modalStates.inviteUsers = action.payload;
    },
    setStopGenerateDebate(state, action) {
      state.stopGenerateDebate.keyName = action.payload.keyName;
      state.stopGenerateDebate.value = action.payload.value;
    },
    resetUpdateInvitedUsers(state, action) {
      state.updateDebateUsers.data = null;
      state.updateDebateUsers.loading = false;
      state.updateDebateUsers.error = null;
    },
    resetLeaveDebateById(state, action) {
      state.leaveDebate.data = null;
      state.leaveDebate.loading = false;
      state.leaveDebate.error = null;
    },
    resetDebateBotMsgData(state, action) {
      state.updateDebateBotMessage.data = null;
      state.updateDebateBotMessage.loading = false;
      state.updateDebateBotMessage.error = null;
    },
    resetDebateUpdateEditedMsgData(state, action) {
      state.updateDebateMessage.data = null;
      state.updateDebateMessage.loading = false;
      state.updateDebateMessage.error = null;
    },
    resetDebateUpdateMsgData(state, action) {
      state.updateDebate.data = null;
      state.updateDebate.loading = false;
      state.updateDebate.error = null;
    },
    resetDebatePageLoading(state, action) {
      state.updateDebate.loading = false;
      state.updateDebateMessage.loading = false;
      state.updateDebateBotMessage.loading = false;
    },
    setStoreDebateMessages(state, action) {
      const messages: any = state.storeDebate.data?.messages;
      const newMessage: any = action.payload;
      if(size(messages) >= 0) {
        const msgToUpdateIdx = messages.findIndex((msg: any) => msg.messageId === newMessage?.messageId && !isEmpty(msg.messageId) && !isEmpty(newMessage?.messageId));
        if(msgToUpdateIdx != -1) {
          messages[msgToUpdateIdx] = { ...messages[msgToUpdateIdx], ...newMessage };
          state.storeDebate.data.messages = messages;
        } else {
          //@ts-ignore
          state.storeDebate.data.messages = [...messages, newMessage];
        }
      }
    },
    setDebateMsgToBotMessageEvent(state, action) {
      if (state.storeDebate.data?.messages) {

        const messages = [...state.storeDebate.data.messages];
        const newMessage = action.payload;
        const indexToUpdate = messages.findIndex((msg: any) => msg.responseTo === newMessage.responseTo);
        if (indexToUpdate !== -1) {
          //@ts-ignore
          messages[indexToUpdate] = newMessage;
        }
        state.storeDebate.data.messages = messages;
      }
    },
    updateDebateEventMessages(state, action) {
      if (state.storeDebate.data && state.storeDebate.data.messages) {

        const messages = [...state.storeDebate.data.messages];
        const updatedMessage = action.payload;
        const indexToUpdate = messages.findIndex((msg: any) => msg.id === updatedMessage.id);
        if (indexToUpdate !== -1) {
          //@ts-ignore
          messages[indexToUpdate] = updatedMessage;
        }
        state.storeDebate.data.messages = messages;
      }
    },
    setDebateInvitedUsers(state, action) {
      state.debateInvitedUsers = action.payload;
    },
    setIsDebateArchieved(state, action) {
      console.log('debate-archieved', action.payload);
      if(state.storeDebate.data) {
        const storeDebateData = state.storeDebate.data ?? {};
      // @ts-ignore
      storeDebateData.isArchived = action.payload ?? false;
      state.storeDebate.data = storeDebateData;
      }
    },
    // Debate Stream Implementation
    pushNewDebateStreamMsg(state, action) {
      const newMessageObj = action.payload;
      let newMessagesList: any = [];
      if(size(state.storeDebate.data?.messages) > 0) {
        newMessagesList = [...state.storeDebate.data?.messages, newMessageObj];
      } else {
        newMessagesList = [newMessageObj];
      }
      state.storeDebate.data.messages = newMessagesList;
    },
    removeDebateStreamMsg(state, action) {
      const messageId = action.payload.messageId;
      const idxToRemove = state.storeDebate.data.messages.findIndex((msg: any) => msg?.messageId === messageId);
      if(idxToRemove !== -1) {
        const debateMessages = state.storeDebate.data.messages;
        debateMessages.splice(idxToRemove, 1);
        state.storeDebate.data.messages = debateMessages;
      }
    },
    setUpdateDebateStreamLoading(state, action) {
      state.updateDebate.loading = action.payload ?? false;
      state.updateDebateStream.loading = action.payload ?? false;
    },
    setUpdateDebateStreamText(state, action) {
      const chunk = action.payload.chunks;
      const messageId = action.payload.messageId;

      if(size(chunk) > 0 && size(state.storeDebate.data.messages) > 0) {
        const msgToUpdateIdx = state.storeDebate.data?.messages.findIndex((msg: any) => msg?.messageId === messageId);
        console.log('msgToUpdateIdx', msgToUpdateIdx);
        if (msgToUpdateIdx !== -1) {
          //@ts-ignore
          state.storeDebate.data.messages[msgToUpdateIdx].message += chunk;
        }
      }
    },
    setRegenerateDebateStreamText(state, action) {
      const chunk = action.payload.chunks;
      const messageId = action.payload.messageId;

      if(size(chunk) > 0 && size(state.storeDebate.data.messages) > 0) {
        const msgToUpdateIdx = state.storeDebate.data?.messages.findIndex((msg: any) => msg?.id === messageId);
        if (msgToUpdateIdx !== -1) {
          //@ts-ignore
          state.storeDebate.data.messages[msgToUpdateIdx].message += chunk;
        }
      }
    },
    setUpdateDebateStreamMessageText(state, action) {
      const chunk = action.payload.chunks;
      const messageId = action.payload.messageId;

      if(size(chunk) > 0 && size(state.storeDebate.data.messages) > 0) {
        const msgToUpdateIdx = state.storeDebate.data?.messages.findIndex((msg: any) => msg?.responseTo === messageId);
        console.log('msgToUpdateIdx-index', msgToUpdateIdx);
        if (msgToUpdateIdx !== -1) {
          //@ts-ignore
          state.storeDebate.data.messages[msgToUpdateIdx].message += chunk;
        }
      }
    },
    resetUpdateDebateStreamMessageText(state, action) {
      const chunk = action.payload.chunks;
      const messageId = action.payload.messageId;

      if(size(state.storeDebate.data.messages) > 0) {
        const msgToUpdateIdx = state.storeDebate.data?.messages.findIndex((msg: any) => msg?.responseTo === messageId);
        if (msgToUpdateIdx !== -1) {
          //@ts-ignore
          state.storeDebate.data.messages[msgToUpdateIdx].message = chunk ?? "";
        }
      }
    },
    updateDebateMsgContent(state, action) {
      const { text, messageId } = action.payload;
      const msgToUpdateIdx = state.storeDebate.data.messages.findIndex((msg: any) => msg.id === messageId);
      if(msgToUpdateIdx !== -1) {
        //@ts-ignore
        state.storeDebate.data.messages[msgToUpdateIdx].message = text ?? "";
      }
    },
    // Debate Bot Message Regenerate
    setDebateBotMsgData(state, action) {
      state.updateDebateBotMessage.data = action.payload ?? null;
    },
    setDebateBotMsgLoading(state, action) {
      state.updateDebateBotMessage.loading = action.payload ?? false;
    },
    setDebateBotMsgError(state, action) {
      state.updateDebateBotMessage.error = action.payload ?? null;
    },
    // Debate Update Message with bot mentioned
    setDebateUpdateStreamMsgData(state, action) {
      state.updateDebateMessage.data = action.payload ?? null;
    },
    setDebateUpdateStreamMsgLoading(state, action) {
      state.updateDebateMessage.loading = action.payload ?? false;
    },
    setDebateUpdateStreamMsgError(state, action) {
      state.updateDebateMessage.error = action.payload ?? null;
    },
  },
  extraReducers: {
    [storeDebate.pending.type]: (state, action) => {
      state.storeDebate.loading = true;
      state.storeDebate.data = debateDataInitialState;
      state.storeDebate.error = null;
    },
    [storeDebate.fulfilled.type]: (state, action) => {
      state.storeDebate.loading = false;
      state.storeDebate.data = action.payload.data;
      state.storeDebate.error = null;
    },
    [storeDebate.rejected.type]: (state, action) => {
      state.storeDebate.data = debateDataInitialState;
      state.storeDebate.loading = false;
      state.storeDebate.error = action.error?.message;
    },
    [getDebateById.pending.type]: (state, action) => {
      state.storeDebate.loading = true;
      state.storeDebate.data = debateDataInitialState;
      state.storeDebate.error = null;
    },
    [getDebateById.fulfilled.type]: (state, action) => {
      state.storeDebate.loading = false;
      state.storeDebate.data = action.payload.data;
      state.storeDebate.error = null;
    },
    [getDebateById.rejected.type]: (state, action) => {
      state.storeDebate.data = debateDataInitialState;
      state.storeDebate.loading = false;
      state.storeDebate.error = action.error?.message;
    },
    [updateDebate.pending.type]: (state, action) => {
      state.updateDebate.loading = true;
      state.updateDebate.error = null;
    },
    [updateDebate.fulfilled.type]: (state, action) => {
      state.updateDebate.loading = false;
      state.updateDebate.data = action.payload.data;
      state.updateDebate.error = null;
    },
    [updateDebate.rejected.type]: (state, action) => {
      state.updateDebate.loading = false;
      state.updateDebate.error = action.error?.message;
    },
    [getAllDebates.pending.type]: (state, action) => {
      state.allDebates.loading = true;
      state.allDebates.error = null;
    },
    [getAllDebates.fulfilled.type]: (state, action) => {
      state.allDebates.loading = false;
      if (action.meta.arg.page === 1 || !isEmpty(action.meta.arg.search)) {//|| !isEmpty(action.meta.arg.filter) 
        state.allDebates.data.debates = action.payload.data.debates;
      } else {
        // @ts-ignore
        state.allDebates.data.debates = [ ...state.allDebates.data.debates, ...action.payload.data.debates];
      }
      state.allDebates.data.currentPage = action.payload.data.currentPage;
      state.allDebates.data.pages = action.payload.data.pages;
      state.allDebates.data.totalDebates = action.payload.data.totalDebates;
      state.allDebates.data.isAvailableRecords = action.payload.data.isAvailableRecords;
      state.allDebates.data.perPage = action.payload.data.perPage;
      state.allDebates.error = null;
      console.log(state.allDebates.data.debates, " Get all debate fullfilled here.");
      
    },
    [getAllDebates.rejected.type]: (state, action) => {
      state.allDebates.loading = false;
      state.allDebates.error = action.error?.message;
    },
    [updateInvitedUsers.pending.type]: (state, action) => {
      state.updateDebateUsers.data = null;
      state.updateDebateUsers.loading = true;
      state.updateDebateUsers.error = null;
    },
    [updateInvitedUsers.fulfilled.type]: (state, action) => {
      state.updateDebateUsers.loading = false;
      const invitedUsers = action.payload.data?.invitedUsers;
      state.updateDebateUsers.data = action.payload.data;
      // @ts-ignore
      if(!isEmpty(state.storeDebate.data) && state.storeDebate?.data?.invitedUsers) {
        // @ts-ignore
        state.storeDebate.data.invitedUsers = invitedUsers;
      }
      state.updateDebateUsers.error = null;
    },
    [updateInvitedUsers.rejected.type]: (state, action) => {
      state.updateDebateUsers.loading = false;
      state.updateDebateUsers.data = null;
      state.updateDebateUsers.error = action.error?.message;
    },
    [removeUserFromDebate.fulfilled.type]: (state, action) => {
      state.updateDebateUsers.loading = false;
      state.updateDebateUsers.data = action.payload.data;
      const invitedUsers = action.payload.data?.invitedUsers;
      // @ts-ignore
      if(!isEmpty(state.storeDebate.data) && state.storeDebate?.data?.invitedUsers) {
        // @ts-ignore
        state.storeDebate.data.invitedUsers = invitedUsers;
      }
      state.updateDebateUsers.error = null;
    },
    [updateDebateMessage.pending.type]: (state, action) => {
      state.updateDebateMessage.data = null;
      state.updateDebateMessage.loading = true;
      state.updateDebateMessage.error = null;
    },
    [updateDebateMessage.fulfilled.type]: (state, action) => {
      state.updateDebateMessage.loading = false;
      state.updateDebateMessage.data = action.payload.data;
      state.updateDebateMessage.error = null;
    },
    [updateDebateMessage.rejected.type]: (state, action) => {
      state.updateDebateMessage.loading = false;
      state.updateDebateMessage.data = null;
      state.updateDebateMessage.error = action.error?.message;
    },
    [leaveDebateById.pending.type]: (state, action) => {
      state.leaveDebate.data = null;
      state.leaveDebate.loading = true;
      state.leaveDebate.error = null;
    },
    [leaveDebateById.fulfilled.type]: (state, action) => {
      state.leaveDebate.loading = false;
      state.leaveDebate.data = action.payload.data;
      const invitedUsers = action.payload.data?.invitedUsers;
      // @ts-ignore
      if(!isEmpty(state.storeDebate.data) && state.storeDebate?.data?.invitedUsers) {
        // @ts-ignore
        state.storeDebate.data.invitedUsers = invitedUsers;
      }
      state.leaveDebate.error = null;
    },
    [leaveDebateById.rejected.type]: (state, action) => {
      state.leaveDebate.loading = false;
      state.leaveDebate.data = null;
      state.leaveDebate.error = action.error?.message;
    },
    [readDebateMessages.pending.type]: (state, action) => {
      state.readDebateMessages.loading = true;
    },
    [readDebateMessages.fulfilled.type]: (state, action) => {
      state.readDebateMessages.loading = false;
      state.leaveDebate.data = action.payload.data;
    },
    [readDebateMessages.rejected.type]: (state, action) => {
      state.readDebateMessages.error = action.error?.message;
    },
  },
});

export const {
  resetAllDebateData,
  setDebateModal,
  resetUpdateInvitedUsers,
  setDebateUsersModal,
  setStoreDebateMessages,
  setStopGenerateDebate,
  resetDebateBotMsgData,
  resetDebateUpdateMsgData,
  resetLeaveDebateById,
  updateDebateEventMessages,
  resetDebatePageLoading,
  resetDebateUpdateEditedMsgData,
  setDebateInvitedUsers,
  setIsDebateArchieved,
  // Debate Stream Work
  pushNewDebateStreamMsg,
  setUpdateDebateStreamText,
  updateDebateMsgContent,
  setUpdateDebateStreamLoading,
  removeDebateStreamMsg,
  setDebateBotMsgData,
  setDebateBotMsgError,
  setDebateBotMsgLoading,
  setDebateUpdateStreamMsgData,
  setDebateUpdateStreamMsgLoading,
  setDebateUpdateStreamMsgError,
  setUpdateDebateStreamMessageText,
  resetUpdateDebateStreamMessageText,
  setDebateMsgToBotMessageEvent,
  setRegenerateDebateStreamText
} = debateSlice.actions;

export default debateSlice.reducer;
