import React, { useEffect, useReducer, useState } from "react";
import Icon from "../../Icon";
import Image from "next/image";
import ResponseImageSelect from "../../ResponseImageSelect";
import ImageQualitySelect from "../../ImageQualitySelect";
import ImageSizeSelect from "../../ImageSizeSelect";
import RangeInput from "../../RangeInput";
import { size } from "lodash";
import AccordionGlobal from "../../AccordionGlobal";
import { Accordion } from "react-accessible-accordion";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCreatePhotoData,
  setSidebarData,
  seteditGeneratedImageScreen,
} from "store/slices/PhotoGenerationSlice";
import UploadImage from "@/components/UploadImage/UploadImage";
import { editImage } from "store/actions/photoGeneration.actions";

const UpscaleScreen = ({ fromFeature }: any) => {
  const { t: lt } = useTranslation("translation");
  const dispatch: any = useDispatch();
  const photoGeneratedData = useSelector(
    (state: any) => state.photoGenerateStore
  );
  const { photoGeneratedChat, sidebarData, editGeneratedImage } =
    photoGeneratedData;

  const [image, setImage] = useState<any>({
    file: "",
  });
  const [btnSelected, setbtnSelected] = useState<Number>(0);

  useEffect(() => {
    if (image?.file) {
      dispatch(
        setSidebarData({ type: "SET_EDIT_IMAGE", payload: image?.file })
      );
      dispatch(resetCreatePhotoData({}));
      image?.file && dispatch(seteditGeneratedImageScreen(null));
    }
  }, [image?.file]);
  const handleNext = () => {
    const data = {
      negativePrompt: sidebarData.negativeText,
      images: sidebarData.images,
      aspect: sidebarData.orientation,
      orientation: 1,
      cfgScale: sidebarData.imageGenerationConfig.cfgScale,
      seed: sidebarData.imageGenerationConfig.seed,
      quality: sidebarData.imageGenerationConfig.quality,
      width: sidebarData.imageGenerationConfig.width,
      height: sidebarData.imageGenerationConfig.height,
      noOfImages: sidebarData.imageGenerationConfig.numberOfImages,
      taskType: sidebarData.taskType,
      editType: "upscale",
      file: editGeneratedImage ? null : sidebarData?.editImage,
      fileName: editGeneratedImage
        ? editGeneratedImage.split("?")[0].split("/").pop()
        : null,
      prompt: "upscale",
      factor: btnSelected == 0 ? "x2" : "x4",
    };
    dispatch(editImage(data));
  };
  return (
    <div className=" flex items-center h-[93%] w-full ">
      <div
        className={` w-full p-4 ${
          photoGeneratedChat.loading && "ointer-event-none opacity-60"
        }`}
      >
        <UploadImage
          state={image}
          setState={setImage}
          description={lt("upload_upscale_image")}
        />

        <div
          className={`${photoGeneratedChat.loading && "cursor-not-allowed"}`}
        >
          <div
            className={`${photoGeneratedChat.loading && "pointer-events-none"}`}
          >
            <label className="block text-base font-semibold !font-mont">
              Upscale
            </label>
            <div
              className={`w-full bg-[#EAEAEA] rounded-lg p-1 mt-2 h-10 ${
                !fromFeature && "dark:bg-neutral-900"
              }`}
            >
              <button
                onClick={() => setbtnSelected(0)}
                className={` h-full rounded-md w-1/2 ${
                  btnSelected === 0
                    ? fromFeature
                      ? "bg-white"
                      : "bg-white dark:bg-[#232627]"
                    : ""
                }`}
              >
                <span
                  className={`text-sm !font-mont ${
                    fromFeature && "text-black"
                  }`}
                >
                  2K
                </span>
              </button>
              <button
                onClick={() => setbtnSelected(1)}
                className={` h-full rounded-md w-1/2  ${
                  btnSelected === 1
                    ? fromFeature
                      ? "bg-white"
                      : "bg-white dark:bg-[#232627]"
                    : ""
                }`}
              >
                <span
                  className={`text-sm !font-mont ${
                    fromFeature && "text-black"
                  }`}
                >
                  4K
                </span>
              </button>
            </div>
            <button
              className="btn bg-[#8E55EA] w-full mt-5 text-white"
              onClick={handleNext}
              disabled={
                (!sidebarData.editImage || sidebarData.editImage == "") &&
                !editGeneratedImage
              }
            >
              Upscale
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpscaleScreen;
