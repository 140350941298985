import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from './data.json';

type LoaderProps = {
    size: number;
  };

const LoderWave = ({size}:LoaderProps) => {
    const container = useRef<any>(null);

    useEffect(() => {
        const animation = lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData
        });

        return () => {
            animation.destroy();
        };
    }, []);

    return <div ref={container} style={{ width: size, height: size }} />;
};

export default LoderWave;