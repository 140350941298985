import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "@/components/Select";
import { isEmpty, size } from "lodash";
import Item from "../Item/DebateItem";
import { RES_PER_PAGE } from "@/constants/pagination";
import { translateDates } from "@/constants/chats";
import { getAllDebates } from "store/actions/debate.actions";
import ChatSearchSkelton from "@/components/ChatSearchSkelton";
import { useTranslation } from "react-i18next";
import NoDebatelistPlaceholder from "@/components/NoDebateListPlaceholder";
import { setDebateModal } from "store/slices/DebateSlice";
import { setSearchModal } from "store/slices/ChatGptSlice";
import Icon from "@/components/Icon";
import { useRouter } from 'next/router';
import { ROUTES } from '@/constants/routes';

const DebateSearchListing = () => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation('translation');
  const dates = translateDates(t);

  const debateData = useSelector((state: any) => state.debateStore);
  const { allDebates } = debateData;

  const [search, setSearch] = useState<string>("");
  const [dateSpan, setDateSpan] = useState<any>("");
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    const params = {
      search: search,
      filter: dateSpan.id || "",
      page: 1,
      perPage: RES_PER_PAGE,
    };
    dispatch(getAllDebates(params));
  }, [dateSpan]);

  const handleNewDebate = (event: any) => {
    event.preventDefault();
    dispatch(setDebateModal(true)); 
    dispatch(setSearchModal(false));
    router.push(ROUTES.CHAT);
  };

  return (
    <div className="pt-5 pb-6 px-6 h-[35.563rem]">
      <div className="flex justify-between items-center mb-5 md:block md:space-y-4 md:mb-0">
        {size(allDebates?.data?.debates) === 0 && isEmpty(dateSpan?.id) && search === '' ? null: <>
          <h3 className="h5 !font-mont !font-extrabold">{t('debate')}</h3>
          <Select
            className="w-[50%] md:w-full"
            classButton="h-11 rounded-full shadow-[inset_0_0_0_0.0625rem_#DADBDC] caption1 dark:shadow-[inset_0_0_0_0.0625rem_#2A2E2F] dark:bg-transparent"
            classOptions="min-w-full"
            classIcon="w-5 h-5 fill-n-4/50"
            classArrow="dark:fill-n-4"
            icon="clock"
            placeholder={t('all')}
            items={dates}
            value={dateSpan}
            onChange={setDateSpan}
          />
        </>}

      </div>
      {allDebates.loading ? (
        <div className="h-[28.75rem] overflow-auto scrollbar-none flex flex-col gap-3 mt-4">
          <ChatSearchSkelton />
          <ChatSearchSkelton />
          <ChatSearchSkelton />
          <ChatSearchSkelton />
        </div>
      ) : size(allDebates?.data?.debates) === 0 && !allDebates.loading ? <div  className="h-[calc(100vh-450px)] flex flex-col items-center justify-center">
        {debateData?.allDebates?.data?.isAvailableRecords ? (
            <p className="h4 !font-mont !font-semibold">No Records Found</p>
          ):(<NoDebatelistPlaceholder /> )}
          <button style={{ display: debateData?.allDebates?.data?.isAvailableRecords ? 'none' : 'block' }} className="btn-blue flex justify-center gap-2 mt-5" onClick={handleNewDebate}><Icon name="plus" /> {t('start_debate')}</button></div> : (
        <div className="h-[28.75rem] overflow-auto scrollbar-none">
          <Item
            title={t('all_debates')}
            date={new Date()}
            list={allDebates.data?.debates}
          />
          {allDebates.loading || allDebates?.data?.totalDebates > size(allDebates?.data?.debates) && (
            <button
              type="button"
              className="btn-stroke-light block mt-4 w-full"
              disabled={size(allDebates?.data?.debates) == allDebates?.data?.totalDebates || allDebates.loading}
              onClick={() => {
                const params = {
                  search: search,
                  filter: dateSpan.id || "",
                  page: page + 1,
                  perPage: RES_PER_PAGE,
                };
                dispatch(getAllDebates(params));
                setTimeout(() => setPage(page + 1), 10);
              }}
              >
                {t('load_more')}
              </button>
            )}
        </div>
      )}
    </div>
  );
};

export default DebateSearchListing;
