import { createAsyncThunk } from "@reduxjs/toolkit";
import { planService } from "services/plan.service";

export const getPlan = createAsyncThunk("planstore/getPlan", async () => {
  try {
    const response = await planService.show();
    return response.data;
  } catch (error: any) {
    const { response } = error;
    throw response.data.message || response.statusText;
  }
});
export const getPlanPrice = createAsyncThunk(
  "planstore/getPlanPrice",
  async () => {
    try {
      // return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
