import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Icon from "@/components/Icon";
import { addPromptQuestion } from "store/slices/ChatGptSlice";
import { useDispatch } from "react-redux";
import { resetAllAdvisorStates } from "store/slices/AdvisorSlice";
import { Keyboard } from "@/components/LandingPage/Icons";
import { getInstantTranslation, getTextLanguage } from "lib/utils";
import toast from "react-hot-toast";
import Notify from "@/components/Notify";
import { useRouter } from "next/router";
import { CopyIcon, TranslateIcon } from "../../../components/SVGs";
import DropdownInput from "./DropdownnInput";
import { debounce } from "lodash";
import Loading from "@/components/Answer/Loading";
import { useColorMode } from "@chakra-ui/color-mode";

type NewDebateModal = {
  onClose: () => void;
};
const InstantTranslatorModal = ({ onClose }: NewDebateModal) => {
  const { t } = useTranslation("translation");
  const { t: lt } = useTranslation("auth");
  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<string>("");
  const [result, setResult] = useState("");
  const router = useRouter();
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState<any>(null);
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(null);

  const keyboardClickHandler = () => {
    onClose();
    dispatch(addPromptQuestion(result));
    router.push("chat");
    dispatch(resetAllAdvisorStates({}));
  };

  useEffect(() => {
    if (value != "" && translatedLanguage) {
      debouncedGetTranslation(value, translatedLanguage);
    }
  }, [translatedLanguage]);

  const debouncedGetTranslation = useCallback(
    debounce(async (inputValue: string, language: string) => {
      setLoading(true);
      try {
        if (inputValue.length) {
          const [res, res2] = await Promise.all([
            getInstantTranslation(inputValue, language),
            getTextLanguage(inputValue),
          ]);
          if (res.status === 200) {
            setResult(res.data.translation);
            setSelectedLanguage(res2.data.language);
          } else {
            toast.error("Failed to get the response");
          }
        } else {
          setResult("");
          setSelectedLanguage(null);
        }
      } catch (err) {
        console.error("Error in getting response:", err);
        toast.error("Error in getting response");
      } finally {
        setLoading(false);
      }
    }, 500), // 500ms debounce time
    []
  );

  const onCopy = () => {
    setTimeout(() => {
      const textArea = document.createElement("textarea");
      textArea.value = result;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      toast((te) => (
        <Notify iconCheck>
          <div className="h6 sm:text-sm">{t("translation_copied")}</div>
        </Notify>
      ));
    }, 1000);
  };
  const onChange = async (event: any) => {
    setValue(event.target.value);
    if (translatedLanguage) {
      debouncedGetTranslation(event.target.value, translatedLanguage);
    } else {
      const res = await getTextLanguage(event.target.value);
      setSelectedLanguage(res.data.language);
    }
  };

  return (
    <div className="px-10 2xl:px-7 h-full w-full pb-10 pt-7">
      <div className="">
        <div className="flex items-center justify-between pb-2 ">
          
          <span className="!font-mont !font-extrabold h5">{t("instant_translator")}</span>
          <button onClick={onClose}>
            <Icon name={"close"} className=" fill-n-4 d" />
          </button>
        </div>
        <h6 className="block !font-mont text-[15px] sm:text-[9px] mt-[1%] mb-[2%] !font-normal ">{t("instant_translator_description")}</h6>
      </div>
      <div className="w-full rounded-xl pt-2 relative border-2 border-[#BDBFBF] h-[77%] mt-5 flex flex-col ">
        <div className=" w-full flex flex-row pl-3 py-2 items-center">
          <div className=" w-1/2 sm:text-xs flex items-center !font-mont font-semibold">
            {selectedLanguage && selectedLanguage != "Unknown"
              ? lt(selectedLanguage) + " - " + t("detected")
              : t("detected_language")}
          </div>
          <div className="w-1/2 pl-3 ">
            <div className=" absolute top-5 left-[48.5%]">
              <TranslateIcon
                fill={loading ? "#A6A9A9" : isDarkMode ? "#FEFEFE" : "black"}
              />
            </div>
            <DropdownInput
              selectedLanguage={translatedLanguage}
              setSelectedLanguage={setTranslatedLanguage}
            />
          </div>
        </div>
        {/* <div className=" w-full flex flex-row pl-3 py-2 items-center">
          <div className=" w-1/2 sm:text-xs flex items-center">
            {selectedLanguage && selectedLanguage != "Unknown"
              ? lt(selectedLanguage) + " - " + t("detected")
              : t("detected_language")}
          </div>
          <div className="w-1/2 pl-3 ">
            <div className=" absolute top-5 left-[48.5%]">
              <TranslateIcon
                fill={loading ? "#A6A9A9" : isDarkMode ? "#FEFEFE" : "black"}
              />
            </div>
            <DropdownInput
              selectedLanguage={translatedLanguage}
              setSelectedLanguage={setTranslatedLanguage}
            />
          </div>
        </div> */}

        <div className=" flex flex-row border-t-2 border-[#BDBFBF] h-[100%]">
          <div className=" w-1/2 border-r-2 border-[#BDBFBF] h-full relative">
            <textarea
              className="h-full w-full resize-none p-2 focus:outline-none text-sm bg-transparent !font-mont "
              placeholder={t("translate_placeholder")}
              value={value}
              onChange={onChange}
            />
            {value.length > 0 && (
              <button
                className=" absolute right-4 top-2"
                onClick={() => {
                  setValue("");
                  setResult("");
                }}
              >
                {" "}
                <Icon name={"close"} className=" fill-n-4 d" />
              </button>
            )}
          </div>
          <div className=" w-1/2 h-full relative ">
          <textarea
              className="h-full w-full resize-none p-2 focus:outline-none text-sm bg-transparent !font-mont "
              placeholder={("")}
              value={result}
              disabled
            />
            {loading && (
              <div className=" absolute bottom-2 left-[47%]">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-end my-3 ">
        <button disabled={result === ""} className=" mr-1" onClick={onCopy}>
          <CopyIcon />
        </button>
        <button disabled={result === ""} onClick={() => keyboardClickHandler()}>
          <span
            className={`w-6 h-6 !font-mont ${
              result === "" ? "bg-neutral-500" : "bg-primary-1"
            } flex justify-center items-center rounded-md cursor-pointer`}
          >
            <Keyboard />
          </span>
        </button>
      </div>
    </div>
  );
};

export default InstantTranslatorModal;
