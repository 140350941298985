import { HttpService } from './base.service'
import { ICreateDevice } from '../interfaces/device.interface'

class DeviceService extends HttpService {
  private readonly prefix: string = "device";
  
  index = (): Promise<any> => this.get(this.prefix + '/index');
  
  store = (data: ICreateDevice): Promise<any> => this.post(this.prefix + '/store', data);

  destroy = (deviceId: string): Promise<any> => this.delete(this.prefix + `/destroy/${deviceId}`);
  destroyAll = (): Promise<any> => this.delete(this.prefix + '/destroy-all');
}

export const deviceService = new DeviceService();