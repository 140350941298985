import { formatSeconds } from "./DateTime";

// export const estimateAudioDuration=(text: string, speed: number) => {
// console.log('text', text);
//   const estimatedMinutes = text ? text.length / (12 * Number(speed)): 0;

//   return formatSeconds(estimatedMinutes);
// }

export const estimateAudioDuration = (text: string, speed: number) => {
  try {
  const words = text?.trim()?.split(/\s+/);

  const wordCount = words && words.length ? words.length : 0;
    const wordsPerMinute = 120;  // according to Gemni avg is 100 - 130 words per min depending on compaxity
  const estimatedMinutes = wordCount / wordsPerMinute;
  const estimatedSeconds = estimatedMinutes * 60;

  return formatSeconds(estimatedSeconds);
  } catch (error) {
    return '00:00';
  }
};

export const convertWordsCountToMins = (wordsCounter: number) => {
  try {
    const wordsPerMinute = 150;
    const estimatedMinutes = wordsCounter / wordsPerMinute;
    const estimatedSeconds = estimatedMinutes * 60;
  
    return formatSeconds(estimatedSeconds);
  } catch(err) {
    return '00:00';
  }

}