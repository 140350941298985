import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import Field from "@/components/Field";
import Icon from "@/components/Icon";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "store/actions/auth.actions";
import SerachEmailSelect from "@/components/SerarchEmailSelect";
import { isEmpty, size, throttle } from "lodash";
import { storeDebate } from "store/actions/debate.actions";
import { IDebateData } from "interfaces/debate.interface";
import { audioService } from "services/audio.service";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import { PROFILE_URL } from "@/constants/user";
import { useTranslation } from "react-i18next";
import { isValidEmailRegex } from "lib/regex";
import {
  resetChatAndNotebooks,
  setSelectedNotebooks,
} from "store/slices/ChatGptSlice";
import { capitalizeFirstLetter } from "lib/utils";

type NewDebateModal = {
  onCancel?: () => void;
  setVisibleModal?: any;
};

const throttledSearch = throttle((search, dispatch) => {
  const params = { search: search };
  dispatch(getAllUsers(params));
}, 1500);

const NewDebateModal = ({ onCancel, setVisibleModal }: NewDebateModal) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation(["translation", "auth"]);

  const debateData = useSelector((state: any) => state.debateStore);

  const [title, setTitle] = useState("");
  const [userIds, setUserIds] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>({});

  const initialLoad = useRef<any>(null);

  useEffect(() => {
    if (!initialLoad.current) {
      const params = { search: "" };
      dispatch(getAllUsers(params));
      initialLoad.current = true;
    }
  }, []);

  const handleSearch = (search: string) => {
    throttledSearch(search, dispatch);
  };

  const isAllowToStartDebate = () => {
    return (
      size(title?.trim()) <= 2 ||
      size(selectedUsers) === 0 ||
      debateData.storeDebate.loading
    );
  };

  const handleStartDebate = () => {
    try {
      const selectedUserIds = selectedUsers.map((user: any) => user.value);
      const data: IDebateData = {
        title: title,
        invitedUsers: selectedUserIds,
      };
      dispatch(storeDebate(data))
        .unwrap()
        .then((response: any) => {
          closeModal();
          dispatch(setSelectedNotebooks([]));
          dispatch(resetChatAndNotebooks(null));
          router.push(`/debate/${response.data.id}`);
        });
    } catch (error) {}
  };

  const loadOptions = async (search: string) => {
    // Removed the email validation to allow loading of initial options
    try {
      const response = await audioService.getAllUsers({
        search,
        page: 1,
        perPage: 12,
      });

      // Assuming `response` contains an array of user objects
      if (response?.data && response?.data?.data?.users?.length === 0) {
        return [];
      }

      return (
        response?.data &&
        response?.data?.data?.users?.map((item: any) => ({
          value: item._id, // Ensure your items have a unique `_id`
          label: (
            <div className="flex gap-3 items-center react-select">
              <figure className="w-8 h-8 rounded-full relative">
                <Image
                  src={item?.profileUrl ?? PROFILE_URL}
                  fill
                  className="w-full h-full rounded-full"
                  alt="email-profile"
                />
              </figure>
              <div className="flex flex-col">
                <span className="text-sm dark:text-white !font-mont">{item.userName}</span>
                <p className="text-xs text-n-4 dark:text-n-1">{item.email}</p>
              </div>
            </div>
          ),
          email: item.email,
          userName: item.userName,
          profileUrl: item.profileUrl,
        }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };


  const handleRemoveInvite = (item: any) => {
    try {
      const allInvitedUsers = [...selectedUsers];
      const allUserIds = [...userIds];

      const indexToRemove = allInvitedUsers.findIndex(
        (el: any) => el.value === item.value
      );
      if (indexToRemove !== -1) {
        allInvitedUsers.splice(indexToRemove, 1);
        allUserIds.splice(indexToRemove, 1);
        setTimeout(() => {
          setUserIds(allUserIds);
          setSelectedUsers(allInvitedUsers);
        }, 10);
      }
    } catch (error) {}
  };

  const handleInviteUser = () => {
    const isUserAlreadySelected = selectedUsers.find(
      (user: any) => user.value === selectedUser.value
    );
    if (isEmpty(isUserAlreadySelected)) {
      setSelectedUsers([...selectedUsers, selectedUser]);
      setSelectedUser({});
    } else {
      setSelectedUser({});
      toast(
        (t) => (
          <Notify iconError>
            <div className="h6 sm:text-sm">{lt("already_select")}</div>
          </Notify>
        ),
        { duration: 1000 }
      );
    }
  };
  const closeModal = () => {
    setSelectedUser({});
    setSelectedUsers([]);
    setVisibleModal(false);
  };

  return (
    <div>
      <h3 className="h4 mb-5 !font-mont !font-extrabold">{lt("startyour_debate")}</h3>
      <Field
        value={title}
        onChange={(e: any) => setTitle(e.target.value)}
        placeholder={lt("debate_title")}
        label={lt("debate_title")}
        classInput="mb-4 !bg-transparent"
      />
      <div className="mb-4">
        <div className="mb-3">
          <p className="text-sm font-semibold mb-2 block">
            {lt("add_userChat")}
          </p>
          <div className="relative">
            <SerachEmailSelect
              loadOptions={loadOptions}
              onSearchChange={handleSearch}
              selectedUserIds={userIds}
              onUserSelect={(selectedUser: any) =>
                setUserIds([...userIds, selectedUser])
              }
              //@ts-ignore
              setSelectedUser={(selectedUser: any) =>
                setSelectedUser(selectedUser)
              }
              selectedUser={selectedUser}
            />
            <button
              disabled={isEmpty(selectedUser)}
              onClick={handleInviteUser}
              className="absolute btn-blue top-1/2 -translate-y-1/2 right-3 !py-1 h-9"
            >
              {lt("invite")}
            </button>
          </div>
        </div>
        <p className="text-n-4 text-sm opacity-[50%] mb-3">
          {selectedUsers?.length <= 0
            ? lt("invited_debate")
            : lt("added_debate")}
        </p>
        <div className="space-y-4 max-h-[calc(100vh-500px)] overflow-auto AtScroll pr-5">
          {selectedUsers &&
            selectedUsers.map((item: any, idx: number) => {
              console.log('Selected Users ' , selectedUsers);
              
              return (
                <div className="flex justify-between items-center" key={idx}>
                  <div className="flex items-center gap-3">
                    <>
                      {
                        // item?.profileUrl?
                        <figure className="w-8 h-8 rounded-full relative">
                          <Image
                            src={item?.profileUrl ?? PROFILE_URL}
                            fill
                            className="w-full h-full rounded-full"
                            alt="email-profile"
                          />
                        </figure>
                        // :
                        // <div className="w-8 h-8 rounded-full text-white bg-primary-1 flex justify-center items-center flex-shrink-0">
                        //   {item.userName && item?.userName?.charAt(0)?.toUpperCase()}
                        // </div>
                      }
                    </>
                    <div>
                      <p className="text-sm font-semibold !mb-0 leading-none">
                        {item?.userName || ""}
                      </p>
                      <span className="text-xs text-n-4 opacity-[50%] !font-mont">
                        {item?.email}
                      </span>
                    </div>
                  </div>
                  <span
                    onClick={() => handleRemoveInvite(item)}
                    className="cursor-pointer !font-mont"
                  >
                    <Icon
                      className="w-4 h-4 fill-n-6 dark:fill-n-3 opacity-[50%] hover:opacity-70"
                      name="close"
                    />
                  </span>
                </div>
              );
            })}
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex gap-3">
          <button
            className="btn btn-border border-n-3 hover:opacity-90"
            onClick={() => closeModal()}
          >
            {lt("cancel_btn")}
          </button>
          <button
            className="btn btn-blue hover:opacity-90"
            disabled={isAllowToStartDebate()}
            onClick={handleStartDebate}
          >
            {lt("create_debate")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewDebateModal;
