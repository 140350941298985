import { createSlice } from "@reduxjs/toolkit";

export const initialState: any = {
    curStep: 1,
    LanguageDetails: null,
    summarizationProgress: 0,
    detectedLanguage: null
};

export const AudioSummarySlice = createSlice({
    name: "AudioSummary",
    initialState: initialState,
    reducers: {
        setcurrentSummaryStep(state, action) {
            state.curStep = action.payload
        },
        setLanguageDetails(state, action) {
            state.LanguageDetails = action.payload
        },
        setSummarizationProgress(state, action) {
            state.summarizationProgress = action.payload;
        },
        setDetectedLanguage(state, action) {
            state.detectedLanguage = action.payload
        },
        resetAudioSummary() {
            return initialState;
        }
    },

});

export const { setcurrentSummaryStep, setLanguageDetails, resetAudioSummary, setSummarizationProgress, setDetectedLanguage } = AudioSummarySlice.actions;

export default AudioSummarySlice.reducer;