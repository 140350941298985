import { createSlice } from "@reduxjs/toolkit";

import { getPlan, getPlanPrice } from "../actions/plan.action";

export const initialState: any = {
  getPlan: {
    data: [],
    loading: false,
    error: null,
  },
  planPrice: {
    data: [],
    loading: false,
    error: null,
  },
  customPlanData: {
    data: [],
    loading: false,
    error: null,
  },
  isSpecialCountry: {
    data: null,
    loading: false,
    error: null,
  },
};

export const audioSlice = createSlice({
  name: "planStore",
  initialState: initialState,
  reducers: {
    resetAllPlanData(state, action) {
      return initialState;
    },
    resetGetPlanLoadingError(state, action) {
      state.getPlan.loading = false;
      state.getPlan.error = null;
    },
    addPlanPrice: (state, action) => {
      state.customPlanData = initialState.customPlanData;
      state.planPrice.data = action.payload;
    },
    setCustomPlanData: (state, action) => {
      state.planPrice = initialState.planPrice;
      state.customPlanData = action.payload;
    },
    setIsSpecialCountry(state, action) {
      state.isSpecialCountry.data = action.payload;
    },
  },

  extraReducers: {
    // Audio Library Implementations
    [getPlan.pending.type]: (state, action) => {
      state.getPlan.loading = true;
    },
    [getPlan.fulfilled.type]: (state, action) => {
      // state.getPlan.data = action.payload.data.sort((a:any,b:any) => b.save - a.save)
      state.getPlan.data = action.payload.data
      state.getPlan.loading = false;
      state.getPlan.error = null;
    },
    [getPlan.rejected.type]: (state, action) => {
      state.getPlan.loading = false;
      state.getPlan.data = [];
      state.getPlan.error = action.error?.message;
    },
    // get plans price
    [getPlanPrice.pending.type]: (state, action) => {
      state.planPrice.loading = true;
    },
    [getPlanPrice.fulfilled.type]: (state, action) => {
      // @ts-ignore
      state.planPrice.data = [...action.payload.data];
      state.planPrice.loading = false;
      state.planPrice.error = null;
    },
    [getPlanPrice.rejected.type]: (state, action) => {
      state.planPrice.loading = false;
      state.planPrice.data = [];
      state.planPrice.error = action.error?.message;
    },
  },
});

export const { resetAllPlanData, resetGetPlanLoadingError, addPlanPrice, setCustomPlanData,setIsSpecialCountry } =
  audioSlice.actions;

export default audioSlice.reducer;