import { createAsyncThunk } from "@reduxjs/toolkit";
import { languageListService } from "services/languageList.service";

// Voices List
export const getVoicesList = createAsyncThunk(
  "audioStore/getVoicesList",
  async () => {
    try {
      const response = await languageListService.index();
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
)