import React, { useEffect, useReducer, useState } from "react";
import RangeInput from "../../RangeInput";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCreatePhotoData,
  setSidebarData,
  seteditGeneratedImageScreen,
} from "store/slices/PhotoGenerationSlice";
import Notify from "../../Notify";
import UploadDocument from "@/components/UploadDocument";
import UploadImage from "@/components/UploadImage/UploadImage";
import { Language } from "@/components/SVGs";
import { editImage } from "store/actions/photoGeneration.actions";
import Select from "@/components/Select";
import { photoGenerateService } from "services/imageGeneration.service";
import { toast } from "react-hot-toast";
import Icon from "@/components/Icon";

const Languages = [
  {
    id: "1",
    title: "English",
    code: "en",
    languageName: "English",
  },
  {
    id: "2",
    title: "French",
    code: "fr",
    languageName: "French",
  },
  {
    id: "3",
    title: "German",
    code: "de",
    languageName: "German",
  },
  {
    id: "4",
    title: "Italian",
    code: "it",
    languageName: "Italian",
  },
  {
    id: "5",
    title: "Spanish",
    code: "es",
    languageName: "Spanish",
  },
];

const CaptionScreen = ({ fromFeature }: any) => {
  const { t: lt } = useTranslation("translation");
  const dispatch: any = useDispatch();
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const photoGeneratedData = useSelector(
    (state: any) => state.photoGenerateStore
  );
  const { photoGeneratedChat, sidebarData, editGeneratedImage } =
    photoGeneratedData;
  const [language, setLanguage] = useState<any>(Languages[0]);
  const [image, setImage] = useState<any>({
    file: "",
  });
  const [captions, setCaptions] = useState([]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setSidebarData({ type: "RESET_DATA" }));
  //     dispatch(resetCreatePhotoData({}));
  //     setImage({ file: "" });
  //   };
  // }, []);

  useEffect(() => {
    if (image?.file) {
      setCaptions([]);
      dispatch(
        setSidebarData({ type: "SET_EDIT_IMAGE", payload: image?.file })
      );
      dispatch(seteditGeneratedImageScreen(null));
      dispatch(resetCreatePhotoData({}));
    }
  }, [image?.file]);
  const handleNext = async () => {
    try {
      setLoading(true);
      setCaptions([]);
      let formData = new FormData();
      if (editGeneratedImage) {
        formData.append(
          "fileName",
          editGeneratedImage.split("?")[0].split("/").pop()
        );
      } else {
        formData.append("file", image.file);
      }
      formData.append("caption_count", count.toString());
      formData.append("language", language.code);
      const response = await photoGenerateService.caption(formData);
      setLoading(false);
      if (response?.data?.predictions) {
        setCaptions(response?.data?.predictions);
      } else {
        throw new Error(lt("caption_error"));
      }
    } catch (e: any) {
      console.log("e ", e.message);
      setLoading(false);
      toast(
        (t) => (
          <Notify iconError>
            <div className="mr-6 ml-3 h6">{lt("caption_error")}</div>
          </Notify>
        ),
        { duration: 3000 }
      );
    }
  };
  const onCopy = async (value: string) => {
    await navigator.clipboard.writeText(value);
    toast(
      (t) => (
        <Notify iconCheck>
          <div className="ml-3 h6 sm:text-sm">{lt("caption_copied")}</div>
        </Notify>
      ),
      { duration: 3000 }
    );
  };

  return (
    <div className=" flex items-center h-[93%] w-full ">
      <div
        className={` w-full p-4 ${
          photoGeneratedChat.loading && "ointer-event-none opacity-60"
        }`}
      >
        <UploadImage
          state={image}
          setState={setImage}
          fileName={image?.file?.name}
          description={lt("upload_image_caption")}
        />

        <div
          className={`${photoGeneratedChat.loading && "cursor-not-allowed"}`}
        >
          <div
            className={`${photoGeneratedChat.loading && "pointer-events-none"}`}
          >
            <div className={`mb-4  ${!fromFeature && "dark:border-n-5"}`}>
              <div className="flex justify-between items-center mb-3">
                <label
                  className={`block text-base font-semibold !font-mont ${
                    fromFeature && "text-black"
                  }`}
                >
                  Count
                </label>
                <span
                  className={`w-14 h-5 !font-mont rounded-full text-sm border border-n-29 ${
                    fromFeature ? "text-black" : "dark:border-n-8"
                  } flex justify-center items-center`}
                >
                  {count}
                </span>
              </div>
              <RangeInput
                min={1}
                max={3}
                fromFeature={fromFeature}
                step={1}
                defaultValue={count}
                onChange={(v: number) => setCount(v)}
              />
            </div>
            <Select
              className="w-full "
              classButton="bg-[#DAF8FF] dark:bg-[#DAF8FF] dark:text-n-6"
              IconComponent={() => <Language />}
              items={Languages}
              value={language}
              fromFeature={fromFeature}
              onChange={setLanguage}
              placeholder={lt("language")}
              arrowColor={"black"}
            />

            {captions?.length > 0 && (
              <>
                <div className=" flex flex-col my-3 border-solid border-2 border-gray-100 px-2 rounded-lg">
                  <label className="block text-base font-semibold !font-mont">
                    Captions
                  </label>
                  {captions.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={` flex justify-between py-1 border-dashed ${
                          index != captions.length - 1 && "border-b-2"
                        } `}
                      >
                        <nav className=" w-[80%]">{item}</nav>
                        <button onClick={() => onCopy(item)}>
                          <Icon
                            className=" h-5 w-5 dark:fill-white"
                            name={"copy"}
                          />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            <button
              className="btn bg-[#8E55EA] w-full mt-5 text-white"
              onClick={handleNext}
              disabled={
                ((!sidebarData.editImage || sidebarData.editImage == "") &&
                  !editGeneratedImage) ||
                loading
              }
            >
              {loading
                ? lt("generating_caption") + "..."
                : lt("generate_caption")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaptionScreen;
