import { twMerge } from "tailwind-merge";
import Icon from "@/components/Icon";
import { size } from "lodash";

type FieldProps = {
  className?: string;
  classInput?: string;
  label?: string;
  textarea?: boolean;
  note?: string;
  type?: string;
  value: string;
  onChange: any;
  placeholder?: string;
  required?: boolean;
  icon?: any;
  disabled?: boolean;
  iconClass?: any;
  name?: string;
  height?: string;
  border?: boolean;
  bugError?: string;
  showCount?: boolean;
  IconComponent?: any;
};

const Field = ({
  className,
  classInput,
  label,
  textarea,
  note,
  type,
  value,
  onChange,
  placeholder,
  required,
  icon,
  disabled,
  iconClass,
  height,
  border = true,
  name,
  IconComponent,
  showCount = true,
}: FieldProps) => {
  const handleKeyDown = (event: any) => {
    const remainingChars = 200 - size(value);
    if (remainingChars <= 0 && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  const remainingChars = 200 - size(value);

  return (
    <div className={`${className}`}>
      <div className="rounded-xl h-full">
        {label && (
          <div className="flex mb-2 base2 font-semibold">
            {label}
            {textarea && showCount && (
              <span className="ml-auto pl-4 text-n-4/50 !font-mont">{remainingChars}</span>
            )}
          </div>
        )}
        <div className="relative rounded-xl h-full">
          {textarea ? (
            <textarea
              className={`w-full ${height ? height : "h-24"} px-3.5 py-3  ${
                border && "bg-n-2 border-2 border-n-2"
              } rounded-xl base2  text-n-7 outline-none transition-colors placeholder:text-n-4/50 focus:bg-transparent resize-none dark:bg-n-6 dark:border-n-6 dark:text-n-3 dark:focus:bg-transparent ${
                (icon || IconComponent) && "pl-[3.125rem]"
              } ${value !== "" && "bg-transparent border-n-3/50"}`}
              value={value}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
              // onPaste={handlePaste}
              required={required}
              name={name ?? ""}
            ></textarea>
          ) : (
            <input
              className={twMerge(
                `w-full ${
                  height ? height : "h-13"
                } px-3.5 bg-n-2 border-2 border-n-2 rounded-xl base2  text-n-7 outline-none transition-colors placeholder:text-n-4/50 focus:bg-transparent dark:bg-n-6 dark:border-n-6 dark:text-n-3 dark:focus:bg-transparent ${
                (icon || IconComponent) && "pl-[3.125rem]"
                } ${
                  value !== "" && "bg-transparent border-n-3/50"
                } ${classInput}`
              )}
              type={type || "text"}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              required={required}
              disabled={disabled}
              name={name ?? ""}
            />
          )}
          {IconComponent ?
            <div className={`absolute top-3.5 left-4`}><IconComponent /></div>
            :
          <Icon
            className={`absolute top-3.5 left-4 fill-n-4/50 pointer-events-none transition-colors ${
              value !== "" && "fill-n-4"
            } ${iconClass}`}
            name={icon}
            />}
        </div>
        {note && <div className="mt-2 base2 text-n-4/50">{note}</div>}
        {/* <div className="mt-2 caption2 text-accent-1">
    return (
        <div className={`${className}`}>
            <div className="rounded-xl h-full">
                {label && (
                    <div className="flex mb-2 base2 font-semibold">
                        {label}
                        {textarea && (
                            <span className="ml-auto pl-4 text-n-4/50">
                                {remainingChars}
                            </span>
                        )}
                    </div>
                )}
                <div className="relative rounded-xl h-full">
                    {textarea ? (
                        <textarea
                            className={`w-full ${height ? height : 'h-24'} px-3.5 py-3  ${border && 'bg-n-2 border-2 border-n-2'} rounded-xl base2  text-n-7 outline-none transition-colors placeholder:text-n-4/50 focus:bg-transparent resize-none dark:bg-transparent dark:border-n-6 dark:text-n-3 dark:focus:bg-transparent ${
                                icon && "pl-[3.125rem]"
                            } ${
                                value !== "" && "bg-transparent border-n-3/50"
                            }`}
                            value={value}
                            onChange={onChange}
                            onKeyDown={handleKeyDown}
                            placeholder={placeholder}
                            required={required}
                        ></textarea>
                    ) : (
                        <input
                            className={twMerge(
                                `w-full h-13 px-3.5 bg-n-2 border-2 border-n-2 rounded-xl base2  text-n-7 outline-none transition-colors placeholder:text-n-4/50 focus:bg-transparent dark:bg-n-6 dark:border-n-6 dark:text-n-3 dark:focus:bg-transparent ${
                                    icon && "pl-[3.125rem]"
                                } ${
                                    value !== "" &&
                                    "bg-transparent border-n-3/50"
                                } ${classInput}`
                            )}
                            type={type || "text"}
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                            required={required}
                            disabled={disabled}
                            name={name ?? ""}
                        />
                    )}
                    <Icon
                        className={`absolute top-3.5 left-4 fill-n-4/50 pointer-events-none transition-colors ${
                            value !== "" && "fill-n-4"
                        } ${iconClass}`}
                        name={icon}
                
                    />
                </div>
                {note && <div className="mt-2 base2 text-n-4/50">{note}</div>}
                {/* <div className="mt-2 caption2 text-accent-1">
                Email is incorrect
            </div> */}
      </div>
    </div>
  );
};

export default Field;
