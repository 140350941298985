export const findMatchingPlanTitle = (subscriptionId: string, planCategories: any) => {
  console.log(subscriptionId,planCategories, " ===> sub and categories");
  
  try {
    for (const category in planCategories) {
      console.log(category , " ===> Category");
      //@ts-ignore
      const matchingPlan = planCategories[category].find((plan: any) => plan._id === subscriptionId);
      if (matchingPlan) return matchingPlan;
    }
    return null;
  } catch (error) {
    return null;
  }
};