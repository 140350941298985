import { createAsyncThunk } from "@reduxjs/toolkit";
import { profileService } from "services/profile.service";

export const updateProfile = createAsyncThunk(
    "authStore/updateProfile",
    async (data?: any) => {
      try {
        const response = await profileService.update(data);
        return response.data;
      } catch (error: any) {
        const { response } = error;
        throw response.data.message || response.statusText;
      }
    }
  );

  export const updatePassword = createAsyncThunk(
    "authStore/updatePassword",
    async (data?: any) => {
      try {
        const response = await profileService.updatePassword(data);
        return response.data;
      } catch (error: any) {
  
        const { response } = error;
        throw response.data.message || response.statusText;
      }
    }
  );