import React, { useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import Modal from "@/components/Modal";
import Field from "@/components/Field";
import MultiSelect from "@/components/MultiSelect";
import Notify from "@/components/Notify";

import { people } from "@/mocks/people";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import config from "@/config/index";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

type ModalShareChatProps = {
  visible: boolean;
  onClose: () => void;
};

const ModalShareChat = ({ visible, onClose }: ModalShareChatProps) => {
  const router = useRouter();
  const { t: lt } = useTranslation( "translation");
  const chatData = useSelector((state: any) => state.chatGptStore);
  const { createChat } = chatData;
  let sharedLink = "";
  if (router.pathname === ROUTES.DEBATE_ID) {
    sharedLink = `${config.frontnedUrl}${ROUTES.DEBATE}/${router.query.id}`;
  } else {
    if (isEmpty(createChat.data?.id)) {
      sharedLink = `${config.frontnedUrl}${ROUTES.CHAT}/${router.query.id}`;
    } else {
      sharedLink = `${config.frontnedUrl}${ROUTES.CHAT}/`;
    }
  }
  const [link, setLink] = useState<string>(
    "https://lokichat.net/architecture/What-are-the-primary"
  );
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [copied, setCopied] = useState<boolean>(false);

  const onCopy = () => {
    setCopied(true);
    toast((e) => (
      <Notify iconCheck>
        <div className="ml-3 h6 sm:text-sm">
          {lt("link_copied")},{" "}
        </div>
      </Notify>
    ),{duration: 3000});
  };

  let copyButtonRef = useRef(null);

  return (
    <Modal
      classWrap="max-w-[40rem]"
      classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:top-5 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
      visible={visible}
      onClose={onClose}
      initialFocus={copyButtonRef}
    >
      <form
        className="p-12 md:p-5"
        action=""
        onSubmit={() => console.log("Submit")}
      >
        <div className="mb-8 h4">{lt("share_chat")}</div>
        <div className="mb-4 base2 font-semibold text-n-6 dark:text-n-3">
          {lt("copy_link")}
        </div>
        <div className="relative mb-8">
          <Field
            classInput="h-14 pr-[6.25rem]  truncate text-[1rem] text-n-4 border-n1 md:base2"
            placeholder={lt("link")}
            value={sharedLink}
            onChange={(e: any) => setLink(e.target.value)}
            required
          />
          <CopyToClipboard text={sharedLink} onCopy={onCopy}>
            <button
              className="btn-dark absolute top-1 right-1"
              ref={copyButtonRef}
              type="button"
            >
              {lt("copy")}
            </button>
          </CopyToClipboard>
        </div>
        <div className="mb-4 base2 font-semibold text-n-6 dark:text-n-3">
          {lt("orShare_member")}
        </div>
        <MultiSelect
          className="mb-8"
          items={people}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
        <div className="flex justify-end">
          <button className="btn-stroke-light mr-3" onClick={onClose}>
            {lt("cancel_btn")}
          </button>
          <button className="btn-blue">
            {lt("share")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalShareChat;
