import { createSlice } from "@reduxjs/toolkit";

export const initialState: any = {
    fileText: "",
    gender: null,
    speed: null,
    voice: null,
    language: null,
    isAudioGenerated: false,
};

export const TextToSpeechSlice = createSlice({
    name: "TextToSpeech",
    initialState: initialState,
    reducers: {
        resetTextToSpeechData() {
            return initialState;
        },
        setFileText(state, action) {
            state.fileText = action.payload;
            state.isAudioGenerated = false;
        },
        setIsAudioGenerated(state, action) {
            state.isAudioGenerated = action.payload;
        },
        setAudioDetails(state, action) {
            const payload = action.payload;
            state.gender = payload.gender;
            state.speed = payload.speed;
            state.voice = payload.voice;
            state.language = payload.language;
        }
    },

});

export const { resetTextToSpeechData, setFileText, setAudioDetails, setIsAudioGenerated } = TextToSpeechSlice.actions;

export default TextToSpeechSlice.reducer;