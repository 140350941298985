import React, { useState, useEffect } from "react";


const EditorCKEditor = ({ value, onChange, editorRef }: any) => {
  const [editorLoaded, setEditorLoaded] = useState(false)
  const { CKEditor, ClassicEditor } = editorRef.current || {}

  const editorConfiguration = {
    toolbar: ["bold", "italic"],
    initialData: value ?? "",
  };

  useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
    }
    setEditorLoaded(true)
  }, []);

  return (
    editorLoaded ? <div style={{ backgroundColor: 'white', color: 'black' }}>
      <CKEditor
        editor={ClassicEditor}
        config={editorConfiguration}
        editorRef={editorRef}
        onReady={(editor: any) => {
          if (editor) {
            // Store the editor reference
            console.log('Editor is ready to use!', editor);
          }
        }}
        onChange={(event: any, editor: any) => {
          if (editor) {
            const data = editor.getData();
            onChange(data);
          }
        }}
      />
    </div> : <p>Loading editor</p>

  );
};

export default EditorCKEditor;
