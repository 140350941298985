type SettingsType = {
    id: string;
    title: string;
    icon: string;
};
export const translate=(lt:(string: string)=>{})=> {
    const settings : SettingsType[] = [
        {
            id: "edit-profile",
            title: lt('edit_profile').toString(),
            icon: "profile",
        },
        {
            id: "password",
            title: lt('password').toString(),
            icon: "lock-1",
        },
        {
            id: "notifications",
            title: lt('notifications').toString(),
            icon: "bell",
        },
        {
            id: "chat-export",
            title: lt('chat_export').toString(),
            icon: "download-fill",
        },
        {
            id: "billing-details",
            title: lt('billing_details').toString(),
            icon: "download-fill",
        },
        {
            id: "sessions",
            title: lt('sessions').toString(),
            icon: "log-in",
        },
        {
            id: "appearance",
            title: lt('appearance').toString(),
            icon: "sun",
        },
        {
            id: "delete-account",
            title: lt('delete_account').toString(),
            icon: "close-fat",
        },
    ];
    return settings;
}
