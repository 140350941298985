import React, { useState, useEffect } from 'react'
import { UploadIcons } from '../LandingPage/Icons'
import { IDocumentTranslatorState } from 'interfaces/dt.interface';
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from 'react-i18next';

interface IProps {
    state: IDocumentTranslatorState;
    setState: Function;
    description?: string;
    fileName?: string;
}
const UploadImage = ({ state, setState, description, fileName }: IProps) => {
    const { t } = useTranslation('translation');

    const fileTypes = ["image/jpeg", "image/png", "image/gif", "image/bmp", "image/webp", 'png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG']
    const [file, setFile] = useState<any>(null);
    useEffect(() => {
        setState({ ...state, file: file })
    }, [file])

    const handleChange = (file: any) => {
        setFile(file);
    };

    return (

        <div className=' bg-primary-1 p-2 rounded-[20px] flex justify-center items-center mb-5 mt-10 h-[185px] w-full'>
            <label className='relative cursor-pointer border-white border-dashed border w-full rounded-[20px] flex flex-col justify-center items-center h-[95%]'>
                <FileUploader handleChange={handleChange} multiple={false} name="file" classes="drag_drop" types={fileTypes}>
                    <div className='flex justify-center items-center flex-col w-full focus:!border-transparent focus-within:!outline-none cursor-pointer'>
                        <span className='mb-3 block'><UploadIcons width={60} height={60} /></span>
                        <h2 className='h6 text-white !font-mont mb-1'>{t('upload_img')}</h2>
                        <p className='text-white m-auto text-center w-full text-sm'>{fileName ? fileName : description}</p>
                    </div>
                </FileUploader>
            </label>

        </div>

    )
}

export default UploadImage