import { AUDIO_PLAYER } from '@/constants/audio';
import { formatDateForAudioLibrary, secondsToHMS } from 'lib/DateTime';
import React, { useState, useRef, useEffect } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { setAudioToPlay, setAudioItem, setIsPlaying, setPlayingAudio, setPlayingDuration, setSidebarPlayingAudio, setVolume } from 'store/slices/AudioSlice';
import AudioProgressBar from '../AudioPlayer/AudioProgressBar';
import { RxCross2 } from "react-icons/rx";
import WaveSurfer from 'wavesurfer.js';
import { useRouter } from 'next/router';
import { ROUTES } from '@/constants/routes';

const CustomSidebarPlayer = ({ audioUrl, isPlaying, audioItem, visible }: any) => {

  const dispatch: any = useDispatch();
  const router = useRouter();
  const { pathname } = router;
  const audioData = useSelector((state: any) => state.audioStore);
  const { playingDuration, volume } = audioData;

  const [totalDuration, setTotalDuration] = useState(0);

  const waveformRef = useRef<any>();
  const wavesurfer = useRef<any>();

  useEffect(() => {
    if (audioUrl.includes('storage.googleapis.com')) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#D7D7D7",
        progressColor: "#B1D9FF",
        cursorColor: "#9E9E9E",
        cursorWidth: 3,
        barWidth: 2,
        barRadius: 1,
        height: 30,
        normalize: true
      });

      wavesurfer.current.load(audioUrl);
      wavesurfer.current.on('ready', () => {
        if (isPlaying) {
          wavesurfer.current.play();
        }
        if (volume !== 'sidebar') {
          wavesurfer.current.setVolume(0);
        }
        setTotalDuration(wavesurfer.current.getDuration())
      });

      wavesurfer.current.on('audioprocess', () => {
        if (volume === 'sidebar' || !(pathname === ROUTES.AUDIOLIBRARY || pathname === ROUTES.CHAT || pathname === ROUTES.CHAT_ID)) {
          dispatch(setPlayingDuration(wavesurfer?.current?.getCurrentTime()));
        }
      });

      wavesurfer.current.on('finish', () => {
        setTimeout(() => {
          dispatch(setPlayingDuration(0));
          wavesurfer.current.seekTo(0);
          dispatch(setIsPlaying(false));
        }, 500);
      });
    }

    return () => wavesurfer?.current?.destroy();
  }, [audioUrl]);

  useEffect(() => {
    if (wavesurfer.current && totalDuration) {
      wavesurfer.current.seekTo(playingDuration / totalDuration)
    }
  }, [playingDuration])


  useEffect(() => {
    if (isPlaying && wavesurfer.current) {
      wavesurfer.current.play();
    }
    else {
      wavesurfer?.current?.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (wavesurfer.current) {
      if (pathname === ROUTES.AUDIOLIBRARY || pathname === ROUTES.CHAT || pathname === ROUTES.CHAT_ID) {
        wavesurfer.current.setVolume(0);
      } else {
        wavesurfer.current.setVolume(1);
      }
    }
  }, [pathname, wavesurfer.current])

  const handlePlayPause = () => {
    if (wavesurfer.current.isPlaying()) {
      wavesurfer.current.pause()
      dispatch(setIsPlaying(false));
    } else {
      wavesurfer.current.play();
      dispatch(setVolume('sidebar'));
      dispatch(setIsPlaying(audioItem?._id));
    }
  }

  const handleDestroyAudio = () => {
    if (volume === 'sidebar') {
      dispatch(setPlayingAudio(false));
    }
    if (!(pathname === ROUTES.AUDIOLIBRARY || pathname === ROUTES.CHAT || pathname === ROUTES.CHAT_ID)) {
      dispatch(setIsPlaying(false))
    }
    dispatch(setAudioItem(null))
  };

  return (
    <div className=' pb-3'>
      {!visible &&
        <span className='flex !font-mont content-between text-n-2 my-1.5 truncate w-[300px]'>
          <h5 className='text-base truncate pr-2.5'>{audioItem?.editedText ?? formatDateForAudioLibrary(audioItem?.createdAt)}</h5>
        </span>
      }
      <div className='flex gap-2 items-center bg-gray-750/10 p-2 pb-3 pt-5 rounded-lg relative overflow-hidden z-1'>
        <button onClick={handlePlayPause} className='shrink-0 w-7 h-7 text-xs bg-white flex justify-center items-center rounded-full text-primary-1'>
          {(audioData.isPlaying) ? <FaPause /> : <FaPlay />}
        </button>
        <button onClick={handleDestroyAudio} className='absolute top-1 right-2 text-white hover:text-red-500'>
          <RxCross2 />
        </button>

        <div id="waveform" ref={waveformRef} className=" w-full" /> 
      </div>
      {/* {!visible && <div className='flex justify-between mt-2 mb-1'>
        <p className='shrink-0 text-sm text-n-3'>{secondsToHMS(playingDuration)}</p>
        <p className='shrink-0 text-sm text-n-3'>{secondsToHMS(totalDuration)}</p>
      </div>} */}
    </div>
  );
};

export default CustomSidebarPlayer;
