import React, { useState } from 'react';
import { useGoogleLogin } from "@react-oauth/google";
import Image from "@/components/Image";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileByToken } from "store/actions/auth.actions";
import { useRouter } from "next/router";
import { SOCIAL_AUTH } from '@/constants/user';
import { getUserBrowserInfo } from 'lib/user';
import { useTranslation } from 'react-i18next';
import { addEmail } from 'store/slices/AuthSlice';

export default function GoogleAuth() {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation('auth');
  
  const userData = useSelector((state: any) => state.authStore);

  const { user } = userData;
  const { query } = router;
  const type = query.type;
  const referralCode = query.referralCode || "";

  const [isLoading, setIsLoading] = useState(false);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setIsLoading(true);
      try {
        const browserInfo = getUserBrowserInfo();
        const data: any = {
          token: codeResponse.access_token,
          provider: SOCIAL_AUTH.GOOGLE,
          referalInvite: referralCode,
          os: browserInfo?.os,
          browserName: browserInfo?.browserName,
          browserVersion: browserInfo?.browserVersion,
          type: type,
        };

        // Fetch user profile information from Google API
        const userProfileResponse = await fetch('https://www.googleapis.com/oauth2/v1/userinfo', {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
          },
        });

        if (userProfileResponse.ok) {
          const userProfile = await userProfileResponse.json();
          const userEmail = userProfile.email;
          dispatch(addEmail(userEmail))
          dispatch(getUserProfileByToken(data)).unwrap().then(()=>{
            setIsLoading(false);
          }).catch((err: any) => {
            setIsLoading(false);
          });
        } else {
          console.error('Error fetching user profile from Google API');
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error in onSuccess callback:', error);
        setIsLoading(false);
      }
    },
  });

  return (
    <button
      className="btn-stroke-light btn-large w-full mb-3"
      onClick={() => login()}
      disabled={user?.loading || isLoading}
    >
      <Image src="https://lokichatdev.blob.core.windows.net/images/google.svg" width={24} height={24} alt="" />
      <span className="ml-4 !font-mont">{lt('continue_google')}</span>
    </button>
  );
}
