import React, { useState } from "react";
import { formatDateRelative, formatOnlyDate } from "lib/DateTime";
import { getPlainTextFromMarkdown } from "lib/getPlainText";
import MoveChatDropdown from "@/components/Search/MoveChatDropdown";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import {
  resetAllChatsData,
  resetChatAndNotebooks,
  setChatListModal,
  setSelectedChatListItem,
  setSelectedNotebooks,
} from "store/slices/ChatGptSlice";
import { ROUTES } from "@/constants/routes";
import {
  resetAllAudioData,
  resetCreateAudioData,
} from "store/slices/AudioSlice";
import { useTranslation } from "react-i18next";
import { size } from "lodash";
import Icon from "@/components/Icon";
import toast from "react-hot-toast";
import Notify from "@/components/Notify";
import {
  destroyChatList,
  getStoredChatList,
} from "store/actions/chatList.actions";
import { chatListService } from "services/chatList.service";
import Checkbox from "@/components/Checkbox";

type ItemProps = {
  title: any;
  date?: any;
  list: any;
  onClose?: any;
  chatListItem: any;
  deletingChats?: any;
  setDeletingChats?: any;
};

const Item = ({ title, date, list, chatListItem, deletingChats, setDeletingChats }: ItemProps) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation("translation");

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleJump = (listItem: any) => {
    dispatch(setChatListModal(false));
    if (router.query.id != listItem.id) {
      dispatch(setSelectedChatListItem(chatListItem));
      dispatch(resetChatAndNotebooks(null));
      // dispatch(resetAllAudioData(null));
      dispatch(resetCreateAudioData(null));
      dispatch(setSelectedNotebooks([]));
      router.push(`/${ROUTES.CHAT_LIST}/${listItem.id}`);
    }
  };

  const handleSelect = (id: any, e: any) => {
    e.stopPropagation();
    if (deletingChats && deletingChats?.length) {
      if (deletingChats.includes(id)) {
        let temp = [...deletingChats];
        let indexToRemove = temp.indexOf(id);
        temp.splice(indexToRemove, 1);
        setDeletingChats(temp);
      } else {
        setDeletingChats([...deletingChats, id]);
      }
    } else {
      setDeletingChats([id]);
    }
  }
  const chatData = useSelector((state: any) => state.chatGptStore);
  const [selectAll, setSelectAll] = useState(false)

  const handleSelectAll = () => {
    if (deletingChats?.length && deletingChats?.length === list.length) {
      setDeletingChats(null)
    } else {
      const ids = list.map((obj: any) => obj.id);
      setDeletingChats(ids);
    }
  }

  // const handleChatListDelete = () => {
  //   toast(
  //     (t) => (
  //       <Notify className="md:flex-col md:items-center md:px-10" iconDelete>
  //         <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">
  //           {"Are you sure?"}
  //         </div>

  //         <div className="flex justify-center">
  //           <button
  //             className="btn-stroke-light btn-medium md:min-w-[6rem]"
  //             onClick={() => toast.dismiss(t.id)}
  //           >
  //             {"cancel"}
  //           </button>

  //           <button
  //             className="btn-blue btn-medium ml-3 md:min-w-[6rem]"
  //             onClick={(e) => {
  //               e.preventDefault();
  //               console.log("I am here ");
  //               dispatch(destroyChatList(chatListItem.id))
  //                 .then((actionResponse: any) => {
  //                   if (actionResponse.type.endsWith("fulfilled")) {
  //                     console.log(
  //                       "Successfully destroyed chat list item:",
  //                       actionResponse.payload
  //                     );
  //                     dispatch(getStoredChatList());
  //                   } else {
  //                     console.error(
  //                       "Failed to destroy chat list item:",
  //                       actionResponse.error.message
  //                     );
  //                   }
  //                 })
  //                 .catch((error: any) => {
  //                   console.error("Unexpected error:", error);
  //                 });
  //               dispatch(resetAllChatsData(null));
  //               dispatch(setChatListModal(false));
  //               dispatch(getStoredChatList());

  //               router.push(ROUTES.CHAT);
  //               toast.dismiss(t.id);
  //             }}
  //           >
  //             {"yes"}
  //           </button>
  //         </div>
  //       </Notify>
  //     ),
  //     { duration: 5000, position: "top-right" }
  //   );
  // };

  return (
    <div>
      <div className="flex items-center justify-between py-3 md:pt-6">
        <div className="flex items-center">
          <div className="h6">{title}</div>
          {date && (
            <div className="ml-5 caption1 text-n-4/75">
              {formatOnlyDate(date)}
            </div>
          )}
        </div>
        {deletingChats && deletingChats?.length ? <Checkbox
          disabled={false}
          className="mb-4 last:mb-0 mr-2"
          label={'Select All'}
          value={deletingChats?.length && deletingChats?.length === list.length}
          onChange={handleSelectAll}
          reverse
          size={4.5}
          borderColor="[#6F767E]"
        /> : null}
      </div>
      <div className="space-y-3">
        {list &&
          list.map((x: any) => (
            <div
              className={` relative rounded-lg p-5 cursor-pointer transition-colors bg-n-3/50 dark:bg-n-6 `}
              key={x.id}
              onClick={(e: any) => {
                e.stopPropagation();
                if (e?.target?.nodeName === 'DIV') {
                  handleJump(x);
                }
              }}
            >
              <div className=" absolute right-2 top-2">
                <Checkbox
                  disabled={false}
                  // className=""
                  // key={checkbox.id}
                  size={4.5}
                  borderColor="[#6F767E]"
                  value={deletingChats?.includes(x.id)}
                  onChange={(e: any) => handleSelect(x.id, e)}
                // reverse
                />
              </div>
              <div className="w-full">
                <div className="mb-1 truncate base1 font-semibold flex flex-row">
                  <span className="!font-mont w-11/12 overflow-hidden">{x.messages?.[0]?.content ?? "Empty"}</span>
                  </div>
                  <div className="truncate caption1 text-n-4/75 dark:text-neutral-100">
                    {getPlainTextFromMarkdown(
                      x?.messages?.[1]?.content ?? "Empty"
                    )}
                  </div>
                </div>
                <div className="absolute  !font-mont top-1/2 right-5 -translate-y-1/2 caption1 text-n-4/50 group-hover:hidden md:right-0">
                  {list.createdAt}
                </div>
                <div className="flex sm:flex-col justify-between items-center sm:items-start gap-4 mt-3">
                  <div className="flex items-center rounded-lg whitespace-nowrap text-xs text-n-7/75 dark:text-n-3/75">
                    {t("last_edit")}{" "}
                    <span className="mr-2 !font-mont font-semibold text-n-1 dark:text-n-7">
                      {list.id}
                    </span>
                    {formatDateRelative(x.updatedAt)}
                  </div>
                  <div
                    className="flex items-start gap-2"
                    onClick={(e: any) => e.stopPropagation()}
                  >
                    <MoveChatDropdown
                      showDropDown={true}
                      chatId={x.id}
                      chatList={x?.chatList ?? null}
                    />
                    <div
                      onClick={() => handleJump(x)}
                      className="rounded cursor-pointer bg-n-1 caption1 font-semibold text-n-4 md:right-0 dark:bg-n-5 dark:text-n-3 w-[95px] flex justify-center py-0.5"
                    >
                    <span className="truncate !font-mont">{t("jump")}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {showDeleteModal && (
          <Notify
            className="mt-20 md:px-10 absolute right-22 bottom-22"
            iconDelete
          >
            <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">
              {"Are you sure?"}
            </div>

            <div className="flex justify-center">
              <button
                className="btn-stroke-light btn-medium md:min-w-[6rem]"
                onClick={() => setShowDeleteModal(false)}
              >
                {"Cancel"}
              </button>

              <button
                className="btn-blue btn-medium ml-3 md:min-w-[6rem]"
                onClick={(e) => {
                  e.preventDefault();
                  console.log("I am here ");
                  dispatch(destroyChatList(chatListItem.id))
                    .then((actionResponse: any) => {
                      if (actionResponse.type.endsWith("fulfilled")) {
                        console.log(
                          "Successfully destroyed chat list item:",
                          actionResponse.payload
                        );
                        dispatch(getStoredChatList());
                      } else {
                        console.error(
                          "Failed to destroy chat list item:",
                          actionResponse.error.message
                        );
                      }
                    })
                    .catch((error: any) => {
                      console.error("Unexpected error:", error);
                    });
                  dispatch(resetAllChatsData(null));
                  dispatch(setChatListModal(false));
                  dispatch(getStoredChatList());

                  router.push(ROUTES.CHAT);
                  setShowDeleteModal(false);
                  // toast.dismiss(t.id);
                }}
              >
                {"Yes"}
              </button>
            </div>
          </Notify>
        )}
      </div>
    </div>
  );
};

export default Item;
