import Icon from "@/components/Icon";
import { BiErrorCircle } from 'react-icons/bi';

type NotifyProps = {
    className?: string;
    iconCheck?: boolean;
    iconDelete?: boolean;
    iconError?: boolean;
    children: React.ReactNode;
};

const Notify = ({
    className,
    iconCheck,
    iconDelete,
    iconError,
    children,
}: NotifyProps) => (
    <div
        className={`flex gap-2 items-center p-4 rounded-2xl bg-n-7 text-n-1 md:-mb-5 shadow-2xl ${className}`}
    >
        {iconCheck && (
            <div className="flex justify-center items-center shrink-0 w-10 sm:w-7 h-10 sm:h-7 rounded-full bg-primary-2">
                <Icon className="fill-n-7 sm:w-4 sm:h-4" name="check-thin" />
            </div>
        )}
        {iconDelete && (
            <div className="flex justify-center items-center shrink-0 w-10 sm:w-7 h-10 sm:h-7 rounded-full bg-accent-1">
                <Icon className="fill-n-1 sm:w-4 sm:h-4" name="trash" />
            </div>
        )}
         {iconError && (
            <div className="flex justify-center items-center shrink-0 w-10 sm:w-7 h-10 sm:h-7">
                {/* <BiErrorCircle className="text-3xl text-red-500 sm:w-4 sm:h-4"/> */}
                <svg  viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_12887_88909)">
                        <path d="M20 0C25.3043 0 30.3914 2.10714 34.1421 5.85786C37.8929 9.60859 40 14.6957 40 20C40 25.3043 37.8929 30.3914 34.1421 34.1421C30.3914 37.8929 25.3043 40 20 40C14.6957 40 9.60859 37.8929 5.85786 34.1421C2.10714 30.3914 0 25.3043 0 20C0 14.6957 2.10714 9.60859 5.85786 5.85786C9.60859 2.10714 14.6957 0 20 0ZM22.9998 12.2791C24.4854 12.2791 25.691 11.2478 25.691 9.7193C25.691 8.19084 24.4825 7.15949 22.9998 7.15949C21.5142 7.15949 20.3143 8.19084 20.3143 9.7193C20.3143 11.2478 21.5142 12.2791 22.9998 12.2791ZM23.5226 28.3551C23.5226 28.0494 23.6283 27.2552 23.5683 26.8038L21.2199 29.5065C20.7342 30.0179 20.1257 30.3721 19.84 30.2778C19.7104 30.2301 19.6021 30.1377 19.5345 30.0173C19.467 29.8968 19.4446 29.7562 19.4715 29.6207L23.3855 17.2559C23.7055 15.6874 22.8255 14.2561 20.9599 14.0733C18.9915 14.0733 16.0946 16.0703 14.3318 18.6044C14.3318 18.9072 14.2747 19.6615 14.3347 20.1128L16.6802 17.4073C17.1659 16.9016 17.7316 16.5445 18.0173 16.6417C18.158 16.6922 18.2734 16.7959 18.3385 16.9305C18.4037 17.0651 18.4135 17.2199 18.3658 17.3616L14.4861 29.6664C14.0376 31.1063 14.8861 32.5177 16.9431 32.8377C19.9714 32.8377 21.7599 30.8892 23.5255 28.3551H23.5226Z" fill="#0084FF"/>
                        <path d="M22.9767 12.2653C24.4602 12.2653 25.6641 11.2345 25.6641 9.70685C25.6641 8.17923 24.4573 7.14844 22.9767 7.14844C21.4933 7.14844 20.2951 8.17923 20.2951 9.70685C20.2951 11.2345 21.4933 12.2653 22.9767 12.2653ZM23.4988 28.3324C23.4988 28.0269 23.6044 27.2331 23.5444 26.782L21.1995 29.4831C20.7145 29.9942 20.1068 30.3483 19.8216 30.2541C19.6921 30.2064 19.584 30.1141 19.5165 29.9937C19.4491 29.8733 19.4267 29.7327 19.4536 29.5974L23.3619 17.2393C23.6814 15.6717 22.8027 14.2412 20.9399 14.0584C18.9743 14.0584 16.0816 16.0543 14.3214 18.587C14.3214 18.8897 14.2644 19.6435 14.3243 20.0947L16.6664 17.3906C17.1514 16.8852 17.7162 16.5283 18.0015 16.6254C18.142 16.6759 18.2572 16.7795 18.3223 16.9141C18.3874 17.0486 18.3971 17.2033 18.3495 17.345L14.4755 29.643C14.0276 31.0821 14.8748 32.4927 16.9288 32.8125C19.9528 32.8125 21.7386 30.8651 23.5017 28.3324H23.4988Z" fill="#F3F5F7"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_12887_88909">
                        <rect width="40" height="40" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
             </div>
        )}
        {children}
    </div>
);

export default Notify;
