import { createAsyncThunk } from "@reduxjs/toolkit";
import { deviceService } from "services/device.service";
import { ICreateDevice } from "interfaces/device.interface";

export const storeDevice = createAsyncThunk(
  "deviceStore/storeDevice",
  async (data: ICreateDevice) => {
    try {
      const response = await deviceService.store(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getAllDevices = createAsyncThunk(
  "deviceStore/getAllDevices",
  async () => {
    try {
      const response = await deviceService.index();
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const deleteDeviceById = createAsyncThunk(
  "deviceStore/deleteDeviceById",
  async (deviceId: string) => {
    try {
      const response = await deviceService.destroy(deviceId);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const deleteFromAllDevices = createAsyncThunk(
  "deviceStore/deleteFromAllDevices",
  async () => {
    try {
      const response = await deviceService.destroyAll();
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
