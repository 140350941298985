import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Select from "@/components/Select";
import { speakerCountOptions } from "@/constants/audio";
import { SpeakerIcon } from '../SVGs'
import Field from "../Field";
import UploadDocument from "../UploadTranscriptionDocument";
import { IDocumentTranscriptionState } from "interfaces/dt.interface";
import toast from "react-hot-toast";
import axios from "axios";
import Notify from "../Notify";
import { setInProgressTranscription, setTextTranscriptionId, setUploadingTranscription,setAudioTranscriptionMinute, setAudioTranscriptionCounter } from "store/slices/TranscriptionSlice";

import { FFmpeg } from '@ffmpeg/ffmpeg';
import Config from "../../config/index";

type TranscriptionProps = {
    fromGuest?: boolean;
};
const Transcription = ({fromGuest}:TranscriptionProps) => {
    const dispatch: any = useDispatch();
    const { t } = useTranslation('translation');
    const [count, setCount] = useState<any>(null);
    const [inputValues, setInputValues] = useState<any>({});
    const [transcriptionProgress, setTranscriptionProgress] = useState<boolean>(false);
    const [payload, setpayload] = useState<IDocumentTranscriptionState>({
        file: ""
    });
    const [response, setresponse] = useState<any>({});
    const [disabled, setDisabled] = useState(false);

    const { user } = useSelector((state: any) => state.authStore);
    const { inProgressTranscriptions, textTranscriptionId,audioTranscriptionMinute } = useSelector((state: any) => state.transcriptionStore);

    useEffect(() => {
        if (inProgressTranscriptions?.inProgressCount > 0) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [inProgressTranscriptions])


    useEffect(() => {
        const newInputValues = { ...inputValues };
        Object.keys(newInputValues).forEach(key => {
            if (key >= count.value) {
                delete newInputValues[key];
            }
        });
        setInputValues(newInputValues);
    }, [count?.value]);

    const handleChange = (index: number, event: any) => {
        const newInputValues = { ...inputValues, [index]: event.target.value };
        setInputValues(newInputValues);
    };

    const renderInputs = () => {
        const inputs = [];
        for (let i = 0; i < count.value; i++) {
            inputs.push(
                <Field
                    className={` mr-3 md:mr-0 md:mb-3 my-3 text-black `}
                    classInput={`dark:border-[#E8ECEF] !bg-transparent ${fromGuest && '!text-black'}`}
                    placeholder={`${t('speaker_placeholder')} ${i + 1}`}
                    value={inputValues[i] || ''}
                    onChange={(event: any) => handleChange(i, event)}
                />
            );
        }
        return inputs;
    };

    const ffmpeg = new FFmpeg();

    const load = async () => {
        if (!ffmpeg.loaded) {
            await ffmpeg.load();
        }
    };

    const getAudioDuration = (audio: HTMLAudioElement): Promise<number> => {
        return new Promise((resolve, reject) => {
            audio.onloadedmetadata = () => {
                resolve(audio.duration);
            };
            audio.onerror = () => {
                reject(new Error("Failed to load audio metadata."));
            };
        });
    };

    async function compressAudio(file: any) {
        const time = new Date().getTime();
        let compressedFile;
        if (file.size <= (10 * 1024 * 1024)) {

            const audio = new Audio();
            audio.src = URL.createObjectURL(file);
            const duration = await getAudioDuration(audio);
            dispatch(setAudioTranscriptionMinute(duration));
            compressedFile = file;
        } else {
            const audio = new Audio();
            audio.src = URL.createObjectURL(file);
            const duration = await getAudioDuration(audio);
            dispatch(setAudioTranscriptionMinute(duration));
            await load();
            const targetSizeInKB = 18 * 1024;  // 18 MB target size
            const fileDurationInSeconds = duration; // Make sure this duration is correct.
            const targetBitrate = Math.floor((targetSizeInKB * 8) / fileDurationInSeconds); // Calculate target bitrate in kbps, rounded down

            const b = await file.arrayBuffer();
            const uint8Array = new Uint8Array(b);
            await ffmpeg.writeFile('input.mp3', uint8Array);

            // Compress the file using a constant bitrate that should approximate the desired file size
            await ffmpeg.exec([
                '-i', 'input.mp3',
                '-ar', '22050', // Set audio sample rate to 22050 Hz if it's higher
                '-ac', '1',
                '-codec:a', 'libmp3lame',
                '-b:a', `${targetBitrate}k`,  // Use a constant bitrate
                'output.mp3'
            ]);

            // Read the resulting file
            const data = await ffmpeg.readFile('output.mp3');
            const audioBlob = new Blob([data], { type: 'audio/mp3' });
            compressedFile = new File([audioBlob], file.name, {
                type: file.type,
            });

            // Check the size of the resulting file
            console.log(`Compressed size: ${compressedFile.size / 1024 / 1024} MB`, 'time taken ', (new Date().getTime() - time) / 1000);

        }

        return compressedFile;
    }

    const showToast = (msg: any, err?: boolean) => {
        const icon = err == true ? 'iconError' : 'iconCheck'
        toast((t) => (
            //@ts-ignore
            <Notify className="md:flex-col md:items-center md:px-10" iconError>
                <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">
                    {msg}
                </div>
            </Notify>
        ), { duration: 5000 });
    }

    const initTranslation = async (val: string) => {
        if (!payload.file) {
            toast.error("File is required.");
            return;
        }
        if (payload.file?.size > 600 * 1024 * 1024) {
            showToast('File size should be less than 500mb')
            return;
        }
        if (payload.file?.size < 1024) {
            showToast('File size should be greater than 1kb')
            return;
        }
        if (val !== 'transcribe' && !count) {
            showToast('Please select number of speakers')
            return;
        }
        try {
            dispatch(setUploadingTranscription(true));
            setTranscriptionProgress(true);
            const formData = new FormData();
            formData.append('userId', user?.data?.id);
            formData.append('type', val === 'transcribe' ? "transcription_text" : 'text_response_diarization')
            let compressedFile = await compressAudio(payload.file);
            formData.append('file', compressedFile);

            if (val !== 'transcribe') {
                const resultArray = Array.from({ length: count.value }, (v, index) => {
                    const value = inputValues[index];
                    return (value !== undefined && value !== "") ? `${value}` : `Speaker ${index + 1}`;
                });
                formData.append('speaker_names', JSON.stringify(resultArray));
            }

            const res = await axios.post(
                `${Config.baseApiUrl}/transcriptions/generate`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    timeout: 3000000,
                },
            );
            if (res.status == 200) {
                dispatch(setInProgressTranscription(1))
                setTranscriptionProgress(false);
                setresponse(res.data);
                dispatch(setTextTranscriptionId(res.data._id));
                dispatch(setAudioTranscriptionCounter(0));
            } else {
                toast.error("Failed to transcribe.");
                showToast("Failed to transcribe.")
            }
            dispatch(setUploadingTranscription(false));
            setTranscriptionProgress(false);
        } catch (error: any) {
            dispatch(setUploadingTranscription(false));
            setTranscriptionProgress(false);

            const errorMessages = [];
            if (error?.response?.data?.errors && Object.keys(error?.response?.data?.errors)?.length > 0) {
                for (const key in error?.response?.data?.errors) {
                    if (error?.response?.data?.errors.hasOwnProperty(key)) {
                        errorMessages.push(`${error?.response?.data?.errors[key].join(', ')}`);
                    }
                }
            }
            const errorMessagesString = errorMessages.join(', ');
            console.log({ errorMessagesString });
            if (errorMessagesString && errorMessagesString.length > 0) {
                //@ts-ignore
                toast.error(errorMessagesString ?? error?.response?.data?.message ?? error?.data?.message ?? error?.message ?? "Failed to transcribe due to Unknown reason.");
                showToast(errorMessagesString ?? error?.response?.data?.message ?? error?.data?.message ?? error?.message ?? "Failed to transcribe due to Unknown reason.");
            }
            else {
                //@ts-ignore
                toast.error(error?.response?.data?.message ?? error?.data?.message ?? error?.message ?? "Failed to transcribe due to Unknown reason.");
                showToast(error?.response?.data?.message ?? error?.data?.message ?? error?.message ?? "Failed to transcribe due to Unknown reason.");
            }
        }
    };

    return (
        <>
            <div className={`flex flex-col  items-center sm:flex-col sm:gap-3 sm:mb-2 px-9 md:px-6 h-[93%] -mt-[5%] ${disabled && " opacity-40"}`}>
                <div className="w-full">
                    <UploadDocument transcriptionProgress={transcriptionProgress} nextClick={initTranslation} state={payload} setState={setpayload} small disabled={disabled} />
                </div>
                <div className=" w-full mt-5">
                    <Select
                        className="w-full "
                        classButton="bg-[#DAF8FF] dark:bg-[#DAF8FF] dark:text-n-6"
                        IconComponent={() => <SpeakerIcon />}
                        items={speakerCountOptions(t)}
                        value={count}
                        onChange={setCount}
                        placeholder={t('no_of_speaker')}
                        arrowColor={'black'}
                        fromFeature
                    />
                    {count &&
                        <>
                            <div className="my-5">
                                <div className=" h6 ">{t("get_speaker_names")}</div>
                            </div>
                            {renderInputs()}
                        </>
                    }
                </div>
            </div>
        </>
    );
};

export default Transcription;
