import { createAsyncThunk } from "@reduxjs/toolkit";
import { transcriptionService } from "services/transcription.service";

export const generateTranscription = createAsyncThunk(
  "transcriptionStore/store",
  async (data: any, { signal }) => {
    try {
      const response = await transcriptionService.store(data, { signal });

      console.log({response})

      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateTranscriptionById = createAsyncThunk(
  "transcriptionstore/updateTranscriptionById",
  async (data: any) => {
    try {
      const response = await transcriptionService.updateTranscriptionById(data);
      return response.data; // Assuming your API returns the user data
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const destroyTranscription = createAsyncThunk(
  "transcriptionStore/destroyTranscription",
  async (id: any) => {
    try {
      const response = await transcriptionService.destroy(id);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
)

export const getAllTranscriptionLibrary = createAsyncThunk(
  "transcriptionStore/getAllTranscriptionLibrary",
  async (params:{date?: string, page?:number}) => {
    try {
      const response =  await transcriptionService.index(params);
      return response?.data?.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getInprogressTranscriptions = createAsyncThunk(
  "transcriptionStore/getInprogressTranscriptions",
  async () => {
    try {
      const response =  await transcriptionService.getInprogressTranscriptions({});
      return response?.data?.data?.inProgressCount || 0;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);



export const getAllTranscriptionLibraryPrevMonth = createAsyncThunk(
  "transcriptionStore/getAllTranscriptionLibraryPrevMonth",
  async (params:{date?: string, page?:number}) => {
    try {
      
      const response =  await transcriptionService.indexPrevMonth(params);
      
      return response?.data?.data;
    } catch (error: any) {
      console.log(error,"error");
      
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
export const updateTranscriptionLibrary = createAsyncThunk(
  "transcriptionStore/updateTranscriptionLibrary",
  async (data?: any) => {
    try {
      
      const response = await transcriptionService.update(data);
      
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
export const updateTranscriptionLibraryPrev = createAsyncThunk(
  "transcriptionStore/updateTranscriptionLibraryPrev",
  async (data?: any) => {
    try {
      
      const response = await transcriptionService.update(data);
      
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
export const deleteTranscriptionLibrary = createAsyncThunk(
  "transcriptionStore/deleteTranscriptionLibrary",
  async (id: any) => {
    try {
      const response = await transcriptionService.destroy(id);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
)

export const deleteTranscriptionLibraryPrev= createAsyncThunk(
  "transcriptionStore/deleteTranscriptionLibraryPrev",
  async (id: any) => {
    try {
      const response = await transcriptionService.destroy(id);
      return response.data;
    } catch (error: any) {
      console.log("errrrr", error)
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
)
export const getUserDetails = createAsyncThunk(
  "transcriptionStore/getUserDetails",
  async () => {
    try {
      const response = await transcriptionService.getUserDetails();
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
