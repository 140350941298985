export const members = [
    {
        id: "0",
        name: "Tyrique",
        login: "username",
        avatar: "https://lokichatdev.blob.core.windows.net/images/avatar-1.jpg",
        admin: true,
    },
    {
        id: "1",
        name: "Josianne",
        login: "username",
        avatar: "https://lokichatdev.blob.core.windows.net/images/avatar-2.jpg",
        admin: false,
    },
    {
        id: "2",
        name: "Ellis",
        login: "username",
        avatar: "https://lokichatdev.blob.core.windows.net/images/avatar-3.jpg",
        admin: false,
    },
    {
        id: "3",
        name: "Alvah",
        login: "username",
        avatar: "https://lokichatdev.blob.core.windows.net/images/avatar-4.jpg",
        admin: false,
    },
    {
        id: "4",
        name: "Chaim",
        login: "username",
        avatar: "https://lokichatdev.blob.core.windows.net/images/avatar-5.jpg",
        admin: false,
    },
    {
        id: "5",
        name: "Kellen",
        login: "username",
        avatar: "https://lokichatdev.blob.core.windows.net/images/avatar-6.jpg",
        admin: false,
    },
];
