import { useState } from "react";
import Icon from "@/components/Icon";
import Image from "next/image";
import { useDispatch } from "react-redux";
import { resetAllUserData } from "store/slices/AuthSlice";
import { useTranslation } from "react-i18next";

type SuccessProps = {
  setForgot?: any;
  setForgetSelectedIndex?: any;
  setSelectedIndex?: any;
  dark?: any;
};

const Success = ({
  setForgot,
  setForgetSelectedIndex,
  setSelectedIndex,
  dark,
}: SuccessProps) => {
  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation("auth");

  const [password, setPassword] = useState<string>("");

  return (
    <div>
      <p className=" mb-3 h4 text-center" id="headlessui-portal-root">
        {lt("successfully")}!
      </p>
      <p className="text-center text-lg max-w-[370px] m-auto mb-8">
        {lt("password_changed")}
      </p>
      <Image
        className="object-cover rounded-full m-auto mb-12"
        src={
          dark
            ? "https://lokichatdev.blob.core.windows.net/images/congrates.gif"
            : "https://lokichatdev.blob.core.windows.net/images/congrates.gif"
        }
        height={140}
        width={140}
        alt={lt("avatar")}
      />
      <button
        className="btn-blue btn-large w-full mb-6"
        type="submit"
        onClick={() => {
          setSelectedIndex(1);
          setForgetSelectedIndex(0);
          setForgot(false);
          dispatch(resetAllUserData(null));
        }}
      >
        {lt("continue_logIn")}
      </button>
      <div
        className="group absolute cursor-pointer top-4 left-8 flex justify-center items-center w-10 h-10 rounded-full text-0 transition-colors"
        onClick={() => {
          setForgetSelectedIndex(2);
        }}
      >
        <Icon
          className="mr-4 transition-transform group-hover:-translate-x-1 dark:fill-n-1"
          name="arrow-prev"
        />
      </div>
    </div>
  );
};

export default Success;
