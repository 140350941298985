import { HttpService } from "./base.service";
import { IExportChatData, IStoreChatListData } from "../interfaces/chat.interface";

class ChatListService extends HttpService {
  private readonly prefix: string = "chat-list";

  index = (): Promise<any> => this.get(this.prefix + '/index');

  store = (data: IStoreChatListData): Promise<any> => this.post(this.prefix + "/store", data);
  update = (data: any): Promise<any> => this.put(this.prefix + `/update/${data.id}`, data);
  show = (params: any): Promise<any> => this.get(this.prefix + `/showByPagination?chatListId=${params.chatListId}&page=${params.page}&perPage=${params.perPage}&search=${params.search}&filter=${params.filter}`);
  export = (data: IExportChatData): Promise<any> => this.post(this.prefix + '/export', data);
  destroy = (id: any): Promise<any> => this.delete(this.prefix + `/destroy/${id}`, null);
  deleteMultiple = (ids: any): Promise<any> => this.put(this.prefix + `/delete`, { chatIds: ids });
}

export const chatListService = new ChatListService();
