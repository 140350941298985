import { ROUTES } from "@/constants/routes";
import { USER_LOCAL } from "@/constants/user";
import { encryptAndSave, loadDecryptedValue } from "lib/encryption";
import { clearLogoutStorage, getUserBrowserInfo, getUserIP } from "lib/user";
import config from "../config";
import {
  setStream,
  setStreamText,
  pushStreamText,
  setUpdateStreamText,
  setUpdateStreamLoading,
  setUpdateStreamTextPush,
  setStreamError,
  setUpdateStreamError,
  pushNewStreamMsg,
  resetUpdateStreamTextPush,
  removePushedMsgObject,
} from "store/slices/ChatGptSlice";
import { IRefreshToken, ISession } from "interfaces/auth.interface";
import axios from "axios";
import Config from "../config/index";
import { MESSAGE_SENDER } from "@/constants/chats";

export const fetchStream = (data: any, signal: any) => async (dispatch: any) => {
  try {
    dispatch(setStreamText(""));
    dispatch(setStream(true));
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Transfer-Encoding", "chunked");
    headers.append('Accept', 'application/json, text/plain, */*');
console.log('Im called-fetch');
    const sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
    if (!sessions) {
      clearLogoutStorage();
      localStorage.clear();
      window.location.href = ROUTES.SIGNIN;
    }

    headers.append('Authorization', `Bearer ${sessions.token}`);
    const streamUrl = `${config.baseApiUrl}/chats/store-stream`;
    const messageId = localStorage.getItem("messageId");
    const msgDataObject = {
      messageId: "",
      sender: MESSAGE_SENDER.ASSISTANT,
      content: "",
      addedInNotebook: false,
      responseTo: messageId,
      _id: "",
      createdAt: new Date(),
      updatedAt: new Date()
    };
    dispatch(pushNewStreamMsg(msgDataObject));
    let response: any = await fetch(streamUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
      signal: signal
    });
    if (!response.ok) {
      console.error(
        `Request failed with status: ${response.status} - ${response.statusText}`
      );
      if (response.status === 405) {
        await handleTokenExpiry();
        headers.delete('Authorization');
        const updatedSessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
        headers.append('Authorization', `Bearer ${updatedSessions.token}`);

        response = await fetch(streamUrl, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
          signal: signal
        });
      } else {
        const errorData = await response.json();
        console.log('errorData', errorData);
        const data = { messageId };
        dispatch(removePushedMsgObject(data));
        dispatch(setStream(false));
        dispatch(setStreamError(errorData.message ?? "Stream service error"));
        return;
      }
    }
    const reader = await response.body.getReader();

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        dispatch(setStream(false));
        dispatch(setStreamText(""));
        break;
      }
      try {
        let chunks: any = new TextDecoder("utf-8").decode(value).toString();
        console.log('chunks', chunks);
        dispatch(pushStreamText(chunks ?? ""));
      } catch (error) {
        console.log("Error json parse", error);
      }
    }
  } catch (err: any) {
    console.log("fetch error", err);
    if (err.name === "AbortError") {
      console.log("Request aborted");
      dispatch(setStream(false));
      dispatch(setStreamText(''));
      return;
    } else {
      dispatch(removePushedMsgObject(data));
    }
    dispatch(setStream(false));
  }
};

export const updateStream = (data: any, signal: any) => async (dispatch: any) => {
  try {
    dispatch(setUpdateStreamText(""));
    dispatch(setUpdateStreamLoading(true));
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Transfer-Encoding", "chunked");
    headers.append('Accept', 'application/json, text/plain, */*');

    const sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
    if (!sessions) {
      clearLogoutStorage();
      localStorage.clear();
      window.location.href = ROUTES.SIGNIN;
    }

    const updateStreamUrl = `${config.baseApiUrl}/chats/update-stream`;
    headers.append("Authorization", `Bearer ${sessions.token}`);
    dispatch(resetUpdateStreamTextPush(''));
    let response: any = await fetch(updateStreamUrl,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
        signal: signal
      }
    );
    if (!response.ok) {
      console.error(
        `Request failed with status: ${response.status} - ${response.statusText}`
      );
      if (response.status === 405) {
        await handleTokenExpiry();
        headers.delete("Authorization");
        const updatedSessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
        headers.append("Authorization", `Bearer ${updatedSessions.token}`);

        response = await fetch(updateStreamUrl, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
          signal: signal
        });
      } else {
        const errorData = await response.json();
        console.log('errorData', errorData);
        const previousMsgContent = JSON.parse(localStorage.getItem("previousMsgContent") ?? "");
        dispatch(resetUpdateStreamTextPush(previousMsgContent));
        dispatch(setUpdateStreamLoading(false));
        dispatch(setUpdateStreamError(errorData.message ?? "Stream service error"));
        return;
      }
    }
    const reader = await response.body.getReader();

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        dispatch(setUpdateStreamLoading(false));
        dispatch(setUpdateStreamText(""));
        break;
      }
      try {
        let chunks: any = new TextDecoder("utf-8").decode(value).toString();
        dispatch(setUpdateStreamTextPush(chunks ?? ""));
      } catch (error) {
        console.log("Error json parse", error);
      }
    }
  } catch (err: any) {
    console.log("fetch error", err);
    if (err.name === "AbortError") {
      console.log("Request aborted");
      dispatch(setUpdateStreamLoading(false));
      dispatch(setUpdateStreamText(''));
      return;
    } else {
      const previousMsgContent = JSON.parse(localStorage.getItem("previousMsgContent") ?? "");
      dispatch(resetUpdateStreamTextPush(previousMsgContent));
    }
    dispatch(setUpdateStreamLoading(false));
  }
};

const handleTokenExpiry = async () => {
  return new Promise(async function (resolve, reject) {
    const sessions: any = loadDecryptedValue(USER_LOCAL.LOCAL);
    const browserInfo = getUserBrowserInfo();
    const ipAddress = await getUserIP();

    const refreshTokenPayload: IRefreshToken | any = {
      refreshToken: sessions.refreshToken,
      userId: sessions.userId,
      ipAddress: ipAddress,
      os: browserInfo.os,
      browserName: browserInfo.browserName,
      browserVersion: browserInfo.browserVersion,
    };
    axios
      .post(Config.baseApiUrl + "/auth/refresh-token", refreshTokenPayload)
      .then(async ({ data }) => {
        const updatedResponse: ISession = {
          refreshToken: data.data.refreshToken,
          userId: sessions.userId,
          token: data.data.token,
          role: sessions.role,
          loginType:sessions?.role
        };
        console.log("updated", data);
        resolve(
          encryptAndSave(USER_LOCAL.LOCAL, JSON.stringify(updatedResponse))
        );
      })
      .catch((err) => {
        console.log("erere", err);
        if (window.location.pathname !== ROUTES.SIGNIN) {
          clearLogoutStorage();
          localStorage.clear();
          window.location.href = ROUTES.SIGNIN;
        }
        reject(err);
      });
  });
};
