import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatService } from "services/chat.service";
import { IChatData, IChatFeedbackData, IShowChatData, IUpdateChatListData } from "interfaces/chat.interface";

export const getChatLists = createAsyncThunk(
  "chatgptStore/getChatLists",
  async (params: any) => {
    try {
      const response = await chatService.index(params);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getChatById = createAsyncThunk(
  "chatgptStore/getChatById",
  async (data: IShowChatData) => {
    try {
      const response = await chatService.show(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);


export const updateChatFeedback = createAsyncThunk(
  "chatgptStore/updateChatFeedback",
  async (data: IChatFeedbackData) => {
    try {
      const response = await chatService.updateFeedback(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateChatListId = createAsyncThunk(
  "chatgptStore/updateChatListId",
  async (data: IUpdateChatListData) => {
    try {
      const response = await chatService.updateChatList(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const storeChatGoal = createAsyncThunk(
  "chatgptStore/storeChatGoal",
  async (data: IChatData) => {
    try {
      const response = await chatService.storeGoal(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const deleteChatMessage = createAsyncThunk(
  "chatgptStore/deleteChatMessage",
  async (data: any) => {
    try {
      const response = await chatService.deleteMessage(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateMessage = createAsyncThunk(
  "chatgptStore/deleteChatMessage",
  async (data: any) => {
    try {
      const response = await chatService.updateMessage(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const stopMessageStream = createAsyncThunk(
  "chatgptStore/stopMessageStream",
  async (data: any) => {
    try {
      const response = await chatService.stopMessageStream(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const storeMessageStream = createAsyncThunk(
  "chatgptStore/storeMessageStream",
  async (data: any) => {
    try {
      const response = await chatService.storeStreamMessage(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateMessageStream = createAsyncThunk(
  "chatgptStore/updateMessageStream",
  async (data: any) => {
    try {
      const response = await chatService.updateStreamMessage(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);