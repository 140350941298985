import { createSlice } from "@reduxjs/toolkit";
import { getVoicesList } from "../actions/voicesList.actions";

export const initialState = {
  voicesList: {
    data: [],
    loading: false,
    error: null,
  },
};

export const voiceListSlice = createSlice({
  name: "voicesListStore",
  initialState,
  reducers: {
    resetAllVoiceListData(state, action) {
      return initialState;
    },
  },
  extraReducers: {
    // Generate a new Audio
    [getVoicesList.pending.type]: (state, action) => {
      state.voicesList.loading = true;
      state.voicesList.data = [];
      state.voicesList.error = null;
    },
    [getVoicesList.fulfilled.type]: (state, action) => {
      state.voicesList.data = action.payload.data;
      state.voicesList.loading = false;
      state.voicesList.error = null;
    },
    [getVoicesList.rejected.type]: (state, action) => {
      state.voicesList.data = [];
      state.voicesList.loading = false;
      state.voicesList.error = action.error?.message;
    },
  },
});

export const { resetAllVoiceListData } = voiceListSlice.actions;

export default voiceListSlice.reducer;
