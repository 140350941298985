import React from 'react'
import Navbar from '../LandingPage/Header'
import Footer from '../LandingPage/Footer'
import { useRouter } from 'next/router'
import { ROUTES } from '@/constants/routes'


const ThemeLayout = ({ children }: any) => {
  const router = useRouter()
  const { pathname } = router;
  
  return (
    <div className={`overflow-hidden min-h-screen flex flex-col dark:bg-[#272B30] bg-white`}>
     {
        (pathname !== ROUTES.SIGNIN && pathname !== ROUTES.Invite_Super_User && pathname !== ROUTES.PLAY_AUDIO && pathname !== ROUTES.REFERRALCODE && pathname !== ROUTES.THANKYOUCREATTINGACCOUNT) && <Navbar />
      }
     
    <main id="main" className='main flex-auto flex h-full flex-grow flex-col'>
      {children}
    </main>
     {(pathname !== ROUTES.SIGNIN && pathname !== ROUTES.Invite_Super_User && pathname !== ROUTES.PLAY_AUDIO && pathname !== ROUTES.REFERRALCODE && pathname !== ROUTES.THANKYOUCREATTINGACCOUNT)&&<Footer/>}
    </div>
  )
}

export default ThemeLayout
