import { detect } from "detect-browser";
import { USER_LOCAL, REDUX_PERSISTOR } from "@/constants/user";
import { isEmpty } from "lodash";

export const clearLogoutStorage = () => {
  localStorage.removeItem(USER_LOCAL.LOCAL);
  localStorage.removeItem("audioItem");
  localStorage.removeItem("audioFilePath");
  localStorage.removeItem("audioPlayedDuration");
  localStorage.removeItem("isAudioPlaying");
  localStorage.removeItem("isPageReload");
  localStorage.removeItem(`persist:${REDUX_PERSISTOR.KEY}`);
};

export const getUserIPFromIpifyGeolocation = async () => {
  const response = await fetch("https://geolocation-db.com/json/");
  //   console.log("response--geolocation", response);
  // // return "192.168.12.3"
  const result = await response.json();
  //   console.log("response===", result);
  if (isEmpty(result.IPv4) || result?.IPv4?.toLowerCase()?.includes("found")) {
    return await result;
  } else {
    return result?.IPv4;
  }
};
export const getUserFromIpApi = async () => {
  try {
    const responseIP = await fetch("https://ipwhois.app/json/");
    const jsonIpApi = await responseIP.json();
    // console.log("response===IpApi", jsonIpApi);
    if (
      isEmpty(jsonIpApi?.ip) ||
      jsonIpApi?.ip?.toLowerCase()?.includes("found")
    ) {
      return await getUserIPFromIpifyGeolocation();
    } else {
      return jsonIpApi?.ip;
    }
  } catch (err) {
    console.log("respon err", err);
    return await getUserIPFromIpifyGeolocation();
  }
};

export const getUserIP = async () => {
  try {
    const responseIP = await fetch("https://api.ipify.org?format=json");
    const jsonIP = await responseIP.json();
    // console.log("response===ipify", jsonIP);
    if (isEmpty(jsonIP?.ip) || jsonIP?.ip?.toLowerCase()?.includes("found")) {
      return await getUserFromIpApi();
    } else {
      return jsonIP?.ip;
    }
  } catch (err) {
    console.log("respon err", err);
    return await getUserFromIpApi();
  }
};
export const getUserBrowserInfo = () => {
  const browser = detect();
  return {
    os: browser?.os,
    browserName: browser?.name,
    browserVersion: browser?.version,
  };
};

export const parseJwt = (token: any) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

interface IpApiResponse {
  ip: string;
  countryCode: string;
}
// 1 https://ip-api.io/json/
// 2 https://ipwhois.app/json/ prefer
// 1 https://ipinfo.io/json

export const getUserFromIpApiFull3 = async (): Promise<IpApiResponse> => {
  try {
    const responseIP = await fetch("https://ipinfo.io/json");
    const jsonIpApi = await responseIP.json();
    console.log("getUserFromIpApiFull3");
    return { ip: jsonIpApi?.ip, countryCode: jsonIpApi?.country };
  } catch (err) {
    console.log("respon err", err);
    throw err;
  }
};

export const getUserFromIpApiFull2 = async (): Promise<IpApiResponse> => {
  try {
    const responseIP = await fetch("https://ip-api.io/json/");
    const jsonIpApi = await responseIP.json();
    console.log("getUserFromIpApiFull2");

    if (
      !jsonIpApi?.ip ||
      !jsonIpApi?.country_code ||
      jsonIpApi?.ip.toLowerCase().includes("found")
    ) {
      return await getUserFromIpApiFull3();
    } else {
      return { ip: jsonIpApi?.ip, countryCode: jsonIpApi?.country_code };
    }
  } catch (err) {
    console.log("respon err", err);
    return await getUserFromIpApiFull3();
  }
};

export const getUserFromIpApiFull = async (): Promise<IpApiResponse> => {
  try {
    const responseIP = await fetch("https://ipwhois.app/json/");
    const jsonIpApi = await responseIP.json();
    console.log("getUserFromIpApiFull");
    if (
      !jsonIpApi?.ip ||
      !jsonIpApi?.country_code ||
      jsonIpApi?.ip.toLowerCase().includes("found")
    ) {
      return await getUserFromIpApiFull2();
    } else {
      return { ip: jsonIpApi?.ip, countryCode: jsonIpApi?.country_code };
    }
  } catch (err) {
    console.log("respon err", err);
    return await getUserFromIpApiFull2();
  }
};
