import React from 'react';
import { useRouter } from "next/router";
import { twMerge } from "tailwind-merge";
import Icon from "@/components/Icon";

type NavigationType = {
    title: string;
    icon?: string;
    IconComponent?: any;
    color: string;
    onlyMobile?: boolean;
    url?: string;
    onClick?: () => void;
};

type NavigationProps = {
    visible?: boolean;
    items: NavigationType[];
    url?: string;
};

const Navigation = ({ visible, items }: NavigationProps) => {
    const router = useRouter();
    return (
        <div className={`${visible && "px-2"}`}>
            {items && items.map((item, index) =>
                item.url ? (
                    <div
                        className={twMerge(
                            `flex cursor-pointer items-center h-12 base2 font-semibold text-n-3/75 rounded-lg transition-colors hover:text-n-1 ${
                                router.pathname === item.url &&
                                "text-n-1 bg-gradient-to-l from-[#323337] to-[rgba(70,79,111,0.3)] shadow-[inset_0px_0.0625rem_0_rgba(255,255,255,0.05),0_0.25rem_0.5rem_0_rgba(0,0,0,0.1)]"
                            } ${visible ? "px-3 justify-center" : "px-5"}`
                        )}
                        onClick={item.onClick}
                        key={index}
                        {...(item.url === '/photo-generation' ? { id: 'step5' } : {})}
                    >
                        {
                            !visible && item.onlyMobile ? null :
                            <>
                            {item.title==="Image Library"  || item.title==="Biblioteca de imágenes" || item.title==="Bibliothèque d'images" || item.title==="Bildbibliothek" || item.title==="Libreria immagini" || item.title==="画像ライブラリ" || item.title==="Bildebibliotek" || item.title==="Библиотека изображений" ||item.title=="Image Library(图像库)" || item.title=="Audiobibliotheek" || item.title=="Biblioteca de imagens" ?(
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_6920_36264)">
                                    <path d="M19.5617 7C19.7904 5.69523 18.7863 4.5 17.4617 4.5H6.53788C5.21323 4.5 4.20922 5.69523 4.43784 7" stroke="#FF97E8" strokeWidth="1.5"/>
                                    <path d="M17.4999 4.5C17.5283 4.24092 17.5425 4.11135 17.5427 4.00435C17.545 2.98072 16.7739 2.12064 15.7561 2.01142C15.6497 2 15.5194 2 15.2588 2H8.74099C8.48035 2 8.35002 2 8.24362 2.01142C7.22584 2.12064 6.45481 2.98072 6.45704 4.00434C6.45727 4.11135 6.47146 4.2409 6.49983 4.5" stroke="#FF97E8" strokeWidth="1.5"/>
                                    <path d="M2.38351 13.793C1.93748 10.6294 1.71447 9.04765 2.66232 8.02383C3.61017 7 5.29758 7 8.67239 7H15.3276C18.7024 7 20.3898 7 21.3377 8.02383C22.2855 9.04765 22.0625 10.6294 21.6165 13.793L21.1935 16.793C20.8437 19.2739 20.6689 20.5143 19.7717 21.2572C18.8745 22 17.5512 22 14.9046 22H9.09536C6.44881 22 5.12553 22 4.22834 21.2572C3.33115 20.5143 3.15626 19.2739 2.80648 16.793L2.38351 13.793Z" stroke="#FF97E8" strokeWidth="1.5"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_6920_36264">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                </defs>
                                </svg>):item.title==="Audio Library" || item.title=="Biblioteca de audio" || item.title=="Bibliothèque audio" || item.title=="Audiobibliothek" || item.title=="音声ライブラリ" || item.title==="Lydbibliotek" || item.title==="Biblioteca de áudio" || item.title=="Аудиотека" ||item.title=="音频资料库" || item.title=="Afbeeldingsbibliotheek" ?(
                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                 <g clip-path="url(#clip0_6920_36257)">
                                     <path d="M19.3825 11.2295C19.3825 13.2693 18.5721 15.2256 17.1298 16.668C15.6874 18.1104 13.7311 18.9207 11.6912 18.9207C9.65139 18.9207 7.6951 18.1104 6.25271 16.668C4.81032 15.2256 4 13.2693 4 11.2295M11.6912 2C12.7111 2 13.6893 2.40516 14.4105 3.12635C15.1317 3.84755 15.5368 4.82569 15.5368 5.84562V11.2295C15.5368 12.2494 15.1317 13.2275 14.4105 13.9487C13.6893 14.6699 12.7112 15.0751 11.6912 15.0751C11.1862 15.0751 10.6861 14.9756 10.2196 14.7824C9.753 14.5891 9.32907 14.3058 8.97197 13.9487C8.61487 13.5916 8.33161 13.1677 8.13835 12.7011C7.94509 12.2346 7.84562 11.7345 7.84562 11.2295V5.84561C7.84562 4.82569 8.25078 3.84754 8.97197 3.12635C9.69316 2.40516 10.6713 2 11.6912 2Z" stroke="#DDA73F" strokeWidth="2.25" strokeLinecap="round" stroke-linejoin="round"/>
                                     <path d="M11.6914 18.9219V21.9984" stroke="#DDA73F" strokeWidth="2.25" strokeLinecap="round" stroke-linejoin="round"/>
                                 </g>
                                 <defs>
                                     <clipPath id="clip0_6920_36257">
                                     <rect width="24" height="24" fill="white"/>
                                     </clipPath>
                                 </defs>
                             </svg>):
                                    item.IconComponent ?
                                        <item.IconComponent /> :
                        <Icon className={item.color} name={item.icon} />}
                        </>
                        }
                       
                        {!visible && !item.onlyMobile  && <div className="ml-5 truncate !font-mont">{item.title}</div>}
                    </div>
                ) : (
                    <button
                        className={`flex  items-center w-full truncate h-12 base2 font-semibold text-n-3/75 rounded-lg transition-colors hover:text-n-1 ${
                            visible ? "px-3" : "px-5"
                        }`}
                        key={index}
                        onClick={item.onClick}
                    >
                        {
                            item.title==="Audio Library" || item.title==="Biblioteca de audio" ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_6920_36257)">
                                    <path d="M19.3825 11.2295C19.3825 13.2693 18.5721 15.2256 17.1298 16.668C15.6874 18.1104 13.7311 18.9207 11.6912 18.9207C9.65139 18.9207 7.6951 18.1104 6.25271 16.668C4.81032 15.2256 4 13.2693 4 11.2295M11.6912 2C12.7111 2 13.6893 2.40516 14.4105 3.12635C15.1317 3.84755 15.5368 4.82569 15.5368 5.84562V11.2295C15.5368 12.2494 15.1317 13.2275 14.4105 13.9487C13.6893 14.6699 12.7112 15.0751 11.6912 15.0751C11.1862 15.0751 10.6861 14.9756 10.2196 14.7824C9.753 14.5891 9.32907 14.3058 8.97197 13.9487C8.61487 13.5916 8.33161 13.1677 8.13835 12.7011C7.94509 12.2346 7.84562 11.7345 7.84562 11.2295V5.84561C7.84562 4.82569 8.25078 3.84754 8.97197 3.12635C9.69316 2.40516 10.6713 2 11.6912 2Z" stroke="#DDA73F" strokeWidth="2.25" strokeLinecap="round" stroke-linejoin="round"/>
                                    <path d="M11.6914 18.9219V21.9984" stroke="#DDA73F" strokeWidth="2.25" strokeLinecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_6920_36257">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            : <Icon className={item.color} name={item.icon} />
                        }                        
                        {!visible && <div className="ml-5">{item.title}</div>}
                        {item.title === "Search" && !visible && (
                            <div className="ml-auto px-2 rounded-md bg-n-4/50 caption1 font-semibold text-n-3 font-mont">
                                ⌘ F
                            </div>
                        )}
                    </button>
                )
            )}
        </div>
    );
};

export default Navigation;
