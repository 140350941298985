import { createSlice } from "@reduxjs/toolkit";
import {
  generateTranscription,
  updateTranscriptionById,
  destroyTranscription,
  getAllTranscriptionLibrary,
  deleteTranscriptionLibrary,
  updateTranscriptionLibrary,
  getAllTranscriptionLibraryPrevMonth,
  getInprogressTranscriptions,
  updateTranscriptionLibraryPrev,
  deleteTranscriptionLibraryPrev,
} from "../actions/transcription.actions";
import toast from "react-hot-toast";

export const initialState = {
  createTranscription: {
    data: {
      data: null,
    },
    loading: false,
    error: null,
    draggableLoading: false,
    draggableError: false,
    draggableSuccess:'NONE',
    draggableShow:false,
    message: null
  },
  uploadingTranscription: false,
  destroyTranscription: {
    data: null,
    loading: false,
    error: null,
  },
  transcriptions: {
    data: [],
    totalCount: 0,
    currentPage: 1,
    loading: true,
    error: null,
  },
  inProgressTranscriptions: {
    inProgressCount: 0,
    loading: false,
    error: null
  },
  prevTranscriptions: {
    data: [],
    totalCount: 0,
    loading: true,
    error: null,
  },
  voiceLists: {
    data: [],
    loading: false,
    error: null,
  },
  sidebarTranscriptionData: { data: {} },
  playingTranscription: { data: null },
  currentlyPlayingTranscriptions: {
    libraryTranscription: false,
    sidebarTranscription: false,
    drawerTranscription: false,
    transcriptionToPlay: null
  },
  textTranscriptionId: null,
  audioTranscriptionMinute:null,
  audioTranscriptionCounter:null
};

export const transcriptionSlice = createSlice({
  name: "transcriptionStore",
  initialState,
  reducers: {
    resetAllTranscriptionData(state, action) {
      return initialState;
    },
    closeDraggable(state,action){
      state.createTranscription.draggableShow = false;
    },
    resetTranscription(state) {
      return { ...state, transcriptions: initialState.transcriptions };
    },
    resetCreateTranscriptionLoadingError(state, action) {
      state.createTranscription.loading = false;
      state.createTranscription.error = null;
    },
    resetCreateTranscriptionLoadingMessage(state, action) {
      state.createTranscription.message = null;
    },
    resetCreateTranscriptionData(state, action) {
      state.createTranscription.loading = false;
      state.createTranscription.error = null;
      state.createTranscription.data.data = null;
    },
    setPlayingTranscription(state, action) {
      console.log('Im Called!');
      state.playingTranscription.data = action.payload;
    },
    setDrawerPlayingTranscription(state, action) {
      state.currentlyPlayingTranscriptions.drawerTranscription = action.payload;
    },
    setLibraryPlayingTranscription(state, action) {
      state.currentlyPlayingTranscriptions.libraryTranscription = action.payload;
    },
    setSidebarPlayingTranscription(state, action) {
      state.currentlyPlayingTranscriptions.sidebarTranscription = action.payload;
    },
    setTranscriptionToPlay(state, action) {
      state.currentlyPlayingTranscriptions.transcriptionToPlay = action.payload;
    },
    resetAllPlayingTranscriptions(state, action) {
      state.currentlyPlayingTranscriptions.drawerTranscription = false;
      state.currentlyPlayingTranscriptions.libraryTranscription = false;
      state.currentlyPlayingTranscriptions.sidebarTranscription = false;
      state.currentlyPlayingTranscriptions.transcriptionToPlay  = null;
    },
    setUploadingTranscription(state, action) {
      state.uploadingTranscription = action.payload;
    },
    setTextTranscriptionId(state, action) {
      state.textTranscriptionId = action.payload;
    },
    setAudioTranscriptionMinute(state, action) {
      state.audioTranscriptionMinute = action.payload;
    },
    setAudioTranscriptionCounter(state, action) {
      state.audioTranscriptionCounter = action.payload;
    },
    setInProgressTranscription(state, action) {
      state.inProgressTranscriptions.loading = action.payload === 0 ? false : true;
      state.inProgressTranscriptions.inProgressCount = action.payload;
    }
  },
  extraReducers: {
    // Generate a new Transcription
    [generateTranscription.pending.type]: (state, action) => {
      state.createTranscription.loading = true;
      state.createTranscription.data.data = null;
      state.createTranscription.error = null;
      state.createTranscription.draggableLoading = true;
      state.createTranscription.draggableShow = true;
      state.createTranscription.draggableSuccess = 'PENDING';
    },
    [generateTranscription.fulfilled.type]: (state, action) => {
      state.createTranscription.data.data = action.payload.data;
      state.createTranscription.loading = false;
      state.createTranscription.error = null;
      state.createTranscription.draggableLoading = false;
      state.createTranscription.draggableError = false;
      if(action.payload && action?.payload?.status == 202){
        state.createTranscription.draggableShow = false;
        state.createTranscription.draggableSuccess = 'NONE';
        state.createTranscription.message = action.payload.message;
        state.inProgressTranscriptions.inProgressCount = 1;
      }
      else {
        state.createTranscription.draggableShow = true;
        state.createTranscription.draggableSuccess = 'OK';
        state.createTranscription.message = null;
      }
      
    },
    [generateTranscription.rejected.type]: (state, action) => {
      toast.error(action.error?.message)
      state.createTranscription.data.data = null;
      state.createTranscription.loading = false;
      state.createTranscription.error = action.error?.message;
      state.createTranscription.draggableLoading = false;
      state.createTranscription.draggableShow = false;
      state.createTranscription.draggableError = true;
      state.createTranscription.draggableSuccess = 'REJECT';
    },
    // Update Transcription By Id
    [updateTranscriptionById.pending.type]: (state, action) => {
      state.createTranscription.loading = true;
      state.createTranscription.data.data = null;
      state.createTranscription.error = null;
    },
    [updateTranscriptionById.fulfilled.type]: (state, action) => {
      state.createTranscription.data.data = action.payload.data;
      state.createTranscription.loading = false;
      state.createTranscription.error = null;

      if(action.payload && action?.payload?.status == 202){
        state.createTranscription.message = action.payload.message;
        state.inProgressTranscriptions.inProgressCount = 1;
      }
    },
    [updateTranscriptionById.rejected.type]: (state, action) => {
      state.createTranscription.data.data = null;
      state.createTranscription.loading = false;
      state.createTranscription.error = action.error?.message;
    },
    // Delete Transcription By Id
    [destroyTranscription.pending.type]: (state, action) => {
      state.destroyTranscription.loading = true;
      state.destroyTranscription.data = null;
      state.destroyTranscription.error = null;
    },
    [destroyTranscription.fulfilled.type]: (state, action) => {
      state.destroyTranscription.data = action.payload.data;
      state.createTranscription = initialState.createTranscription;
      state.destroyTranscription.loading = false;
      state.destroyTranscription.error = null;
    },
    [destroyTranscription.rejected.type]: (state, action) => {
      state.destroyTranscription.data = null;
      state.destroyTranscription.loading = false;
      state.destroyTranscription.error = action.error?.message;
    },

    [getInprogressTranscriptions.pending.type]: (state,action)=>{
      state.inProgressTranscriptions.loading = true;
    },
    [getInprogressTranscriptions.fulfilled.type]: (state,action)=>{
      state.inProgressTranscriptions.loading = false;
      state.inProgressTranscriptions.inProgressCount = action.payload;
      state.transcriptions.error = null;
    },
    [getInprogressTranscriptions.rejected.type]: (state,action)=>{
      state.inProgressTranscriptions.loading = false;
      state.inProgressTranscriptions.inProgressCount = 0;
      state.transcriptions.error = action.error?.message;
    },

    // Transcription Library Implementations
    [getAllTranscriptionLibrary.pending.type]: (state, action) => {
      state.transcriptions.loading = true;
    },
    [getAllTranscriptionLibrary.fulfilled.type]: (state, action) => {
      if (action.meta.arg.page === 1) {
        state.transcriptions.data = action.payload.transcriptions;
      } else {
        // @ts-ignore
        state.transcriptions.data = [...state.transcriptions.data, ...action.payload.transcriptions];
      }
      console.log('action.payload.:::::',action.payload);
      state.transcriptions.totalCount = action.payload.totalTranscriptions;
      state.transcriptions.currentPage = action.payload.currentPage;
      state.transcriptions.loading = false;
      state.transcriptions.error = null;
    },
    [getAllTranscriptionLibrary.rejected.type]: (state, action) => {
      state.transcriptions.loading = false;
      state.transcriptions.data = [];
      state.transcriptions.error = action.error?.message;
    },
    [getAllTranscriptionLibraryPrevMonth.pending.type]: (state, action) => {
      state.prevTranscriptions.loading = true;
    },
    [getAllTranscriptionLibraryPrevMonth.fulfilled.type]: (state, action) => {
      if (action.meta.arg.page === 1) {
        state.prevTranscriptions.data = action.payload.transcriptions;
      } else {
        // @ts-ignore
        state.prevTranscriptions.data = [
          ...state.prevTranscriptions.data,
          ...action.payload.transcriptions,
        ];
      }
      state.prevTranscriptions.totalCount = action.payload.totalTranscriptions;
      state.prevTranscriptions.loading = false;
      state.prevTranscriptions.error = null;
    },
    [getAllTranscriptionLibraryPrevMonth.rejected.type]: (state, action) => {
      state.prevTranscriptions.loading = false;
      state.prevTranscriptions.data = [];
      state.prevTranscriptions.error = action.error?.message;
    },
    [deleteTranscriptionLibrary.pending.type]: (state, action) => {
      // state.transcriptions.loading = true;
    },
    [deleteTranscriptionLibrary.fulfilled.type]: (state, action) => {
      const deletedAtranscriptionIndex = state.transcriptions.data.findIndex((transcription: any) => transcription._id == action.payload.data._id);
      if(deletedAtranscriptionIndex > -1){
        state.transcriptions.data.splice(deletedAtranscriptionIndex, 1);
      }
      // state.transcriptions.data = [
      //   ...state.transcriptions.data.filter(
      //     (transcription: any) => transcription._id != action.payload.data._id
      //   ),
      // ];
      state.transcriptions.totalCount = state.transcriptions.totalCount - 1;
      state.transcriptions.loading = false;
      state.transcriptions.error = null;
    },
    [deleteTranscriptionLibrary.rejected.type]: (state, action) => {
      state.transcriptions.loading = false;
      state.transcriptions.error = action.error?.message;
    },

    [deleteTranscriptionLibraryPrev.pending.type]: (state, action) => {
      // state.prevTranscriptions.loading = true;
    },
    [deleteTranscriptionLibraryPrev.fulfilled.type]: (state, action) => {
      state.prevTranscriptions.data = [
        ...state.prevTranscriptions.data.filter(
          (transcription: any) => transcription._id != action.payload.data._id
        ),
      ];
      state.prevTranscriptions.totalCount = state.prevTranscriptions.totalCount - 1;
      state.prevTranscriptions.loading = false;
      state.prevTranscriptions.error = null;
    },
    [deleteTranscriptionLibraryPrev.rejected.type]: (state, action) => {
      state.prevTranscriptions.loading = false;
      state.prevTranscriptions.error = action.error?.message;
    },

    [updateTranscriptionLibrary.pending.type]: (state, action) => {
      // state.transcriptions.loading = true;
    },
    [updateTranscriptionLibrary.fulfilled.type]: (state, action) => {      
      // state.transcriptions.loading = false;
      state.transcriptions.error = null;
    },
    [updateTranscriptionLibrary.rejected.type]: (state, action) => {
      // state.transcriptions.loading = false;
      state.transcriptions.error = action.error?.message;
    },

    [updateTranscriptionLibraryPrev.pending.type]: (state, action) => {
      // state.prevTranscriptions.loading = true;
    },
    [updateTranscriptionLibraryPrev.fulfilled.type]: (state, action) => {
      state.prevTranscriptions.loading = false;
      state.prevTranscriptions.error = null;
    },
    [updateTranscriptionLibraryPrev.rejected.type]: (state, action) => {
      state.prevTranscriptions.loading = false;
      state.prevTranscriptions.error = action.error?.message;
    },
  },
});

export const {
  resetTranscription,
  closeDraggable,
  setTranscriptionToPlay,
  setPlayingTranscription,
  resetAllTranscriptionData,
  resetCreateTranscriptionData,
  resetAllPlayingTranscriptions,
  setDrawerPlayingTranscription,
  setLibraryPlayingTranscription,
  setSidebarPlayingTranscription,
  resetCreateTranscriptionLoadingError,
  resetCreateTranscriptionLoadingMessage,
  setUploadingTranscription,
  setTextTranscriptionId,
  setAudioTranscriptionMinute,
  setAudioTranscriptionCounter,
  setInProgressTranscription
} = transcriptionSlice.actions;

export default transcriptionSlice.reducer;
