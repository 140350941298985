import { passwordRegex } from "lib/regex";
import * as yup from "yup";


export const updatePasswordSchema = yup.object().shape({
    oldPassword: yup.string().optional(),
    newPassword: yup
      .string()
      .required('New Password is required')
      .matches(passwordRegex, 'Password must contain Uppercase, Lowercase and 8 to 20 characters long'),
    confirmPassword: yup
      .string()
      .required('Confirm new Password is required')
      .matches(passwordRegex, 'Password must contain Uppercase, Lowercase and 8 to 20 characters long')
      .oneOf([yup.ref('newPassword'), ''], 'Passwords must match'),
  });