import React, { useState } from "react";
import Icon from "@/components/Icon";
import { useDispatch } from "react-redux";
import { getChatLists } from "store/actions/chat.actions";
import { throttle } from "lodash";
import ChatSearchTabs from "./ChatSearchTabs";
import { RES_PER_PAGE } from "@/constants/pagination";
import { getAllDebates } from "store/actions/debate.actions";
import { useTranslation } from "react-i18next";

const throttledDebateSearch = throttle((search, dateSpan, page, dispatch) => {
  const params = {
    search: search,
    filter: dateSpan.id || "",
    page: page,
    perPage: RES_PER_PAGE,
  };
  dispatch(getChatLists(params));
}, 1500);

const throttledChatSearch = throttle((search, dateSpan, page, dispatch) => {
  const params = {
    search: search,
    filter: dateSpan.id || "",
    page: page,
    perPage: RES_PER_PAGE,
  };
  dispatch(getAllDebates(params));
}, 1500);


const Search = () => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation('translation')

  const [search, setSearch] = useState<string>("");
  const [dateSpan, setDateSpan] = useState<any>("");
  const [page, setPage] = useState<number>(1);
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  const handleTextSearch = (event: any) => {
    const inputValue = event.target.value;
    setSearch(inputValue);

    if(selectedTabIdx === 1) {
      throttledChatSearch(inputValue, dateSpan, page, dispatch);
    } else {
      throttledDebateSearch(inputValue, dateSpan, page, dispatch);
    }
  };

  return (
    <>
      <form className="" action="" onSubmit={(e) => e.preventDefault()}>
        <>
          <div className="relative border-b border-n-3 dark:border-n-6">
            <button className="group absolute top-7 left-10 outline-none md:hidden" type="submit">
              <Icon
                className="w-8 h-8 fill-n-4/50 transition-colors group-hover:fill-n-7 dark:group-hover:fill-n-3"
                name="search-1"
              />
            </button>
            <input
              className="w-full h-22 pl-24 pr-5 bg-transparent border-none outline-none h5 text-n-7 placeholder:text-n-4/50 md:h-18 md:pl-18 dark:text-n-1"
              type="text"
              name="search"
              placeholder={t('search')}
              value={search}
              onChange={handleTextSearch}
            />
          </div>
          <ChatSearchTabs setSelectedTabIdx={(value: any) => {setSearch('');setSelectedTabIdx(value);}}/>
        </>
      </form>
    </>
  );
};

export default Search;
