import React, { useState } from 'react';
import AppleLogin from "react-apple-login";
import Image from "@/components/Image";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileByToken } from "store/actions/auth.actions";
import { useRouter } from "next/router";
import { SOCIAL_AUTH } from "@/constants/user";
import { getUserBrowserInfo } from 'lib/user';
import { useTranslation } from 'react-i18next';

export default function AppleAuth({ dark }: any) {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation('auth');
  const userData = useSelector((state: any) => state.authStore);
  const { user } = userData;
  const { query } = router;

  const type = query.type;
  const referralCode = query.referralCode || "";
  
  const [isLoading, setIsLoading] = useState(false);

  const appleResponse = async (response: any) => {
    console.log("apple response", response);
    setIsLoading(true);
    if (!response.error) {
      console.log("apple response", response);
      const browserInfo = getUserBrowserInfo();
      
      const payload: any = {
        token: response.authorization?.id_token,
        provider: SOCIAL_AUTH.APPLE,
        referalInvite: referralCode,
        os: browserInfo?.os,
        browserName: browserInfo?.browserName,
        browserVersion: browserInfo?.browserVersion,
        type:type
      };
      dispatch(getUserProfileByToken(payload)).unwrap().then(()=>{
        setIsLoading(false);
      }).catch((err: any) => {
          setIsLoading(false);
      });
    } else {
      console.error('apple error response', response);
      setIsLoading(false);
    }
  };

  return (
    <AppleLogin
      clientId={`${process.env.NEXT_PUBLIC_APPLE_CLIENT_ID ?? "com.lokichat"}`}
      scope="name email"
      redirectURI={`${(process.env.NEXT_PUBLIC_FRONTEND_URL ?? 'https://lokichat.com').replace('com/', 'com')}`}
      callback={appleResponse}
      responseMode="form_post"
      responseType="code id_token"
      usePopup={true}
      render={(renderProps) => {
        return (
          <button
            className="btn-stroke-light btn-large w-full mb-3"
            onClick={renderProps.onClick}
            disabled={user?.loading || isLoading}
          >
            <Image
              src={dark ? "https://lokichatdev.blob.core.windows.net/images/apple.svg" : "https://lokichatdev.blob.core.windows.net/images/dark-apple.svg"}
              width={24}
              height={24}
              alt=""
            />
            <span className="ml-4 !font-mont">{lt('continue_apple')}</span>
          </button>
        );
      }}
    />
  );
}
