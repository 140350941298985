import { createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "services/auth.service";
import { profileService } from "services/profile.service";
import {
  IProfileData,
  ILoginData,
  IRegisterData,
  IOtpData,
  //commented to remove register guest
  // IGuestRegisterData,
} from "interfaces/auth.interface";
import { getUserIP } from "lib/user";
import { audioService } from "services/audio.service";

export const getUserProfileByToken = createAsyncThunk(
  "authStore/getUserProfileByToken",
  async (data: IProfileData) => {
    try {
      const ipAddress = await getUserIP();
      data.ipAddress = await ipAddress;
      const response = await authService.getUserProfileByToken(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const loginUser = createAsyncThunk(
  "authStore/loginUser", // The action type string
  async (data: ILoginData) => {
    try {
      const ipAddress = await getUserIP();
      data.ipAddress = await ipAddress;

      const response = await authService.login(data);
      console.log(response.data, " ===> login data");
      
      return response.data; // Assuming your API returns the user data
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
export const registerUser = createAsyncThunk(
  "authStore/registerUser", // The action type string
  async (data: IRegisterData) => {
    try {
      const response = await authService.register(data);
      return response.data; // Assuming your API returns the user data
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "authStore/verifyOtp",
  async (data: IOtpData) => {
    try {
      const response = await authService.verifyOtp(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const requestOtp = createAsyncThunk(
  "authStore/requestOtp", // The action type string
  async (data: any) => {
    try {
      const response = await authService.requestOtp(data);
      return response.data; // Assuming your API returns the user data
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "authStore/resetPassword",
  async (data: any) => {
    try {
      const response = await authService.resetPassword(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "authStore/getAllUsers",
  async (params: any) => {
    try {
      const response = await audioService.getAllUsers(params);
      return response.data;
    } catch (error: any) {
      console.log('eer', error);
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
export const verifyRefferalCode = createAsyncThunk(
  "authStore/verifyRefferalCode",
  async (data: any) => {
    try {
      const ipAddress = await getUserIP();
      data.ipAddress = await ipAddress;
      const response = await authService.verifyRefferalCode(data);
  
      // alert("Verified Successfully")
      // toast.success("Verified Successfully.",{duration:3000})
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const userOnBoardRole = createAsyncThunk(
  "authStore/userOnBoardRole",
  async (data: any) => {
    try {
      const ipAddress = await getUserIP();

      const response = await authService.userOnBoardRole(data);
  
      console.log('succeedded', response)
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
)
export const updateUserName = createAsyncThunk(
  "authStore/updateUserName",
  async (data: any) => {
    try {
      const ipAddress = await getUserIP();
      data.ipAddress = await ipAddress;
      const response = await profileService.updateUserName(data);
  
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateTourStatus = createAsyncThunk(
  "authStore/updateTourStatus",
  async (data: any) => {
    try {
      const response = await profileService.updateTourStatus(data);
  
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

//commented to remove register guest
// export const RegisterGuestUser = createAsyncThunk(
//   "authStore/RegisterGuestUser", // The action type string
//   async (data: IGuestRegisterData) => {
//     try {
//       const ipAddress = await getUserIP();
//       data.ipAddress = await ipAddress;

//       const response = await authService.guestRegister(data);
      
//       return response.data; // Assuming your API returns the user data
//     } catch (error: any) {
//       const { response } = error;
//       throw response.data.message || response.statusText;
//     }
//   }
// );