import React, { useState } from "react";
import Field from "@/components/Field";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "store/actions/auth.actions";
import { isEmpty } from "lodash";
import { signinSchema } from "lib/validations/authentications";
import { BiErrorCircle } from "react-icons/bi";
import { detect } from 'detect-browser';
import { useTranslation } from "react-i18next";
import { BsEye } from "react-icons/bs";
import { IoEyeOffOutline } from "react-icons/io5";
import { useRouter } from 'next/router';
import { resetAllChatsData } from "store/slices/ChatGptSlice";
import { resetPhotoGenerations } from "store/slices/PhotoGenerationSlice";
import { resetAllAudioData } from "store/slices/AudioSlice";
import { resetTextToSpeechData } from "store/slices/TextToSpeechSlice";
import { resetAllTranscriptionData } from "store/slices/TranscriptionSlice";
import { resetAllTranslations } from "store/slices/TranslationSlice";
import { setFiftyPercentOff } from "store/slices/AuthSlice";

type SignInProps = {
  onClick: () => void;
  setSelectedIndex: any;
  setUserEmail: any;
};

const SignIn = ({ onClick, setSelectedIndex, setUserEmail }: SignInProps) => {  
  const initialErrors = { email: "", password: "" };
  const router = useRouter();
  const browser = detect();
  const dispatch: any = useDispatch();
  const { t:lt } = useTranslation('auth');

  const userData = useSelector((state: any) => state.authStore);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState(initialErrors);
  const [eye, setEye] = useState(false);
  const type = router.query.type

  const clearGuestData = () => {
    dispatch(resetAllChatsData(null))
    dispatch(resetPhotoGenerations(null))
    dispatch(resetAllAudioData(null))
    dispatch(resetTextToSpeechData())
    dispatch(resetAllTranscriptionData(null))
    dispatch(resetAllTranslations(null))
  }

  const handleLogin = async (event: any) => {
    event.preventDefault();
    const data: any = { email: email.toLowerCase(), password,type:type };

    let fieldErrors;

    await signinSchema
      .validate({ email, password }, { abortEarly: false })
      .catch((error: any) => {
        fieldErrors = error.inner.reduce((acc: any, err: any) => {
          return {
            ...acc,
            [err.path]: err.message,
          };
        }, {});

        setErrors(fieldErrors);
      });

    if (isEmpty(fieldErrors)) {
      setErrors(initialErrors);
      setUserEmail(email.toLowerCase());
      data.os = browser?.os;
      data.browserName = browser?.name;
      data.browserVersion = browser?.version;
      data.role = "user";
      data.loginType = "user";
      if(userData.guestUser.data){
        data.guestId = userData.guestUser.data.id
      }
      dispatch(loginUser(data)).unwrap().then(() => {
        clearGuestData();
        sessionStorage.setItem('hasRefreshed', 'true');
        dispatch(setFiftyPercentOff(false));
      });
    }
  };

  const isAllowToSignin = () => {
    if (userData.user.loading || isEmpty(email) || isEmpty(password)) {
      return true;
    }
  };

  return (
    <form action="">
      <div className="mb-4">
      <Field
        className=""
        classInput="dark:bg-n-7 dark:border-n-7 dark:focus:bg-transparent"
        placeholder={lt('type_email')}
        icon="email"
        value={email}
        onChange={(e: any) => {
          setEmail(e.target.value);
          setErrors(initialErrors);
        }}
        required
      />
      {errors.email && (
        <div className="text-red-500 text-xs mt-1 flex items-center gap-1"> <BiErrorCircle className="text-base"/> {errors.email}</div>
      )}
      </div>
      <div className="mb-4 relative">
      <Field
        classInput="dark:bg-n-7 dark:border-n-7 dark:focus:bg-transparent"
        placeholder={lt('type_password')}
        icon="lock"
        type={eye?"text":"password"}
        value={password}
        onChange={(e: any) => {
          setPassword(e.target.value);
          setErrors(initialErrors);
        }}
        required
      />
      {errors.password && (
        <div className="text-red-500 text-xs mt-1 flex items-center gap-1"> <BiErrorCircle className="text-base"/> {errors.password}</div>
      )}
       <span className="absolute top-1/2 -translate-y-1/2 right-3 !font-mont">
       {
          !eye?
        <BsEye className="cursor-pointer fill-n-7 dark:fill-n-3" onClick={()=>{setEye(true)}}/>
         :
        <IoEyeOffOutline className="cursor-pointer fill-n-7 dark:fill-n-3" onClick={()=>{setEye(false)}}/>

        }
      </span>
      </div>
      <div className="flex justify-between">
        <div className="flex items-start gap-2">
          <input
            className="h-[22px] w-[22px]  inline-flex flex-shrink-0"
            type="checkbox"
          />
          <div className=" caption1 text-n-4 mb-6">{lt('remember_me')}</div>
        </div>

        <button
          className="mb-6 base2 text-primary-1 transition-colors hover:text-primary-1/90"
          type="button"
          onClick={onClick}
        >
          {lt('forgot_password')}?
        </button>
      </div>

      <button
        disabled={isAllowToSignin()}
        onClick={handleLogin}
        className="btn-blue btn-large w-full"
        type="submit"
      >
        {lt('signIn_btn')}
      </button>

      <div
        className="group absolute cursor-pointer top-4 left-8 flex justify-center items-center w-10 h-10 rounded-full text-0 transition-colors"
        onClick={() => {
          setSelectedIndex(0);
        }}
      >
      </div>
    </form>
  );
};

export default SignIn;
