import React, { useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import Icon from "@/components/Icon";
import Modal from "@/components/Modal";
import Settings from "@/components/Settings";
import Notification from "./Notification";

import { translate } from "@/constants/settings";
import { useDispatch, useSelector } from "react-redux";
import { selectNotifications } from "store/slices/NotificationSlice";
import { getMineNotifications, readNotificationsList } from "store/actions/notification.actions";
import { useTranslation } from "react-i18next";
import { formatOnlyDate } from "lib/DateTime";
import { useRouter } from "next/router";
import { DEBATE_INVITATION, DEBATE_LEAVE, DEBATE_NEW_MESSAGE, DEBATE_UNARCHIVED, DEBATE_UPDATE_MESSAGE } from "@/constants/debate";
import { AUDIO_GENERATED } from "@/constants/audio";
import { TRANSCRIPTION_GENERATED } from "@/constants/transcription";
import {
  resetChatAndNotebooks,
  setAbortNavigationData,
  setSelectedChatListItem,
  setSelectedNotebooks,
  setStopGenerateChat,
  setAbortChatModal,
} from "store/slices/ChatGptSlice";
import { resetCreateAudioData } from "store/slices/AudioSlice";
import NofificationPlaceholder from "@/components/NotificationPlaceholder";
import { size, isEmpty } from "lodash";
import { ROUTES } from "@/constants/routes";
import { getGoalById } from "store/actions/goal.actions";
import { ABORT_EVENTS, CHAT_MODEL } from "@/constants/chats";
import { stopMessageStream, storeChatGoal } from "store/actions/chat.actions";
import { fetchStream } from "services/chat.fetch.service";
import { GOAL_REMINDER } from "@/constants/goals";
import { v4 as uuidv4 } from 'uuid';
import { ErrorToast } from "@/constants/toast";
import Image from "@/components/Image";
import notification from "../../../public/images/notification.gif";

type NotificationsType = {
  id: string;
  avatar: string;
  content: any;
  time: string;
  online?: boolean;
  new?: boolean;
  url: string;
};

type NotificationsProps = {
  items: NotificationsType[];
};

let abortController = new AbortController();

const Notifications = ({ items }: NotificationsProps) => {
  const router = useRouter();
  const dispatch: any = useDispatch();

  const { t:lt } = useTranslation("translation");
  const settings = translate(lt);

  const notificationsObj = useSelector(selectNotifications);
  const userData = useSelector((state: any) => state.authStore);
  const chatData = useSelector((state: any) => state.chatGptStore);
  const debateData = useSelector((state: any) => state.debateStore);

  const { user } = userData;
  const { stream, streamText, updateStream, createChat } = chatData;
  const {
    updateDebate,
    updateDebateMessage,
    updateDebateBotMessage,
  } = debateData;

  const [visibleSettings, setVisibleSettings] = useState<boolean>(false);
  const [messagePassCode, setMessagePassCode] = useState('');

  useEffect(() => {
    dispatch(getMineNotifications());
  }, []);

  useEffect(() => {
    try {
      if (chatData.stopGenerateChat?.keyName === ABORT_EVENTS.GENERATE && chatData.stopGenerateChat?.value) {
        console.log('Called-3');
        abortController.abort('Chat Paused with user permission');
        abortController = new AbortController();
        const data = {
          messageId: messagePassCode,
          chatId: createChat.data?.id ?? null,
          isUpdate: false,
        };
        if(!isEmpty(createChat?.data?.id)) {
          dispatch(stopMessageStream(data)).unwrap().then((res: any)=>{
            console.log("test", res);
          });
        }
      }
    } catch (error) {
      
    }
    
  }, [chatData.stopGenerateChat]);

  const handleClick = (close: any) => {
    setVisibleSettings(true);
    close();
  };

  const readNotification = (notificationItem: any, close: any) => {
    dispatch(readNotificationsList({ id: notificationItem._id }));
    if(notificationItem?.title !== GOAL_REMINDER) {
      close();
      isChatOrDebateLoading(notificationItem);
    }
  };

  const handleNotificationsNavigation = (data: any) => {
    const { title } = data;
    if (title === DEBATE_INVITATION || title === DEBATE_UNARCHIVED || title === DEBATE_NEW_MESSAGE || title === DEBATE_UPDATE_MESSAGE || title === DEBATE_LEAVE || title == AUDIO_GENERATED || title == TRANSCRIPTION_GENERATED) {
      dispatch(setSelectedChatListItem(null));
      dispatch(resetChatAndNotebooks(null));
      dispatch(resetCreateAudioData(null));
      dispatch(setSelectedNotebooks([]));
      close();
      if(data.pageId && data.pagePath){
        router.push(`${data.pagePath}/${data.pageId}`);
      } else if (data.pagePath){
        router.push(`${data.pagePath}`);
      }
      
    }
    if(data?.name == 'AUDIO_SHARE'){
      router.push(`${ROUTES.AUDIOLIBRARY}?to=shared`);
    }
  }

  const isChatOrDebateLoading = (notificationItem: any) => {
    try {
      const data: any = {
        clickHandler: () => handleNotificationsNavigation(notificationItem),
        url: null
      };
      if (stream && size(streamText) >= 0) {
        // abortStream Event
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else if (updateStream.loading && size(updateStream.text)) {
        // abort updateStream Event
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else if (updateDebate.loading || updateDebateMessage.loading || updateDebateBotMessage.loading) {
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else {
        handleNotificationsNavigation(notificationItem);
      };
    } catch (error) {
      
    }
    
  }

  const isChatOrDebateLoadingForGoal = (notificationItem: any) => {
    try {
      const data: any = {
        clickHandler: () => handleStartChat(notificationItem),
        url: ROUTES.CHAT
      };
      if (stream && size(streamText) >= 0) {
        // abortStream Event
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else if (updateStream.loading && size(updateStream.text)) {
        // abort updateStream Event
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else if (updateDebate.loading || updateDebateMessage.loading || updateDebateBotMessage.loading) {
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else {
        handleStartChat(notificationItem);
      };
    } catch (error) {
      
    }
    
  }

  const handleStartChat = (element: any) => {
    if(!isEmpty(element?.referenceId)) {
      dispatch(getGoalById({goalId: element.referenceId})).unwrap().then((response: any)=> {
        const { data } = response;
        if (data && size(data?.keyPoint1) && size(data?.keyPoint2) && size(data?.keyPoint3)) {
           const message = `${lt('hello')}, ****${user.data?.userName ?? user.data.email}!**** 🧙🏾‍♂️ ${lt('guide_goals')}, ****${data?.name}**** ${lt('by')} ****${formatOnlyDate(data.dueOnDate)}****. ${lt('goals_accomplish')}:
\n
1. ${data.keyPoint1}
2. ${data.keyPoint2 ?? ""}
3. ${data.keyPoint3 ?? ""}
\n${lt('infoKey_points')}`;
            startGoalChat(message);
        } else if (data && (size(data?.keyPoint1) && size(data?.keyPoint2))) {
          const message = `${lt('hello')}, ****${user.data?.userName ?? user.data.email}!**** 🧙🏾‍♂️ ${lt('guide_goals')}, ****${data?.name}**** ${lt('by')} ****${formatOnlyDate(data.dueOnDate)}****. ${lt('goals_accomplish')}:
\n
1. ${data.keyPoint1}
2. ${data.keyPoint2 ?? ""}
\n${lt('infoKey_points')}`;
           startGoalChat(message);
        } else if (data && (size(data?.keyPoint1) && size(data?.keyPoint3))) {
          const message = `${lt('hello')}, ****${user.data?.userName ?? user.data.email}!**** 🧙🏾‍♂️ ${lt('guide_goals')}, ****${data?.name}**** ${lt('by')} ****${formatOnlyDate(data.dueOnDate)}****. ${lt('goals_accomplish')}:
\n
1. ${data.keyPoint1}
2. ${data.keyPoint3 ?? ""}
\n${lt('infoKey_points')}`;
           startGoalChat(message);
        } else if (data && size(data?.keyPoint1)) {
          const message = `${lt('hello')}, ****${user.data?.userName ?? user.data.email}!**** 🧙🏾‍♂️ ${lt('guide_goals')}, ****${data?.name}**** ${lt('by')} ****${formatOnlyDate(data.dueOnDate)}****. ${lt('goals_accomplish')}:
\n
1. ${data.keyPoint1}
\n${lt('infoKey_points')}`;
           startGoalChat(message);
        }
      }).catch((error: any) => {
        console.log('error', error);
        ErrorToast(error?.message);
      });
    }
  }

  const startGoalChat = (value: any) => {
    dispatch(setStopGenerateChat({ keyName: "", value: false }));
    const { pathname } = router;
    close();
    const data: any = {message: value, chatModel: CHAT_MODEL.GEMENI_MODEL};
    if (pathname === ROUTES.CHAT || pathname === ROUTES.CHAT_ID || pathname === ROUTES.CHAT_LIST_ID && isEmpty(chatData.createChat.data?.id)) {
      dispatch(resetChatAndNotebooks(null));
      dispatch(resetCreateAudioData(null));
    }
    dispatch(storeChatGoal(data)).unwrap().then((response: any) => {
      const { data } = response;
      const passCode = data?.messageData?.messageId;
      setMessagePassCode(passCode);
      const msgData = { chatId: data?.id, message: value, messagePassCode: passCode, isGoalStream: true };
      localStorage.setItem('isUpdate', 'false');
      localStorage.setItem('chatId', data?.id);
      localStorage.setItem('messageId', passCode);
      dispatch(fetchStream(msgData, abortController.signal));
    }).catch((error: any) => {
      ErrorToast(error.message);
    });
    router.push(ROUTES.CHAT);
  };

  const handleGoalChatClick = (element: any) => {
    isChatOrDebateLoadingForGoal(element);
  }

  return (
    <>
      <div className="relative z-10 flex items-center">
        <Menu>
          {({ close }) => (
            <>
              <Menu.Button className="group relative w-10 h-10">
                {
                  notificationsObj?.data?.find((i: any) => !(i?.isRead)) ?
                    <Image
                      className="object-cover rounded-full"
                      src={notification}
                      fill
                      alt="Avatar"
                    />
                    :
                    <Icon
                      className="fill-n-4 transition-colors group-hover:fill-primary-1 ui-open:fill-primary-1"
                      name="notification"
                    />
                }
                <div className="absolute top-2.5 right-2.5 w-2 h-2 rounded-full border-fill-n-4 bg-transparent"></div>
              </Menu.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items className="absolute top-[20px] z-50  w-[37.5rem] lg:w-[30rem] md:w-[calc(100vw-90px)] p-6 bg-n-1 rounded-[1.25rem] shadow-[0_0_1rem_0.25rem_rgba(0,0,0,0.04),0px_2rem_2rem_-1rem_rgba(0,0,0,0.1)] lg:mt-2 lg:right-0 md:-right-[140px]  -right-2.5 md:p-4 dark:bg-n-7 dark:border dark:border-n-5">
                  <div className="flex justify-between items-center mb-3">
                    <div className="h4 md:h5">{lt("notifications")}</div>
                    <button
                      className="group"
                      onClick={() => handleClick(close)}
                    >
                      <Icon
                        className="fill-n-4 transition-colors group-hover:fill-primary-1"
                        name="settings"
                      />
                    </button>
                  </div>
                
                  {size(notificationsObj?.data) === 0 ?
                    <div className="h-[calc(100vh-350px)] flex justify-center items-center">
                      <NofificationPlaceholder/>
                    </div> 
                   :
                   <>
                   <div className="py-3 flex items-center gap-5">
                     <p className="text-lg font-semibold">{lt("today")}</p>
                     <span className="text-n-4 !font-mont text-xs">{formatOnlyDate(new Date())}</span>   
                   </div>
                   <div className="h-[calc(100vh-350px)] cursor-pointer -mx-6 px-6 space-y-3 overflow-y-auto scroll-smooth scrollbar-none md:space-y-6">
                     {notificationsObj?.data &&
                       notificationsObj?.data?.map((notification: any) => (
                         <Notification
                           onClick={() => readNotification(notification, close)}
                           item={notification}
                           close={close}
                           handleStartChat={() => handleGoalChatClick(notification)}
                         />
                       ))}
                   </div>
                  </>
                }
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
      <Modal
        className="md:!p-0"
        classWrap="max-w-[48rem] md:min-h-screen-ios md:rounded-none"
        classButtonClose="hidden md:block md:absolute md:top-5 md:right-5 dark:fill-n-4"
        classOverlay="md:bg-n-1"
        visible={visibleSettings}
        onClose={() => setVisibleSettings(false)}
      >
        <Settings items={settings} activeItem={2} />
      </Modal>
    </>
  );
};

export default Notifications;
