import { HttpService } from './base.service'

class promptAdvisorService extends HttpService {
  private readonly prefix: string = "prompt";
  
  getAllCategory = (params: any): Promise<any> => this.get(this.prefix + `/getAllCategory?searchQuery=${params?.search ? params?.search : ''}&page=${params.page}&per_page=10`);
  getAllSubCategory = (params: any): Promise<any> => this.get(this.prefix + `/getAllSubCategory/${params?.id}?searchQuery=${params?.search ? params?.search : ''}&page=${params.page}&per_page=10`);
  getAllPrompts = (params: any): Promise<any> => this.get(this.prefix + `/getAllPrompts/${params?.id}?searchQuery=${params?.search ? params?.search : ''}&page=${params.page}&per_page=10`);

}

export const advisorService = new promptAdvisorService();