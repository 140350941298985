export const USER_LOCAL = {
    LOCAL:'LOCAL',
    GUEST:'GUEST'
}
export const REDUX_PERSISTOR = {
    KEY: 'loki-1.3-chat-root-storage-v1'
}
export const SOCIAL_AUTH = {
    APPLE: 'apple',
    GOOGLE: 'google'
}

export const USER_GENDER = {
    MALE: 'MALE',
    Female: 'FEMALE'
}

export const USER_ROLES = {
    USER: 'user',
    SUERP_USER: 'super-user',
    SUPER_ADMIN: 'super-admin'
}

export const SUPER_USER_STATUS = {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected'
}

export const PROFILE_URL = "https://lokichatdev.blob.core.windows.net/images/loki/profile-placeholder.svg"

export const GARMONT_FONT = "/fonts/GFont.ttf"
export const GARMONT_FONT_ITALIC = "/fonts/GItalicFont.ttf"

export const GARMONT_FONT_BASE64 = ``

