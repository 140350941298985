import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from "@/components/Select";
import { isEmpty, size } from "lodash";
import Icon from "@/components/Icon";
import Item from "../Item/ChatItem";
import { RES_PER_PAGE } from "@/constants/pagination";
import { getChatLists } from "store/actions/chat.actions";
import { translateDates } from '@/constants/chats';
import ChatSearchSkelton from '@/components/ChatSearchSkelton';
import NoChatlistPlaceholder from '@/components/NoChalistPlaceholder';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@/constants/routes';
import { useRouter } from 'next/router';
import { resetChatAndNotebooks, setSearchModal, setSelectedChatListItem } from 'store/slices/ChatGptSlice';
import { resetAllAudioData, resetCreateAudioData } from 'store/slices/AudioSlice';

const ChatSearchListing = () => {
  const router = useRouter();
    const dispatch: any = useDispatch();
    const { t } = useTranslation('translation');
    const dates  = translateDates (t)

    const chatData = useSelector((state: any) => state.chatGptStore);
    const { chatLists } = chatData;
  
    const [search, setSearch] = useState<string>("");
    const [dateSpan, setDateSpan] = useState<any>("");
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
      const params = {
        search: search,
        filter: dateSpan.id || "",
        page: 1,
        perPage: RES_PER_PAGE,
      };
      dispatch(getChatLists(params));
    }, [dateSpan]);

    const handleNewChat = (event: any) => {
      event.preventDefault();
      dispatch(setSelectedChatListItem(null));
      dispatch(resetChatAndNotebooks(null));
      // dispatch(resetAllAudioData(null));
      dispatch(resetCreateAudioData(null));
      dispatch(setSearchModal(false));
      router.push(ROUTES.CHAT);
    };
    useEffect(()=>{
      setPage(1)
    },[dateSpan])

    return (
        <div className="pt-5 pb-6 px-6 h-[35.563rem]">
        <div className="flex justify-between items-center mb-5 md:block md:space-y-4 md:mb-0">
        {size(chatLists?.data?.chats) === 0 && isEmpty(dateSpan?.id) && search === '' ?
        null:
        <>
        <h3 className="h5 !font-mont !font-extrabold">{t('chat')}</h3>
          <Select
            className="w-[50%] md:w-full"
            classButton="h-11 rounded-full shadow-[inset_0_0_0_0.0625rem_#DADBDC] caption1 dark:shadow-[inset_0_0_0_0.0625rem_#2A2E2F] dark:bg-transparent"
            classOptions="min-w-full"
            classIcon="w-5 h-5 fill-n-4/50"
            classArrow="dark:fill-n-4"
            icon="clock"
            placeholder={t('all')}
            items={dates}
            value={dateSpan}
            onChange={setDateSpan}
          />
          </>
        }
        </div>
        {chatLists.loading ? (
          <div className="h-[28.27rem] overflow-auto scrollbar-none flex flex-col gap-3 mt-4">
            <ChatSearchSkelton/>
            <ChatSearchSkelton/>
            <ChatSearchSkelton/>
            <ChatSearchSkelton/>
          </div>
        ) : size(chatLists?.data?.chats) === 0 && !chatLists?.loading ? 
        <div className="h-[calc(100vh-450px)] flex flex-col items-center justify-center">
          {chatData?.chatLists?.data?.isAvailableChat ? (
            <p className="h4">No Records Found</p>
          ):(<NoChatlistPlaceholder /> )}
          <button style={{display: chatData?.chatLists?.data?.isAvailableChat?'none':'block'}} onClick={handleNewChat} className="btn-blue flex justify-center gap-2 mt-5"><Icon name="plus" />{t('new_chat')}</button>
        </div> : (
        <div className="h-[28.27rem] overflow-auto scrollbar-none">
          <Item
            title={t('all_chat')}
            date={new Date()}
            list={chatLists.data.chats}
        />

        {chatLists.loading || chatLists?.data?.totalChats > size(chatLists?.data?.chats) && (
          <button
            type="button"
            className="btn-stroke-light block mt-4 w-full"
            disabled={size(chatLists?.data?.chats) == chatLists?.data?.totalChats || chatLists.loading}
            onClick={() => {
              const params = {
                search: search,
                filter: dateSpan.id || "",
                page: page + 1,
                perPage: RES_PER_PAGE,
              };
              dispatch(getChatLists(params));
              setTimeout(() => {
                setPage(page + 1);
              }, 10);
            }}
          >
            {t('load_more')}
          </button>
          )}
        </div>
        )}
      </div>
    )
}

export default ChatSearchListing;