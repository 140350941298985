import { createSlice } from "@reduxjs/toolkit";
import {
  verifyOtp,
  loginUser,
  requestOtp,
  registerUser,
  resetPassword,
  getAllUsers,
  getUserProfileByToken,
  verifyRefferalCode,
  updateUserName,
  userOnBoardRole,
  updateTourStatus,
  //commented to remove register guest
  // RegisterGuestUser
} from "../actions/auth.actions";
import { updatePassword, updateProfile } from "../actions/profile.action";
import { encryptAndSave } from "lib/encryption";
import { USER_LOCAL } from "@/constants/user";
import { ISession } from "interfaces/auth.interface";
import { ROUTES } from "@/constants/routes";

export const initialState = {
  user: {
    data: null,
    loading: false,
    error: null,
  },
  guestUser: {
    data: null,
    loading: false,
    error: null,
  },
  access_token: null,
  session: null,
  guestSession: null,
  registerUser: {
    data: null,
    loading: false,
    error: null,
  },
  userOnBoardRole: {
    data: null,
    loading: false,
    error: null,
  },
  requestOtp: {
    data: null,
    loading: false,
    error: null,
  },
  verifyOtp: {
    data: null,
    loading: false,
    error: null,
  },
  resetPassword: {
    data: null,
    loading: false,
    error: null,
  },
  deviceInfo: {
    data: null,
    loading: false,
    error: null,
  },
  allUsers: {
    data: [],
    loading: false,
    error: null,
  },
  updateUserName: {
    data: null,
    loading: false,
    error: null,
  },
  updateTourStatus: {
    data: null,
    loading: false,
    error: null,
  },
  verifyRefferalCode: {
    data: null,
    loading: false,
    error: null,
  },
  email: {
    data: null,
    loading: false,
    error: null,
  },
  fiftyPercentOff: {
    data: null,
    loading: false,
    error: null,
  },
  refferalVerificationFailed: false,
  guest: true,
  navigateTo: null
};

export const authSlice = createSlice({
  name: "authStore",
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.access_token = action.payload;
    },
    removeAccessToken: (state) => {
      state.access_token = null;
    },
    setRegisterUser(state, action) {
      state.registerUser.data = action.payload;
    },
    setRequestOtp(state, action) {
      state.requestOtp.data = action.payload;
    },
    setUserSubscription(state, action) {
      state.user.data = action.payload;
    },
    setFiftyPercentOff(state, action) {
      state.fiftyPercentOff.data = action.payload;
    },
    resetAllUserData(state, action) {
      return initialState;
    },
    setGuestUser(state, action) {
      state.guest = action.payload
    },
  
    resetPasswordError(state, action) {
      state.user.error = null;
    },
    addEmail: (state, action) => {
      state.email.data = action.payload;
    },
    setReferringUser(state, action) {
      state.user.data = action.payload;
    },
    resetUserData: (state) => {
      state.user.data = null;
      state.user.loading = false;
      state.user.error = null;

      state.registerUser.data = null;
    },
    skipRefferalCodeEnterance: (state, action) => {},
    setNavigateTo: (state, action) => {
      state.navigateTo =action.payload
    }
  },
  extraReducers: {
    [getUserProfileByToken.pending.type]: (state, action) => {
      state.user.data = null;
      state.user.loading = true;
      state.user.error = null;
    },
    [getUserProfileByToken.fulfilled.type]: (state, action) => {
      state.user.data = action.payload.data;
      state.user.loading = false;
      state.session = action.payload.data.token;
      const sessions: ISession = {
        token: action.payload.data.token,
        userId: action.payload.data.id,
        refreshToken: action.payload.data.refreshToken,
        role: action.payload.data.role,
        loginType: action.payload.data.loginType,
      };
      encryptAndSave(USER_LOCAL.LOCAL, JSON.stringify(sessions));
      state.user.error = null;
      if (
        action.payload.data.isReferralVerified &&
        action.payload.data.userName
      ) {
        window.location.href = ROUTES.CHAT;
      }
    },
    [getUserProfileByToken.rejected.type]: (state, action) => {
      state.user.data = null;
      state.user.loading = false;
      state.user.error = action.error?.message;
    },
    [loginUser.pending.type]: (state, action) => {
      state.user.data = null;
      state.session = null;
      state.user.error = null;
      state.user.loading = true;
    },
    [loginUser.fulfilled.type]: (state, action) => {
      state.user.data = action.payload.data;
      state.session = action.payload.data.token;
      state.user.loading = false;
      const sessions: ISession = {
        token: action.payload.data.token,
        userId: action.payload.data.id,
        role: action.payload.data.role,
        loginType: action.payload.data.loginType,
        refreshToken: action.payload.data.refreshToken,
      };
      encryptAndSave(USER_LOCAL.LOCAL, JSON.stringify(sessions));
      window.location.href = ROUTES.CHAT;
      //removing guest user
      state.guestUser.data = null;
      state.guestSession = null;
      state.guestUser.error = null;
      state.guestUser.loading = false;
      localStorage.removeItem(USER_LOCAL.GUEST);
    },
    [loginUser.rejected.type]: (state, action) => {
      state.user.data = null;
      state.session = null;
      state.user.loading = false;
      state.user.error = action.error?.message;
    },
    //commented to remove register guest
    // [RegisterGuestUser.pending.type]: (state, action) => {
    //   state.guestUser.data = null;
    //   state.guestSession = null;
    //   state.guestUser.error = null;
    //   state.guestUser.loading = true;
    // },
    // [RegisterGuestUser.fulfilled.type]: (state, action) => {
    //   state.guestUser.data = action.payload.data;
    //   state.guestSession = action.payload.data.token;
    //   state.guestUser.loading = false;
    //   const sessions: ISession = {
    //     token: action.payload.data.token,
    //     userId: action.payload.data.id,
    //     role: action.payload.data.role,
    //     loginType: action.payload.data.loginType,
    //     refreshToken: action.payload.data.refreshToken,
    //   };
    //   encryptAndSave(USER_LOCAL.GUEST, JSON.stringify(sessions));
    // },
    // [RegisterGuestUser.rejected.type]: (state, action) => {
    //   state.guestUser.data = null;
    //   state.guestSession = null;
    //   state.guestUser.loading = false;
    //   state.guestUser.error = action.error?.message;
    // },
    [registerUser.pending.type]: (state, action) => {
      state.registerUser.loading = true;
      state.registerUser.error = null;
      state.registerUser.data = null;
    },
    [registerUser.fulfilled.type]: (state, action) => {

      state.registerUser.data = action.payload.data;

      state.registerUser.loading = false;
      state.registerUser.error = null;
    },
    [registerUser.rejected.type]: (state, action) => {
      state.registerUser.data = null;
      state.registerUser.loading = false;
      state.registerUser.error = action.error?.message;
    },
    [verifyOtp.pending.type]: (state, action) => {
      state.verifyOtp.data = null;
      state.verifyOtp.loading = true;
      state.verifyOtp.error = null;
    },
    [verifyOtp.fulfilled.type]: (state, action) => {
      state.verifyOtp.data = action.payload.data;
      state.verifyOtp.loading = false;
      state.verifyOtp.error = null;
      state.registerUser.data = null;
    },
    [verifyOtp.rejected.type]: (state, action) => {
      state.verifyOtp.data = null;
      state.verifyOtp.error = action.error.message;
      state.verifyOtp.loading = false;
    },
    [requestOtp.pending.type]: (state, action) => {
      state.requestOtp.loading = true;
      state.requestOtp.data = null;
      state.requestOtp.error = null;
    },
    [requestOtp.fulfilled.type]: (state, action) => {
      state.requestOtp.data = action.payload.data;
      state.requestOtp.loading = false;
      state.requestOtp.error = null;
    },
    [requestOtp.rejected.type]: (state, action) => {
      state.requestOtp.data = null;
      state.requestOtp.loading = false;
      state.requestOtp.error = action.error?.message;
    },
    [resetPassword.pending.type]: (state, action) => {
      state.resetPassword.loading = true;
      state.resetPassword.data = null;
      state.resetPassword.error = null;
    },
    [resetPassword.fulfilled.type]: (state, action) => {
      state.resetPassword.data = action.payload.data;
      state.resetPassword.loading = false;
      state.resetPassword.error = null;
    },
    [resetPassword.rejected.type]: (state, action) => {
      state.resetPassword.data = null;
      state.resetPassword.loading = false;
      state.resetPassword.error = action.error?.message;
    },
    [updateProfile.pending.type]: (state, action) => {
      state.user.loading = true;
    },
    [updateProfile.fulfilled.type]: (state, action) => {
      //@ts-ignore
      state.user.data = { ...state.user.data, ...action.payload.data };
      state.user.loading = false;
    },
    [updateProfile.rejected.type]: (state, action) => {
      state.user.loading = false;
      state.user.error = action.error?.message;
    },
    [updatePassword.pending.type]: (state, action) => {
      state.user.loading = true;
    },
    [updatePassword.fulfilled.type]: (state, action) => {
      state.user.loading = false;
    },
    [updatePassword.rejected.type]: (state, action) => {
      state.user.error = action.error?.message;
      state.user.loading = false;
    },
    [getAllUsers.pending.type]: (state, action) => {
      state.allUsers.loading = true;
    },
    [getAllUsers.fulfilled.type]: (state, action) => {
      state.allUsers.data = action.payload.data;
      state.allUsers.loading = false;
    },
    [getAllUsers.rejected.type]: (state, action) => {
      console.log("ere", action);
      state.allUsers.error = action.error?.message;
      state.allUsers.loading = false;
    },
    [verifyRefferalCode.pending.type]: (state, action) => {
      state.verifyRefferalCode.data = null;
      state.verifyRefferalCode.loading = true;
      state.verifyRefferalCode.error = null;
      state.refferalVerificationFailed = false;
    },
    [verifyRefferalCode.fulfilled.type]: (state, action) => {
      state.verifyRefferalCode.data = action.payload;
      state.verifyRefferalCode.loading = false;
      state.verifyRefferalCode.error = null;
      state.registerUser.data = null;

      //login logic applied here
      state.user.data = action.payload.data;
      state.session = action.payload.data.token;
      state.user.loading = false;

      const sessions: ISession = {
        token: action.payload.data.token,
        userId: action.payload.data.id ?? action.payload.data._id,
        role: action.payload.data.role,
        refreshToken: action.payload.data.refreshToken,
        loginType: action.payload.data.loginType,
      }
      encryptAndSave(USER_LOCAL.LOCAL, JSON.stringify(sessions));

      if(action.payload.data.userName){ //action.payload.data.isReferralVerified && 'This condition removed for skipping refferal'
        window.location.href = ROUTES.CHAT;
      }
      state.refferalVerificationFailed = false;
    },
    [verifyRefferalCode.rejected.type]: (state, action) => {
      state.verifyRefferalCode.data = null;
      state.verifyRefferalCode.error = action.error.message;
      state.verifyRefferalCode.loading = false;
      state.refferalVerificationFailed = true;
    },
    [userOnBoardRole.pending.type]: (state, action) => {
      state.userOnBoardRole.data = null;
      state.userOnBoardRole.loading = true;
      state.userOnBoardRole.error = null;
      
    },
    [userOnBoardRole.fulfilled.type]: (state, action) => {
      state.userOnBoardRole.data = action.payload;
      state.userOnBoardRole.loading = false;
      state.userOnBoardRole.error = null;
      //login logic
      // state.user.data = action.payload.data;
      // state.session = action.payload.data.token;
      // state.user.loading = false;
      // // other logic to login

      // const sessions: ISession = {
      //   token: action.payload.data.token,
      //   userId: action.payload.data.id ?? action.payload.data._id,
      //   role: action.payload.data.role,
      //   refreshToken: action.payload.data.refreshToken,
      //   loginType: action.payload.data.loginType,
      // }
      // encryptAndSave(USER_LOCAL.LOCAL, JSON.stringify(sessions));

      if(action.payload.data.userName){ //action.payload.data.isReferralVerified && 'This condition removed for skipping refferal'
        window.location.href = ROUTES.CHAT;
      }
    },
    [userOnBoardRole.rejected.type]: (state, action) => {
      state.userOnBoardRole.data = null;
      state.userOnBoardRole.loading = false;
      state.userOnBoardRole.error = action.error.message;
    },
    [updateUserName.pending.type]: (state, action) => {
      state.updateUserName.data = null;
      state.updateUserName.loading = true;
      state.updateUserName.error = null;
    },
    [updateUserName.fulfilled.type]: (state, action) => {
      state.updateUserName.data = null;
      state.updateUserName.loading = false;
      state.updateUserName.error = null;

      state.user.data = action.payload.data;
      if (
        action.payload.data.isReferralVerified &&
        action.payload.data.userName
      ) {
        window.location.href = ROUTES.CHAT;
      }
    },
    [updateUserName.rejected.type]: (state, action) => {
      state.updateUserName.data = null;
      state.updateUserName.error = action.error.message;
      state.updateUserName.loading = false;
    },

    [updateTourStatus.pending.type]: (state, action) => {
      state.updateTourStatus.data = null;
      state.updateTourStatus.loading = true;
      state.updateTourStatus.error = null;
    },
    [updateTourStatus.fulfilled.type]: (state, action) => {
      state.updateTourStatus.data = action.payload.user;
      state.updateTourStatus.loading = false;
      state.updateTourStatus.error = null;
    },
    [updateTourStatus.rejected.type]: (state, action) => {
      state.updateTourStatus.data = null;
      state.updateTourStatus.error = action.error.message;
      state.updateTourStatus.loading = false;
    },
  },
});

export const {
  addEmail,
  resetUserData,
  setRequestOtp,
  setAccessToken,
  setRegisterUser,
  resetAllUserData,
  setGuestUser,
  setReferringUser,
  removeAccessToken,
  setUserSubscription,
  resetPasswordError,
  setNavigateTo,
  setFiftyPercentOff
} = authSlice.actions;

export default authSlice.reducer;
