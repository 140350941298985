import { createAsyncThunk } from "@reduxjs/toolkit";
import { goalService } from "services/goal.service";
import { ICreateGoalData, IGetGoalDataParams, IShowGoalData, IUpdateGoalData } from "interfaces/goal.interface";

export const createGoalHandler = createAsyncThunk(
  "goalStore/createGoal",
  async (data: ICreateGoalData) => {
    try {
      const response = await goalService.store(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getGoalById = createAsyncThunk(
  "goalStore/showGoal",
  async (data: IShowGoalData) => {
    try {
      const response = await goalService.show(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateGoalById = createAsyncThunk(
  "goalStore/updateGoal",
  async (data: IUpdateGoalData) => {
    try {
      const response = await goalService.update(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const deleteGoalById = createAsyncThunk(
    "goalStore/deleteGoal",
    async (goalId: string) => {
      try {
        const response = await goalService.destroy(goalId);
        return response.data;
      } catch (error: any) {
        const { response } = error;
        throw response.data.message || response.statusText;
      }
    }
  );

  export const getAllGoals = createAsyncThunk(
    "goalStore/getAllGoals",
    async (params: IGetGoalDataParams) => {
      try {
        const response = await goalService.index(params);
        return response.data;
      } catch (error: any) {
        const { response } = error;
        throw response.data.message || response.statusText;
      }
    }
  );