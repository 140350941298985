import React from "react";
import Icon from "../Icon";
import { twMerge } from "tailwind-merge";
import { formatteDateIntoString } from "lib/DateTime";
import { capitalizeFirstLetter } from "lib/utils";
import { useDispatch } from "react-redux";
import { setSelectedGoal } from "store/slices/GoalSlice";

const GoalDetails = ({
  visible,
  className,
  addGoalSide,
  setaddGoalSide,
  setEdit,
  selectedItem,
  edit,
}: any) => {
  const dispatch = useDispatch()
  return (
    <div
      className={twMerge(
        `p-5 absolute overflow-scroll top-0 right-0 bottom-0 flex flex-col  pb-8 bg-n-1 duration-500 rounded-r-[1.25rem] border-l border-n-3 shadow-[inset_0_1.5rem_3.75rem_rgba(0,0,0,0.1)]  lg:rounded-[1.25rem] lg:invisible lg:opacity-0 lg:transition-opacity lg:z-20 lg:border-l-0 lg:shadow-2xl md:fixed  md:border-l md:rounded-none dark:bg-n-6 dark:border-n-5 ${
          visible && "lg:visible lg:opacity-100"
        } ${
          addGoalSide
            ? "w-[31.875rem] 2xl:w-[25rem] md:w-[calc(100%-4rem)] opacity-1"
            : "w-0 opacity-0"
        } ${className}`
      )}
    >
      <span
        className="absolute right-5 top-5 lg:hidden !font-mont"
        onClick={() => {
          setaddGoalSide(false);
          setEdit(false);
          dispatch(setSelectedGoal(null))
        }}
      >
        <Icon
          className="fill-n-4 transition-colors hover:fill-accent-1 cursor-pointer"
          name={"close"}
        />
      </span>

      <div className="pt-16">
        <div className="flex justify-between items-center mb-4">
          <h1 className="h4">Goal Details</h1>
          <button
            className="btn-stroke-light btn"
            onClick={() => {
              setaddGoalSide(false);
              setEdit(true);
            }}
          >
            Edit Goal
          </button>
        </div>
        <div className="pb-4 border-b dark:border-n-4/50 border-n-10 mb-5">
          <span className="text-sm font-semibold !font-mont text-n-9 mb-2 block ">
            Goal Name
          </span>
          <p className="text-lg !font-mont">{selectedItem?.name}</p>
        </div>
        <div className="pb-4 border-b dark:border-n-4/50 border-n-10 mb-5">
          <span className="text-sm  font-semibold !font-mont text-n-9 mb-2 block">
            Status
          </span>
          <p className="text-lg !font-mont">{selectedItem?.status === 'inprogress'?'In Progress': capitalizeFirstLetter(selectedItem?.status)}</p>
        </div>
        <div className="pb-4 border-b dark:border-n-4/50 border-n-10 mb-5">
          <span className="text-sm !font-mont font-semibold !font-mont text-n-9 mb-3 flex gap-2 items-center">
            End date
          </span>
          <p className="text-lg !font-mont flex gap-2 items-center">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z"
                stroke="#6C7275"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
             {formatteDateIntoString(selectedItem?.dueOnDate)}</p>
        </div>

        <div className="pb-4 border-b dark:border-n-4/50 border-n-10 mb-5">
          <span className="text-sm !font-mont font-semibold !font-mont text-n-9 mb-2 block">
            Notifications Reminder
          </span>
          <p className="text-lg !font-mont">{selectedItem?.notificationsReminder ? 'Yes': 'No'}</p>
        </div>
        {selectedItem?.notificationsReminder && <div className="pb-4 border-b dark:border-n-4/50 border-n-10 mb-5">
          <span className="text-sm !font-mont font-semibold !font-mont text-n-9 mb-2 block">
            Reminder Frequency
          </span>
          {selectedItem?.notificationsReminder && <p className="text-lg !font-mont">{selectedItem?.reminderFrequency} before</p>}
        </div>}
        {(selectedItem?.keyPoint1 || selectedItem?.keyPoint2 || selectedItem?.keyPoint3) && (
        <div className="pb-4 border-b dark:border-n-4/50 border-n-10 mb-5">
          <span className="text-sm font-semibold !font-mont text-n-9 mb-2 block">
          Key Points
          </span>
          {(selectedItem && selectedItem.keyPoint1) && (
            <p className="text-lg !font-mont">{'- '+selectedItem.keyPoint1}</p>
          )}
          {(selectedItem && selectedItem.keyPoint2) && (
            <p className="text-lg !font-mont">{'- '+selectedItem.keyPoint2}</p>
          )}
          {(selectedItem && selectedItem.keyPoint3) && (
            <p className="text-lg !font-mont">{'- '+selectedItem.keyPoint3}</p>
          )}
        </div>
        )}
      </div>
    </div>
  );
};

export default GoalDetails;
