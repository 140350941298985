import React, { useEffect, useReducer, useState } from "react";
import Icon from "../../Icon";
import Image from "next/image";
import ResponseImageSelect from "../../ResponseImageSelect";
import ImageQualitySelect from "../../ImageQualitySelect";
import ImageSizeSelect from "../../ImageSizeSelect";
import RangeInput from "../../RangeInput";
import { size } from "lodash";
import AccordionGlobal from "../../AccordionGlobal";
import { Accordion } from "react-accessible-accordion";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCreatePhotoData,
  setSidebarData,
} from "store/slices/PhotoGenerationSlice";
import toast from "react-hot-toast";
import Notify from "../../Notify";

const orientationMap = {
  "1:1": 1,
  "9:16": 2,
  "16:9": 3,
  "3:4": 4,
  "4:3": 5,
  "4:5": 7,
  "2:3": 8,
  "4:7": 9,
  "7:4": 10,
  "3:2": 11,
  "5:4": 12,
};
type OrientationKey = keyof typeof orientationMap;

const PhotoGeneration = ({ fromFeature }: any) => {
  const { t: lt } = useTranslation("translation");
  const dispatch: any = useDispatch();
  // const sidebarData = useSelector(
  //   (state: any) => state.photoGenerateStore.sidebarData
  // );
  const photoGeneratedData = useSelector(
    (state: any) => state.photoGenerateStore
  );
  const { photoGeneratedChat, sidebarData } = photoGeneratedData;

  const [image, setImage] = useState<string[]>([]);
  const [btnSelected, setbtnSelected] = useState<Number>(0);

  const onImageChange = async (event: any) => {
    const selectedFile: any = event.target.files[0];

    if (["image/png", "image/jpeg"].includes(selectedFile.type)) {
      const imageUrl: any = URL.createObjectURL(selectedFile);
      setImage([...image, imageUrl]); // Assuming you have a state variable named imageUrl to store the URL
    } else {
      toast(
        (t) => (
          <Notify iconError>
            <div className="mr-6 ml-3 h6">Unsupported Image type</div>
          </Notify>
        ),
        { duration: 3000 }
      );
    }
  };

  const getOrientation = (orientation: string): number => {
    if (orientation === "1:1" && sidebarData?.model === 2) {
      return 6;
    }
    if (orientation in orientationMap) {
      return orientationMap[orientation as OrientationKey];
    }
    return 1; // default value
  };

  useEffect(() => {
    dispatch(setSidebarData({ type: "SET_IMAGES", payload: image }));
    console.log("image", image);
  }, [image]);
  const handleDimensionsChange = (val: any) => {
    const temp = val.name.split("x");
    dispatch(setSidebarData({ type: "SET_WIDTH", payload: temp }));
  };
  const handleOrio = (val: any, wv: any) => {
    dispatch(setSidebarData({ type: "SET_ORIENTATION", payload: val }));
    const temp = wv.split("x");
    dispatch(setSidebarData({ type: "SET_WIDTH", payload: temp }));
  };
  return (
    <div className=" flex items-center h-full w-full">
      <div
        className={`overflow-y-auto w-full p-4 ${
          photoGeneratedChat.loading && "pointer-event-none opacity-60"
        }`}
      >
        <fieldset className="mb-3">
          <label className="flex  mb-4 justify-between">
            <span>
              <span
                className={`text-base font-semibold !font-mont ${
                  fromFeature && "text-black"
                }`}
              >
                {" "}
                {lt("negative_prompt_heading")}{" "}
              </span>{" "}
            </span>
          </label>
          <textarea
            placeholder={lt("write_here") + "..."}
            className={`w-full !font-mont h-20 p-2 bg-n-16 ${
              fromFeature
                ? "dark:bg-n-1"
                : "dark:bg-n-5 dark:border-n-5 dark:text-n-16 placeholder:dark:text-n-16 placeholder:text-n-4/50"
            } border-2 border-n-16 rounded-xl base2 text-n-7 outline-none transition-colors  ${
              fromFeature
                ? "focus:bg-black"
                : "focus:bg-transparent dark:focus:bg-transparent"
            } resize-none  disabled:cursor-not-allowed`}
            disabled={photoGeneratedChat.loading}
            onChange={(event) =>
              dispatch(
                setSidebarData({
                  type: event.target.name,
                  payload: event.target.value,
                })
              )
            }
            name="SET_NEGATIVE_TEXT"
            value={sidebarData.negativeText}
          ></textarea>
        </fieldset>

        <div
          className={`${photoGeneratedChat.loading && "cursor-not-allowed"}`}
        >
          <div
            className={`${photoGeneratedChat.loading && "pointer-events-none"}`}
          >
            <label className="flex  mb-4 justify-between">
              <span>
                <span
                  className={`text-base font-semibold !font-mont ${
                    fromFeature && "text-black"
                  }`}
                >
                  {" "}
                  {lt("aspect_ratio")}
                </span>{" "}
              </span>
            </label>
            <ResponseImageSelect
              disabled={photoGeneratedChat.loading}
              orientation={sidebarData.orientation}
              handleOrientationChange={(v: any, t: any) => handleOrio(v, t)}
              model={sidebarData.model}
              fromFeature={fromFeature}
            />

            <ImageSizeSelect
              width={sidebarData.imageGenerationConfig.width}
              height={sidebarData.imageGenerationConfig.height}
              orientation={getOrientation(sidebarData.orientation)}
              handleDimensionsChange={handleDimensionsChange}
              fromFeature={fromFeature}
            />
            <div
              className={`pb-4 mb-4 border-b border-n-28 ${
                !fromFeature && "dark:border-n-5"
              }`}
            >
              <div className="flex justify-between items-center mb-3">
                <label
                  className={`block text-base font-semibold !font-mont ${
                    fromFeature && "text-black"
                  }`}
                >
                  {lt("num_img")}
                </label>
                <span
                  className={`w-14 h-5 !font-mont rounded-full ${
                    fromFeature ? "text-black" : "dark:border-n-8"
                  } text-sm border border-n-29  flex justify-center items-center`}
                >
                  {sidebarData.imageGenerationConfig.numberOfImages}
                </span>
              </div>
              <RangeInput
                min={1}
                max={4}
                step={1}
                fromFeature={fromFeature}
                defaultValue={sidebarData.imageGenerationConfig.numberOfImages}
                onChange={(v: number) =>
                  dispatch(
                    setSidebarData({ type: "SET_NUMBER_OF_IMAGES", payload: v })
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoGeneration;
