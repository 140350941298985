import { HttpService } from './base.service'
import { IProfileData, ILoginData, IRegisterData, IOtpData, IResetPasswordData, IOnBoardRole,
    //commented to remove register guest
    //  IGuestRegisterData 
    } from '../interfaces/auth.interface'

class AuthService extends HttpService {
    private readonly prefix: string = "auth";

    login = (data: ILoginData): Promise<any> => this.post(this.prefix + '/login', data);
    register = (data: IRegisterData): Promise<any> => this.post(this.prefix + '/register', data);
    getUserProfileByToken = (data: IProfileData): Promise<any> => this.post(this.prefix + '/user/getProfile', data);
    verifyOtp = (data: IOtpData): Promise<any> => this.post(this.prefix + '/verify-otp', data);
    requestOtp = (data: any): Promise<any> => this.post(this.prefix + '/request-otp', data);
    resetPassword = (data: IResetPasswordData): Promise<any> => this.post(this.prefix + '/reset-password', data);
    inviteUserCount = (data: IResetPasswordData): Promise<any> => this.post(this.prefix + '/user-invites', data);

    referralDetail = (userId: IResetPasswordData): Promise<any> => this.get(this.prefix + `/user/referral-detail/${userId}`);
    userDetail = (userId: IResetPasswordData): Promise<any> => this.get(this.prefix + `/user/user-detail/${userId}`);
    verifyRefferalCode = (data: IOtpData): Promise<any> => this.post(this.prefix + '/verify-refferral', data);
    userOnBoardRole = (data: IOnBoardRole): Promise<any> => this.post(this.prefix + '/user-role', data);
    //commented to remove register guest
    // guestRegister = (data: IGuestRegisterData): Promise<any> => this.post(this.prefix + '/guestRegister', data);
}

export const authService = new AuthService();