import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";

export const ErrorToast = (message: string, duration=2000) => {
  return (
    toast(() => (
      <Notify iconDelete>
        <div className="mr-6 ml-3 h6">{message ?? "something went wrong"}</div>
      </Notify>        
  ), {duration}));
}

export const SuccessToast = (message: string, duration=2000) => {
  return (
    toast(() => (
      <Notify iconDelete>
        <div className="mr-6 ml-3 h6">{message ?? "Operation Completed successfully"}</div>
      </Notify>        
  ), {duration}));
}

export const InformationToast = (message: string, duration=2000) => {
  return (
    toast(() => (
      <Notify iconError>
        <div className="mr-6 ml-3 h6">{message ?? "something went wrong"}</div>
      </Notify>        
  ), {duration}));
}