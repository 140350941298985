import React, { useEffect, useState } from "react";
import { useColorMode } from "@chakra-ui/color-mode";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

const Calendar = ({ setDate, date, minDate, yourAudio, yourTranscription }: any) => {
  const [placeHolderDate, setPlcaeHolderDate] = useState("");
  const [calendarDate, setCalendarDate] = useState("");
  const { t } = useTranslation('auth')

  const { colorMode } = useColorMode();
  const isLightMode = colorMode === "light";

  useEffect(() => {
    setCalendarDate(date);
    setPlcaeHolderDate(date);
  }, [date]);

  const handleChange = (date: any) => {
    setDate(date);
    setPlcaeHolderDate(date);
  };

  const filterDate = (date: Date) => {
    return date <= new Date();
  };

  const placeholderText = placeHolderDate ? new Date(placeHolderDate).toDateString() : t('select_date');

  return (
    <div
      className={`date-Picker dark:!text-white bg-n-3/75 dark:bg-n-5/50 sm:w-full rounded-xl relative z-100001 ${minDate ? "custom-datepicker" : ""
        }`}
    >
      <DatePicker
        selected={calendarDate ? new Date(calendarDate) : null}
        onChange={handleChange}
        placeholderText={placeholderText}
        className="text-black dark:text-white"
        minDate={minDate}
        //@ts-ignore
        filterDate={(yourAudio || yourTranscription) ? filterDate : ""} // Set the filterDate function
        id="datePicker"
      />
      {!isLightMode ? (
        <svg
          onClick={() => {
            const datePicker = document.getElementById("datePicker");
            if (datePicker) {
              datePicker.click();
            }
          }}
          className="react-datepicker__calendar-icon cursor-pointer absolute right-4 top-1/2 -translate-y-1/2 !w-6 !h-6"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.09375 9.40234H20.9177"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.4414 13.3086H16.4504"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.0059 13.3086H12.0149"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.55859 13.3086H7.56759"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.4414 17.1953H16.4504"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.0059 17.1953H12.0149"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.55859 17.1953H7.56759"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.0449 2V5.291"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.9668 2V5.291"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.2383 3.57812H7.77096C4.83427 3.57812 3 5.21406 3 8.22115V17.2708C3 20.3252 4.83427 21.9989 7.77096 21.9989H16.229C19.175 21.9989 21 20.3535 21 17.3464V8.22115C21.0092 5.21406 19.1842 3.57812 16.2383 3.57812Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <svg
          onClick={() => {
            const datePicker = document.getElementById("datePicker");
            if (datePicker) {
              datePicker.click();
            }
          }}
          className="react-datepicker__calendar-icon cursor-pointer absolute right-4 top-1/2 -translate-y-1/2 !w-6 !h-6"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.09375 9.40234H20.9177"
            stroke="#6C7275"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.4414 13.3086H16.4504"
            stroke="#6C7275"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.0059 13.3086H12.0149"
            stroke="#6C7275"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.55859 13.3086H7.56759"
            stroke="#6C7275"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.4414 17.1953H16.4504"
            stroke="#6C7275"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.0059 17.1953H12.0149"
            stroke="#6C7275"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.55859 17.1953H7.56759"
            stroke="#6C7275"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.0449 2V5.291"
            stroke="#6C7275"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.9668 2V5.291"
            stroke="#6C7275"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.2383 3.57812H7.77096C4.83427 3.57812 3 5.21406 3 8.22115V17.2708C3 20.3252 4.83427 21.9989 7.77096 21.9989H16.229C19.175 21.9989 21 20.3535 21 17.3464V8.22115C21.0092 5.21406 19.1842 3.57812 16.2383 3.57812Z"
            stroke="#6C7275"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </div>
  );
};

export default Calendar;
