import React from 'react'
import Image from '../Image'
import { useTranslation } from 'react-i18next';

const Placeholder = () => {
  const { t } = useTranslation('translation');
  return (
    <div className='flex flex-col justify-center items-center overflow-y-auto scroll-smooth scrollbar-none '>
        <Image src={'https://lokichatdev.blob.core.windows.net/images/placeholder/placeholder.svg'} alt={'placeholder'} width={244} height={182} className='mb-4'/>
        <h4 className='h5 mb-2 text-center'>{t('start_addAns')}</h4>
        <p className='text-sm text-center max-w-[280px]'>{t('convert_notebook')}</p>
        {/* <Image src={'https://lokichatdev.blob.core.windows.net/images/loki/placeholder/arrow.svg'} alt={'placeholder'} width={138} height={259} className='-ml-[200px] grow'/> */}
        </div>
  )
}

export default Placeholder