import React, { useState } from "react";
import Image from "@/components/Image";
import Icon from "@/components/Icon";
import encodeWAV from "audiobuffer-to-wav";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import { exportImage, audioTranslate } from "@/constants/export";
import { useTranslation } from "react-i18next";

type ExportProps = {
  typeImage?: boolean;
  url?: any;
  webLink?:any;
  showCopyLink? : boolean;
};

const Export = ({ typeImage, url, webLink , showCopyLink = true }: ExportProps) => {
  const { t:At } = useTranslation('auth');
  const { t:lt } = useTranslation('translation');

  const exportAudio = audioTranslate(lt);

  const items = typeImage ? exportImage : exportAudio;



  const [copyButtonDisabled, setCopyButtonDisabled] = useState(false); // Track the copy button disabled state
  const [isExportLoading, setIsExportLoading] = useState(false);

  const handleExportAudio = async (item: any) => {
    try {
      if (item.title === ".WAV") {
        setIsExportLoading(true);
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const audioContext = new window.AudioContext();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
        // Convert the audio buffer to WAV format
        const wavBuffer = encodeWAV(audioBuffer);
  
        // Create a Blob from the WAV buffer
        const wavBlob = new Blob([new Uint8Array(wavBuffer)], {
          type: "audio/wav",
        });
  
        // Create object URL and initiate download
        const wavObjectUrl = URL.createObjectURL(wavBlob);
        const link = document.createElement("a");
        link.href = wavObjectUrl;
        link.download = "audio.wav";
        link.click();
  
        URL.revokeObjectURL(wavObjectUrl);
        setIsExportLoading(false);
      } else if (item.title === ".MP3") {
        try {
          setIsExportLoading(true);
          const response = await fetch(url);
          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);
  
          const link = document.createElement("a");
          link.href = objectUrl;
          link.download = "filename";
          link.click();
  
          URL.revokeObjectURL(objectUrl);
          setIsExportLoading(false);
        } catch (error) {
          setIsExportLoading(false);
          console.error("Error downloading audio:", error);
        }
      }
    } catch (error) {
      
    }
    
  };
  const onCopy = () => {
    if (copyButtonDisabled) {
      return; // Don't do anything if the copy button is disabled
    }

    setCopyButtonDisabled(true); // Disable the copy button

    setTimeout(() => {
      toast((t) => (
        <Notify iconCheck>
          <div className="h6 sm:text-sm">{At('link_copied')}</div>
        </Notify>
      ),{duration: 3000});
      setCopyButtonDisabled(false); // Re-enable the copy button after showing the toast
    }, 1000);
  };

  return (
    <div className="">
      {items && items.map((item: any, index) =>
        item?.title === "Export" ? (
          <div className="mt-3 border-t border-n-3 dark:border-n-6" key={index}>
            <div className="flex items-center h-8 pl-3  font-semibold text-n-4/75">
              {item.title}
            </div>
            {item?.list?.map((x: any, index: number) => (
              <button
                className="flex items-center w-full px-3 py-2 rounded-lg transition-colors hover:bg-n-2 dark:hover:bg-n-6"
                key={index}
                disabled={isExportLoading}
                onClick={() => handleExportAudio(x)}
              >
                <div className="relative shrink-0 flex items-center justify-center w-10 h-10 mr-4 rounded-full">
                  {x.image && (
                    <Image
                      className="object-contain"
                      src={x.image}
                      fill
                      alt=""
                    />
                  )}
                  {x.icon && <Icon className="dark:fill-n-1" name={x.icon} />}
                </div>
                <div className="text-left">
                  <div className="base2 font-semibold">{x.title}</div>
                  {x.details && (
                    <div className="caption2 text-n-4/75">{x.details}</div>
                  )}
                </div>
              </button>
            ))}
          </div>
        ) : (
          <>
          {
            showCopyLink ?
          <div className="mt-3 border-t border-n-3 dark:border-n-6" key={index}>
            <div className="flex items-center h-8 pl-3  font-semibold text-n-4/75">
              {item?.title}
            </div>
            {item?.list?.map((x: any, index: number) => (
              <CopyToClipboard text={webLink} onCopy={onCopy} key={index}>
                <button
                  className="flex items-center w-full px-3 py-2 rounded-lg transition-colors hover:bg-n-2 dark:hover:bg-n-6"
                  key={index}
                  onClick={() => handleExportAudio(x)}
                >
                  <div className="relative shrink-0 flex items-center justify-center w-10 h-10 mr-4 rounded-full">
                    {x.image && (
                      <Image
                        className="object-contain"
                        src={x.image}
                        fill
                        alt=""
                      />
                    )}
                    {x.icon && <Icon className="dark:fill-n-1" name={x.icon} />}
                  </div>
                  <div className="text-left">
                    <div className="base2 font-semibold">{x.title}</div>
                    {x.details && (
                      <div className="caption2 text-n-4/75">{x.details}</div>
                    )}
                  </div>
                </button>
              </CopyToClipboard>
            ))}
          </div> : <div>
            </div>
          }
          </>
        )
      )}
    </div>
  );
};

export default Export;
