import React, { useRef, useState } from "react";
import PinField from "react-pin-field";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtp, requestOtp } from "store/actions/auth.actions";
import { size } from "lodash";
import { useTranslation } from "react-i18next";
import Loader from "@/components/Loader";
import { detect } from "detect-browser";
import { verifyRefferalCode } from "store/actions/auth.actions";

type SendLinkProps = {
  setForgot?: any;
  setSelectedIndex?: any;
  setForgetSelectedIndex?: any;
  signUp?: any;
  setSignUp?: any;
  userEmail: string;
};

const SendLink = ({ signUp, userEmail }: SendLinkProps) => {
  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation("auth");

  const userData = useSelector((state: any) => state.authStore);
  const { registerUser,verifyRefferalCode: verifyingReferalCode,user } = userData;
  // console.log("verifyRefferalCode",verifyingReferalCode);
  
  const browser = detect();

  const [fullCode, setFullCode] = useState("");
const [fullCode2, setFullCode2] = useState("");
const [disable, setDisable] = useState(false);
function autoSubmitForm(pin: string) {}

  const handleConfirmCode = (event: any) => {
    event.preventDefault();
    setDisable(true);
    if (signUp) {
      const data = {
        email: registerUser.data?.email || userEmail,
        otp: Number(fullCode),
        isSignUp: true,
      };
      // setTimeout(() => {
   dispatch(verifyOtp(data));
      // let data2 = {
      //   email: registerUser?.data?.email || userEmail || userData?.requestOtp?.data?.email || user?.data?.email,
      //   appleProviderId: user?.data?.appleProviderId,
      //   gmailProviderId: user?.data?.gmailProviderId,
      //   referralCode: fullCode2,
      //   os: browser?.os,
      //   browserName: browser?.name,
      //   browserVersion: browser?.version,
      //   otp: Number(fullCode2),
      //   isSignUp: !!registerUser?.data?.email, // Assuming that if registerUser.data.email exists, it's a sign-up scenario
      // };
      // dispatch(verifyRefferalCode(data2));
      setDisable(false);
    //  }, 4000);
   
    } else {
      const data = {
        email: userData.requestOtp.data?.email,
        otp: Number(fullCode),
        isSignUp: false,
      };
      dispatch(verifyOtp(data));
      setDisable(false);
    }
  };

  const isValidOtp = () => {
    if (size(fullCode) < 4 || userData.user.loading) {
      return true;
    }
  };

  // Create a ref for the PinField component
  const pinFieldRef = useRef<any>(null);

  // Function to clear the PinField
  const clearPinField = () => {
    if (pinFieldRef.current) {
      // pinFieldRef.current.clear();
      // reset all inputs
      pinFieldRef.current.forEach((input: any) => (input.value = ""));
    }
  };

  return (
    <>
      <p className=" mb-3 h4 text-center" id="headlessui-portal-root">
        {lt("verification")}
      </p>
      <p className="text-center text-lg max-w-[370px] m-auto mb-[3.5rem]">
        {lt("click_code")}{" "}
        <span className="text-primary-1 !font-mont">
          {signUp ? registerUser.data?.email : userData.requestOtp.data?.email}
        </span>{" "}
        {lt("order_proceed")}
      </p>
      <div className="w-[17rem]  grid grid-cols-4  mx-auto my-[3.75rem] gap-4 mb-10">
        <PinField
          ref={pinFieldRef}
          type=""
          length={4}
          validate={/^[0-9]$/}
          onChange={async (pin) => {
            await setFullCode(pin);
            if (pin && pin?.length === 4) {
              autoSubmitForm(pin);
            }
          }}
          className="p-3 text-center text-2xl inline-block border dark:text-white border-gray-200 dark:bg-transparent rounded-xl outline-none"
        />
      </div>
      <button
        className="btn-blue btn-large w-full mb-6"
        type="submit"
        disabled={
          disable||
          isValidOtp() ||
          userData?.verifyOtp?.loading ||
          userData?.verifyRefferalCode?.loading
        }
        
        onClick={handleConfirmCode}
      >
        {userData?.verifyOtp?.loading ||
        userData?.verifyRefferalCode?.loading ? (
          <Loader color={"white"} />
        ) : (
          lt("confirm_code")
        )}
      </button>
      <p className="text-center">
        {lt("dont_receiveCode")} {""}
        <span
          className="text-primary-1 cursor-pointer"
          onClick={() => {
            if (signUp) {
              const data = { email: registerUser.data?.email || userEmail };
              dispatch(requestOtp(data));
            } else {
              const data = { email: userData.requestOtp.data?.email };
              dispatch(requestOtp(data));
            }
            clearPinField();
          }}
        >
          {lt("resend")}
        </span>
      </p>
    </>
  );
};

export default SendLink;
