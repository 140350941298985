import React, { useState, useEffect, useRef } from "react";
import Notify from "@/components/Notify";
import { toast } from "react-hot-toast";
import ChatEmpty from "../ChatEmpty";
import ChatItem from "../ChatItem";
import Audio from "@/components/Audio";
import Icon from "@/components/Icon";
import { BsFiletypeDoc } from "react-icons/bs";
import DraggableList from "react-draggable-list";

import {
  resetChatAndNotebooks,
  resetDeletedNotebook,
  resetUpdatedNotebook,
  setSelectedChatListItem,
  setSelectedChatModal,
  setSelectedNotebooks,
} from "store/slices/ChatGptSlice";
import {
  resetCreateAudioData,
  resetCreateAudioLoadingError,
  setFromAudioSummary,
} from "store/slices/AudioSlice";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, size } from "lodash";
import {
  deleteNotebookByUserId,
  getChatNotebooks,
} from "store/actions/notebook.actions";
import Placeholder from "@/components/Placeholder";
import { resetAllAudioData } from "store/slices/AudioSlice";
import { useRouter } from "next/router";
import { createDocument, exportArrayAsPDF } from "lib/notebooks";
import Modal from "@/components/Modal";
import NewDebateModal from "@/components/Modal/NewDebateModal";
import ChatFeedbackModal from "@/components/Modal/ChatFeedbackModal";
import { ROUTES } from "@/constants/routes";
import { updateChatFeedback } from "store/actions/chat.actions";
import { IChatFeedbackData } from "interfaces/chat.interface";
import { setDebateModal } from "store/slices/DebateSlice";
import { useTranslation } from "react-i18next";
import { AiOutlinePlus } from "react-icons/ai";
import UploadLiveNoteBookModal from "@/components/Modal/UploadLiveNoteBookModal";
import { NewDebate, NewChat } from "@/components/LandingPage/Icons";
import { CHAT_MODEL } from "@/constants/chats";

const LiveNoteBook = () => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation("translation");

  const { pathname } = router;

  const userData = useSelector((state: any) => state.authStore);
  const chatData = useSelector((state: any) => state.chatGptStore);
  const debateData = useSelector((state: any) => state.debateStore);
  const { user } = userData;

  const {
    createChat,
    allLiveNotebooks,
    deletedNotebook,
    updatedNotebook,
    selectedNotebooks,
    addToLiveNotebook,
    stream,
    updateStream,
    selectedModal,
  } = chatData;
  const {
    debateStates,
    storeDebate,
    updateDebate,
    updateDebateMessage,
    updateDebateBotMessage,
    debateInvitedUsers,
  } = debateData;

  const [clean, setClean] = useState<boolean>(false);
  const [todayAllNotebooks, setTodayAllNotebooks] = useState([]);
  const [previousDaysNotebooks, setPreviousDaysNotebooks] = useState([]);
  const [previousMonthNotebooks, setPreviousMonthNotebooks] = useState([]);
  const [previousMonthName, setPreviousMonthName] = useState("");
  const [resetCheckBox, setResetCheckBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notebookData, setNotebookData] = useState([]);
  const [abortAudioGenerate, setAbortAudioGenerate] = useState<boolean>(false);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState<boolean>(false);
  const [uploadDocument, setuploadDocument] = useState<boolean>(false);
  const [lastDetectedLanguage, setLastDetectedLanguage] = useState("");

  const initialRef: any = useRef(null);

  const today = new Date();
  const numDaysPassedInCurrentMonth = today.getDate() - 1;

  useEffect(() => {
    if (!initialRef.current && !isEmpty(createChat.data?.id)) {
      initialRef.current = true;
      const data = { chatId: createChat.data?.id };
      dispatch(getChatNotebooks(data));
    }
  }, []);

  useEffect(() => {
    if (selectedModal && selectedModal.key == "group_chat") {
      dispatch(setDebateModal(true));
      dispatch(setSelectedChatModal({ name: "Normal Chat", key: CHAT_MODEL.GEMENI_MODEL }));
    }
  }, [selectedModal]);

  useEffect(() => {
    if (createChat.loading || createChat.error) {
      dispatch(resetCreateAudioLoadingError(null));
    }
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const startOfPreviousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    const monthName = startOfPreviousMonth.toLocaleString("default", {
      month: "long",
    });
    setPreviousMonthName(monthName);
    const filterNotebooks = (notebooks: any) => {
      const isToday = (notebook: any) => {
        const notebookDate = new Date(notebook.createdAt);
        return (
          notebookDate.getDate() === currentDate.getDate() &&
          notebookDate.getMonth() === currentDate.getMonth() &&
          notebookDate.getFullYear() === currentDate.getFullYear()
        );
      };

      const isPreviousDaysOfMonth = (notebook: any) => {
        const currentDate = new Date();
        const startOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const endOfPreviousDay = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        endOfPreviousDay.setDate(currentDate.getDate() - 1);
        endOfPreviousDay.setHours(23, 59, 59);
        const notebookDate = new Date(notebook.createdAt);

        return notebookDate >= startOfMonth && notebookDate <= endOfPreviousDay;
      };

      const isPreviousMonth = (notebook: any) => {
        const currentDate = new Date();
        const notebookDate = new Date(notebook.createdAt);
        const startOfPreviousMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          1
        );
        const endOfPreviousMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          0
        );

        return (
          notebookDate >= startOfPreviousMonth &&
          notebookDate <= endOfPreviousMonth
        );
      };

      const todayAll = notebooks?.filter((notebook: any) => isToday(notebook));
      const previousDays = notebooks?.filter((notebook: any) =>
        isPreviousDaysOfMonth(notebook)
      );
      const previousMonth = notebooks?.filter((notebook: any) =>
        isPreviousMonth(notebook)
      );

      setTodayAllNotebooks(todayAll);
      setPreviousDaysNotebooks(previousDays);
      setPreviousMonthNotebooks(previousMonth);
    };

    filterNotebooks(allLiveNotebooks.data);
    setNotebookData(allLiveNotebooks.data);
  }, [allLiveNotebooks]);

  useEffect(() => {
    if (!isEmpty(deletedNotebook.data) && !deletedNotebook.loading) {
      toast((t) => {
        setTimeout(() => {
          toast.dismiss(t.id);
        }, 4000);

        return (
          <Notify iconCheck>
            <div className="h6 sm:text-sm">{lt("notebook_del")}</div>
          </Notify>
        );
      });
      setTimeout(() => {
        dispatch(resetDeletedNotebook(null));
      }, 4000);
    }
  }, [deletedNotebook]);

  useEffect(() => {
    if (!isEmpty(updatedNotebook.data) && !updatedNotebook.loading) {
      if (!isEmpty(createChat.data?.id)) {
        const data = { chatId: createChat.data.id };
        dispatch(getChatNotebooks(data));
      }
      toast((t) => {
        setTimeout(() => toast.dismiss(t.id), 3000);
        return (
          <Notify iconCheck>
            <div className="h6 sm:text-sm">{lt("notebook_update")}</div>
          </Notify>
        );
      });

      setTimeout(() => {
        dispatch(resetUpdatedNotebook(null));
      }, 4000);
    }
  }, [updatedNotebook]);

  const handleClickClear = (t: any) => {
    clearChatNotebooks(t);
  };

  const clearChatNotebooks = (t: any) => {
    const data: any = {};
    if (pathname === ROUTES.DEBATE_ID) {
      const debateId = router.query.id;
      data.debateId = debateId;
    } else {
      data.chatId = createChat.data?.id;
    }

    setLoading(true);
    setAbortAudioGenerate(true);
    toast.dismiss(t.id);
    dispatch(deleteNotebookByUserId(data));
    dispatch(resetCreateAudioData(null));
    dispatch(setSelectedNotebooks([]));
    // dispatch(resetAllAudioData(null));
    setLoading(false);
  };

  const newChatClickHandler = () => {
    if (pathname !== ROUTES.DEBATE_ID) {
      if (!isEmpty(createChat.data?.id)) {
        setFeedbackModal(true);
      } else {
        handleNewChat();
      }
    }
    if (pathname === ROUTES.DEBATE_ID) {
      setAbortAudioGenerate(true);
      dispatch(resetChatAndNotebooks(null));
      // dispatch(resetAllAudioData(null));
      dispatch(resetCreateAudioData(null));
      dispatch(setSelectedChatListItem(null));
      dispatch(setSelectedNotebooks([]));
      router.push(ROUTES.CHAT);
    }
  };

  const handleNewChat = () => {
    setAbortAudioGenerate(true);
    dispatch(resetChatAndNotebooks(null));
    // dispatch(resetAllAudioData(null));
    dispatch(resetCreateAudioData(null));
    dispatch(setSelectedChatListItem(null));
    dispatch(setSelectedNotebooks([]));
    dispatch(setSelectedChatModal(null));
    router.push(ROUTES.CHAT);
  };

  const saveChatFeedback = (feedbackComment: string, rating: number) => {
    const feedback = { comment: feedbackComment, rating };
    const data: IChatFeedbackData = { chatId: createChat.data.id, feedback };
    dispatch(updateChatFeedback(data))
      .unwrap()
      .then(() => {
        setFeedbackModal(false);
        handleNewChat();
      });
  };
  setTimeout(() => {
    setLoading(false);
  }, 1000);

  const isAllowedToNewChatOrDebate = () => {
    return (
      createChat.loading ||
      addToLiveNotebook.loading ||
      allLiveNotebooks.loading ||
      storeDebate.loading ||
      updateDebate.loading ||
      updateDebateMessage.loading ||
      updateDebateBotMessage.loading ||
      stream ||
      updateStream.loading
    );
  };

  const skipFeedbackModal = () => {
    setFeedbackModal(false);
    handleNewChat();
  };

  const handleLanguageDetection = (detectedLanguage: any) => {
    setLastDetectedLanguage(detectedLanguage);
  };

  type AddChatListProps = {
    item?: any;
    dragHandleProps?: any;
  };

  const ChatItemWrapper = ({ item, dragHandleProps }: AddChatListProps) => (
    <ChatItem
      item={item}
      resetCheckBox={resetCheckBox}
      isAudioLoading={isAudioLoading}
      setResetCheckBox={setResetCheckBox}
      lastDetectedLanguage={lastDetectedLanguage}
      onLanguageDetected={handleLanguageDetection}
      dragHandleProps={dragHandleProps}
      // setIsEditAble={setIsEditable}
    />
  );

  interface MyObject {
    id: string;
  }

  function extractIds(arr: MyObject[]): string[] {
    return arr.map((obj) => obj.id);
  }
  function sortTempByIdsOrder(ids: string[], temp: string[]): string[] {
    const indexMap: { [key: string]: number } = ids.reduce<{
      [key: string]: number;
    }>((acc, id, index) => {
      acc[id] = index;
      return acc;
    }, {});
    return temp.slice().sort((a, b) => indexMap[a] - indexMap[b]);
  }

  useEffect(() => {
    if (selectedNotebooks?.length) {
      let newIds = [
        ...extractIds(todayAllNotebooks),
        ...extractIds(previousDaysNotebooks),
        ...extractIds(previousMonthNotebooks),
      ];
      let temp = [...selectedNotebooks];
      const res = sortTempByIdsOrder(newIds, temp);
      if (JSON.stringify(temp) !== JSON.stringify(res)) {
        dispatch(setSelectedNotebooks(sortTempByIdsOrder(newIds, temp)));
      }
      dispatch(setFromAudioSummary(false));
    }
  }, [selectedNotebooks]);

  const handleRearrange = (newList: any, type: string) => {
    type === "today"
      ? setTodayAllNotebooks(newList)
      : type === "prevDays"
      ? setPreviousDaysNotebooks(newList)
      : setPreviousMonthNotebooks(newList);
    if (selectedNotebooks?.length && selectedNotebooks?.length > 1) {
      let ids = [...selectedNotebooks];
      ids.sort((a: string, b: string) => {
        // Find the index of a and b in newList
        let indexA = newList.findIndex((obj: any) => obj.id === a);
        let indexB = newList.findIndex((obj: any) => obj.id === b);
        return indexA - indexB;
      });
      dispatch(setSelectedNotebooks(ids));
    }
  };

  const isDebateUser = debateInvitedUsers?.find(
    (el: any) => el?._id === user?.data?.id
  );
  const isDebateAdmin = storeDebate.data?.user?._id === user.data?.id;
  const isDebateArchived = storeDebate.data?.isArchived;

  return (
    <>
      <div className="flex items-center sm:flex-col sm:gap-3 sm:mb-2 justify-between px-9 md:px-6">
        <div className="flex gap-3 items-center">
          <span className="base2 font-semibold !font-mont">
            {lt("speech_creator")}
          </span>
          <div className="px-2 bg-n-3 rounded-lg caption1  dark:bg-n-5/50">
            {size(notebookData)}
          </div>
        </div>
        {!(
          ((!isDebateAdmin && !isDebateUser) || isDebateArchived) &&
          router.pathname === ROUTES.DEBATE_ID
        ) && (
          <div className="flex gap-2 items-center ">
            <div
              onClick={() => {
                setuploadDocument(true);
              }}
              className="relative rounded-lg cursor-pointer w-8 h-8 flex items-center justify-center bg-primary-1 hover:opacity-80 text-white text-lg"
              id="step4"
            >
              <AiOutlinePlus className="text-white" />
            </div>
            <button
              disabled={selectedNotebooks?.length === 0}
              onClick={() =>
                exportArrayAsPDF(selectedNotebooks, false, allLiveNotebooks)
              }
              className={`${
                selectedNotebooks?.length === 0 ? "" : "group"
              }  relative pt-2 pb-1.5 pl-2.5 pr-1.5 flex justify-center items-center bg-n-3 hover:bg-slate-200 hover:text-primary-1 rounded-lg caption1 text-n-4 dark:bg-n-5/50 text-sm`}
            >
              <Icon
                className={`${
                  selectedNotebooks?.length === 0
                    ? "cursor-not-allowed opacity-30"
                    : ""
                } w-5 h-5 !p-0 fill-red-500 transition-colors group-hover:fill-accent-1 flex justify-center items-center`}
                name="pdf"
              />
              <div className="absolute w-[8rem] top-1/2 -translate-y-1/2 right-full mr-1 px-2 py-1.5 rounded-lg bg-white border border-n-3  caption1 text-n-4 invisible opacity-0 transition-opacity pointer-events-none lg:hidden  group-hover:opacity-100 group-hover:visible">
                {lt("export_PDF")}
              </div>
            </button>
            <button
              disabled={selectedNotebooks?.length === 0}
              onClick={() =>
                createDocument(selectedNotebooks, allLiveNotebooks)
              }
              className={`${
                selectedNotebooks?.length === 0 ? "" : "group"
              } relative pt-2 pb-1.5 pl-2.5 pr-1.5 flex justify-center items-center bg-n-3 hover:bg-slate-200 hover:text-primary-1 rounded-lg caption1 text-n-4 dark:bg-n-5/50 text-sm`}
            >
              <span
                className={`${
                  selectedNotebooks?.length === 0
                    ? "cursor-not-allowed  opacity-30"
                    : ""
                } w-5 h-5 flex justify-center items-center text-lg text-red-500 !font-mont`}
              >
                <BsFiletypeDoc />
              </span>
              <div className="absolute w-[8rem] top-1/2 -translate-y-1/2 right-full mr-1 px-2 py-1.5 rounded-lg bg-white border border-n-3  caption1 text-n-4 invisible opacity-0 transition-opacity pointer-events-none lg:hidden  group-hover:opacity-100 group-hover:visible">
                {lt("export_doc")}
              </div>
            </button>
            {!clean && (
              <button
                disabled={loading}
                className="group relative py-2 px-3 bg-n-3 hover:bg-slate-200 rounded-lg caption1 text-n-4 hover:text-primary-1 dark:hover:text-primary-1 dark:text-white dark:bg-n-5/50 dark:hover:bg-slate-800 text-sm"
                onClick={() => {
                  setLoading(true);
                  toast(
                    (t) => (
                      <Notify
                        className="md:flex-col md:items-center md:px-10"
                        iconDelete
                      >
                        <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">
                          {lt("clear_notebooks")}
                        </div>
                        <div className="flex justify-center">
                          <button
                            className="btn-stroke-light btn-medium md:min-w-[5rem]"
                            onClick={() => {
                              toast.dismiss(t.id);
                              setLoading(false);
                            }}
                          >
                            {lt("cancel_btn")}
                          </button>
                          <button
                            className="btn-blue btn-medium ml-3 md:min-w-[5rem]"
                            onClick={() => {
                              handleClickClear(t);
                              setLoading(false);
                            }}
                          >
                            {lt("yes")}
                          </button>
                        </div>
                      </Notify>
                    ),
                    { duration: Infinity }
                  );
                }}
              >
                {lt("clear")}
              </button>
            )}
          </div>
        )}
      </div>
      {notebookData ? (
        <>
          <div className="flex grow min-h-[60vh] flex-col justify-between overflow-y-auto scroll-smooth scrollbar-none">
            <div className="overflow-y-auto min-h-[10rem] scroll-smooth px-6 md:px-3 mt-2 scrollbar-none mb-5">
              <div className="mb-3">
                {clean ? (
                  <ChatEmpty />
                ) : (
                  <DraggableList
                    itemKey="id"
                    template={ChatItemWrapper as any}
                    list={todayAllNotebooks}
                    onMoveEnd={(list: any) => handleRearrange(list, "today")}
                    container={() => document.body}
                  />
                )}
              </div>
              <div className="mb-3">
                {!clean && size(previousDaysNotebooks) > 0 && (
                  <span className="base2 !font-mont text-n-4/75 font-semibold">
                    {lt("previous")} {numDaysPassedInCurrentMonth} {lt("days")}
                  </span>
                )}
                {clean ? (
                  <ChatEmpty />
                ) : (
                  <DraggableList
                    itemKey="id"
                    template={ChatItemWrapper as any}
                    list={previousDaysNotebooks}
                    onMoveEnd={(list: any) => handleRearrange(list, "prevDays")}
                    container={() => document.body}
                  />
                )}
              </div>
              <div className="">
                {!clean && size(previousMonthNotebooks) > 0 && (
                  <span className="base2 !font-mont text-n-4/75 font-semibold">
                    {previousMonthName}
                  </span>
                )}
                {clean ? (
                  <ChatEmpty />
                ) : (
                  <DraggableList
                    itemKey="id"
                    template={ChatItemWrapper as any}
                    list={previousMonthNotebooks}
                    onMoveEnd={(list: any) =>
                      handleRearrange(list, "prevMonth")
                    }
                    container={() => document.body}
                  />
                )}
              </div>
            </div>
            <div className="px-6 md:px-3 relative">
              <Audio
                abortGeneration={abortAudioGenerate}
                setAbortGeneration={setAbortAudioGenerate}
                setIsAudioLoading={(audioState: boolean) =>
                  setIsAudioLoading(audioState)
                }
              />
              <div>
                <div className="gap-2 mt-6 grid grid-cols-2 sm:grid-cols-1">
                  <button
                    disabled={isAllowedToNewChatOrDebate()}
                    className="btn-blue flex justify-center whitespace-nowrap"
                    onClick={newChatClickHandler}
                  >
                    <NewChat />
                    {/* <Icon name="plus" /> */}
                    {lt("new_chat")}
                  </button>
                  <button
                    className="btn-blue flex justify-center !pr-8 !bg-accent-3 !border-accent-3 hover:opacity-90 whitespace-nowrap"
                    onClick={() => dispatch(setDebateModal(true))}
                    disabled={isAllowedToNewChatOrDebate()}
                    id="step6"
                  >
                    <NewDebate />
                    {/* <Icon name="plus" className="flex-shrink-0" /> */}
                    {lt("new_debate")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Placeholder />
          <div className="gap-2 mt-6 grid grid-cols-2 sm:grid-cols-1 px-5">
            <button
              disabled={isAllowedToNewChatOrDebate()}
              className="btn-blue flex justify-center whitespace-nowrap"
              onClick={newChatClickHandler}
            >
              <Icon name="plus" />
              {lt("new_chat")}
            </button>
            <button
              className="btn-blue flex justify-center !pr-8 !bg-accent-3 !border-accent-3 hover:opacity-90 whitespace-nowrap"
              onClick={() => {
                dispatch(setDebateModal(true));
              }}
              disabled={isAllowedToNewChatOrDebate()}
            >
              <Icon name="plus" className="flex-shrink-0" />
              {lt("new_debate")}
            </button>
          </div>
        </>
      )}

      <Modal
        className=""
        classWrap="max-w-[640px] md:min-h-screen-ios !p-9 !pt-6"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={debateStates.debateModal}
        onClose={() => dispatch(setDebateModal(false))}
      >
        <NewDebateModal
          onCancel={() => dispatch(setDebateModal(false))}
          setVisibleModal={(value: any) => dispatch(setDebateModal(value))}
        />
      </Modal>
      <Modal
        className=""
        classWrap="max-w-[469px] md:min-h-screen-ios !p-6"
        classButtonClose="absolute top-5 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1 hidden"
        visible={feedbackModal}
        onClose={() => setFeedbackModal(false)}
      >
        <ChatFeedbackModal
          onSkip={skipFeedbackModal}
          onSave={(feedback: string, rating: number) =>
            saveChatFeedback(feedback, rating)
          }
        />
      </Modal>
      <Modal
        className="d-flex align-items-center justify-content-center overflow-auto"
        classOverlay=""
        classWrap="max-w-[640px] md:min-h-screen-ios !p-9 max-h-[75vh] overflow-a !pr-0 relative"
        classButtonClose="absolute top-3 right-3 rounded-full dark:fill-n-4 dark:hover:fill-n-1"
        visible={uploadDocument}
        onClose={() => {
          setuploadDocument(false);
        }}
        nonClosed
      >
        <div className="overflow-y-auto max-h-[calc(75vh-72px)] pr-8">
          <UploadLiveNoteBookModal setuploadDocument={setuploadDocument} />
        </div>
      </Modal>
    </>
  );
};

export default LiveNoteBook;
