import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatListService } from "services/chatList.service";
import { IExportChatData, IStoreChatListData } from "interfaces/chat.interface";

export const storeChatList = createAsyncThunk(
  "chatgptStore/storeChatList",
  async (data: IStoreChatListData) => {
    try {
      const response = await chatListService.store(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const editChatList = createAsyncThunk(
  "chatgptStore/storeChatList",
  async (params: any) => {
    try {
      const response = await chatListService.update(params);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getStoredChatList = createAsyncThunk(
  "chatgptStore/getStoredChatList",
  async () => {
    try {
      const response = await chatListService.index();
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getChatListById = createAsyncThunk(
  "chatgptStore/getChatListById",
  async (params: any) => {
    try {
      const response = await chatListService.show(params);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const exportChatLists = createAsyncThunk(
  "chatgptStore/exportChatLists",
  async (data: IExportChatData) => {
    try {
      const response = await chatListService.export(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const destroyChatList = createAsyncThunk(
  "chatgptStore/destroyChatList",
  async (id: any) => {
    try {
      const response = await chatListService.destroy(id);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
export const deleteChatList = createAsyncThunk(
  "chatgptStore/destroyChatList",
  async (ids: any) => {
    try {
      const response = await chatListService.deleteMultiple(ids);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);