import React, { useState } from "react";
import Icon from "@/components/Icon";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { setDebateModal } from "store/slices/DebateSlice";
import { useTranslation } from "react-i18next";
import Field from "../Field";
import { BiErrorCircle } from "react-icons/bi";
import Notify from "../Notify";
import toast from "react-hot-toast";
import axios from "axios";
import { ImSpinner9 } from "react-icons/im";
import CopyToClipboard from "react-copy-to-clipboard";
import { Keyboard } from "../LandingPage/Icons";
import { addPromptQuestion, resetChatAndNotebooks, setSelectedChatListItem, setSelectedChatModal, setSelectedNotebooks } from "store/slices/ChatGptSlice";
import { removeHeadings , getCopyWriter } from "lib/utils";
import { useMediaQuery } from "react-responsive";
import { CHAT_MODEL } from "@/constants/chats";
import { resetCreateAudioData } from "store/slices/AudioSlice";


type MenuType = {
  title: string;
  icon: string;
  color: string;
  url: string;
};

type MenuProps = {
  className?: string;
  items: MenuType[];
  selectedChatModel?: any;
  onClose?: any;
};

interface IInitialErrors {
  text?: string;
  results?: string;
}

const initialErrors: IInitialErrors = {
  text: "",
  results: ""
};

const Menu = ({ className, items, selectedChatModel, onClose }: MenuProps) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation("translation");
  const [errors, setErrors] = useState<IInitialErrors>(initialErrors);

  const [text, setText] = useState<string>("");
  const [results, setResults] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const debateData = useSelector((state: any) => state.debateStore);
  const { storeDebate } = debateData;

  
  
  // Example usage
  const originalText = `Your text here with various headings like Heading 1: or Heading2: that you want to remove.`;
  const cleanedText =  removeHeadings(originalText);
  console.log(cleanedText);

  const handleButtonClick = (item: any) => {
    try {
      if (item.url === ROUTES.DEBATE) {
        console.log("storeDebate", storeDebate);
        if (!isEmpty(storeDebate.data?.id)) {
          router.push(`${ROUTES.DEBATE}/${storeDebate.data.id}`);
        } else {
          dispatch(setDebateModal(true));
        }
      } else {
        router.push(item.url);
      }
    } catch (error) {}
  };

  const copyWriterHandler = async () => {
    // e.eventPreventdefault();
    if (!text) {
      setErrors({ ...errors, text: "Please Enter Valid Text" });
      return;
    }
    setLoading(true);
    try{
      const res = await getCopyWriter(text);
      if (res.status == 200) {
        let cleanResults = removeHeadings(res.data.result)
        setResults(cleanResults);
        setLoading(false);
        toast(
          (t) => (
            <Notify iconCheck>
              <div className="h6">Response from Copywriter!</div>
            </Notify>
          ),
          { duration: 5000 }
        );
      } else {
        toast.error("Failed to get the response");
        setLoading(false);
      }
    }catch(err){
      toast.error("Error in geeting response response");
      setLoading(false);
    }
  
  };

  const onCopy = () => {
    setTimeout(() => {
      toast(
        (toast) => (
          <Notify iconCheck>
            <div className="h6 sm:text-sm">{"Copy Writer Result Copied"} </div>
          </Notify>
        ),
        { duration: 3000 }
      );
    }, 1000);
  };

  const dispatchItemToRedux = (question: any) => {
    dispatch(addPromptQuestion(question));
};

  const keyboardClickHandler = (it: any) => {
    dispatch(resetChatAndNotebooks(null));
    // dispatch(resetAllAudioData(null));
    dispatch(resetCreateAudioData(null));
    dispatch(setSelectedChatListItem(null));
    dispatch(setSelectedNotebooks([]));
    router.push(ROUTES.CHAT);
    onClose && onClose();
    dispatchItemToRedux(it);
    dispatch(setSelectedChatModal({ name: "Copywriter", key: CHAT_MODEL.COPY_WRITER, current: false }));
}

  return (
    <div
      className={`${className}  ${
        selectedChatModel?.name == "Copywriter" &&
      `flex items-center justify-center h-[90%]`
      }`}
    >
      {selectedChatModel?.name == "Copywriter" ? (
        <form
          className="flex w-full flex-col h-full "
          action=""
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="flex w-full flex-col h-full ">
            <div className="flex flex-row w-full gap-x-4 h-full" >

              <div className="mb-4 w-[50%]">
                <label className="!font-semibold !font-mont text-md mb-4 block text-black dark:text-white">
                  {t('type__text_here')} :
              </label>
              <Field
                textarea
                border={false}
                  height="h-full"
                  className="mb-1 !bg-white !focus:bg-white dark:!bg-[#343839]  focus:dark:!bg-[#343839]   !border-white !rounded-xl h-[82%] "
                  classInput={`!bg-white !focus:bg-white dark:!text-white bg-n-3/75 dark:bg-[#343839] text-[#6C7275] dark:text-white ${
                  errors.text ? "border-red-500" : ""
                }`}
                placeholder={"Enter Text"}
                type="text"
                value={text}
                onChange={(e: any) => {
                  const newValue = e.target.value;
                  // if (newValue.length <= 25) {
                  setText(newValue);
                  setErrors({ ...errors, text: "" });
                  // }
                  // else {
                  //   setErrors({ ...errors, text: "character_valid" });
                  // }
                }}
                // required
              />
              {errors.text && (
                <div className="text-red-500 text-xs mt-1 flex items-center gap-1">
                  {" "}
                  <BiErrorCircle className="text-base" /> {errors.text}
                </div>
              )}
            </div>
            <div className="mb-4 w-[50%]">
                <label className="!font-semibold text-md mb-4 block ml-1 !font-mont">
                  {t('find_result')}
              </label>
              <Field
                  disabled
                textarea
                border={false}
                  height="h-full"
                  className="mb-1 !bg-white !focus:bg-white dark:!bg-[#343839]  focus:dark:!bg-[#343839]   !border-white !rounded-xl h-[82%]"
                  classInput={`!bg-white !focus:bg-white dark:!text-white bg-n-3/75 dark:bg-[#343839] text-[#6C7275] dark:text-white ${
                  errors.text ? "border-red-500" : ""
                }`}
                placeholder={"Results...."}
                type="text"
                value={results}
                  onChange={() => null}
                // required
              />
              {errors.results && (
                <div className="text-red-500 text-xs mt-1 flex items-center gap-1">
                  {" "}
                  <BiErrorCircle className="text-base" /> {errors.results}
                </div>
              )}
              <div className="flex gap-3 mt-4 md:gap-1.5 flex-shrink-0 items-center justify-end l2xl:mt-5">
                <CopyToClipboard text={results} onCopy={onCopy}>
                  <button className="" 
                  // ref={copyButtonRef}
                   type="button">
                    <Icon
                      name={"Copy"}
                      className="fill-n-5 dark:fill-n-2 w-7 h-7 md:w-5 md:h-5"
                    />
                  </button>
                </CopyToClipboard>
                  <button
                  onClick={() => keyboardClickHandler(results)}
                    disabled={!results?.length}
                    className={`w-7 h-7 md:w-6 md:h-6 ${!results?.length ? "bg-neutral-500" : "bg-primary-1"} flex justify-center items-center rounded-md cursor-pointer`}
                >
                  <Keyboard />
                  </button>
              </div>
              {/* <CopyToClipboard
              text={results}
              onCopy={onCopy}
            ></CopyToClipboard> */}
            </div>
            </div>

            <div className="mt-4 flex gap-4 mb-5">
              <button
                onClick={() => copyWriterHandler()}
                className="btn-blue w-full"
                disabled={loading}
              >
                {loading && <ImSpinner9 className="animate-spin" />}
                {t('enhance_copywriter')}
              </button>
            </div>
          </div>
        </form>
      ) : (
        items &&
        items.map((item, index) => (
          <>
            {
              <button
                className="cursor-pointer group w-[230px] sm:w-full  flex gap-3 items-center pl-2 py-2 pr-4 text-base border border-n-3 bg-white dark:bg-n-5/50 rounded-full h6 transition-all hover:border-transparent hover:shadow-[0_0_1rem_0.25rem_rgba(0,0,0,0.04),0px_2rem_1.5rem_-1rem_rgba(0,0,0,0.12)] last:mb-0 2xl:p-2.5 lg:p-3.5 dark:border-n-5 dark:hover:border-n-7 dark:hover:bg-n-5/50"
                key={index}
                onClick={() => handleButtonClick(item)}
              >
                <div className="relative flex justify-center items-center w-10 h-10 shrink-0">
                  <div
                    className="absolute inset-0 opacity-20 rounded-full"
                    style={{ backgroundColor: item.color }}
                  />
                  <Icon
                    className="relative z-1"
                    fill={item.color}
                    name={item.icon}
                  />
                </div>
                <span className="truncate !font-mont">
                  {item.url === ROUTES.DEBATE && !isEmpty(storeDebate.data?.id)
                    ? t("continue_debate")
                    : item.title}
                </span>
              </button>
            }
          </>
        ))
      )}
    </div>
  );
};

export default Menu;
