import React from 'react';
import Link from "next/link";
import Image from "@/components/Image";
import { useRouter } from 'next/router';
import { ROUTES } from '@/constants/routes';

type TestProps = {
    className?: string;
    dark?: boolean;
};

const Test = ({ className, dark }: TestProps) => {
    const router = useRouter();
    const { pathname } = router;

    const isSignInPage = () => pathname === ROUTES.SIGNIN;

    return (
        <Link className={`flex w-[10rem] ${className}`} href={isSignInPage() ? ROUTES.HOME : ROUTES.CHAT}>
        <Image
            className="w-full h-auto"
            src={dark ? "https://lokichatdev.blob.core.windows.net/images/loki/logow.svg" : "https://lokichatdev.blob.core.windows.net/images/loki/logo.svg"}
            width={190}
            height={40}
            alt="Brainwave"
        />
    </Link>
    )
};

export default Test;
