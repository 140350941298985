import { Listbox, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import Icon from "@/components/Icon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StudioVoices } from "@/constants/studioVoices";

type SelectProps = {
  label?: string;
  title?: string;
  icon?: string;
  className?: string;
  classButton?: string;
  classArrow?: string;
  classOptions?: string;
  classOption?: string;
  classIcon?: string;
  placeholder?: string;
  items: any;
  value: any;
  onChange: any;
  small?: boolean;
  up?: boolean;
  disabled?: boolean;
  voice?: boolean;
  PreIcon?: any;
  IconComponent?: any;
  arrowColor?: string;
  fromFeature?: any;
};

const Select = ({
  label,
  title,
  icon,
  className,
  classButton,
  classArrow,
  classOptions,
  classOption,
  classIcon,
  placeholder,
  items,
  value,
  onChange,
  small,
  up,
  disabled,
  voice,
  PreIcon,
  IconComponent,
  arrowColor,
  fromFeature
}: SelectProps) => {
  const [audio] = useState<any>(new Audio());
  const [isPlaying, setIsPlaying] = useState<{ [key: string]: boolean }>({});
  const { t } = useTranslation("translation");

  const playAudio = (item: any) => {
    const voiceUrl = StudioVoices.includes(item.name)
      ? `https://storage.cloud.google.com/loki-text-speech/${item.name}.wav`
      : `https://cloud.google.com/static/text-to-speech/docs/audio/${item.name}.wav`;
    audio.src = voiceUrl;
    // Stop the currently playing audio
    Object.keys(isPlaying).forEach((key) => {
      if (isPlaying[key]) {
        audio.pause();
        setIsPlaying((prevIsPlaying) => ({ ...prevIsPlaying, [key]: false }));
      }
    });

    // Play the new audio
    let playing = false;
    if (isPlaying[item.name]) {
      audio.pause();
      playing = false;
    } else {
      audio.play();
      playing = true;
    }

    setIsPlaying((prevIsPlaying) => ({
      ...prevIsPlaying,
      [item.name]: playing,
    }));
    audio.onended = () => {
      setIsPlaying((prevIsPlaying) => ({
        ...prevIsPlaying,
        [item.name]: false,
      }));
    };
  };

  return (
    <div
      className={`relative ${className} ${
        disabled == true ? "opacity-50" : "opacity-100"
      }`}
    >
      {label && <div className="flex mb-2 base2 font-semibold">{label}</div>}
      <Listbox
        disabled={disabled == true ? true : false}
        value={value}
        onChange={onChange}
      >
        {({ open }) => (
          <>
            <Listbox.Button
              className={twMerge(
                `flex items-center w-full h-[3.25rem]  px-4 rounded-xl bg-n-1 base2 outline-none tap-highlight-color ${
                  small
                    ? `h-9  rounded-md shadow-[0_0.125rem_0.25rem_rgba(0,0,0,0.15)] dark:shadow-[0_0.125rem_0.25rem_rgba(0,0,0,0.15),inset_0_0_0_0.0625rem_rgba(254,254,254,.1)] dark:bg-n-6 ${
                        open && "shadow-[0_0.125rem_0.25rem_rgba(0,0,0,0.15)]"
                      }`
                    : `shadow-[inset_0_0_0_0.0625rem_#E8ECEF] ${!fromFeature && "dark:shadow-[inset_0_0_0_0.0625rem_#343839]"} dark:bg-transparent ${
                        open && "!shadow-[inset_0_0_0_0.125rem_#0084FF]"
                      }`
                } ${classButton}`
              )}
            >
              {PreIcon && <PreIcon />}
              {title && (
                <div className="shrink-0 mr-2 pr-2 !font-mont border-r border-n-3 dark:border-n-4/50 text-n-4 ">
                  {title}
                </div>
              )}
              {IconComponent ? (
                <IconComponent />
              ) : (
                icon && (
                  <Icon
                    className={`shrink-0 mr-2 dark:fill-n-4 ${
                      small && "w-5 h-5 mr-1.5"
                    } ${classIcon}`}
                    name={icon}
                  />
                )
              )}
              {value?.color && (
                <div
                  className="shrink-0 w-3.5 h-3.5 ml-1 mr-4 rounded"
                  style={{ backgroundColor: value.color }}
                ></div>
              )}
              {value?.icon && (
                <Icon
                  className="w-5 h-5 mr-3 dark:fill-n-1"
                  name={value.icon}
                />
              )}
              <span
                className={`mr-auto truncate !font-mont ${
                  small && "font-semibold"
                }`}
              >
                {value ? (
                  t(value.title)
                ) : (
                  <span className="text-n-4 !font-mont ">{placeholder}</span>
                )}
              </span>
              <Icon
                className={`shrink-0 ml-2 transition-transform dark:fill-${
                  arrowColor ? arrowColor : "n-1"
                }  ${open && "rotate-180"} ${small && "ml-1"} ${classArrow}`}
                name="arrow-down"
              />
            </Listbox.Button>
            <Transition
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={twMerge(
                  `absolute left-0 right-0 w-full mt-2 p-2 max-h-[200px] AtScroll overflow-auto scrollbar-track-primary-1 bg-n-1 rounded-lg shadow-[0_0_1rem_0.25rem_rgba(0,0,0,0.04),0_2rem_2rem_-1.5rem_rgba(0,0,0,0.1),inset_0_0_0_0.0625rem_#E8ECEF] outline-none  ${!fromFeature && "dark:bg-n-6 dark:shadow-[0_0_1rem_0.25rem_rgba(0,0,0,0.04),0_2rem_2rem_-1.5rem_rgba(0,0,0,0.1),inset_0_0_0_0.0625rem_#343839]"} ${
                    small && "right-auto mt-1 shadow-md"
                  } ${
                    up && `top-auto bottom-full mt-0 ${small ? "mb-1" : "mb-2"}`
                  } ${open && "z-10"} ${classOptions}`
                )}
              >
                {items?.length > 0 &&
                  items?.map((item: any) => (
                    <Listbox.Option
                      className={`flex items-start p-2 rounded-lg base2 text-n-4 transition-colors cursor-pointer hover:text-n-7 ui-selected:!bg-n-3/50 ui-selected:!text-n-7 tap-highlight-color ${!fromFeature && "dark:hover:text-n-3 dark:ui-selected:!bg-n-7 dark:ui-selected:!text-n-1"} ${
                        small && "py-1 font-semibold"
                      } ${classOption} ${
                        item &&
                        item?.id === "delete-account" &&
                        "!text-accent-1"
                      }`}
                      key={item && item?.id}
                      value={item}
                    >
                      {item && item?.color && (
                        <div
                          className="shrink-0 w-3.5 h-3.5 mt-[0.3125rem] ml-1 mr-4 rounded"
                          style={{
                            backgroundColor: item.color,
                          }}
                        ></div>
                      )}
                      {item && item?.icon && (
                        <Icon
                          className={`w-5 h-5 mt-0.5 mr-3 dark:fill-n-1 ${
                            item &&
                            item?.id === "delete-account" &&
                            "!fill-accent-1"
                          }`}
                          name={item && item?.icon}
                        />
                      )}
                      <div
                        className={`${
                          voice ? "flex justify-between w-full" : "mr-auto"
                        } truncate`}
                      >
                        {t(item?.title)}
                        {voice && (
                          <a
                            className="ml-auto"
                            href={`https://cloud.google.com/static/text-to-speech/docs/audio/${item?.name}.wav`}
                            onClick={(e) => {
                              e.preventDefault();
                              playAudio(item);
                            }}
                          >
                            <Icon
                              className="w-5 h-5 ml-2 mt-0.5 fill-n-7 dark:fill-n-1"
                              name={
                                isPlaying[item?.name]
                                  ? "pause-circle"
                                  : "volume"
                              }
                            />
                          </a>
                        )}
                      </div>
                      {!small && (
                        <Icon
                          className="hidden w-5 h-5 ml-2 mt-0.5 fill-n-7 ui-selected:inline-block dark:fill-n-1"
                          name="check-thin"
                        />
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default Select;
