import React, { useEffect, useState } from "react";
import ResponseImageSelect from "../../ResponseImageSelect";
import ImageSizeSelect from "../../ImageSizeSelect";
import RangeInput from "../../RangeInput";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCreatePhotoData,
  setSidebarData,
  seteditGeneratedImageScreen,
} from "store/slices/PhotoGenerationSlice";
import UploadImage from "@/components/UploadImage/UploadImage";
import {
  AddObj,
  BackgroundIcon,
  BrushIcon,
  ForegroundIcon,
  OutPaintIcon,
  RemoveObj,
} from "@/components/SVGs";

const EditImageScreen = ({ fromFeature }: any) => {
  const { t: lt } = useTranslation("translation");
  const dispatch: any = useDispatch();
  const photoGeneratedData = useSelector(
    (state: any) => state.photoGenerateStore
  );
  const { photoGeneratedChat, sidebarData } = photoGeneratedData;

  const [image, setImage] = useState<any>({
    file: "",
  });

  const handleDimensionsChange = (val: any) => {
    const temp = val.name.split("x");
    dispatch(setSidebarData({ type: "SET_WIDTH", payload: temp }));
  };
  const handleOrio = (val: any, wv: any) => {
    dispatch(setSidebarData({ type: "SET_ORIENTATION", payload: val }));
    const temp = wv.split("x");
    dispatch(setSidebarData({ type: "SET_WIDTH", payload: temp }));
  };

  const EditButtons = [
    {
      title: lt("modify_foreground"),
      value: "edit-foreground",
      bgColor: "bg-[#DAF8FF] dark:bg-[#80D1FF]",
      onClick: () => null,
      IconComponent: () => <ForegroundIcon />,
    },
    {
      title: lt("modify_background"),
      value: "edit-background",
      bgColor: "bg-[#EFF9EA] dark:bg-[#C9FDAF]",
      onClick: () => null,
      IconComponent: () => <BackgroundIcon />,
    },
    {
      title: lt("remove_object"),
      value: "edit-objects",
      bgColor: "bg-[#FFE8D6] dark:bg-[#FFD8BA]",
      onClick: () => null,
      IconComponent: () => <RemoveObj />,
    },
    {
      title: lt("add_object"),
      value: "add-object",
      bgColor: "bg-[#EBE7FE] dark:bg-[#C0B3FF]",
      onClick: () => null,
      IconComponent: () => <AddObj />,
    },
    {
      title: lt("outpaint"),
      value: "edit-outpaint",
      bgColor: "bg-[#E5E4E4] dark:bg-[#DACFCF]",
      onClick: () => null,
      IconComponent: () => <OutPaintIcon />,
    },
    {
      title: lt("brush"),
      value: "brush",
      bgColor: "bg-[#FFF2C3] dark:bg-[#FFE68D]",
      onClick: () => null,
      IconComponent: () => <BrushIcon />,
    },
  ];

  const onEditTypeSelect = (value: String) => {
    dispatch(setSidebarData({ type: "SET_EDIT_TYPE", payload: value }));
  };

  useEffect(() => {
    if (image?.file) {
      dispatch(
        setSidebarData({ type: "SET_EDIT_IMAGE", payload: image?.file })
      );
      dispatch(seteditGeneratedImageScreen(null));
      dispatch(resetCreatePhotoData({}));
    }
  }, [image?.file]);

  return (
    <div
      style={{ paddingTop: "40%" }}
      className={`flex items-center h-full w-full overflow-auto overflow-x-hidden`}
    >
      <div
        className={`w-full  px-4 ${
          photoGeneratedChat.loading && "pointer-event-none opacity-60"
        }`}
      >
        <UploadImage
          state={image}
          setState={setImage}
          fileName={sidebarData?.editImage?.name}
          description={lt("upload_edit_image")}
        />
        <fieldset className="mb-3">
          <label className="flex  mb-4 justify-between">
            <span>
              <span className="text-base font-semibold !font-mont">
                {" "}
                {lt("negative_prompt_heading")}{" "}
              </span>{" "}
            </span>
          </label>
          <textarea
            placeholder={lt("write_here") + "..."}
            className={`w-full !font-mont h-20 p-2 bg-n-16 ${
              !fromFeature &&
              "dark:bg-n-5 placeholder:dark:text-n-16 dark:border-n-5 dark:text-n-16 dark:focus:bg-transparent"
            } border-2 border-n-16 rounded-xl base2 text-n-7 outline-none transition-colors  placeholder:text-n-4/50 focus:bg-transparent resize-none  disabled:cursor-not-allowed`}
            disabled={photoGeneratedChat.loading}
            onChange={(event) =>
              dispatch(
                setSidebarData({
                  type: event.target.name,
                  payload: event.target.value,
                })
              )
            }
            name="SET_NEGATIVE_TEXT"
            value={sidebarData.negativeText}
          ></textarea>
        </fieldset>

        <div
          className={`${photoGeneratedChat.loading && "cursor-not-allowed"}`}
        >
          <div
            className={`${photoGeneratedChat.loading && "pointer-events-none"}`}
          >
            <label className="flex  mb-4 justify-between">
              <span>
                <span
                  className={`text-base font-semibold !font-mont ${
                    fromFeature && "text-black"
                  }`}
                >
                  {" "}
                  {lt("aspect_ratio")}
                </span>{" "}
              </span>
              {/* <button onClick={handleResent}>{lt('reset')}</button> */}
            </label>
            <ResponseImageSelect
              disabled={photoGeneratedChat.loading}
              orientation={sidebarData.orientation}
              handleOrientationChange={(v: any, t: any) => handleOrio(v, t)}
              fromFeature={fromFeature}
            />

            <ImageSizeSelect
              width={sidebarData.imageGenerationConfig.width}
              height={sidebarData.imageGenerationConfig.height}
              fromFeature={fromFeature}
              orientation={
                sidebarData.orientation === "1:1"
                  ? 1
                  : sidebarData.orientation === "9:16"
                  ? 2
                  : sidebarData.orientation === "16:9"
                  ? 3
                  : sidebarData.orientation === "3:4"
                  ? 4
                  : sidebarData.orientation === "4:3"
                  ? 5
                  : 1
              }
              handleDimensionsChange={handleDimensionsChange}
            />
            <div className={` mb-4  ${!fromFeature && "dark:border-n-5"}`}>
              <div className="flex justify-between items-center mb-3">
                <label
                  className={`block text-base font-semibold !font-mont ${
                    fromFeature && "text-black"
                  }`}
                >
                  {lt("num_img")}
                </label>
                <span
                  className={`w-14 h-5 rounded-full !font-mont text-sm border ${
                    fromFeature ? "text-black" : "dark:border-n-8"
                  } border-n-29  flex justify-center items-center`}
                >
                  {sidebarData.imageGenerationConfig.numberOfImages}
                </span>
              </div>
              <RangeInput
                min={1}
                max={4}
                step={1}
                defaultValue={sidebarData.imageGenerationConfig.numberOfImages}
                onChange={(v: number) =>
                  dispatch(
                    setSidebarData({ type: "SET_NUMBER_OF_IMAGES", payload: v })
                  )
                }
              />
            </div>
            <div className="w-full flex justify-between flex-wrap mt-3">
              {EditButtons.map((item, index) => (
                <button
                  onClick={() => onEditTypeSelect(item.value)}
                  key={index}
                  className={`btn ${item.bgColor} border-0 w-[48%] my-1 ${
                    sidebarData?.editType === item.value
                      ? "shadow-xl"
                      : sidebarData?.editType
                      ? "opacity-30"
                      : ""
                  } hover:opacity-100`}
                >
                  <item.IconComponent />
                  <span className=" w-[80%] text-sm sm:text-xs dark:text-black !font-mont">
                    {item.title}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditImageScreen;
