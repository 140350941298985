import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISuperUserData, IUpdateSuperUserRequest, IBugReport } from "interfaces/superUser.interface";
import { superUserService } from "services/superUser.service";
import { bugReportService } from "services/bugreport.service";

export const createSuperUserRequest = createAsyncThunk(
  "superUserStore/createSuperUserRequest",
  async (data: ISuperUserData) => {
    try {
      const response = await superUserService.store(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getAllSuperUserRequests = createAsyncThunk(
    "superUserStore/getAllSuperUserRequests",
    async () => {
      try {
        const response = await superUserService.index();
        return response.data;
      } catch (error: any) {
        const { response } = error;
        throw response.data.message || response.statusText;
      }
    }
  );

  export const updateSuperUserRequest = createAsyncThunk(
    "superUserStore/updateSuperUserRequest",
    async (data: IUpdateSuperUserRequest) => {
      try {
        const response = await superUserService.update(data);
        return response.data;
      } catch (error: any) {
        const { response } = error;
        throw response.data.message || response.statusText;
      }
    }
  );

  export const getSuperUserRequest = createAsyncThunk(
    "superUserStore/getSuperUserRequest",
    async () => {
      try {
        const response = await superUserService.show();
        return response.data;
      } catch (error: any) {
        const { response } = error;
        throw response.data.message || response.statusText;
      }
    }
  );

  export const postBugReport = createAsyncThunk(
    "superUserStore/postBugReport",
    async (data: IBugReport) => {
      try {
        console.log('hitted 2');
        
        const response = await bugReportService.store(data);
        return response.data;
      } catch (error: any) {
        const { response } = error;
        throw response.data.message || response.statusText;
      }
    }
  );
  