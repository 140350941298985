import { createAsyncThunk } from "@reduxjs/toolkit";
import { debateService } from "services/debate.service";
import { 
    IDebateData,
    IShowDebateData,
    ILeaveDebateData,
    IUpdateDebateData,
    IUpdateDebateUsers,
    IUpdateDebateBotMsg,
    IRemoveUserFromDebate,
  } from "interfaces/debate.interface";

export const storeDebate = createAsyncThunk(
  "debateStore/storeDebate",
  async (data: IDebateData) => {
    try {
      const response = await debateService.store(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getAllDebates = createAsyncThunk(
  "debateStore/getAllDebates",
  async (params: any) => {
    try {
      const response = await debateService.index(params);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getDebateById = createAsyncThunk(
  "debateStore/getDebateById",
  async (debateId: any) => {
    try {
      const response = await debateService.show(debateId);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateDebate = createAsyncThunk(
  "debateStore/updateDebate",
  async (data: IUpdateDebateData, { signal }) => {
    try {
      const response = await debateService.update(data, signal);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateDebateMessage = createAsyncThunk(
  "debateStore/updateDebateMessage",
  async (data: any, { signal }) => {
    try {
      const response = await debateService.updateMessage(data, signal);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateInvitedUsers = createAsyncThunk(
  "debateStore/updateInvitedUsers",
  async (data: IUpdateDebateUsers) => {
    try {
      const response = await debateService.updateInvitedUsers(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateBotMessage = createAsyncThunk(
  "debateStore/updateBotMessage",
  async (data: IUpdateDebateBotMsg, { signal }) => {
    try {
      const response = await debateService.updateBotMessage(data, signal);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const leaveDebateById = createAsyncThunk(
  "debateStore/leaveDebate",
  async (data: ILeaveDebateData) => {
    try {
      const response = await debateService.leaveDebate(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const readDebateMessages = createAsyncThunk(
  "debateStore/readDebateMessages",
  async (data: IShowDebateData) => {
    try {
      const response = await debateService.readDebateMessages(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const removeUserFromDebate = createAsyncThunk(
  "debateStore/removeUserFromDebate",
  async (data: IRemoveUserFromDebate) => {
    try {
      const response = await debateService.removeUserFromDebate(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const stopDebateMessageStream = createAsyncThunk(
  "debateStore/stopMessageStream",
  async (data: any) => {
    try {
      const response = await debateService.stopMessageStream(data);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);