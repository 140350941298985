import React from 'react'
import { useSelector } from 'react-redux';
import PhotoGeneration from './Screens/PhotoGeneration';
import EditImageScreen from './Screens/EditImageScreen';
import UpscaleScreen from './Screens/UpscaleScreen';
import CaptionScreen from './Screens/CaptionScreen';

function ImageLibrarySidebar({fromFeature}: any) {
  const photoGeneratedData = useSelector(
    (state: any) => state.photoGenerateStore
  );
  const { selectedScreen } = photoGeneratedData;
  return (
    selectedScreen === 0 ? <PhotoGeneration  fromFeature={fromFeature}/> :
      selectedScreen === 1 ? <EditImageScreen fromFeature={fromFeature}/> :
        selectedScreen === 2 ? <UpscaleScreen fromFeature={fromFeature}/> :
          selectedScreen === 3 ? <CaptionScreen fromFeature={fromFeature}/> :
          <div>{'selectedScreen'}</div>
  )
}

export default ImageLibrarySidebar