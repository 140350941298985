import { HttpService } from './base.service'
import { ICreateGoalData, IGetGoalDataParams, IShowGoalData, IUpdateGoalData } from '../interfaces/goal.interface'

class GoalService extends HttpService {
  private readonly prefix: string = "goal";

  index = (params: IGetGoalDataParams): Promise<any> => this.get(this.prefix + `/indexByPagination?status=${params.status}&page=${params.page}&perPage=${params.perPage}`);
  stats = (): Promise<any> => this.get(this.prefix + `/stats`);
  store = (data: ICreateGoalData): Promise<any> => this.post(this.prefix + '/store', data);
  show = (data: IShowGoalData): Promise<any> => this.post(this.prefix + '/show', data);
  update = (data: IUpdateGoalData): Promise<any> => this.put(this.prefix + '/update', data);
  destroy = (goalId: string): Promise<any> => this.delete(this.prefix + `/destroy/${goalId}`);
}

export const goalService = new GoalService();