import { IAudioData } from 'interfaces/audio.interface';
import { HttpService } from './base.service';

class AudioService extends HttpService {
    private readonly prefix: string = "audio";
    private readonly prefixLibrary: string = "audio-library";

    store = (data: IAudioData, signal: any): Promise<any> => this.post(this.prefix + '/store', data, signal);
    show = (data: any): Promise<any> => this.post(this.prefix + '/show', data);
    getInprogressAudios = (data: any): Promise<any> => this.get(this.prefix + '/in-progress-audios', data);
    updateAudioById = (data: any): Promise<any> => this.post(this.prefix + '/update', data);
    sharedAudioLibrary = (params?: any): Promise<any> => this.get(this.prefixLibrary + `/sharedAudioLibrary`,params);
    sharedAudioLibraryPrevMonth = (params?: any): Promise<any> => this.get(this.prefixLibrary + `/sharedAudioLibraryPrevMonth`,params);
    addSharedAudio = (data: any): Promise<any> => this.put(this.prefixLibrary + '/addSharedAudio', data);
    index = (params?: any): Promise<any> => this.get(this.prefixLibrary + `/index`,params);
    indexPrevMonth = (params?:any): Promise<any> => this.get(this.prefixLibrary + `/indexPreviousMonth`, params);
    update = (data: any): Promise<any> => this.put(this.prefixLibrary + '/update', data);
    destroy = (id: any): Promise<any> => this.delete(this.prefixLibrary + `/destroy/${id}`);
    destroyMultiple = (data: any): Promise<any> => this.post(this.prefixLibrary + `/destroyMultiple` , data);
    removeSharedAudio = (audioId: any): Promise<any> => this.delete(this.prefixLibrary + `/removeSharedAudio/${audioId}`);
    getAllUsers = (params?: any): Promise<any> => this.get(this.prefixLibrary + `/getAllUsers`, params);
    getUserDetails = (params?: any): Promise<any> => this.get(this.prefixLibrary + `/getUser`, params);
}

export const audioService = new AudioService();