import { createSlice } from "@reduxjs/toolkit";
import { getAllCategory, getAllPrompts, getAllSubCategory } from "store/actions/advisor.actions";
export enum Tabs {
  PROMPT_BASE = 'PROMPT_BASE',
  RELATED_PROMPTS = 'RELATED_PROMPTS',
  SUB_CATEGORY_PROMPT = 'SUB_CATEGORY_PROMPT',
}
interface CategoryState {
  loading: boolean;
  data: any[]; // Replace 'any[]' with the actual type of 'data' if possible
  error: any | null; // Replace 'any' with the actual type of 'error' if possible
  total: number;
  curPage: number;
  lastPage: number;
}

export interface IAdvisorState {
  categories: CategoryState;
  subCategories: CategoryState;
  prompts: CategoryState;
  selectedCategory: string | null; // Replace 'string' with the actual type of 'selectedCategory' if possible
  selectedSubCategory: string | null; // Replace 'string' with the actual type of 'selectedSubCategory' if possible
  selectedSubCategoryTitle: string | null;
  selectedTab: string | null;
  tabChang:number;
}


export const initialState:IAdvisorState = {
  categories:{
    loading:false,
    data:[],
    error:null,
    total:0,
    curPage:1,
    lastPage:1,
  },
  subCategories:{
    loading:true,
    data:[],
    error:null,
    total:0,
    curPage:1,
    lastPage:1,
  },
  prompts:{
    loading:false,
    data:[],
    error:null,
    total:0,
    curPage:1,
    lastPage:1,
  },
  selectedCategory:null,
  selectedSubCategory:null,
  selectedSubCategoryTitle: null,
  selectedTab:Tabs.PROMPT_BASE,
  tabChang:0
}

export const advisorSlice = createSlice({
  name: "advisorStore",
  initialState,
  reducers: {
    resetUpdateUserNotificationData(state, action) {
      // state.updateUserNotificationList.loading = false;
      // state.updateUserNotificationList.data = null;
      // state.updateUserNotificationList.error = null;
    },
    setCategory(state, action){
      state.subCategories.curPage = 1;
      state.subCategories.data = [];
      state.subCategories.total = 0;
      state.subCategories.lastPage = 1;
      console.log(action?.payload, " setCategory Action Payload");
      
      state.selectedCategory = action.payload
      state.tabChang = state.tabChang + 1
    },
    setSubCategory(state, action){
      console.log("Set Selected Sub Category Run here,", action.payload);
      state.selectedSubCategory = action.payload?._id
      state.selectedSubCategoryTitle = action.payload.title
      state.prompts.data = [];
      state.prompts.lastPage = 1;
      state.prompts.curPage = 1;
      state.prompts.total = 0;
      state.tabChang = state.tabChang + 1
    },
    resetAllAdvisorStates(state,action){
      console.log("Reset All Advisor States Run here");
      return initialState
    },
    setCategoryPage(state,action){
      state.categories.curPage = action.payload
    },
    setSubCategoryPage(state,action){
      console.log("actionPayload of set Sub Category", action.payload)
      state.subCategories.curPage = action.payload
    },
    setPromptPage(state,action){
      state.prompts.curPage = action.payload
    },
    setSelectedTab(state,action){
      state.selectedTab = action.payload
      state.tabChang = state.tabChang + 1
    }
  },
  extraReducers: {
    [getAllCategory.pending.type]: (state, action) => {
      state.categories.loading = true;
      state.categories.error = null;
    },
    [getAllCategory.fulfilled.type]: (state, action) => {
      console.log("action.payload.data",action.payload);
      state.categories.data = state.categories.curPage == 1 ? action.payload.data : [...state.categories.data,...action.payload.data];
      state.categories.error = null;
      state.categories.loading = false;
      state.categories.total = action.payload?.total ?? 0;
      state.categories.curPage = action.payload?.current_page ?? 1;
      state.categories.lastPage = action.payload?.last_page ?? 1;
      if(state.categories.curPage == 1 && state.categories.data?.length > 0){
        state.selectedCategory = state.categories.data[0]?._id
      }
    },
    [getAllCategory.rejected.type]: (state, action) => {
      state.categories.loading = false;
      state.categories.error = action.error?.message;
    },
    //////////////////////
    [getAllSubCategory.pending.type]: (state, action) => {
      state.subCategories.loading = true;
      state.subCategories.error = null;
    },
    [getAllSubCategory.fulfilled.type]: (state, action) => {
      console.log("action.payload.data",action.payload.data);
      state.subCategories.data = state.subCategories.curPage == 1 ? action.payload.data : [...state.subCategories.data,...action.payload.data];
      state.subCategories.error = null;
      state.subCategories.loading = false;
      state.subCategories.total = action.payload?.total ?? 0;
      state.subCategories.curPage = action.payload?.current_page ?? 1;
      state.subCategories.lastPage = action.payload?.last_page ?? 1;
    },
    [getAllSubCategory.rejected.type]: (state, action) => {
      state.subCategories.loading = false;
      state.subCategories.error = action.error?.message;
    },
    //////////////////////
    [getAllPrompts.pending.type]: (state, action) => {
      state.prompts.loading = true;
      state.prompts.error = null;
    },
    [getAllPrompts.fulfilled.type]: (state, action) => {
      console.log("action.payload.data",action.payload.data);
      state.prompts.data = state.prompts.curPage == 1 ? action.payload.data : [...state.prompts.data,...action.payload.data];
      state.prompts.error = null;
      state.prompts.loading = false;
      state.prompts.total = action.payload?.total ?? 0;
      state.prompts.curPage = action.payload?.current_page ?? 1;
      state.prompts.lastPage = action.payload?.last_page ?? 1;
    },
    [getAllPrompts.rejected.type]: (state, action) => {
      state.prompts.loading = false;
      state.prompts.error = action.error?.message;
    },
  },
});

export const selectAdvisorStore = (state:any) => state.advisorStore

export const { setSelectedTab,resetUpdateUserNotificationData, setCategory, setSubCategory, resetAllAdvisorStates, setCategoryPage, setPromptPage, setSubCategoryPage } = advisorSlice.actions;

export default advisorSlice.reducer;
