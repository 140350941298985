import React, { useEffect, useState } from "react";
import Image from "@/components/Image";
import { useDispatch, useSelector } from "react-redux";
import { deleteDeviceById } from "store/actions/device.actions";
import { capitalize, isEmpty } from "lodash";
import { formatDateIntoUTC } from "lib/DateTime";
import { resetDeleteDeviceData } from "store/slices/DeviceSlice";
import { useRouter } from "next/router";
import { resetAllAudioData } from "store/slices/AudioSlice";
import { resetPhotoGenerations } from "store/slices/PhotoGenerationSlice";
import { resetAllGoalData } from "store/slices/GoalSlice";
import { resetImageLibraryData } from "store/slices/ImageLibrarySlice";
import { resetAllPlanData } from "store/slices/PlanSlice";
import { resetAllDevicesData } from "store/slices/DeviceSlice";
import { clearLogoutStorage, parseJwt } from "lib/user";
import { ROUTES } from "@/constants/routes";
import { resetAllUserData } from "store/slices/AuthSlice";
import { resetAllChatsData } from "store/slices/ChatGptSlice";
import { loadDecryptedValue } from "lib/encryption";
import { USER_LOCAL } from "@/constants/user";
import { useTranslation } from "react-i18next";
import { OS } from "@/constants/devices";
import { capitalizeFirstLetter } from "lib/utils";

type DeviceProps = {
  item: any;
};

const Device = ({ item }: DeviceProps) => {
  let imageSrc;
  const router = useRouter();
  const { t } = useTranslation('translation');
  const dispatch: any = useDispatch();
  const devicesData = useSelector((state: any) => state.deviceStore);

  const { deletedDevice, deleteAllDevices } = devicesData;

  const [selectedItem, setSelectedItem] = useState<any>(null);

  useEffect(() => {
    if(!isEmpty(deletedDevice.data) && !deletedDevice.loading) {
      setTimeout(() => {
        dispatch(resetDeleteDeviceData(null));
      }, 1500)
    }
  },[deletedDevice.data])

  const handleDeleteDevice = (ipItem: any) => {
    setSelectedItem(ipItem);
    dispatch(deleteDeviceById(ipItem.id)).unwrap().then(async(resp: any) => {
      const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
      const sessionsValue = parseJwt(sessions?.token);

      if(resp?.data?.id === sessionsValue?.deviceId) {
        dispatch(resetAllUserData(null));
        dispatch(resetAllChatsData(null));
        dispatch(resetAllAudioData(null));
        dispatch(resetPhotoGenerations(null));
        dispatch(resetAllGoalData(null));
        dispatch(resetImageLibraryData(null));
        dispatch(resetPhotoGenerations(null));
        dispatch(resetAllPlanData(null));
        dispatch(resetAllDevicesData(null));
        clearLogoutStorage();
        router.push(ROUTES.SIGNIN);
      }
    });
  };

  if (item.browserName == "chrome") {
    imageSrc = "https://lokichatdev.blob.core.windows.net/images/chrome.svg";
  } else if (item.browserName == "firefox") {
    imageSrc = "https://lokichatdev.blob.core.windows.net/images/firefox.svg";
  } else if (item.browserName === "safari" || item.browserName === 'ios') {
    imageSrc = "https://lokichatdev.blob.core.windows.net/images/safari.svg";
  } else if (item.browserName === "opera") {
    imageSrc = "https://lokichatdev.blob.core.windows.net/opera.svg";
  } else if (isEmpty(item.browserName) && item?.os && item.os?.toLowerCase()?.includes(OS.ANDROID)) {
    imageSrc = "https://lokichatdev.blob.core.windows.net/images/loki/android.svg";
  } else if (isEmpty(item.browserName) && item?.os && item.os?.toLowerCase()?.includes(OS.IOS)) {
    imageSrc = "https://lokichatdev.blob.core.windows.net/images/loki/apple_logo.svg";
  }
  return (
    <div className="flex items-start py-6 border-t border-n-3 dark:border-n-6">
      <div className="flex justify-center items-center shrink-0 w-12 h-12 mr-4 px-2 bg-n-3 rounded-xl dark:bg-n-5">
        <Image
          className="w-full"
          src={imageSrc || "https://lokichatdev.blob.core.windows.net/images/chrome.svg"}
          width={32}
          height={32}
          alt=""
        />
      </div>
      <div className="grow">
        <div className="mb-1 base1 font-semibold text-n-6 dark:text-n-3">
          {`${item.browserName && item.browserName?.toLowerCase() === 'ios' ? 'Safari': capitalize(item.browserName)} ${item.browserName ? 'on': 'OS'} ${capitalizeFirstLetter(item?.os)}`}
        </div>
        <div className="base2 text-n-4">
          <p>{item.ipAddress}</p>
          <p>{formatDateIntoUTC(item.date)}</p>
        </div>
      </div>
      <button
        disabled={selectedItem?.id === item.id && deletedDevice.loading || deleteAllDevices.loading}
        onClick={() => handleDeleteDevice(item)}
        className="btn-stroke-light shrink-0 ml-4"
      >
        {t('revoke')}
      </button>
    </div>
  );
};

export default Device;
