import React, { useEffect, useState } from "react";
import Image from "@/components/Image";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@/components/Modal";
import SuggestedPromptsModal from "@/components/Modal/SuggestedPromptsModal";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { PROFILE_URL } from "@/constants/user";
import { ROUTES } from "@/constants/routes";
import { size } from "lodash";
import { setAbortChatModal, setAbortNavigationData } from "store/slices/ChatGptSlice";
import { resetAllAdvisorStates } from "store/slices/AdvisorSlice";
import PromptAdvisorModal from "@/components/Modal/PromptAdvisorModal";
import { CopyWriterIcon, TranslatorIcon } from "@/components/SVGs";
import InstantTranslatorModal from "@/components/Modal/InstantTranslatorModal";
import Menu from "@/components/Menu";
import { translate } from "@/constants/navigation";
import { CHAT_MODEL } from "@/constants/chats";
import Icon from "@/components/Icon";

type ProfileProps = {
  visible?: boolean;
  getPlans?: any;
};
const Profile = ({ visible }: ProfileProps) => {

  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t: lt } = useTranslation('auth');
  const { t } = useTranslation('translation');

  const navigation = translate(t);

  const userData = useSelector((state: any) => state.authStore);
  const chatData = useSelector((state: any) => state.chatGptStore);
  const debateData = useSelector((state: any) => state.debateStore);

  const { stream, streamText, updateStream } = chatData;
  const {
    updateDebate,
    updateDebateMessage,
    updateDebateBotMessage,
  } = debateData;

  const isChatPath = router.pathname.includes(ROUTES.CHAT);
  const { user } = userData;

  const [visibleModal, setVisibleModal] = useState<any>(false);

  const findMatchingPlanTitle = () => {
    try {
      return user?.data?.planData?.title
    } catch (error) {

    }

    return null;
  };

  // Get the matching plan title for the user
  const matchingPlanTitle = findMatchingPlanTitle();

  const onClickHandler = () => {
    isChatOrDebateLoading(null, ROUTES.TRACKER);
  }

  const isChatOrDebateLoading = (clickHandler: any, url: any) => {
    try {
      if (stream && size(streamText) >= 0) {
        const data = {
          clickHandler: clickHandler ?? null,
          url: url ?? null
        };
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else if (updateStream.loading && size(updateStream.text) >= 0) {
        const data = {
          clickHandler: clickHandler ?? null,
          url: url ?? null
        };
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else if (updateDebate.loading || updateDebateMessage.loading || updateDebateBotMessage.loading) {
        const data = {
          clickHandler: clickHandler ?? null,
          url: url ?? null
        };
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else {
        if (clickHandler) {
          clickHandler();
        }
        if (url) {
          router.push(url);
        }
      };
    } catch (error) {

    }

  }

  const onModalClose = () => {
    if (visibleModal) {
      setVisibleModal(false);
      dispatch(resetAllAdvisorStates({}))
    }
  }

  const items = [
    {
      title: lt('update_faq'),
      IconComponent: () => <CopyWriterIcon />,
      color: "fill-accent-1",
      onlyMobile: true,
      onClick: () => setVisibleModal('copywriter'),
      url: ROUTES.UPDATESANDFAQS,
    },
    {
      title: lt('update_faq'),
      IconComponent: () => <TranslatorIcon />,
      color: "fill-accent-1",
      onlyMobile: true,
      onClick: () => setVisibleModal('translator'),
      url: ROUTES.UPDATESANDFAQS,
    },
  ];
  useEffect(() => {
    const isChatPath = router.pathname.includes(ROUTES.CHAT);
    const hasRefreshed = sessionStorage.getItem('hasRefreshed');

    if (isChatPath) {
      if (hasRefreshed !== 'true') {
        // console.log('Refreshing the page...');
        sessionStorage.setItem('hasRefreshed', 'true');
        window.location.reload();
      } else {
        console.log('Page has already been refreshed');
      }
    }
  }, [router.pathname]);


  return (
    <div
    className={`${visible ? "mb-2" : "mb-3 shadow-[0_1.25rem_1.5rem_0_rgba(0,0,0,0.5)]"
      }`}
  >
    <div className={`${!visible && "p-2.5 bg-n-6 rounded-xl"}`}>
      <div
        className={`flex   ${visible ? "justify-center" : " flex-col"
          }`}
      >         
       {visible && <div className="relative flex-shrink-0 w-10 h-10">
            <Image
              className="rounded-full object-cover"
              src={user?.data?.profileUrl ?? PROFILE_URL}
              fill
              alt={lt('avatar')}
            />
            <div className="absolute -right-0.75 -bottom-0.75 w-4.5 h-4.5 bg-primary-2 rounded-full border-4 border-n-6"></div>
          </div>}
          {!visible && (
            <div className="flex  gap-2" >
              <button
              className="btn-stroke-dark cursor-pointer w-1/2 truncate text-xs px-2"
                onClick={() => setVisibleModal('copywriter')}
                id="step7"
              >
                {/* <CopyWriterIcon /> */}
                <span className="truncate text-left !font-mont"> {t('Copywriter')}</span>
              </button>
              <button
                 className="btn-stroke-dark cursor-pointer w-1/2 truncate text-xs px-2"
                onClick={() => setVisibleModal('translator')}
                id="step7"
              >
                {/* <TranslatorIcon /> */}
                <span className="truncate text-left !font-mont"> {t('instant_translator')}</span>
              </button>
            </div>
          )}
        </div>

        {!visible &&
          <div className="flex gap-2 mt-4">
            {matchingPlanTitle &&
              <div
                className="btn-stroke-dark cursor-pointer w-1/2 truncate text-xs px-2"
                onClick={onClickHandler}
              >
                <span className="truncate !font-mont"> {lt('track_asset')}</span>
              </div>
            }
            <button
              className={`${matchingPlanTitle ? "w-1/2" : "w-full"} btn-stroke-dark text-xs px-2`}
              onClick={() => setVisibleModal('prompt')}
              id="step7"
              disabled= {router.pathname === ROUTES.PHOTO_GENERATION}
            >
              <span className="truncate !font-mont"> {lt('prompt_advisor_small')}</span>
            </button>
          </div>
        }
      </div>
      <Modal
        visible={visibleModal ? true : false}
        onClose={onModalClose}
        noicon
        classWrap={visibleModal === 'translator' || visibleModal === 'copywriter' ? "max-w-[64rem] h-[85%] l2xl:h-[70%] l3xl:h-[60%]" : ""}
      >
        {visibleModal === 'prompt' ?
          <PromptAdvisorModal 
          onClose={onModalClose} 
          />
          : 
          visibleModal === "copywriter" ?
            <div className="relative px-8 pt-8 pb-10 bg-n-3/75 dark:bg-n-5/50 rounded-3xl h-full ">
              <div className=" mb-3">
                <div className=" w-full flex justify-between">
                  <span className="!font-mont !font-extrabold h5">{t("Copywriter")}</span>
                  <button onClick={onModalClose}><Icon name={'close'} className=" fill-n-4 d " /></button>
                </div>
                <span className="block !font-mont text-[15px] sm:text-[9px] mt-[1%] mb-[2%] !font-normal ">{t("copywriting_chat_desc_short")}</span>
              </div>
              <Menu
                className="flex items-start gap-2 flex-wrap"
                items={navigation}
                selectedChatModel={{ name: "Copywriter", key: CHAT_MODEL.COPY_WRITER, icon: <></>, current: false }}
                onClose={onModalClose}
              />
            </div>
            : visibleModal === "translator" ?
              <InstantTranslatorModal onClose={onModalClose} />
              : <></>
        }
      </Modal>
    </div>
  );
};

export default Profile;
