import { Button } from "@/components/LandingPage/Common/Button";
import { ROUTES } from "@/constants/routes";
import { useColorMode } from "@chakra-ui/color-mode";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "@/components/Icon";
type FreeTrialThreeDaysModal = {};

const FreeTrialThreeDaysModal = ({}: FreeTrialThreeDaysModal) => {
  const { colorMode, setColorMode } = useColorMode();
  const router = useRouter();
  const fiftyPercentOff = "true";
  const { t } = useTranslation("translation");
  return (
    <div className="rounded-3xl overflow-hidden w-full h-full bg-white flex">
      <div className="w-full pt-14">
        <div className=" md:px-10 px-18">
          <figure className="">
            <Image
              src={
                colorMode !== "dark"
                  ? "https://lokichatdev.blob.core.windows.net/images/loki/logow.svg"
                  : "https://lokichatdev.blob.core.windows.net/images/loki/logow.svg"
              }
              height={40}
              width={136}
              alt={"Loki"}
            />
          </figure>
          <div
            style={{ backgroundColor: "rgb(235 240 240)" }}
            className="flex flex-col items-center justify-center rounded-3xl border border-n-3 pb-64 pt-48 "
          >
            <div className="flex justify-center items-center w-32 h-32 mb-12 rounded-full bg-primary-2 xl:w-20 xl:h-20 lg:mx-auto lg:mb-6">
              <Icon className="w-12 h-12" name="check-thin" />
            </div>
            <h3 className="text-n-6 text-4xl font-extrabold !font-sans pb-5">
              Grab your 50% Discount Now!
            </h3>
            <p className="text-n-6/50 text-xl tracking-wide font-medium">
              Upgrade Today and Save Big — Limited Time Offer!
            </p>
          </div>
          <Button
            className="ml-auto  w-full py-3 text-base mt-5 py-5 px-8"
            onClick={() => {
              router.push({
                pathname: ROUTES.PRICING,
                query: { fiftyPercentOff },
              });
            }}
      
          >
            Claim My Discount
          </Button>
        </div>
        <div className="w-full flex justify-end py-5 px-8"></div>
      </div>
    </div>
  );
};

export default FreeTrialThreeDaysModal;
