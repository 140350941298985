import { createSlice } from "@reduxjs/toolkit";

export const initialState: any = {
  translations: {
    response: null,
    translateProgress: 0,
    curStep: 1,
    loading: false,
    payloadData : {
      targetLanguage: "",
      file: "",
    },
    error: null,
  },
};

export const TranslationSlice = createSlice({
  name: "translationStore",
  initialState: initialState,
  reducers: {
    resetAllTranslations(state, action) {
      return initialState;
    },
    setresponse(state, action) {
      state.translations.response = action.payload;
    },
    setcurStep(state, action) {
      state.translations.curStep = action.payload;
    },
    setpayload(state, action) {
      console.log("Action Payload ", action.payload);
      
      state.translations.payloadData = action.payload;
    },  
    settranslateProgress(state, action) {
      state.translations.translateProgress = action.payload;
    }
  },
});

export const { resetAllTranslations, setcurStep, setpayload, setresponse, settranslateProgress } = TranslationSlice.actions;

export default TranslationSlice.reducer;
