import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  colorMode: 'light'
};

export const preferenceSlice = createSlice({
  name: "preferenceStore",
  initialState,
  reducers: {
    setPresistColorMode(state, action) {
        state.colorMode = action.payload;
    }
  }
});

export const { setPresistColorMode } = preferenceSlice.actions;

export default preferenceSlice.reducer;
