import React from "react";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, size } from "lodash";
import { setAbortNavigationData, setStopGenerateChat } from "store/slices/ChatGptSlice";
import { ABORT_EVENTS } from "@/constants/chats";
import { useRouter } from "next/router";
import { setStopGenerateDebate } from "store/slices/DebateSlice";
import { ROUTES } from "@/constants/routes";

const AbortChatModal = ({ onCancel }: any) => {

  const router = useRouter();
  const dispatch: any = useDispatch();

  const chatData = useSelector((state: any) => state.chatGptStore);
  const debateData = useSelector((state: any) => state.debateStore);

  const {
    updateDebate,
    updateDebateMessage,
    updateDebateBotMessage,
  } = debateData;

  const { navigationData, stream, streamText, updateStream } = chatData;

  const continueAbortChat=() => {
    try {

    const isRegenerateChat: any = localStorage.getItem('isRegenerateChat');
    if (stream && size(streamText) >= 0) {
      dispatch(setStopGenerateChat({ keyName: ABORT_EVENTS.GENERATE, value: true }));
      setTimeout(() => {
        dispatch(setStopGenerateChat({ keyName: "", value: false }));
      }, 1000);
      console.log('navigationData', navigationData);
      if(navigationData?.data?.clickHandler) {
        navigationData?.data?.clickHandler();
      }
      if(!isEmpty(navigationData?.data?.url)) {
        router.push(navigationData?.data?.url);
      }
      closeModal();
    } else if (updateStream.loading && size(updateStream.text) >= 0 && isRegenerateChat === 'true') {
      dispatch(setStopGenerateChat({ keyName: ABORT_EVENTS.RE_GENERATE, value: true }));
      setTimeout(() => {
        dispatch(setStopGenerateChat({ keyName: "", value: false }));
      }, 1000);
      
      if(navigationData?.data?.clickHandler) {
        navigationData?.data?.clickHandler();
      }
      if(!isEmpty(navigationData?.data?.url)) {
        router.push(navigationData?.data?.url);
      }
      closeModal();
    } else if (updateStream.loading && size(updateStream.text) >= 0) {
      dispatch(setStopGenerateChat({ keyName: ABORT_EVENTS.UPDATE, value: true }));
      setTimeout(() => {
        dispatch(setStopGenerateChat({ keyName: "", value: false }));
      }, 1000);
      
      if(navigationData?.data?.clickHandler) {
        navigationData?.data?.clickHandler();
      }
      if(!isEmpty(navigationData?.data?.url)) {
        router.push(navigationData?.data?.url);
      }
      closeModal();
    }
    // Debate Module Implementation 
    else if (updateDebate.loading) {
      dispatch(setStopGenerateDebate({ keyName: ABORT_EVENTS.GENERATE, value: true }));
      setTimeout(() => {
        dispatch(setStopGenerateDebate({ keyName: "", value: false }));
      }, 1000);
      if(navigationData?.data?.clickHandler) {
        navigationData?.data?.clickHandler();
      }
      if(!isEmpty(navigationData?.data?.url)) {
        router.push(navigationData?.data?.url);
      }
      closeModal();
    } else if (updateDebateMessage.loading) {
      dispatch(setStopGenerateDebate({ keyName: ABORT_EVENTS.UPDATE, value: true }));
      setTimeout(() => {
        dispatch(setStopGenerateDebate({ keyName: "", value: false }));
      }, 1000);
      if (navigationData?.data?.clickHandler) {
        navigationData?.data?.clickHandler();
      }
      if(!isEmpty(navigationData?.data?.url)) {
        router.push(navigationData?.data?.url);
      }
      closeModal();
    } else if (updateDebateBotMessage.loading) {
      dispatch(setStopGenerateDebate({ keyName: ABORT_EVENTS.RE_GENERATE, value: true }));
      setTimeout(() => {
        dispatch(setStopGenerateDebate({ keyName: "", value: false }));
      }, 1000);
      if (navigationData?.data?.clickHandler) {
        navigationData?.data?.clickHandler();
      }
      if(!isEmpty(navigationData?.data?.url)) {
        router.push(navigationData?.data?.url);
      }
      closeModal();
    } else {
      if (navigationData?.data?.clickHandler) {
        navigationData?.data?.clickHandler();
      }
      if(!isEmpty(navigationData?.data?.url)) {
        router.push(navigationData?.data?.url);
      }
      closeModal();
    }
    } catch (error) {
      
    }
    
  }

  const closeModal = () => {
    dispatch(setAbortNavigationData(null));
    onCancel();
  }

  return (
    <div className="max-w-[430px] m-auto">
      <figure className="flex justify-center items-center mb-5">
        <Image
          src={"https://lokichatdev.blob.core.windows.net/images/success.svg"}
          height={70}
          width={70}
          alt="success"
        />
      </figure>
      <h3 className="h4 text-center mb-2">Abort {router.pathname === ROUTES.DEBATE_ID ? 'Debate' : 'Chat'}</h3>
      <p className="text-base text-center text-[#606060] px-5">
        Proceeding here will abort your current chat loading. Are you sure you
        want to continue?{" "}
      </p>
      <div className="gap-2 px-3 mt-6 grid grid-cols-2 sm:grid-cols-1">
        <button onClick={continueAbortChat} className="btn-blue flex justify-center gap-2" type="submit">
          Continue
        </button>
        <button
          onClick={onCancel}
          className="btn-blue flex justify-center gap-2"
          type="submit"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AbortChatModal;
