import React, { useEffect, useState } from "react";
import Icon from "@/components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotebookById, updateNotebookById } from "store/actions/notebook.actions";
import { setSelectedNotebooks,setProcessingNoteBookItem } from "store/slices/ChatGptSlice";
import Notify from "@/components/Notify";
import { toast } from "react-hot-toast";
import { useColorMode } from "@chakra-ui/color-mode";
import { isEmpty, size } from "lodash";
import { getPlainTextFromHTML, getPlainTextFromMarkdown, htmlToMarkdown } from "lib/getPlainText";
import { resetAllAudioData, resetCreateAudioData } from "store/slices/AudioSlice";
import { useTranslation } from "react-i18next";
import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { convertMarkdownToHTML } from "lib/notebooks";
import config from "@/config/index";
//@ts-ignore
import TurndownService from 'turndown';
//@ts-ignore
import convertor from '@wekanteam/html-to-markdown';
import { ROUTES } from "@/constants/routes";
import { useRouter } from "next/router";
import MarkdownRenderer from "./MarkdownRenderer";
import { isNotebookSelected } from "@/constants/notebooks";
import EditorCKEditor from '@/components/EditorCKEditor';

type AddChatListProps = {
  item?: any;
  dragHandleProps?: any
};
type ChatItemProps = {
  item: any;
  selectedLiveNotebook?: any;
  resetCheckBox?: any;
  setResetCheckBox?: any;
  isAudioLoading?: any;
  lastDetectedLanguage?: any;
  onLanguageDetected?: any;
  dragHandleProps?: AddChatListProps,
};

const ChatItem = ({ item, resetCheckBox, setResetCheckBox, isAudioLoading, lastDetectedLanguage, onLanguageDetected, dragHandleProps }: ChatItemProps) => {

  const router = useRouter();
  const dispatch: any = useDispatch();
  const { colorMode } = useColorMode();
  const { t: lt } = useTranslation('translation');

  const dark = colorMode === "light";

  const audioData = useSelector((state: any) => state.audioStore);
  const chatData = useSelector((state: any) => state.chatGptStore);

  const userData = useSelector((state: any) => state.authStore);
  const debateData = useSelector((state: any) => state.debateStore);

  const { user } = userData;
  const { createAudio } = audioData;
  const { debateInvitedUsers, storeDebate } = debateData;
  const { deletedNotebook, selectedNotebooks, processingNoteBookItem } = chatData;

  const [active, setActive] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [readMore, setReadMore] = useState<boolean>(false);
  const [detail, setDetail] = useState(item.response);
  const [updatedContent, setUpdatedContent] = useState<any>("");
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(false);
  const [updatedContentValue, setUpdatedContentValue] = useState<any>();
  const [editingContent,setEditingContent] = useState("");

  const LanguageDetect = require('languagedetect');
  const lngDetector = new LanguageDetect();

  useEffect(() => {
    console.log('is edit on ', edit);
  }, [edit])

  //Editor configurations
  const editorRef = useRef<any>(null);
  const log = () => {
    
    if (editingContent) {

      const turndownS = new TurndownService()
      // const updatedMarkdownText = convertor.convert(editingContent).replace(/\*\*/g, "")
      const updatedMarkdownText = turndownS.turndown(editingContent)
      const updatedPlainText = getPlainTextFromMarkdown(editingContent);
      
      if (size(updatedPlainText) > 2) {
        setEdit(false);
        const data: any = { notebookId: item.id };
        const plainText = getPlainTextFromMarkdown(item.response);

        if (size(updatedContent.trim()) > 0 && updatedPlainText.trim() != plainText) {
          data.response = updatedMarkdownText.trim();
          dispatch(updateNotebookById(data));
          setUpdatedContent(updatedMarkdownText);
        } else {
          setUpdatedContent(updatedMarkdownText);
          setTimeout(() => {
            setUpdatedContent(item.response);
          })
        }
      } else {
        setEdit(false);
        setUpdatedContent(updatedMarkdownText);
        setTimeout(() => {
          setUpdatedContent(item.response);
        })
      }
    }
  };

  useEffect(() => {
    if (selectedNotebooks.includes(item.id)) {
      setActive(true);
    }
  }, []);

  useEffect(() => {
    resetCheckBox === true && setActive(false);
    setResetCheckBox(false);
  }, [resetCheckBox]);

  useEffect(() => {
    setDetail(item.response);
    setUpdatedContent(item.response);
  }, [item.response]);

  const handleDeleteNotebook = () => {
    if (deleteButtonDisabled) {
      return;
    }

    setDeleteButtonDisabled(true);
    const toastId = toast((t) => (
      <Notify className="md:flex-col md:items-center md:px-10" iconDelete>
        <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">
          {lt('confirm_del')}
        </div>
        <div className="flex justify-center">
          <button
            className="btn-stroke-light btn-medium md:min-w-[6rem]"
            onClick={() => {
              toast.dismiss(t.id);
              setDeleteButtonDisabled(false); // Re-enable the delete button
            }}
          >
            {lt('cancel_btn')}
          </button>
          <button
            className="btn-blue btn-medium ml-3 md:min-w-[6rem]"
            onClick={() => {
              toast.dismiss(t.id);
              setDeleteButtonDisabled(false);
              dispatch(deleteNotebookById(item.id)).unwrap().then(() => {
                if (size(selectedNotebooks) === 0) {
                  dispatch(resetCreateAudioData(null));
                } else if(size(selectedNotebooks) > 0) {
                  const newNotebooks = selectedNotebooks.filter((el: any) => el !== item.id);
                  if(size(newNotebooks) === 0) {
                    dispatch(resetCreateAudioData(null));
                  }
                  dispatch(setSelectedNotebooks(newNotebooks));
                }
              });
            }}
          >
            {lt('yes')}
          </button>
        </div>
      </Notify>
    ), { duration: Infinity });
  };

  const handleEditNotebook = () => {
    const updatedMarkdownText = getPlainTextFromHTML(htmlToMarkdown(editingContent));
    // const updatedPlainText = getPlainTextFromHTML(updatedContentValue);
    const updatedPlainText = getPlainTextFromMarkdown(updatedMarkdownText);
    if (size(updatedPlainText) > 2) {
      setEdit(false);
      const data: any = { notebookId: item.id };
      const plainText = getPlainTextFromMarkdown(item.response);

      if (size(updatedContent.trim()) > 0 && updatedPlainText.trim() != plainText) {
        data.response = updatedMarkdownText.trim();
        dispatch(updateNotebookById(data));
        setUpdatedContent(updatedMarkdownText);
      } else {
        setUpdatedContent(updatedMarkdownText);
        setTimeout(() => {
          setUpdatedContent(item.response);
        })
      }
    } else {
      setEdit(false);
      setUpdatedContent(updatedMarkdownText);
      setTimeout(() => {
        setUpdatedContent(item.response);
      })
    }
  };
  const handleContentChange = (event: any) => {
    const value = event.target.innerHTML;
    setUpdatedContentValue(value)
  };

  const handleItemClick = (item: any) => {
    chatGenerationClick(item);
  };
  const chatGenerationClick = (item: any) => {
    const isSelected = isNotebookSelected(selectedNotebooks, item.id);
    if (isSelected) {
      setActive(!active);
      const filteredItems = selectedNotebooks.filter((id: any) => id !== item.id);
      filteredItems.length == 0 ? onLanguageDetected('') : null;
      dispatch(setSelectedNotebooks(filteredItems));
    } else {
      dispatch(setProcessingNoteBookItem(item.id));
      const detectedLanguage = detectSingleLanguage(item.response); 
      
      if (lastDetectedLanguage && lastDetectedLanguage !== detectedLanguage) {
        toast((t) => (
          <Notify iconError>
            <div className="mr-6 ml-3 h6">The language of the selected item differs from the previous selection.</div>
          </Notify>
        ), { duration: 3000 });
        dispatch(setSelectedNotebooks([...selectedNotebooks]));
        return;
      }
      setActive(!active);
      onLanguageDetected(detectedLanguage);

      dispatch(setSelectedNotebooks([...selectedNotebooks, item.id]));
    }
  };


  const detectSingleLanguage = (text: any) => {
    try {
      const result = lngDetector.detect(text, 1); 
      return result.length > 0 ? result[0][0] : null;
    } catch (error) {
      return null;
    }
  };

  const isDebateUser = debateInvitedUsers?.find((el: any) => el?._id === user?.data?.id);
  const isDebateAdmin = storeDebate.data?.user?._id === user.data?.id;
  const isDebateArchived = storeDebate.data?.isArchived;

  const handleEditorChange = (newData: string) => {
    //setContent(newData);
  };

  return (
    <div {...(!edit ? dragHandleProps : {})} style={!edit ? { cursor: 'move' } : {}}>
      <div className={`relative`}>
      <button
        disabled={processingNoteBookItem}
        className={`absolute z-1 top-[1.188rem] left-3 flex justify-center items-center border-2 border-n-4/50 w-5.5 h-5.5 rounded-md transition-colors ${active && "bg-primary-1"
          }`}
        onClick={() => handleItemClick(item)}
      >
        <Icon
          className={`w-4 h-4 fill-white opacity-0 transition-opacity ${active && "opacity-100"
            }`}
          name="check"
        />
      </button>
      <div
          className={`group items-start relative rounded-lg transition-colors bg-n-3/75 dark:bg-n-5/50 pt-2 
        ${active && "bg-n-3/75 dark:bg-n-5"}
        ${readMore && "flex-col"}
        ${edit ? "pl-10 pr-3" : "pl-8"}
        `}
      >
        <div
          key={updatedContent}
          //contentEditable={edit ? true : false}
          className={`
          ${edit ? "border-blue-500" : "border-transparent"} 
          ${readMore ? "" : "truncate"}
          border Answer rounded-md mt-1 py-1.5 px-2 caption1 text-base outline-0 relative contentEditable chatItem`}
          onInput={handleContentChange}
        >
          {/* {!edit && <MarkdownRenderer>{readMore ? updatedContent : getPlainTextFromMarkdown(updatedContent)}</MarkdownRenderer>} */}
          {!edit && <MarkdownRenderer className={`${readMore ? "" : "truncate"}`} markdownContent={readMore ? updatedContent : getPlainTextFromMarkdown(updatedContent)} />}
          {edit && <>
            <EditorCKEditor            
                value={convertMarkdownToHTML(updatedContent)}
                onChange={(v:any) => {
                  setEditingContent(v);
                }}
                editorRef={editorRef}
                className={`${readMore ? "" : "truncate"}`}
            />
          <div className="flex items-center gap-4 mt-3 mb-3">
            <button className="btn-blue btn-medium ml-3 md:min-w-[6rem]" onClick={log}>Save</button>
                <button className="btn-stroke-light btn-medium md:min-w-[6rem]" onClick={() => setEdit(false)}>Cancel</button>
          </div>
            {/* <Editor
              apiKey={config.tinyMiceApiKey}
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={convertMarkdownToHTML(updatedContent)}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
            <button className="cursor-pointer px-2.5 mx-2.5 rounded-md" onClick={log}>Save</button>
            <button className="cursor-pointer px-2.5 mx-2.5 rounded-md" onClick={() => { setEdit(false) }}>Cancel</button> */}
          </>}
        </div>

        <div
          className={`
          ${dark ? "gradientLight" : "gradientDark"}
          ${edit ? "right-4" : "right-0"}
           absolute hidden group-hover:flex  items-center gap-2 top-4 cursor-pointer pl-32 pr-1.5 rounded-md`}
        >
          {!edit && (
            <>
              {readMore ? (
                <span
                  className="transition-colors !font-mont fill-n-4 dark:fill-white  hover:fill-primary-1 hover:dark:fill-primary-1"
                  onClick={() => setReadMore(!readMore)}
                >
                  <Icon className="w-5 h-5 rotate-180" name="arrow-down" />
                </span>
              ) : (
                <span
                  className="transition-colors !font-mont fill-n-4 dark:fill-white hover:fill-primary-1 hover:dark:fill-primary-1"
                  onClick={() => setReadMore(!readMore)}
                >
                  <Icon className="w-5 h-5" name="arrow-down" />
                </span>
              )}
            </>
          )}
          {!edit ? (
            <span
              className="!font-mont transition-colors  fill-n-4 dark:fill-white hover:fill-primary-1 hover:dark:fill-primary-1"
              style={{display: (((!isDebateAdmin && !isDebateUser) || isDebateArchived) && router.pathname === ROUTES.DEBATE_ID) ? 'none': ''}}
              onClick={() => {
                setEdit(true);
                setReadMore(true);
              }}
            >
              <Icon className="w-5 h-5 " name="edit" />
            </span>
          ) : (
            <></>
            // <span
            //   className="fill-n-4 transition-colors dark:fill-white hover:fill-primary-1 hover:dark:fill-primary-1"
            //   onClick={handleEditNotebook}
            // >
            //   <Icon className="w-5 h-5 " name="check" />
            // </span>
          )}
          {!edit ? (
            <span
              style={{display: (((!isDebateAdmin && !isDebateUser) || isDebateArchived) && router.pathname === ROUTES.DEBATE_ID) ? 'none': ''}}
              className={`!font-mont transition-colors fill-n-4 dark:fill-white hover:fill-accent-1 hover:dark:fill-accent-1 ${deletedNotebook.loading || (!isEmpty(createAudio.data.notebookIds.find((el: any) => el.id == item.id)) && (createAudio.loading || isAudioLoading)) ? "opacity-50 pointer-events-none" : ""
                }`}
              onClick={handleDeleteNotebook}
            >
              <Icon className="w-5 h-5 " name="trash" />
            </span>
          ) : (
            <></>
            // <span
            //   className=" transition-colors  fill-n-4 dark:fill-white hover:fill-accent-1 hover:dark:fill-accent-1"
            //   onClick={() => {
            //     setEdit(false);
            //   }}
            // >
            //   <Icon className="w-5 h-5 " name="close" />
            // </span>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};

export default React.memo(ChatItem);
