import React from 'react'
import Modal from "@/components/Modal";
import Image from '@/components/Image';
import { useTranslation } from 'react-i18next';

type SuccessFullyDeletedModal = {
    visible: boolean;
    onClose: () => void;
};
const SuccessFullyDeletedModal = ({ visible, onClose }:SuccessFullyDeletedModal) => {
  const { t } = useTranslation('auth');
  return (
    <>
      <Modal
            classWrap="max-w-[32.188rem]"
            classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:top-5 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
            visible={visible}
            onClose={onClose}
          >
        <div className='py-13 flex flex-col justify-center items-center max-w-[311px] m-auto '>
          <Image src={'https://lokichatdev.blob.core.windows.net/images/delete-gif.gif'} width={228} height={228} alt='gif'/>
            <h1 className='h4 mb-4'>{t('del_account')}</h1>
            <p className='text-center text-sm text-n-4 mb-11'>{t('your_accDel"')}</p>
            <button className='px-8 py-3 rounded-xl border border-accent-1 bg-accent-1 hover:bg-transparent text-white hover:text-n-4 mb-4 w-[182px]'>{t('del_account')}</button>
            <button className='px-8 py-3 rounded-xl bg-transparent hover:bg-accent-1 text-n-4 hover:text-white duration-500 border border-accent-1 w-[182px]' onClick={onClose}>{t('cancel_btn')}</button>
        </div>
    </Modal>
    </>
  )
}
export default SuccessFullyDeletedModal