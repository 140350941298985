import { createSlice } from "@reduxjs/toolkit";
import {
  getChatLists,
  getChatById,
  updateChatFeedback,
  updateChatListId,
  storeChatGoal,
  storeMessageStream,
  updateMessageStream
} from "store/actions/chat.actions";
import {
  addToLiveNotebook,
  getChatNotebooks,
  deleteNotebookById,
  deleteNotebookByUserId,
  updateNotebookById,
} from "store/actions/notebook.actions";
import {
  getStoredChatList,
  getChatListById,
  storeChatList,
  exportChatLists,
  destroyChatList
} from "store/actions/chatList.actions";
import { RES_PER_PAGE } from "@/constants/pagination";
import { isEmpty, size } from "lodash";

const createChatInitialData = {
  messages: []
}

export const initialState = {
  createChat: {
    data: createChatInitialData,
    loading: false,
    error: null,
  },
  chatLists: {
    data: { chats: [], currentPage: 0, pages: 0, totalChats: 0, perPage: RES_PER_PAGE, isAvailableChat:false },
    loading: false,
    error: null,
  },
  addToLiveNotebook: {
    data: {},
    loading: false,
    error: null,
  },
  allLiveNotebooks: {
    data: [],
    loading: false,
    error: null,
  },
  deletedNotebook: {
    data: {},
    loading: false,
    error: null,
  },
  updatedNotebook: {
    data: {},
    loading: false,
    error: null,
  },
  regenerateChatMessage: {
    data: {},
    loading: false,
    error: null,
  },
  storeChatListData: {
    data: null,
    loading: false,
    error: null,
  },
  exportChatListData: {
    data: [],
    loading: false,
    error: null,
  },
  destroyChatListData: {
    data: null,
    loading: false,
    error: null,
  },
  storedChatLists: {
    data: [],
    loading: false,
    error: null,
  },
  storeChatGoalData: {
    data: null,
    loading: false,
    error: null
  },
  chatFeedback: {
    data: null,
    loading: false,
    error: null,
  },
  chatListDetails: {
    data: { chats: [], currentPage: 0, pages: 0, totalChats: 0, perPage: RES_PER_PAGE },
    loading: false,
    error: null,
  },
  chatWithChatList: {
    data: null,
    loading: false,
    error: null,
  },
  updateChatListId: {
    data: null,
    loading: false,
    error: null,
  },
  streamText: '',
  stream: false,
  streamError: null,
  updateStream: {
    text: '',
    loading: false,
    error: null,
  },
  storeMessageStream: {
    data: null,
    loading: false,
    error: null
  },
  updateMessageStream: {
    data: null,
    loading: false,
    error: null
  },
  stopGenerateChat: {
    keyName: "",
    value: false,
  },
  modalStates: {
    search: false,
    chatListModal: false,
    moveChatConfirmation: false,
    abortChat: false
  },
  navigationData: {
    data: null
  },
  chatToAbort: '',
  moveChatListData: { data: null },
  selectedNotebooks: [],
  promptQuestion: "",
  selectedChatListItem: { data: null },
  processingNoteBookItem: null,
  selectedModal:null
};

export const chatGptSlice = createSlice({
  name: "chatgptStore",
  initialState,
  reducers: {
    resetAllChatsData(state, action) {
      return initialState;
    },
    resetChatLoadingAndError(state, action) {
      state.createChat.loading = false;
      state.createChat.error = null;
    },
    setSelectedChatModal(state, action) {
      state.selectedModal = action.payload
    },
    resetAddToLiveNotebook(state, action) {
      state.addToLiveNotebook.loading = false;
      state.addToLiveNotebook.error = null;
      state.addToLiveNotebook.data = {};
    },
    resetUpdatedNotebook(state, action) {
      state.updatedNotebook.loading = false;
      state.updatedNotebook.error = null;
      state.updatedNotebook.data = {};
    },
    resetDeletedNotebook(state, action) {
      state.deletedNotebook.loading = false;
      state.deletedNotebook.error = null;
      state.deletedNotebook.data = {};
    },
    setStopGenerateChat(state, action) {
      state.stopGenerateChat.keyName = action.payload.keyName;
      state.stopGenerateChat.value = action.payload.value;
    },
    setSelectedNotebooks(state, action) {
      state.selectedNotebooks = action.payload;
    },
    setProcessingNoteBookItem(state, action){
      state.processingNoteBookItem = action.payload;
    },
    setSearchModal(state, action) {
      state.modalStates.search = action.payload;
    },
    setMoveChatModal(state, action) {
      state.modalStates.moveChatConfirmation = action.payload;
    },
    setChatListModal(state, action) {
      state.modalStates.chatListModal = action.payload;
    },
    setAbortChatModal(state, action) {
      state.modalStates.abortChat = action.payload;
    },
    resetStoredChatListData(state, action) {
      state.storeChatListData.loading = false;
      state.storeChatListData.data = null;
      state.storeChatListData.error = null;
    },
    resetUpdateChatListId(state, action) {
      state.updateChatListId.loading = false;
      state.updateChatListId.data = null;
      state.updateChatListId.error = null;
    },
    addSummaryDocument(state: any, action: any) {
      // kamran
      const payload = action.payload.data;
      const message = payload.message;
      const response = payload.response;
      console.log('action', message);
      if (message.fileUrl) {
        state.createChat.data.chatDocUrl = message.fileUrl;
      }
      if(isEmpty(state.createChat.data?.id)) {
        state.createChat.data.id = payload.id;
        state.createChat.data.chatModel = payload.chatModel;
        state.createChat.data.chatList = payload.chatList;
        state.createChat.data.messages.push(message);
        state.createChat.data.messages.push(response);
      } else if(state.createChat.data?.id === payload.id) {
        state.createChat.data.messages.push(message);
        state.createChat.data.messages.push(response);
      }
      state.createChat.loading = false;
      state.createChat.error = null;
    },
    resetChatAndNotebooks(state, action) {
      state.allLiveNotebooks.data = [];
      state.createChat.data = createChatInitialData;
    },
    resetNotebooks(state, action) {
      state.allLiveNotebooks.data = [];
    },
    addPromptQuestion: (state, action) => {
      state.promptQuestion = action.payload;
    },
    resetPromptQuestion(state) {
      state.promptQuestion = "";
    },
    setSelectedChatListItem(state, action) {
      state.selectedChatListItem.data = action.payload;
    },
    setAbortNavigationData(state, action) {
      state.navigationData.data = action.payload;
    },
    setMoveChatListData(state, action) {
      state.moveChatListData.data = action.payload;
    },
    updateChatListOfChat(state, action) {
      // @ts-ignore
      state.createChat.data.chatList = action.payload;
    },
    updateChatListOfModalChats(state, action) {
      const itemIndex = state.chatLists.data.chats.findIndex((chatItem: any) => chatItem.id === action.payload.chatId);
      if(itemIndex !== -1) {
        // @ts-ignore
        state.chatLists.data.chats[itemIndex]['chatList'] = action.payload.chatList;
      }
    },
    setStreamText(state, action) {
      state.streamText = action.payload;
    },
    pushNewStreamMsg(state, action) {
      const msgObject: any = action.payload;
      // @ts-ignore
      state.createChat.data.messages.push(msgObject);
    },
    pushStreamText(state, action) {
      const chunkData = action.payload;
      state.streamText += chunkData;
      
      if (chunkData !== "" && size(chunkData) > 0 && size(state.createChat.data.messages) > 0) {
        const latedtMsgIdx = state.createChat.data.messages?.length -1;
        // @ts-ignore
        state.createChat.data.messages[latedtMsgIdx]['content'] += chunkData
      }
    },
    setStream(state, action) {
      state.stream = action.payload;
    },
    setUpdateStreamText(state, action) {
      state.updateStream.text = action.payload;
    },
    resetUpdateStreamTextPush(state, action) {
      const chunk = action.payload;
      const messageId = localStorage.getItem("messageId");
      const msgToUpdateIdx = state.createChat.data?.messages.findIndex((msg: any) => msg?.responseTo === messageId);
      console.log('msgToUpdateIdx', msgToUpdateIdx);
      if (msgToUpdateIdx !== -1) {
        // @ts-ignore
        const previousMsgContent = state.createChat.data.messages[msgToUpdateIdx].content;
        localStorage.setItem("previousMsgContent", JSON.stringify(previousMsgContent));
        // @ts-ignore
        state.createChat.data.messages[msgToUpdateIdx].content = chunk;
      }
    },
    setUpdateStreamTextPush(state, action) {
      const chunk = action.payload;
      state.updateStream.text += chunk;

      if(size(chunk) > 0 && size(state.createChat.data.messages) > 0) {
        const messageId = localStorage.getItem("messageId");
        const msgToUpdateIdx = state.createChat.data?.messages.findIndex((msg: any) => msg?.responseTo === messageId);
        if (msgToUpdateIdx !== -1) {
          // @ts-ignore
          state.createChat.data.messages[msgToUpdateIdx].content += chunk;
        }
      }
    },
    removePushedMsgObject(state, action) {
      const payload = action.payload;

      if(payload?.messageId && size(state.createChat.data.messages) > 0) {
        const messageId = payload.messageId;
        const msgToRemove = state.createChat.data?.messages.findIndex((msg: any) => msg?.responseTo === messageId);
        console.log('msgToUpdateIdx---asas', msgToRemove);

        if (msgToRemove !== -1) {
          const chatMessages = state.createChat.data.messages;
          chatMessages.splice(msgToRemove, 1);
          state.createChat.data.messages = chatMessages;
        }
      }
    },
    setUpdateStreamLoading(state, action) {
      state.updateStream.loading = action.payload;
    },
    setCreateChatData(state, action) {
      const payload = action.payload;
      const msgObject = payload.messageData;
      if(size(state.createChat.data.messages) === 0) {
        state.createChat.data = {...state.createChat.data, ...payload, messages: [msgObject]};
      } else {
        // @ts-ignore
        state.createChat.data.messages = [...state.createChat.data?.messages, msgObject];
      }
    },
    pushIntoCreateChat(state, action) {
      const payload = action.payload;
      const msgObject = payload.messageData;
      const messageIndex = state.createChat.data?.messages.findIndex((msg: any) => msg.responseTo === msgObject.responseTo);
      if (messageIndex !== -1) {
        // @ts-ignore
        state.createChat.data.messages[messageIndex] = msgObject;
      } else {
        // @ts-ignore
        state.createChat.data.messages = [...state.createChat.data?.messages, msgObject];
      }
    },
    pushIntoUpdateChat(state, action) {
      const payload = action.payload;
      const messageObj = payload.messageData;

      const messageIndex = state.createChat.data?.messages.findIndex((msg: any) => msg.messageId === messageObj.messageId);
      if (messageIndex !== -1) {
        // @ts-ignore
        state.createChat.data.messages[messageIndex].content = messageObj.content;
      }
    },
    abortRegenerateOrUpdate(state, action) {
      state.createChat.data = state.createChat.data;
    },
    setStreamError(state, action) {
        state.streamError = action.payload;
    },
    resetStreamError(state, action) {
      state.streamError = null;
    },
    setUpdateStreamError(state, action) {
      state.updateStream.error = action.payload;
    },
    resetUpdateStreamError(state, action) {
      state.updateStream.error = null;
    }
  },
  extraReducers: {
    [storeChatGoal.pending.type]: (state, action: any) => {
      state.createChat.loading = true;
      state.createChat.data = state.createChat.data || createChatInitialData;
      state.storeChatGoalData.loading = true;
      state.storeChatGoalData.data = null;
      state.createChat.error = null;
    },
    [storeChatGoal.fulfilled.type]: (state: any, action: any) => {
      const msgObject = action.payload.data?.messageData;
      state.createChat.data = {...state.createChat.data, ...action.payload.data, messages: [msgObject]};
      state.storeChatGoalData.data = action.payload.data;
      state.storeChatGoalData.loading = false;
      state.createChat.loading = false;
      state.createChat.error = null;
    },
    [storeChatGoal.rejected.type]: (state, action) => {
      state.createChat.data = state.createChat.data || createChatInitialData;
      state.createChat.loading = false;
      state.storeChatGoalData.data = null;
      state.storeChatGoalData.loading = false;
      state.createChat.error = action.error?.message;
    },
    [getChatById.pending.type]: (state, action: any) => {
      state.createChat.loading = true;
      state.createChat.data = createChatInitialData;
      state.createChat.error = null;
    },
    [getChatById.fulfilled.type]: (state: any, action: any) => {
      state.createChat.data = action.payload.data;
      state.createChat.loading = false;
      state.createChat.error = null;
    },
    [getChatById.rejected.type]: (state, action) => {
      state.createChat.data = createChatInitialData;
      state.createChat.loading = false;
      state.createChat.error = action.error?.message;
    },
    // Get Chat Lists for searching
    [getChatLists.pending.type]: (state, action: any) => {
      state.chatLists.loading = true;
      state.chatLists.error = null;
    },
    [getChatLists.fulfilled.type]: (state: any, action: any) => {
      
      state.chatLists.loading = false;

      if (action.meta.arg.page === 1 || !isEmpty(action.meta.arg.search)) {//|| !isEmpty(action.meta.arg.filter) 
        state.chatLists.data.chats = action.payload.data.chats;
      } else {
        // @ts-ignore
        state.chatLists.data.chats = [ ...state.chatLists.data.chats, ...action.payload.data.chats];
      }
      state.chatLists.data.currentPage = action.payload.data.currentPage;
      state.chatLists.data.pages = action.payload.data.pages;
      state.chatLists.data.totalChats = action.payload.data.totalChats;
      state.chatLists.data.perPage = action.payload.data.perPage;
      state.chatLists.data.isAvailableChat = action.payload.data.isAvailableChat;
      state.chatLists.error = null;
      console.log(action, state.chatLists.data.chats, "Get Chat fullfilled called");
    },
    [getChatLists.rejected.type]: (state, action) => {
      state.chatLists.loading = false;
      state.chatLists.error = action.error?.message;
    },
    // Get stored chat lists
    [getStoredChatList.pending.type]: (state, action: any) => {
      state.storedChatLists.loading = true;
      state.storedChatLists.data = state.storedChatLists.data || [];
      state.storedChatLists.error = null;
    },
    [getStoredChatList.fulfilled.type]: (state: any, action: any) => {
      state.storedChatLists.data = action.payload.data;
      state.storedChatLists.loading = false;
      state.storedChatLists.error = null;
    },
    [getStoredChatList.rejected.type]: (state, action) => {
      state.storedChatLists.data = state.storedChatLists.data || [];
      state.storedChatLists.loading = false;
      state.storedChatLists.error = action.error?.message;
    },
    // Export Chat Lists Data
    [exportChatLists.pending.type]: (state, action: any) => {
      state.exportChatListData.loading = true;
      state.exportChatListData.data = [];
      state.exportChatListData.error = null;
    },
    [exportChatLists.fulfilled.type]: (state: any, action: any) => {
      state.exportChatListData.data = action.payload.data;
      state.exportChatListData.loading = false;
      state.exportChatListData.error = null;
    },
    [exportChatLists.rejected.type]: (state, action) => {
      state.exportChatListData.data = [];
      state.exportChatListData.loading = false;
      state.exportChatListData.error = action.error?.message;
    },
    // Destroy Chat List Item
    [destroyChatList.pending.type]: (state, action: any) => {
      state.destroyChatListData.loading = true;
      state.destroyChatListData.data = null;
      state.destroyChatListData.error = null;
    },
    [destroyChatList.fulfilled.type]: (state: any, action: any) => {
      state.destroyChatListData.data = action.payload.data;
      state.destroyChatListData.loading = false;
      state.destroyChatListData.error = null;
    },
    [destroyChatList.rejected.type]: (state, action) => {
      state.destroyChatListData.data = null;
      state.destroyChatListData.loading = false;
      state.destroyChatListData.error = action.error?.message;
    },
    // Add Message To Live Notebook
    [addToLiveNotebook.pending.type]: (state, action: any) => {
      state.addToLiveNotebook.loading = true;
      state.addToLiveNotebook.data = action.meta.arg;
      state.addToLiveNotebook.error = null;
    },
    [addToLiveNotebook.fulfilled.type]: (state: any, action: any) => {
      state.addToLiveNotebook.data = action.payload.data;
      state.allLiveNotebooks.data = [
        action.payload.data,
        ...state.allLiveNotebooks.data,
      ];
      state.addToLiveNotebook.loading = false;
      state.addToLiveNotebook.error = null;
    },
    [addToLiveNotebook.rejected.type]: (state, action) => {
      state.addToLiveNotebook.data = {};
      state.addToLiveNotebook.loading = false;
      state.addToLiveNotebook.error = action.error?.message;
    },
    // Get live notebooks listing
    [getChatNotebooks.pending.type]: (state, action: any) => {
      state.allLiveNotebooks.loading = true;
      state.allLiveNotebooks.data = state.allLiveNotebooks.data || [];
      state.allLiveNotebooks.error = null;
    },
    [getChatNotebooks.fulfilled.type]: (state: any, action: any) => {
      state.allLiveNotebooks.data = action.payload.data;
      state.allLiveNotebooks.loading = false;
      state.allLiveNotebooks.error = null;
    },
    [getChatNotebooks.rejected.type]: (state, action) => {
      state.allLiveNotebooks.data = [];
      state.allLiveNotebooks.loading = false;
      state.allLiveNotebooks.error = action.error?.message;
    },
    // Delete live notebooks by ID
    [deleteNotebookById.pending.type]: (state, action: any) => {
      state.deletedNotebook.loading = true;
      state.deletedNotebook.data = {};
      state.deletedNotebook.error = null;
    },
    [deleteNotebookById.fulfilled.type]: (state: any, action: any) => {
      const itemId = action.payload.data.id;
      const updatedItems = state.allLiveNotebooks.data.filter(
        (notebook: any) => notebook.id !== itemId
      );
      state.allLiveNotebooks.data = updatedItems;
      state.deletedNotebook.data = action.payload.data.id;
      state.deletedNotebook.loading = false;
      state.deletedNotebook.error = null;
      const updatedSelectedNotebooks = state.selectedNotebooks.filter(
        (selectedId: any) => selectedId.id !== itemId
      );
      state.selectedNotebooks = updatedSelectedNotebooks;
    },
    [deleteNotebookById.rejected.type]: (state, action) => {
      state.deletedNotebook.data = {};
      state.deletedNotebook.loading = false;
      state.deletedNotebook.error = action.error?.message;
    },
    [deleteNotebookByUserId.pending.type]: (state, action: any) => {
      state.allLiveNotebooks.loading = true;
      state.allLiveNotebooks.error = null;
    },
    [deleteNotebookByUserId.fulfilled.type]: (state: any, action: any) => {
      state.allLiveNotebooks.data = [];
      state.allLiveNotebooks.loading = false;
    },
    [deleteNotebookByUserId.rejected.type]: (state, action) => {
      state.allLiveNotebooks.loading = false;
      state.allLiveNotebooks.error = action.error?.message;
    },
    // Update live notebooks by ID
    [updateNotebookById.pending.type]: (state, action: any) => {
      state.updatedNotebook.loading = true;
      state.updatedNotebook.data = {};
      state.updatedNotebook.error = null;
    },
    [updateNotebookById.fulfilled.type]: (state: any, action: any) => {
      const updatedNotebook = action.payload.data;

      const notebookIndex = state.allLiveNotebooks.data.findIndex(
        (notebook: any) => notebook.id === updatedNotebook.id
      );
      if (notebookIndex !== -1) {
        state.allLiveNotebooks.data[notebookIndex] = updatedNotebook;
      }
      state.updatedNotebook.data = action.payload.data;
      state.updatedNotebook.loading = false;
      state.updatedNotebook.error = null;

      const selectedNotebookIndex = state.selectedNotebooks.findIndex(
        (notebook: any) => notebook.id === updatedNotebook.id
      );
      if (selectedNotebookIndex !== -1) {
        state.selectedNotebooks[selectedNotebookIndex] = updatedNotebook;
      }
    },
    [updateNotebookById.rejected.type]: (state, action) => {
      state.updatedNotebook.data = {};
      state.updatedNotebook.loading = false;
      state.updatedNotebook.error = action.error?.message;
    },
    [storeChatList.pending.type]: (state, action: any) => {
      state.storeChatListData.loading = true;
      state.storeChatListData.data = null;
      state.storeChatListData.error = null;
    },
    [storeChatList.fulfilled.type]: (state: any, action: any) => {
      state.storeChatListData.data = action.payload.data;
      state.storeChatListData.loading = false;
      state.storeChatListData.error = null;
    },
    [storeChatList.rejected.type]: (state, action) => {
      state.storeChatListData.data = null;
      state.storeChatListData.loading = false;
      state.storeChatListData.error = action.error?.message;
    },
    // update chat feedback
    [updateChatFeedback.pending.type]: (state, action: any) => {
      state.chatFeedback.loading = true;
      state.chatFeedback.data = null;
      state.chatFeedback.error = null;
    },
    [updateChatFeedback.fulfilled.type]: (state: any, action: any) => {
      state.chatFeedback.data = action.payload.data;
      state.chatFeedback.loading = false;
      state.chatFeedback.error = null;
    },
    [updateChatFeedback.rejected.type]: (state, action) => {
      state.chatFeedback.data = null;
      state.chatFeedback.loading = false;
      state.chatFeedback.error = action.error?.message;
    },
    // update chat feedback
    [getChatListById.pending.type]: (state, action: any) => {
      state.chatListDetails.loading = true;
      state.chatListDetails.error = null;
      state.chatListDetails.data.chats = [];
    },
    [getChatListById.fulfilled.type]: (state: any, action: any) => {
      state.chatListDetails.loading = false;

      if (action.meta.arg.page === 1 || !isEmpty(action.meta.arg.filter) || !isEmpty(action.meta.arg.search)) {
        state.chatListDetails.data.chats = action.payload.data.chats;
      } else {
        // @ts-ignore
        state.chatListDetails.data.chats = [ ...state.chatListDetails.data.chats, ...action.payload.data.chats];
      }
      state.chatListDetails.data.currentPage = action.payload.data.currentPage;
      state.chatListDetails.data.pages = action.payload.data.pages;
      state.chatListDetails.data.totalChats = action.payload.data.totalChats;
      state.chatListDetails.data.perPage = action.payload.data.perPage;
      state.chatListDetails.error = null;
    },
    [getChatListById.rejected.type]: (state, action) => {
      state.chatListDetails.loading = false;
      state.chatListDetails.data.chats = [];
      state.chatListDetails.error = action.error?.message;
    },
    [updateChatListId.pending.type]: (state, action: any) => {
      state.updateChatListId.loading = true;
      state.updateChatListId.data = null;
      state.updateChatListId.error = null;
    },
    [updateChatListId.fulfilled.type]: (state: any, action: any) => {
      state.updateChatListId.data = action.payload.data;
      state.updateChatListId.loading = false;
      state.updateChatListId.error = null;
    },
    [updateChatListId.rejected.type]: (state, action) => {
      state.updateChatListId.loading = false;
      state.updateChatListId.data = null;
      state.updateChatListId.error = action.error?.message;
    },

    // Store Stream Messages
    [storeMessageStream.pending.type]: (state, action: any) => {
      state.storeMessageStream.loading = true;
      state.storeMessageStream.data = null;
      state.storeMessageStream.error = null;
    },
    [storeMessageStream.fulfilled.type]: (state: any, action: any) => {
      state.storeMessageStream.data = action.payload.data;
      state.storeMessageStream.loading = false;
      state.storeMessageStream.error = null;
    },
    [storeMessageStream.rejected.type]: (state, action) => {
      state.storeMessageStream.loading = false;
      state.storeMessageStream.data = null;
      state.storeMessageStream.error = action.error?.message;
    },

    // Update Stream Message
    [updateMessageStream.pending.type]: (state, action: any) => {
      state.updateMessageStream.loading = true;
      state.updateMessageStream.data = null;
      state.updateMessageStream.error = null;
    },
    [updateMessageStream.fulfilled.type]: (state: any, action: any) => {
      state.updateMessageStream.data = action.payload.data;
      const messageData = action.payload.data?.messageData;
      console.log('action', action.payload);
      if(size(state.createChat.data.messages) > 0 && !isEmpty(messageData)) {

        const messageIndex = state.createChat.data?.messages.findIndex((msg: any) => msg.messageId === messageData?.messageId);
        if (messageIndex !== -1) {
          if(state.createChat.data.messages[messageIndex].content !== messageData.content)
          // @ts-ignore
          state.createChat.data.messages[messageIndex].content = messageData.content;
          state.createChat.data.messages[messageIndex]['isEditedAt'] = messageData.isEditedAt;
        }
      }
      state.updateMessageStream.loading = false;
      state.updateMessageStream.error = null;
    },
    [updateMessageStream.rejected.type]: (state, action) => {
      state.updateMessageStream.loading = false;
      state.updateMessageStream.data = null;
      state.updateMessageStream.error = action.error?.message;
    },
  },
});

export const {
  resetNotebooks,
  resetAllChatsData,
  resetChatLoadingAndError,
  resetAddToLiveNotebook,
  resetDeletedNotebook,
  setStopGenerateChat,
  setSelectedNotebooks,
  resetUpdatedNotebook,
  setSearchModal,
  resetStoredChatListData,
  addSummaryDocument,
  resetChatAndNotebooks,
  addPromptQuestion,
  resetPromptQuestion,
  setSelectedChatListItem,
  resetUpdateChatListId,
  setMoveChatModal,
  setMoveChatListData,
  setChatListModal,
  setStream,
  setStreamText,
  pushNewStreamMsg,
  pushStreamText,
  pushIntoCreateChat,
  pushIntoUpdateChat,
  setCreateChatData,
  setUpdateStreamText,
  setUpdateStreamLoading,
  setUpdateStreamTextPush,
  updateChatListOfChat,
  updateChatListOfModalChats,
  abortRegenerateOrUpdate,
  setAbortChatModal,
  setAbortNavigationData,
  setStreamError,
  resetStreamError,
  resetUpdateStreamError,
  setUpdateStreamError,
  setProcessingNoteBookItem,
  resetUpdateStreamTextPush,
  removePushedMsgObject,
  setSelectedChatModal
} = chatGptSlice.actions;

export default chatGptSlice.reducer;