import { createSlice } from "@reduxjs/toolkit";
import {
  storeDevice,
  getAllDevices,
  deleteFromAllDevices,
  deleteDeviceById,
} from "../actions/device.actions";

export const initialState = {
  storeDevice: {
    data: null,
    loading: false,
    error: null,
  },
  allDevicesList: {
    data: [],
    loading: false,
    error: null,
  },
  deleteAllDevices: {
    data: null,
    loading: false,
    error: null,
  },
  deletedDevice: {
    data: null,
    loading: false,
    error: null,
  },
};

export const deviceSlice = createSlice({
  name: "deviceStore",
  initialState,
  reducers: {
    resetAllDevicesData(state, action) {
      return initialState;
    },
    resetDeleteDeviceData(state, action) {
      state.deletedDevice.loading = false;
      state.deletedDevice.data = null;
      state.deletedDevice.error = null;
    }
  },
  extraReducers: {
    [storeDevice.pending.type]: (state, action) => {
      state.storeDevice.loading = true;
      state.storeDevice.data = null;
      state.storeDevice.error = null;
    },
    [storeDevice.fulfilled.type]: (state, action) => {
      state.storeDevice.loading = false;
      state.storeDevice.data = action.payload.data;
      state.storeDevice.error = null;
    },
    [storeDevice.rejected.type]: (state, action) => {
      state.storeDevice.data = null;
      state.storeDevice.loading = false;
      state.storeDevice.error = action.error?.message;
    },
    [getAllDevices.pending.type]: (state, action) => {
      state.allDevicesList.loading = true;
      state.allDevicesList.error = null;
    },
    [getAllDevices.fulfilled.type]: (state, action) => {
      state.allDevicesList.loading = false;
      state.allDevicesList.data = action.payload.data;
      state.allDevicesList.error = null;
    },
    [getAllDevices.rejected.type]: (state, action) => {
      state.allDevicesList.loading = false;
      state.allDevicesList.error = action.error?.message;
    },
    [deleteDeviceById.pending.type]: (state, action) => {
      state.deletedDevice.loading = true;
      state.deletedDevice.data = null;
      state.deletedDevice.error = null;
    },
    [deleteDeviceById.fulfilled.type]: (state, action) => {
      state.deletedDevice.loading = false;
      state.deletedDevice.data = action.payload.data;
      state.deletedDevice.error = null;

      const item = action.payload.data;
      const allDeviceItems = state.allDevicesList.data.filter(
        (device: any) => device.id !== item.id
      );
      state.allDevicesList.data = allDeviceItems;
    },
    [deleteDeviceById.rejected.type]: (state, action) => {
      state.deletedDevice.data = null;
      state.deletedDevice.loading = false;
      state.deletedDevice.error = action.error?.message;
    },
    [deleteFromAllDevices.pending.type]: (state, action) => {
      state.deleteAllDevices.loading = true;
      state.deleteAllDevices.data = null;
      state.deleteAllDevices.error = null;
    },
    [deleteFromAllDevices.fulfilled.type]: (state, action) => {
      state.deleteAllDevices.loading = false;
      state.deleteAllDevices.data = action.payload.data;
      state.deleteAllDevices.error = null;
      state.allDevicesList.data = [];
    },
    [deleteFromAllDevices.rejected.type]: (state, action) => {
      state.deleteAllDevices.data = null;
      state.deleteAllDevices.loading = false;
      state.deleteAllDevices.error = action.error?.message;
    },
  },
});

export const { resetAllDevicesData, resetDeleteDeviceData } = deviceSlice.actions;

export default deviceSlice.reducer;
