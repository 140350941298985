import { useEffect, useState } from "react";
import { useColorMode } from "@chakra-ui/color-mode";
import { twMerge } from "tailwind-merge";
import Image from "@/components/Image";
import Select from "@/components/Select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { languageActions, setLanguage } from '../../../store/actions/language.actions';
import { RootState } from "store";

type AppearanceProps = {};

const Appearance = ({ }: AppearanceProps) => {
    const { colorMode, setColorMode } = useColorMode();
    const { t, i18n } = useTranslation('translation');
    const dispatch = useDispatch();
    const selectedLanguage = useSelector((state: RootState) => state.language);
    // console.log("It's my selected language", selectedLanguage);

    const languages = [
        {
            id: "0",
            title: t('English'),
            locale: 'en-US'
        },
        {
            id: "1",
            title: t('Spanish'),
            locale: 'es'
        },
        {
            id: "2",
            title: t('French'),
            locale: 'fr'
          },

          {
            id: "3",
            title: t('German'),
            locale: 'de'
          },
          {
            id: "4",
            title: t('Italian'),
            locale: 'it'
          },
          {
            id: "5",
            title: t('Japanese'),
            locale: 'ja'
          },
          {
            id: "6",
            title: t('Norwegian'),
            locale: 'nb'
          },
          {
            id: "7",
            title: t('Portuguese'),
            locale: 'pt'
          },
          {
            id: "8",
            title: t('Russian'),
            locale: 'ru'
          },
          {
            id: "9",
            title: t('Chinese'),
            locale: 'zh'
          },
          {
            id: "10",
            title: t('Dutch'),
            locale: 'nl'
          },
    ];

    const handleLanguageChange = (item: any) => {
        dispatch(languageActions.setLanguage(item.locale));
        i18n.changeLanguage(item.locale);
        setLang(item)

        // console.log('data is here', item);
    };

    const getLanguage = () => {
        const storedLanguage = localStorage.getItem('i18nextLng');
        return storedLanguage ? languages.find(lang => lang.locale === storedLanguage) : null;
    };

    // const { i18n } = useTranslation()
    // let index = languages.findIndex((item: any) => item.locale === i18n.language)
    // if (index === -1) {
    //     index = 0
    // }

    const [lang, setLang] = useState<any>(getLanguage()||languages[0]);
        // i18n.language === "es"? languages[1] :languages[0] ||

    const items = [
        {
            title: t('light_mode'),
            image: "https://lokichatdev.blob.core.windows.net/images/theme-light.svg",
            active: colorMode === "light",
            onClick: () => setColorMode("light"),
        },
        {
            title: t('dark_mode'),
            image: "https://lokichatdev.blob.core.windows.net/images/theme-dark.svg",
            active: colorMode === "dark",
            onClick: () => setColorMode("dark"),
        },
    ];

    return (
        <>
            <div className="mb-8 h4 !font-mont !font-extrabold">{t('appearance')}</div>
            <div className="mb-5 base1 font-semibold">{t('appearance')}</div>
            <div className="flex mb-8 pr-12 space-x-8 md:pr-0">
                {items?.map((item, index) => (
                    <button
                        className={twMerge(
                            `basis-1/2 p-3 border-4 border-transparent bg-n-2 rounded-2xl text-left transition-colors dark:bg-n-6 dark:text-n-3/50 ${item.active &&
                            "bg-transparent border-primary-1 text-n-6/50 dark:text-n-1 dark:bg-transparent"
                            }`
                        )}
                        key={index}
                        onClick={item.onClick}
                    >
                        <div className="mb-3">
                            <Image
                                className="w-full rounded-xl"
                                src={item.image}
                                width={128}
                                height={80}
                                alt=""
                            />
                        </div>
                        {item.title}
                    </button>
                ))}
            </div>
            <div className="flex items-center md:block">
                <div className="mr-auto base1 font-semibold md:mb-4">
                    {t('primary_language')}
                </div>

                <Select
                    className="min-w-[13.125rem]"
                    classButton="bg-n-3/75 dark:bg-n-6 dark:shadow-[inset_0_0_0_0.0625rem_#232627]"
                    items={languages}
                    value={lang}
                    onChange={(value: any) => {
                        handleLanguageChange(value);
                        setLanguage(value)
                        // i18n.changeLanguage(value.locale)
                    }}
                />
            </div>
        </>
    );
};

export default Appearance;
