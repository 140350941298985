import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { RiArrowDownSFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { setMoveChatListData, setMoveChatModal } from "store/slices/ChatGptSlice";
import { useTranslation } from "react-i18next";
import { useRouter } from 'next/router';
import { ROUTES } from "@/constants/routes";
import { size } from "lodash";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const MoveChatDropdown = (props: any) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation('translation');

  const { pathname } = router;
  const { chatId, chatList, chatButton, showDropDown } = props;

  const chatData = useSelector((state: any) => state.chatGptStore);
  const { storedChatLists, stream, updateStream } = chatData;

  const [showMoveChat, setShowMoveChat] = useState(false);

  useEffect(() => {
    handleShowMoveChat();
  }, [router])

  const handleShowMoveChat = () => {
    if (pathname == ROUTES.CHAT || pathname === ROUTES.CHAT_ID || pathname === ROUTES.CHAT_LIST_ID || pathname === ROUTES.CHAT_LIST) {
      setShowMoveChat(true);
    } else {
      setShowMoveChat(false);
    }
  }

  const handleMoveChatList = (event: any, item: any) => {
    event.preventDefault();
    dispatch(setMoveChatModal(true));
    const data = { chatId: chatId, chatListId: item.id, chatlist: item };
    dispatch(setMoveChatListData(data));
  }

  const showMoveDropDown = () => {
    const result = storedChatLists.data.find((el: any) => el.id === chatList);
    return result && size(storedChatLists.data) > 1 || !result && size(storedChatLists.data) > 0;
  }

  const isChatStreamLoading = () => {
    return stream || updateStream.loading;
  }

  return (
    <>
      {(storedChatLists && showMoveDropDown() && storedChatLists.data && size(storedChatLists.data) > 0) && <Menu as="div" className="relative text-left">
        {(showMoveChat || showDropDown) &&
         <Menu.Button disabled={isChatStreamLoading()} className={`${chatButton ? "!bg-transparent border border-n-3 h-8 py-5 dark:border-n-5 ss:gap-1 ss:h-6 !text-sm ss:!text-[10px] whitespace-nowrap" : "bg-white"} sm:w-full w-[200px]  text-n-4 dark:bg-n-5 dark:text-white hover:opacity-80 rounded-md flex items-center justify-between h-6 px-2 text-xs gap-2`}>
          <svg className="sm:hidden block" width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10890_45439)">
              <path d="M24.073 23.0138L29.041 18.0823M29.041 18.0823L24.073 13.1508M29.041 18.0823H15.3789C14.0613 18.0823 12.7977 18.6019 11.866 19.5267C10.9343 20.4515 10.4109 21.7059 10.4109 23.0138C10.4109 24.3217 10.9343 25.5761 11.866 26.5009C12.7977 27.4257 14.0613 27.9453 15.3789 27.9453H16.6209" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>
          <span className="!font-mont">{(chatButton ? t('move_currentChat') : t('move_chat'))}</span>
          <RiArrowDownSFill className="text-base sm:hidden block" />
        </Menu.Button>
        }

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={`${chatButton ? "w-[238px] max-h-[250px]" : "w-[190px]  max-h-[200px]"} origin-top-right rounded-lg overflow-auto bg-white dark:bg-n-5 absolute z-10 mt-1 right-0 shadow-xl`}>
            <p className="text-n-6 dark:text-n-3 text-sm !font-mont font-semibold px-4 pt-4 py-2">{t('select_chatlist')}</p>
            {storedChatLists?.data?.map((item: any, idx: number) => {
              return item.id != chatList && (
                <Menu.Item key={idx}>
                  {({ active }) => (
                    <button
                      key={item.title}
                      onClick={(event) => handleMoveChatList(event, item)}
                      className={classNames(active ? "" : "", "flex gap-3 items-center text-sm rounded-lg text-n-4 dark:text-white hover:opacity-90 p-3 bg-transparent hover:bg-n-2 dark:hover:bg-n-4 w-full")}
                    >
                      <div className="w-3 h-3 rounded border border-n-4 dark:border-n-3 shrink-0" style={{ background: item.color }}></div>
                      <p className="truncate">{item.title}</p>
                    </button>
                  )}
                </Menu.Item>
              )
            })}
          </Menu.Items>
        </Transition>
      </Menu>}
    </>
  );
}

export default MoveChatDropdown;