
import { initialState as audioInitialState } from '../slices/AudioSlice';
import { initialState as transcriptionInitialState } from '../slices/TranscriptionSlice'
import { initialState as authInitialState } from '../slices/AuthSlice';
import { initialState as chatInitialState } from '../slices/ChatGptSlice';
import { initialState as devicesInitialState } from '../slices/DeviceSlice';
import { initialState as goalInitialState } from '../slices/GoalSlice';
import { initialState as imageLibraryInitialState } from '../slices/ImageLibrarySlice';
import { initialState as photoGenerationInitialState } from '../slices/PhotoGenerationSlice';
import { initialState as planInitialState } from '../slices/PlanSlice';
import { initialState as voiceListsInitialState } from '../slices/VoicesListSlice';
import { initialState as superUserInitialState } from '../slices/SuperUserSlice';
import { initialState as notificationsInitialState } from '../slices/NotificationSlice';
import { initialState as debateInitialState } from '../slices/DebateSlice';
import { initialState as languageInitialState } from '../slices/languageSlice';
import { initialState as preferenceInitialState } from '../slices/PreferenceSlice';
import { initialState as translationInitialState } from '../slices/TranslationSlice';

export const migrations = {
  2.33: (state: any) => {
    return {
      ...state,
      authStore: authInitialState,
      audioStore: audioInitialState,
      transcriptionStore: transcriptionInitialState,
      translationStore: translationInitialState,
      chatGptStore: chatInitialState,
      voicesListStore: voiceListsInitialState,
      photoGenerateStore: photoGenerationInitialState,
      imageLibraryStore: imageLibraryInitialState,
      planStore: planInitialState,
      goalStore: goalInitialState,
      deviceStore: devicesInitialState,
      superUserStore: superUserInitialState,
      notificationStore: notificationsInitialState,
      debateStore: debateInitialState,
      preferenceStore: preferenceInitialState,
      language: languageInitialState,
    }
  },
}
