import React, { Component, ReactNode } from 'react';
// @ts-ignore
// import { logger } from 'lib/logger';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<React.PropsWithChildren<{}>, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);

    // Define a state variable to track whether there is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    // Update state so the next render will show the fallback UI
    console.log('getDerivedStateFromError Called', _);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can use your own error logging service here
    console.log('ComponentDidCatch Called');
    console.log('error', error);
    console.log('errorInfo', errorInfo);
    this.setState({hasError: true});
    try {

    } catch(err){
      console.log('didCatch err', err);
    this.setState({hasError: true});
    }
// @ts-ignore
    // if(logger) {
    //   logger?.error({ message: error?.message, stack: error?.stack, errorInfo });
    // }
  }  

  render() {
    // Check if the error is thrown
    if (this.state?.hasError) {
      console.log('inside this one', this.state.hasError);
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button>
        </div>
      );
    } else {
    // Return children components in case of no error
    return this.props?.children || null;
    }
  }
}

export default ErrorBoundary;
