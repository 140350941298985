import React from "react";
import { formatDateRelative, formatOnlyDate } from "lib/DateTime";
import { getPlainTextFromMarkdown } from "lib/getPlainText";
import { useDispatch } from "react-redux";
import {
  resetChatAndNotebooks,
  setSearchModal,
  setSelectedNotebooks,
} from "store/slices/ChatGptSlice";
import MoveChatDropdown from "../MoveChatDropdown";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@/constants/routes";
import { resetCreateAudioData } from "store/slices/AudioSlice";
import Markdown from "react-markdown";

type ItemProps = {
  title: any;
  date?: any;
  list: any;
};

const ChatItem = ({ title, date, list }: ItemProps) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation("translation");

  const handleJump = (item: any) => {
    dispatch(setSearchModal(false));
    if (router.query.id !== item.id) {
      dispatch(resetChatAndNotebooks(null));
      dispatch(resetCreateAudioData(null));
      dispatch(setSelectedNotebooks([]));
      router.push(`/${ROUTES.CHAT}/${item.id}`);
    }
  };

  return (
    <div className="">
      <div className="flex items-center py-3 md:pt-6">
        <div className="h6">{title}</div>
        {date && (
          <div className="ml-5 caption1 text-n-4/75">
            {formatOnlyDate(date)}
          </div>
        )}
      </div>
      <div className="space-y-3">
        {list.map((x: any) => (
          <div
            className="relative rounded-lg p-5 cursor-pointer transition-colors bg-n-3/50 dark:bg-n-6"
            key={x.id}
            onClick={() => handleJump(x)}
          >
            <div className="w-full">
              <Markdown className="mb-1 truncate contentEditable base1 font-semibold font-mont">
                {getPlainTextFromMarkdown(x?.messages?.[0]?.content) ?? "Empty"}
              </Markdown>
              <div className="truncate caption1 text-n-4/75">
                {getPlainTextFromMarkdown(x?.messages?.[1]?.content ?? "Empty")}
              </div>
            </div>
            <div className="absolute top-1/2 right-5 -translate-y-1/2 caption1 text-n-4/50 group-hover:hidden md:right-0">
              {list.createdAt}
            </div>
            <div className="flex sm:flex-col justify-between items-center sm:items-start gap-4 mt-3">
              <div className="flex items-center rounded-lg whitespace-nowrap text-xs text-n-7/75 dark:text-n-3/75 w-[240px]">
                <span className="truncate !font-mont">{t("last_edit")} </span>
                <span className="mr-2 font-semibold !font-mont text-n-3 dark:text-n-7">
                  {list.id}
                </span>
                <span>{formatDateRelative(x.updatedAt)}</span>
              </div>
              <div
                className="flex items-start gap-2"
                onClick={(e: any) => e.stopPropagation()}
              >
                {list?.length > 1 && (
                  <MoveChatDropdown
                    chatId={x.id}
                    showDropDown={true}
                    chatList={x?.chatList ?? null}
                    className=""
                  />
                )}
                <div
                  className="rounded cursor-pointer bg-n-1 caption1 font-semibold text-n-4 md:right-0 dark:bg-n-5 dark:text-n-3 w-[105px] px-2 flex justify-center py-0.5"
                  onClick={() => handleJump(x)}
                >
                  <span className="truncate">{t("jump")}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatItem;
