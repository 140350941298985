import React, { useState } from "react";
import ChatSearchListing from "./ChatSearchListing";
import DebateSearchListing from "./DebateSearchListing";
import { useTranslation } from "react-i18next";

const ChatSearchTabs = (props: any) => {
  const { t } = useTranslation('translation');
  const [selectedTabIdx, setSelectedTabIdx] = useState<number>(0);

  const tabs = [
    { name: t('chat_search'), current: true },
    { name: t('debate_search'), current: false },
  ];
  
  return (
    <>
    <div>
      <nav
        className=" flex sm:flex-col items-center justify-between px-12 gap-2 py-1.5 border-b border-n-3 dark:border-n-6"
        aria-label="Tabs"
      >
        {tabs?.map((tab, i) => (
          <a
            key={tab?.name}
            className={`${
              i === selectedTabIdx
                ? " bg-primary-1 focus:outline-none text-white"
                : "text-black dark:text-white"
            } w-full flex justify-center items-center py-2.5 cursor-pointer text-base rounded-md hover:text-white hover:bg-primary-1 font-semibold !font-mont`}
            onClick={() => {setSelectedTabIdx(i);props.setSelectedTabIdx(i)}}
          >
            {tab?.name}
          </a>
        ))}
      </nav>
      {selectedTabIdx === 0 && <ChatSearchListing />}
      {selectedTabIdx === 1 && <DebateSearchListing />}
    </div>
    </>
  );
};
export default ChatSearchTabs;
