import React, { useEffect, useState } from "react";
import Icon from "@/components/Icon";
import Select from "@/components/Select";
import Item from "./Item";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, size, throttle } from "lodash";
import { deleteChatList, destroyChatList, getChatListById, getStoredChatList } from "store/actions/chatList.actions";
import { resetAllChatsData, resetChatAndNotebooks, setChatListModal, setSelectedChatListItem, setSelectedNotebooks } from "store/slices/ChatGptSlice";
import { resetAllAudioData, resetCreateAudioData } from "store/slices/AudioSlice";
import { useRouter } from "next/router";
import { ROUTES } from "@/constants/routes";
import { RES_PER_PAGE } from "@/constants/pagination";
import NoChatlistPlaceholder from "@/components/NoChalistPlaceholder";
import ChatSearchSkelton from "@/components/ChatSearchSkelton";
import { useTranslation } from "react-i18next";
import Notify from "@/components/Notify";

const throttledSearch = throttle((chatListId, search, dateSpan, page, dispatch) => {
  const params = {
    chatListId: chatListId,
    search: search,
    filter: dateSpan.id || "",
    page: page,
    perPage: RES_PER_PAGE,
  };
  dispatch(getChatListById(params));
}, 1500);

const ChatListModal = (props: any) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t } = useTranslation('translation');

  const chatData = useSelector((state: any) => state.chatGptStore);
  const { chatListDetails } = chatData;
  const { chatListItem, onClose, chatName } = props;

  const [search, setSearch] = useState<string>("");
  const [dateSpan, setDateSpan] = useState<any>("");
  const [page, setPage] = useState<number>(1);
  const [deletingChats, setDeletingChats] = useState<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dates = [
    {
      id: "all",
      title: t('all'),
    },
    {
      id: "today",
      title: t('today'),
    },
    {
      id: "lastWeek",
      title: t('last_week'),
    },
    {
      id: "last30Days",
      title: t('last_days'),
    },
    {
      id: "older",
      title: t('older'),
    },
  ];

  useEffect(() => {
    try {
      if (!isEmpty(chatListItem)) {
        const params = {
          chatListId: chatListItem.id,
          search: search,
          filter: dateSpan.id || "",
          page: page,
          perPage: RES_PER_PAGE,
        };
        dispatch(getChatListById(params));
      }
    } catch (error) {
      
    }
    
  }, [dateSpan]);

  const handleTextSearch = (event: any) => {
    const inputValue = event.target.value;
    setSearch(inputValue);

    throttledSearch(chatListItem.id, inputValue, dateSpan, page, dispatch);
  };

  const handleNewChat = (event: any) => {
    if (deletingChats && deletingChats?.length) {
      setShowDeleteModal(true);
    }
    else {
      event.preventDefault();
      dispatch(setSelectedChatListItem(chatListItem));
      dispatch(resetChatAndNotebooks(null));
      // dispatch(resetAllAudioData(null));
      dispatch(resetCreateAudioData(null));
      dispatch(setChatListModal(false));
      dispatch(setSelectedNotebooks([]));
      onClose();
      router.push(ROUTES.CHAT_LIST);
    }
  };

  return (
    <form className="" action="" onSubmit={(e) => e.preventDefault()}>
   {size(chatListDetails?.data?.chats) === 0 && !chatListDetails.loading && dateSpan === '' && search === '' ? (
        <div className="h-[80vh] flex flex-col items-center justify-center">
          <NoChatlistPlaceholder />
          <button onClick={handleNewChat} className="btn-blue flex justify-center gap-2 mt-5"><Icon name="plus" />{t('start_chat')}</button>
        </div>
      ) : (
        <>
        {
          chatListItem?.chatsCount > 0 && (
            <div className="relative border-b border-n-3 dark:border-n-6">
              <button
                className="group absolute top-7 left-10 outline-none md:hidden"
                type="submit"
              >
                <Icon
                  className="w-8 h-8 fill-n-4/50 transition-colors group-hover:fill-n-7 dark:group-hover:fill-n-3"
                  name="search-1"
                />
              </button>
              <input
                className="w-full h-22 pl-24 pr-5 bg-transparent border-none outline-none h5 text-n-7 placeholder:text-n-4/50 md:h-18 md:pl-18 dark:text-n-1"
                type="text"
                name="search"
                placeholder={t('search')}
                value={search}
                onChange={handleTextSearch}
              />
            </div>
          )
        }
            <div className="pt-5 px-8 pb-6 md:px-6 ">
            <div className="flex justify-between gap-3 items-center mb-5 md:block md:space-y-4 md:mb-0">
              <h6 className="h6 md:max-w-full max-w-[300px] truncate">{chatName}</h6>
              {
                chatListItem?.chatsCount > 0 && (
                <Select
                  className="w-[50%] md:w-full"
                  classButton="h-11 rounded-full shadow-[inset_0_0_0_0.0625rem_#DADBDC] caption1 dark:shadow-[inset_0_0_0_0.0625rem_#2A2E2F] dark:bg-transparent"
                  classOptions="min-w-full"
                  classIcon="w-5 h-5 fill-n-4/50"
                  classArrow="dark:fill-n-4"
                  icon="clock"
                  placeholder={t('all')}
                  items={dates}
                  value={dateSpan}
                  onChange={setDateSpan}
                />
                )
              }
            </div>
            <div>
            {size(chatListDetails?.data?.chats) === 0 && chatListDetails.loading ? (
              <div className="h-[60vh] overflow-auto scrollbar-none">
                <ChatSearchSkelton/>
                <ChatSearchSkelton/>
                <ChatSearchSkelton/>
                <ChatSearchSkelton/>
                <ChatSearchSkelton/>
                <ChatSearchSkelton/>
                <ChatSearchSkelton/>
                <ChatSearchSkelton/>
              </div>
           ):
           
             <div className="h-[60vh] overflow-auto scrollbar-none">
              {size(chatListDetails?.data?.chats) > 0 ? (
                <Item
                  title={t('all_chat')}
                  date={new Date()}
                  list={chatListDetails.data.chats}
                  onClose={onClose}
                  chatListItem={chatListItem}
                        deletingChats={deletingChats}
                        setDeletingChats={setDeletingChats}
                />
              ):
              (
                <p style={{
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  fontSize: '30px',
                  marginTop: '6rem'
                }}>{t('chatData_notFound')}</p>
              )
              }
                    {showDeleteModal && (
                      <Notify
                        className="mt-20 md:px-10 absolute right-22 bottom-22"
                        iconDelete
                      >
                        <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">
                          {"Are you sure?"}
                        </div>

                        <div className="flex justify-center">
                          <button
                            className="btn-stroke-light btn-medium md:min-w-[6rem]"
                            onClick={() => setShowDeleteModal(false)}
                          >
                            {"Cancel"}
                          </button>

                          <button
                            className="btn-blue btn-medium ml-3 md:min-w-[6rem]"
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(deleteChatList(deletingChats))
                                .then((actionResponse: any) => {
                                  if (actionResponse.type.endsWith("fulfilled")) {
                                    console.log(
                                      "Successfully destroyed chat list item:",
                                      actionResponse.payload
                                    );
                                    dispatch(getStoredChatList());
                                  } else {
                                    console.error(
                                      "Failed to destroy chat list item:",
                                      actionResponse.error.message
                                    );
                                  }
                                })
                                .catch((error: any) => {
                                  console.error("Unexpected error:", error);
                                });
                              // dispatch(resetAllChatsData(null));
                              dispatch(setChatListModal(false));
                              dispatch(getStoredChatList());

                              router.push(ROUTES.CHAT);
                              setShowDeleteModal(false);
                              // toast.dismiss(t.id);
                            }}
                          >
                            {"Yes"}
                          </button>
                        </div>
                      </Notify>
                    )}
             </div>
}
            </div>
            {!chatListDetails.loading && chatListDetails?.data?.totalChats > size(chatListDetails?.data?.chats) && (
            <button
              type="button"
              className="btn-blue block mb-7"
              disabled={size(chatListDetails?.data?.chats) == chatListDetails?.data?.totalChats || chatListDetails.loading}
              onClick={() => {
                const params = {
                  chatListId: chatListItem.id,
                  search: search,
                  filter: dateSpan.id || "",
                  page: page + 1,
                  perPage: RES_PER_PAGE,
                };
                dispatch(getChatListById(params));
                setTimeout(() => {
                  setPage(page + 1);
                }, 10);
              }}
            >
              {t('load_more')}
            </button>
          )}
            <button
                className={`${deletingChats?.length ? 'btn-red' : 'btn-blue'} w-full`}
              disabled={chatListDetails.loading}
              onClick={handleNewChat}
            >
                {deletingChats?.length ? `Delete (${deletingChats.length}) Chat${deletingChats.length > 1 ? 's' : ''}` : t('new_chat')}
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default ChatListModal;
