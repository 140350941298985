import React, { useEffect, useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import { twMerge } from "tailwind-merge";
import Icon from "@/components/Icon";
import Modal from "@/components/Modal";
import AddChatList from "@/components/AddChatList";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, size } from "lodash";
import Notify from "@/components/Notify";
import { toast } from "react-hot-toast";
import { resetStoredChatListData, setAbortChatModal, setAbortNavigationData, setChatListModal } from "store/slices/ChatGptSlice";
import ChatListModal from "@/components/Modal/ChatListModal";
import { useTranslation } from "react-i18next";
import { deleteChatList, destroyChatList, getStoredChatList } from "store/actions/chatList.actions";

type ChatListProps = {
  visible?: boolean;
};

const ChatList = ({ visible }: ChatListProps) => {
  const router = useRouter();
  const dispatch: any = useDispatch();
  const { t:lt } = useTranslation('translation');

  const chatData = useSelector((state: any) => state.chatGptStore);
  const debateData = useSelector((state: any) => state.debateStore);
  const { storedChatLists, storeChatListData, modalStates, stream, streamText, updateStream } = chatData;
  const {
    updateDebate,
    updateDebateMessage,
    updateDebateBotMessage,
  } = debateData;

  const [createChatListModal, setCreateChatListModal] = useState<boolean>(false);
  const [selectedChatItem, setSelectedChatItem] = useState<any>(null);
  const [chatName, setChatName] = useState<any>();
  const [hover, setHover] = useState<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    try {
      if(!isEmpty(storeChatListData.data) && !storeChatListData.loading) {
        setCreateChatListModal(false);
        toast((t) => (
          <Notify iconCheck>
            <div className="h6 sm:text-sm">{lt(selectedChatItem ? 'chat_listupdated' : 'chat_listCreate')}</div>
          </Notify>
        ),
        { duration: 1400 });
        setSelectedChatItem(null)
        setTimeout(() => {
          dispatch(resetStoredChatListData(null));
        }, 2400)
      }
    } catch (error) {
      
    }
    
  }, [storeChatListData.data]);

  const handleChatNavigation = (chatItem: any) => {
    console.log('Im called', chatItem);
    setChatName(chatItem.title);
    dispatch(setChatListModal(true));
    setSelectedChatItem(chatItem);
  }

  const handleChatModalClose = () => {
    dispatch(setChatListModal(false));
    setSelectedChatItem(null);
  }

  const isChatOrDebateLoading = (item: any) => {
    try {
      const data = {
        clickHandler: () => handleChatNavigation(item),
        url: null
      };
      if (stream && size(streamText) >= 0) {
        // abortStream Event
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else if (updateStream.loading && size(updateStream.text)) {
        // abort updateStream Event
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else if (updateDebate.loading || updateDebateMessage.loading || updateDebateBotMessage.loading) {
        dispatch(setAbortNavigationData(data));
        dispatch(setAbortChatModal(true));
      } else {
        handleChatNavigation(item);
      };
    } catch (error) {
      
    }
    
  }

  return (
    <>
      <div className="mb-auto">
        <Disclosure defaultOpen={true}>
          <Disclosure.Button
            className={`flex items-center w-full h-12 text-left base2 text-n-4/75 transition-colors hover:text-n-3 ${
              visible ? "justify-center px-3" : "px-5"
            }`}
          >
            <Icon
              className="fill-n-4 transition-transform ui-open:rotate-180"
              name="arrow-down"
            />
            {!visible && <div className="ml-5">{lt('chat_list')}</div>}
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className={`${visible && "px-2"}`}>
              {storedChatLists?.data?.map((item: any) => (
                <button
                  onClick={(e: any) => {
                    (e?.target?.nodeName === 'BUTTON' || e?.target?.nodeName === 'DIV') && isChatOrDebateLoading(item);
                  }}
                  className={twMerge(
                    `flex items-center w-full h-12 rounded-lg text-n-3/75 base2 font-semibold transition-colors hover:text-n-1 ${
                      visible ? "px-3" : "px-5"
                    } ${ router.query?.id === item.id && "text-n-1 bg-gradient-to-l from-[#323337] to-[rgba(80,62,110,0.29)]"}`
                  )}
                  key={item.id}
                  onMouseEnter={() => setHover(item.id)}
                  onMouseLeave={() => setHover(null)}
                >
                  <div className="flex justify-center items-center w-6 h-6">
                    <div
                      className="w-3.5 h-3.5 border border-n-3 rounded"
                      style={{ backgroundColor: item.color }}
                    />
                  </div>
                  {!visible && (
                    <>
                      <div className="ml-5 truncate max-w-[160px]">{item.title}</div>
                      {hover === item.id && <nav
                        className=" transition-colors mx-2 fill-n-4 dark:fill-white hover:fill-primary-1 hover:dark:fill-primary-1"
                        onClick={() => {
                          setCreateChatListModal(true);
                          setSelectedChatItem(item);
                        }}
                      > 
                        <Icon className="w-4 h-4 " name="edit" />
                      </nav>}
                      {hover === item.id && <nav
                        className=" transition-colors mr-2 fill-n-4 dark:fill-white hover:fill-red-600 hover:dark:fill-red-600"
                        onClick={() => {
                          setShowDeleteModal(true);
                          setSelectedChatItem(item);
                        }}
                      >
                        <Icon className="w-4.5 h-4.5 " name="trash" />
                      </nav>}
                      <div className="ml-auto px-2 bg-n-6 rounded-lg base2 font-semibold text-n-4">
                        {item.chatsCount}
                      </div>
                    </>
                  )}

                </button>
              ))}
            </Disclosure.Panel>
          </Transition>
        </Disclosure>
        <button
          className={`group flex items-center w-full h-12 text-left base2 text-n-3/75 transition-colors hover:text-n-3 ${
            visible ? "justify-center px-3" : "px-5"
          }`}
          id="step8"
          onClick={() => setCreateChatListModal(true)}
        >
          <Icon
            className="fill-n-4 transition-colors group-hover:fill-n-3"
            name="plus-circle"
          />
          {!visible && <div className="ml-5">{lt('new_list')}</div>}
        </button>
      </div>
      <Modal
        className="md:!p-0"
        classWrap="max-w-[40rem] md:min-h-screen-ios md:rounded-none md:pb-8"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={createChatListModal}
        onClose={() => { setCreateChatListModal(false); setSelectedChatItem(null) }}
      >
        <AddChatList onCancel={() => { setCreateChatListModal(false); setSelectedChatItem(null) }} isFromEdit={selectedChatItem} />
      </Modal>
      <Modal
        className="md:!p-0"
        classWrap="md:min-h-screen-ios md:rounded-none dark:shadow-[inset_0_0_0_0.0625rem_#232627,0_2rem_4rem_-1rem_rgba(0,0,0,0.33)] dark:md:shadow-none"
        classButtonClose="hidden md:flex md:absolute md:top-6 md:left-6 dark:fill-n-1"
        classOverlay="md:bg-n-1"
        visible={modalStates?.chatListModal}
        onClose={handleChatModalClose}
      >
        <ChatListModal chatListItem={selectedChatItem} chatName={chatName} onClose={handleChatModalClose} />
      </Modal>
      {showDeleteModal && (
        <Modal
          className="md:!p-0 mt-30"
          classWrap="max-w-[40rem] md:min-h-screen-ios md:rounded-none md:pb-8"
          classButtonClose="hidden"
          visible={showDeleteModal}
          onClose={() => { setShowDeleteModal(false); setSelectedChatItem(null) }}
        >
          <Notify
            className="mt-20 md:px-10 absolute"
            iconDelete
          >
            <div className="ml-3 mr-6 h6 sm:text-sm md:mx-0 md:my-2">
              {"Are you sure?"}
            </div>

            <div className="flex justify-center">
              <button
                className="btn-stroke-light btn-medium md:min-w-[6rem]"
                onClick={() => { setShowDeleteModal(false); setSelectedChatItem(null) }}
              >
                {"Cancel"}
              </button>

              <button
                className="btn-blue btn-medium ml-3 md:min-w-[6rem]"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(destroyChatList(selectedChatItem.id))
                    .then((actionResponse: any) => {
                      if (actionResponse.type.endsWith("fulfilled")) {
                        console.log(
                          "Successfully deleted chat list item:",
                          actionResponse.payload
                        );
                        dispatch(getStoredChatList());
                      } else {
                        console.error(
                          "Failed to delete chat list item:",
                          actionResponse.error.message
                        );
                      }
                    })
                    .catch((error: any) => {
                      console.error("Unexpected error:", error);
                    });
                  // dispatch(resetAllChatsData(null));
                  dispatch(setChatListModal(false));
                  dispatch(getStoredChatList());
                  setShowDeleteModal(false);
                  setSelectedChatItem(null);
                  // toast.dismiss(t.id);
                }}
              >
                {"Yes"}
              </button>
            </div>
          </Notify>
        </Modal>
      )}
    </>
  );
};

export default ChatList;
