import { useTranslation } from "react-i18next";
import { ROUTES } from "./routes";

type MenuType = {
  title: string;
  icon: string;
  color: string;
  url: string;
  name?: string;
};

export const translate = (lt: (string: string) => {}) => {
  const navigation: MenuType[] = [
    {
      title: lt("start_aChat").toString(),
      icon: "chat",
      color: "#E68A1D",
      url: ROUTES.CHAT,
      // value: '0',
      // key: 'start_aChat'
    },
    {
      title: lt("start_debate").toString(),
      icon: "debate",
      color: "#52BA69",
      url: ROUTES.DEBATE,
      // value: '1',
      // key:'start_debate'
    }
  ];

  return navigation;
};
