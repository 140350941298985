import pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfMake from "html-to-pdfmake";
import { saveAs } from "file-saver";
import { AlignmentType, Document, Packer, Paragraph, TextRun } from 'docx';
//@ts-ignore
// import base64Img from 'base64-img';

//@ts-ignore
import MarkdownIt from 'markdown-it';
import { GARMONT_FONT, GARMONT_FONT_ITALIC } from "@/constants/user";
import { Base64 } from "js-base64";
import { BOLD_GARMOND_FONT_64, GARMOND_FONT_64, ITALIC_GARMOND_FONT_64 } from "./fonts";
// import fs from 'fs/promises';


pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs['garamond.ttf'] = GARMOND_FONT_64;
pdfMake.vfs['garamond-bold.ttf'] = BOLD_GARMOND_FONT_64;
pdfMake.vfs['garamond-italic.ttf'] = ITALIC_GARMOND_FONT_64;
// pdfMake.vfs['garamond-bold-italic.ttf'] = base64GaramondBoldItalic;

pdfMake.fonts = {
  Garamond: {
    normal: 'garamond.ttf',
    bold: 'garamond-bold.ttf',
    italics: 'garamond-italic.ttf',
    bolditalics: 'garamond-italic.ttf'
  }
};

const handleRemoveHtmlTags = (text: string) =>
  text
    .replace(/<[^>]*>/g, "")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/style="[^"]*"/g, "");

export const convertMarkdownToHTML = (markdown: any) => {
  const md = new MarkdownIt({
    html: true,
    breaks: true,
    linkify: true,
  });

  return md.render(markdown);
}

export const exportArrayAsPDF = async (chats: any[], isChatList: boolean, allLiveNotebooks: any = null) => {
  console.log({ allLiveNotebooks }, { chats });
  let htmlContent = "";
  if (isChatList) {

    for (const chat of chats) {
      for (let message of chat.messages) {
        htmlContent += convertMarkdownToHTML(message.content);
      }
    }

  } else {
    if (allLiveNotebooks) {
      for (const chat of chats) {
        const text = allLiveNotebooks?.data?.filter((item: any) => item.id == chat)[0].response;
        htmlContent += convertMarkdownToHTML(text);
      }
    }
    else {
      for (const item of chats) {
        htmlContent += convertMarkdownToHTML(item.response);
      }
    }
  }

  console.log({ htmlContent });

  // htmlContent = htmlContent.replace(
  //   /font-family:\s*__Inter_[^,;]*[,;]/g,
  //   "font-family: Garamond,"
  // );

  // htmlContent = htmlContent.replace(
  //   /font-family:\s*__Karla_[^,;]*[,;]/g,
  //   "font-family: Garamond,"
  // );
  // htmlContent = htmlContent.replace(
  //   /font-family:\s*ui-monospace[^,;]*[,;]/g,
  //   "font-family: Garamond,"
  // );

  // htmlContent = htmlContent.replace(
  //   /font-family:\s*var\(--!font-mont\)[^;]+display:\s*inline\s*!\s*important/g,
  //   "font-family: Garamond,"
  // );

  // htmlContent = htmlContent.replace(
  //   /font-family:\s*__ui-monospace_[^,;]*[,;]/g,
  //   "font-family: Garamond,"
  // );

  // htmlContent = htmlContent.replace(
  //   /font-family:\s*__var(--!font-mont)_[^,;]*[,;]/g,
  //   "font-family: Garamond,"
  // );

  // htmlContent = htmlContent.replace(
  //   /background-color[^;]+;/g,
  //   "background-color: #ffffff;"
  // );

  //   htmlContent = `<html>
  //     <head>
  //     <link href="https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet">
  //     </head>
  //     <body>
  //       <!-- Your HTML content here -->
  //       ${htmlContent}
  //     </body>
  //   </html>
  // `

  let pdfMakeContent: any = htmlToPdfMake(htmlContent);

  pdfMakeContent = pdfMakeContent?.filter((i: any) => i?.text != " ")
  console.log(pdfMakeContent, " after pdf make");

  const docDefinition: any = {
    content: [pdfMakeContent],
    defaultStyle: {
      font: 'Garamond', // Set 'EB Garamond' as the default font for the entire document
      fontSize: 13.5,
    },
  };
  await pdfMake.createPdf(docDefinition).download("lokichat-notebook.pdf");
};

export const createDocument = (records: any, allLiveNotebooks: any = null) => {
  console.log({ records });
  const htmlArr: any = [];
  if (allLiveNotebooks) {
    records.forEach((element: any) => {
      const text = allLiveNotebooks?.data?.filter((item: any) => item.id == element)[0].response;
      htmlArr.push(convertMarkdownToHTML(text));
    });
  }
  else {
    records.forEach((element: any) => {
      htmlArr.push(convertMarkdownToHTML(element.response));
    });
  }



  const htmlString = htmlArr.join('<br />');

  // var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  // var postHtml = "</body></html>";
  // var html = preHtml + htmlString;

  // Specify font-family: 'EB Garamond', serif in the style tag
  const styleTag = `
    <style>
      body {
        font-family: 'EB Garamond', serif;
      }
      p {
        font-size: 18px;
      }
      strong {
        font-size: 22px;
      }
    </style>
  `;

  var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title>" + styleTag + "</head><body>";
  var postHtml = "</body></html>";
  var html = preHtml + htmlString + postHtml;

  console.log(html, " html to export");


  var blob = new Blob(['\ufeff', html], {
    type: 'application/msword'
  });

  // Specify link url
  var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

  // Specify file name
  let filename = 'lokichat-notebook.doc';

  // Create download link element
  var downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  // Create a link to the file
  downloadLink.href = url;

  // Setting the file name
  downloadLink.download = filename;

  //triggering the function
  downloadLink.click();


  document.body.removeChild(downloadLink);

};
