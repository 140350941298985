import React from "react";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { useColorMode } from "@chakra-ui/color-mode";

const RatingStars = (props: any) => {
  const { setRating } = props;

  const { colorMode } = useColorMode();
    const dark = colorMode === "dark";
    const ratingChanged = (newRating: any) => {
      setRating(newRating);
      };
  return (
    <ReactStars
    count={5}
    // @ts-ignore
    onChange={ratingChanged}
    size={35}
    isHalf={false}
    classNames="RatingStars"
    emptyIcon={<i className="far fa-star"></i>}
    halfIcon={<i className="fa fa-star-half-alt"></i>}
    fullIcon={<i className="fa fa-star"></i>}
    activeColor="#E1A018"
    color= {dark? "#4F5456":"#DCDCDC"}
  />
  )
}

export default RatingStars