import React, { useState, useEffect } from 'react'
import { UploadIcons } from '../LandingPage/Icons'
import { IDocumentTranscriptionState } from 'interfaces/dt.interface';
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from 'react-i18next';

interface IProps {
  nextClick: Function;
  state: IDocumentTranscriptionState;
  setState: Function;
  transcriptionProgress: any,
  small?: boolean,
  disabled?: boolean,
}
const UploadDocument = ({ nextClick, state, setState, transcriptionProgress, small, disabled }: IProps) => {
  
  const { t } = useTranslation('translation');

  const fileTypes = ["mp3"];
  const [file, setFile] = useState<any>(null);
  useEffect(()=>{
    setState((ps:IDocumentTranscriptionState)=>({...ps,file:file}))
  },[file])

  const handleChange = (file: any) => {
    setFile(file);
  };
  
  return (
    <div>
      <div className=' bg-primary-1 p-3 rounded-[20px] flex justify-center items-center mb-5 mt-4'>
        <label className={`relative cursor-pointer border-white border-dashed border w-full ${small ? "h-[200px]" : "h-[288px]"} rounded-[20px] flex flex-col justify-center items-center`}>
           <FileUploader handleChange={handleChange} multiple={false} name="file" classes="drag_drop" types={fileTypes}>
            <div className='flex justify-center items-center flex-col w-full focus:!border-transparent focus-within:!outline-none cursor-pointer'>
              <span className='mb-6 block'><UploadIcons /></span>
              <h2 className='h5 text-white !font-mont mb-1'>{t('upload_audio_doc')}</h2>
              <p className={`text-white m-auto text-center  ${small ? "w-[420px] lg:w-[200px] md:w-[150px] sm:w-[100px]" : "w-[450px]"} md:w-[300px]  sm:w-[230px] truncate`}>{file ? `${file?.name}` : small ? t("audio_placeholder") : t('doc_dragDrop')}</p>
            </div>
           </FileUploader>
        </label>
      
      </div>
      <div className=' w-full flex flex-row justify-between'>
        <button className='btn btn-blue w-[49%] 2xl:text-[11px]' disabled={!state.file || disabled} onClick={() => { if (!transcriptionProgress) { nextClick('transcribe') } }}>{transcriptionProgress ? t('uploading') : t('transcribe')}</button>
        <button className="btn btn-blue w-[49%]" disabled={!state.file || disabled} onClick={() => { if (!transcriptionProgress) { nextClick('Character Recognition') } }}>
          <nav className="text-md 2xl:text-[11px] !font-bold inline-flex items-center space-x-1 whitespace-nowrap">
            {transcriptionProgress ? t('uploading') : t('transcribe')}
            <span className="text-[8px]  ml-1 !font-bold !font-mont ">
              (Character Recognition)
            </span>
          </nav>
        </button>
      </div>
    </div>
  )
}

export default UploadDocument