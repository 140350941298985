import * as yup from "yup";

export const chatListSchema = yup.object().shape({
  title: yup
    .string()
    .required("Chat name is required")
    .matches(/^[a-zA-Z0-9\s]+$/, "Name should only contain letters and numbers")
    .max(25, "Name should not exceed 25 characters"),
  color: yup.string().required("color name is required"),
});
