import { createAsyncThunk } from "@reduxjs/toolkit";
import { IUpdateImage } from "interfaces/imageGeneration.interface";
import { imageLibraryService } from "services/imageLibrary.service";

export const getAllLibraryImages = createAsyncThunk(
  "imageLibraryStore/getAllImageLibrary",
  async (params:{date?: string, page?:number}) => {
    try {
      const response =  await imageLibraryService.index(params);
      console.log('response?.data?.data', response?.data?.data)
      return response?.data?.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const updateLibraryImage = createAsyncThunk(
  "imageLibraryStore/updateLibraryImage",
  async (data: IUpdateImage) => {
    try {
      
      const response = await imageLibraryService.update(data);
      
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const deleteLibraryImage = createAsyncThunk(
  "imageLibraryStore/deleteLibraryImage",
  async (id: any) => {
    console.log('url', id.url)
    try {
      const response = await imageLibraryService.destroy(id.id, id.url);
      return response.data;
    } catch (error: any) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
)