import { HttpService } from './base.service';

class TranscriptionService extends HttpService {
    private readonly prefix: string = "transcriptions";

    store = (data: any, signal: any): Promise<any> => this.post(this.prefix + '/store', data, signal);
    show = (data: any): Promise<any> => this.post(this.prefix + '/show', data);
    getInprogressTranscriptions = (data: any): Promise<any> => this.get(this.prefix + '/in-progress-transcriptions', data);
    updateTranscriptionById = (data: any): Promise<any> => this.post(this.prefix + '/update', data);
    sharedTranscriptionLibrary = (params?: any): Promise<any> => this.get(this.prefix + `/sharedTranscriptionLibrary`,params);
    sharedTranscriptionLibraryPrevMonth = (params?: any): Promise<any> => this.get(this.prefix + `/sharedTranscriptionLibraryPrevMonth`,params);
    addSharedTranscription = (data: any): Promise<any> => this.put(this.prefix + '/addSharedTranscription', data);
    index = (params?: any): Promise<any> => this.get(this.prefix + `/index`,params);
    indexPrevMonth = (params?:any): Promise<any> => this.get(this.prefix + `/indexPreviousMonth`, params);
    update = (data: any): Promise<any> => this.put(this.prefix + '/update', data);
    destroy = (id: any): Promise<any> => this.delete(this.prefix + `/destroy/${id}`);
    removeSharedTranscription = (transcriptionId: any): Promise<any> => this.delete(this.prefix + `/removeSharedTranscription/${transcriptionId}`);
    getAllUsers = (params?: any): Promise<any> => this.get(this.prefix + `/getAllUsers`, params);
    getUserDetails = (params?: any): Promise<any> => this.get(this.prefix + `/getUser`, params);
    getById = (id: any): Promise<any> => this.get(this.prefix + `/get/${id}`);
}

export const transcriptionService = new TranscriptionService();