import React, { useState } from "react";
import Field from "@/components/Field";
import { updatePasswordSchema } from "lib/validations/updatePassword";
import { isEmpty, size } from "lodash";
import { updatePassword } from "store/actions/profile.action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Notify from "@/components/Notify";
import { resetPasswordError } from "store/slices/AuthSlice";
import { BiErrorCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";

type PasswordProps = {};

const Password = ({}: PasswordProps) => {
  const dispatch: any = useDispatch();
  const { t:lt } = useTranslation('auth');

  const userData = useSelector((state: any) => state.authStore);

  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const initialErrors = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [errors, setErrors] = useState(initialErrors);

  const handleUpdatePassword = async (event: any) => {
    event.preventDefault();

    const data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    let fieldErrors;
    setIsLoading(true);

    await updatePasswordSchema
      .validate(
        { oldPassword, newPassword, confirmPassword },
        { abortEarly: false }
      )
      .catch((error: any) => {
        fieldErrors = error.inner.reduce((acc: any, err: any) => {
          return {
            ...acc,
            [err.path]: err.message,
          };
        }, {});

        setErrors(fieldErrors);
      });

    if (isEmpty(fieldErrors)) {
      setErrors(initialErrors);
      dispatch(updatePassword(data))
        .unwrap()
        .then(() => {
          toast(
            <Notify iconCheck>
              <div className="ml-3 h6 sm:text-sm">
                {lt('password_updated')}
              </div>
            </Notify>
          );
        })
        .catch((err: any) => {
          toast(
            <Notify iconError>
              <div className="h6">{err?.message}</div>
            </Notify>
          );
          dispatch(resetPasswordError(null));
        });
    }
  };

  const isValidToChangePassword = () => {
    if (
      userData?.user?.loading ||
      size(oldPassword) < 7 ||
      size(newPassword) < 7 ||
      size(confirmPassword) < 7
    )
      return true;
    else return false;
  };

  return (
    <form className="" action="" onSubmit={() => console.log(lt('submit'))}>
      <div className="mb-8 h4 md:mb-6 !font-mont !font-extrabold">{lt('password')}</div>
      <div className="mb-4">
        <Field
          className=""
          label={lt('current_password')}
          placeholder={lt('current_password')}
          type="password"
          icon="lock"
          value={oldPassword}
          onChange={(e: any) => {setOldPassword(e.target.value); setErrors(initialErrors)}}
        />
        {errors?.oldPassword && (
          <div className="text-red-500 text-xs mt-1 flex items-center gap-1">
            <BiErrorCircle className="text-base" /> {errors.oldPassword}
          </div>
        )}
      </div>
      <div className="mb-6">
        <Field
          className=""
          label={lt('new_password')}
          placeholder={lt('new_password')}
          note={lt('password_valid')}
          type="password"
          icon="lock"
          value={newPassword}
          onChange={(e: any) => {setNewPassword(e.target.value); setErrors(initialErrors)}}
          required
        />
        {errors?.newPassword && (
          <div className="text-red-500 text-xs mt-1 flex items-center gap-1">
            <BiErrorCircle className="text-base" /> {errors.newPassword}
          </div>
        )}
      </div>
      <div className="mb-6">
        <Field
          className=""
          label={lt('confirm_password')}
          placeholder={lt('confirm_password')}
          note={lt('password_valid')}
          type="password"
          icon="lock"
          value={confirmPassword}
          onChange={(e: any) => {setConfirmPassword(e.target.value); setErrors(initialErrors)}}
          required
        />
        {errors?.confirmPassword && (
          <div className="text-red-500 text-xs mt-1 flex items-center gap-1">
            <BiErrorCircle className="text-base" /> {errors.confirmPassword}
          </div>
        )}
      </div>
      <button
        onClick={handleUpdatePassword}
        disabled={isValidToChangePassword()}
        className="btn-blue w-full"
      >
        {lt('change_password')}
      </button>
    </form>
  );
};

export default Password;
